export function ErrorMessageActive({ className }: { className?: string }) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13Z"
        fill="#130E46"
      />
      <path
        d="M1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13Z"
        stroke="#F4F4F4"
      />
      <path
        d="M13.25 6.5C11.593 6.50182 10.0043 7.16088 8.83259 8.33259C7.66088 9.5043 7.00182 11.093 7 12.75V18.0206C7.00033 18.2803 7.10362 18.5292 7.28722 18.7128C7.47081 18.8964 7.71973 18.9997 7.97937 19H13.25C14.9076 19 16.4973 18.3415 17.6694 17.1694C18.8415 15.9973 19.5 14.4076 19.5 12.75C19.5 11.0924 18.8415 9.50269 17.6694 8.33058C16.4973 7.15848 14.9076 6.5 13.25 6.5ZM10.5 13.75C10.3517 13.75 10.2067 13.706 10.0833 13.6236C9.95999 13.5412 9.86386 13.4241 9.80709 13.287C9.75032 13.15 9.73547 12.9992 9.76441 12.8537C9.79335 12.7082 9.86478 12.5746 9.96967 12.4697C10.0746 12.3648 10.2082 12.2933 10.3537 12.2644C10.4992 12.2355 10.65 12.2503 10.787 12.3071C10.9241 12.3639 11.0412 12.46 11.1236 12.5833C11.206 12.7067 11.25 12.8517 11.25 13C11.25 13.1989 11.171 13.3897 11.0303 13.5303C10.8897 13.671 10.6989 13.75 10.5 13.75ZM13.25 13.75C13.1017 13.75 12.9567 13.706 12.8333 13.6236C12.71 13.5412 12.6139 13.4241 12.5571 13.287C12.5003 13.15 12.4855 12.9992 12.5144 12.8537C12.5433 12.7082 12.6148 12.5746 12.7197 12.4697C12.8246 12.3648 12.9582 12.2933 13.1037 12.2644C13.2492 12.2355 13.4 12.2503 13.537 12.3071C13.6741 12.3639 13.7912 12.46 13.8736 12.5833C13.956 12.7067 14 12.8517 14 13C14 13.1989 13.921 13.3897 13.7803 13.5303C13.6397 13.671 13.4489 13.75 13.25 13.75ZM16 13.75C15.8517 13.75 15.7067 13.706 15.5833 13.6236C15.46 13.5412 15.3639 13.4241 15.3071 13.287C15.2503 13.15 15.2355 12.9992 15.2644 12.8537C15.2934 12.7082 15.3648 12.5746 15.4697 12.4697C15.5746 12.3648 15.7082 12.2933 15.8537 12.2644C15.9992 12.2355 16.15 12.2503 16.287 12.3071C16.4241 12.3639 16.5412 12.46 16.6236 12.5833C16.706 12.7067 16.75 12.8517 16.75 13C16.75 13.1989 16.671 13.3897 16.5303 13.5303C16.3897 13.671 16.1989 13.75 16 13.75Z"
        fill="white"
      />
    </svg>
  );
}
