import React from 'react';
import Text from './Text';
import Toggle from './Toggle';
import Dropdown from './Dropdown';
// import Password from './Password';
// import Search from './Search';
import './styles.css';
import TextArea from './TextArea';
import DropdownCheckbox from './DropdownCheckbox';

interface InputProps {
  children?: React.ReactNode;
}

const Input = ({ children }: InputProps) => {
  const subComponentList = Object.keys(Input);

  const subComponents = subComponentList.map((key) => {
    return React.Children.map(children, (child: any) => (child?.type.name === key ? child : null));
  });

  return <>{subComponents.map((component) => component)}</>;
};

Input.Text = Text;
Input.Toggle = Toggle;
Input.Dropdown = Dropdown;
Input.DropdownCheckbox = DropdownCheckbox;
Input.TextArea = TextArea;
// Input.Password = Password;
// Input.Search = Search;

export default Input;
