import { useTheme } from 'context/theme';
import { useState } from 'react';
import { cn } from 'utils/className';

type IconButtonProps = {
  /**
   * The icon to display in the button.
   */
  icon: React.ReactNode;

  /**
   * The function to call when the button is clicked.
   */
  onClick?: () => void;

  /**
   * Whether the button is disabled.
   */
  disabled?: boolean;

  /**
   * Additional classes to apply to the button.
   */
  className?: string;

  /**
   * The size of the button.
   */
  size?: 'xs' | 'sm' | 'lg';

  /**
   * Color behind the button. Used to determine the color of the button and the border.
   */
  backgroundColor?: 'light' | 'dark';
};

export function IconButton({ icon, onClick, disabled, className, backgroundColor = 'light' }: IconButtonProps) {
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState(false);
  return (
    <button
      className={cn(
        `h-[40px] w-[40px] min-w-[40px] rounded-full p-2 transition-all`,
        className,
        disabled && 'opacity-50',
        backgroundColor === 'light' ? 'border' : 'hover:border',
      )}
      style={{
        borderColor: backgroundColor === 'light' ? theme.border.border : theme.border.subtle,
        backgroundColor: isHovering ? theme.background.inverse : theme.background.primary,
        color: isHovering ? theme.label.inverse : theme.label.primary,
      }}
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}>
      {icon}
    </button>
  );
}
