import { cn } from 'utils/className';

export function Users({ className, fill }: { className?: string; fill?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
      fill={fill}
      className={cn('h-[29px] w-[29px] p-1', className)}>
      <path
        d="M18.9426 17.3907C17.6933 15.2308 15.768 13.682 13.5212 12.9478C14.6326 12.2862 15.4961 11.2781 15.979 10.0782C16.462 8.87837 16.5377 7.55314 16.1946 6.30607C15.8516 5.05899 15.1086 3.95902 14.0798 3.17507C13.0511 2.39113 11.7934 1.96655 10.5 1.96655C9.20658 1.96655 7.94893 2.39113 6.92017 3.17507C5.89141 3.95902 5.14843 5.05899 4.80533 6.30607C4.46223 7.55314 4.53798 8.87837 5.02095 10.0782C5.50392 11.2781 6.3674 12.2862 7.47878 12.9478C5.23195 13.6812 3.30667 15.23 2.05734 17.3907C2.01152 17.4654 1.98113 17.5485 1.96796 17.6351C1.95479 17.7218 1.95911 17.8102 1.98066 17.8951C2.00221 17.9801 2.04055 18.0598 2.09342 18.1297C2.1463 18.1996 2.21264 18.2582 2.28852 18.302C2.36441 18.3458 2.4483 18.374 2.53526 18.3849C2.62221 18.3958 2.71047 18.3892 2.79482 18.3654C2.87916 18.3416 2.95789 18.3012 3.02636 18.2465C3.09483 18.1918 3.15165 18.1239 3.19347 18.0469C4.73894 15.376 7.47058 13.7813 10.5 13.7813C13.5294 13.7813 16.261 15.376 17.8065 18.0469C17.8483 18.1239 17.9052 18.1918 17.9736 18.2465C18.0421 18.3012 18.1208 18.3416 18.2052 18.3654C18.2895 18.3892 18.3778 18.3958 18.4647 18.3849C18.5517 18.374 18.6356 18.3458 18.7115 18.302C18.7873 18.2582 18.8537 18.1996 18.9066 18.1297C18.9594 18.0598 18.9978 17.9801 19.0193 17.8951C19.0409 17.8102 19.0452 17.7218 19.032 17.6351C19.0189 17.5485 18.9885 17.4654 18.9426 17.3907ZM5.90624 7.87503C5.90624 6.96648 6.17566 6.07832 6.68043 5.32288C7.1852 4.56745 7.90264 3.97865 8.74204 3.63096C9.58144 3.28327 10.5051 3.1923 11.3962 3.36955C12.2873 3.5468 13.1058 3.98431 13.7483 4.62676C14.3907 5.26921 14.8282 6.08774 15.0055 6.97884C15.1827 7.86994 15.0918 8.79359 14.7441 9.63299C14.3964 10.4724 13.8076 11.1898 13.0521 11.6946C12.2967 12.1994 11.4086 12.4688 10.5 12.4688C9.28205 12.4675 8.11437 11.9831 7.25316 11.1219C6.39195 10.2607 5.90755 9.09297 5.90624 7.87503Z"
        fill={fill}
        stroke="none"
        fillOpacity="1"
      />
    </svg>
  );
}
