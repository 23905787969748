import {
  FloatingPortal,
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
} from '@floating-ui/react';
import { CaretLeft } from 'assets/svgcomponents/CaretLeft';
import { Chip } from 'components/chips/Chip';
import { toast } from 'components/toast/Toast';
import { useTheme } from 'context/theme';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { cn } from 'utils/className';
import { Checkbox } from './Checkbox';
import { InputLabel } from './InputLabel';

export type Option = {
  value: string;
  label: string;
  description?: string;
};

type BaseSelectProps = {
  placeholder?: string;
  label?: string;
  description?: string;
  disabled?: boolean;
  search?: boolean;
  showValueCount?: boolean;
  required?: boolean;
  error?: string;
};

type SingleSelectProps = {
  options: Option[];
  value?: Option;
  onSelect: (option: Option) => void;
  multiselect?: false;
  defaultValue?: Option['value'];
};

type MultiSelectProps = {
  options?: Option[];
  value?: Option[];
  onSelect: (options: Option[]) => void;
  multiselect: true;
  defaultValue?: Option['value'][];
};

type CreateEnabledProps = {
  enableCreate: true;
  onCreate: (value: string) => void;
  createValidation?: (value: string) => true | string;
};

type CreateDisabledProps = {
  enableCreate?: false;
  onCreate?: never;
  createValidation?: never;
};

type ComboboxProps = BaseSelectProps &
  (SingleSelectProps | MultiSelectProps) &
  (CreateEnabledProps | CreateDisabledProps);

export function Combobox({
  label,
  options,
  description,
  required,
  disabled,
  onSelect,
  placeholder,
  value,
  error,
  multiselect = false,
  showValueCount = false,
  enableCreate = false,
  createValidation,
  onCreate,
  defaultValue,
}: ComboboxProps) {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options || []);

  useEffect(() => {
    if (options) {
      setFilteredOptions(options.filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase())));
    }
  }, [searchValue, options]);

  useEffect(() => {
    if (defaultValue && options) {
      if (multiselect) {
        const defaultValues = options.filter((option) => defaultValue.includes(option.value));
        (onSelect as (options: Option[]) => void)(defaultValues);
      } else {
        const defaultOption = options.find((option) => option.value === defaultValue);
        (onSelect as (option: Option) => void)(defaultOption as Option);
      }
    }
  }, []);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(8),
      size({
        apply({ availableHeight, elements, rects }) {
          const padding = 20;
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight - padding}px`,
          });

          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
    ],
  });

  const dismiss = useDismiss(context);

  const listRef = useRef<any>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
  });

  const toggleSelectAll = () => {
    if (options) {
      if ((value as Option[]).length === options.length) {
        (onSelect as (options: Option[]) => void)([]);
      } else {
        (onSelect as (options: Option[]) => void)(options);
      }
    }
    setSearchValue('');
    setIsOpen(false);
  };

  const handleSelect = (option: Option) => {
    if (multiselect) {
      let newValues = (value as Option[]) || [];

      if (newValues.some((v) => v.value === option.value)) {
        newValues = newValues.filter((v) => v.value !== option.value);
      } else {
        newValues = [...newValues, option];
      }
      (onSelect as (options: Option[]) => void)(newValues);
    } else {
      (onSelect as (option: Option) => void)(option);
      setIsOpen(false);
    }

    setSearchValue('');
  };

  const handleCreate = (showToast = true) => {
    if (
      searchValue.length === 0 ||
      (multiselect && value && (value as Option[])?.some((v) => v.value === searchValue)) ||
      (!multiselect && value && (value as Option)?.value === searchValue)
    ) {
      return;
    }

    const validation = createValidation ? createValidation(searchValue) : true;
    const validationError = typeof validation === 'string' ? validation : '';

    if (validation === true && onCreate) {
      onCreate(searchValue);
      handleSelect({ value: searchValue, label: searchValue });
    } else {
      showToast && toast.error({ description: validationError });
    }
  };

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, listNavigation]);

  const ValueLabel = ({ value }: { value: string }) => {
    return <Chip value={value} type="overlay" size="large" />;
  };

  return (
    <div className="relative">
      <div className={cn('flex select-none flex-col space-y-1', disabled && 'opacity-50')}>
        <InputLabel label={label} description={description} required={required} />
        <div
          id="control"
          className={cn(
            'relative flex min-h-[56px] flex-wrap items-center rounded-small border-[1px]',
            'outline outline-[3px] outline-offset-[-3px] focus-within:outline-violet-300',
          )}
          style={{
            borderColor: theme.label.primary,
            outlineColor: isOpen ? theme.base.accent : 'transparent',
            transition: 'all 0.3s',
            backgroundColor: theme.background.primary,
            ...(error && { borderColor: theme.border.critical, backgroundColor: theme.background.critical }),
          }}
          ref={refs.setReference}
          {...getReferenceProps()}
          onClick={() => {
            setIsOpen(!isOpen);
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}>
          <div className="thin-scrollbar relative flex max-h-[100px] w-full flex-wrap items-center gap-2 overflow-y-auto px-4 py-2">
            {multiselect &&
              Array.isArray(value) &&
              value.length !== 0 &&
              (!showValueCount ? (
                value.map((current) => (
                  <div
                    key={current.value}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelect(current);
                    }}>
                    <ValueLabel value={current.label} />
                  </div>
                ))
              ) : (
                <ValueLabel value={`${value.length} valgt`} />
              ))}
            {!multiselect && value && 'label' in value && <ValueLabel value={value.label} />}
            {(multiselect || !value) && (
              <input
                className="h-full w-fit flex-1 bg-transparent outline-none placeholder:text-[#100C3AB2]"
                ref={inputRef}
                placeholder={placeholder}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  setIsOpen(true);
                }}
                onBlur={() => {
                  handleCreate(false);
                }}
                style={{ width: `${searchValue.length}ch` }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (searchValue.length === 0) {
                      return;
                    }

                    if (filteredOptions.length === 1) {
                      const option = filteredOptions[0];
                      handleSelect(option);
                      setSearchValue('');
                      setIsOpen(false);
                    }

                    if (enableCreate && onCreate) {
                      handleCreate(true);
                    }
                  }

                  if (e.key === 'Backspace' && searchValue === '') {
                    if (multiselect && value && (value as Option[]).length > 0 && !showValueCount) {
                      const newValues = (value as Option[]).slice(0, -1);
                      (onSelect as (options: Option[]) => void)(newValues);
                    } else if (!multiselect && value) {
                      onSelect(null as any);
                    }
                  }
                }}
              />
            )}
            {options && options.length > 0 && (
              <CaretLeft
                className={cn(
                  'absolute right-4 h-[18px] w-[18px] transition-transform',
                  isOpen ? '-rotate-[270deg]' : '-rotate-90',
                )}
              />
            )}
          </div>
        </div>
      </div>

      <FloatingPortal preserveTabOrder>
        <AnimatePresence mode="wait">
          {isOpen && options && options.length > 0 && (
            <motion.ul
              id="dropdown"
              key="dropdown"
              initial={{ height: '0' }}
              animate={{ height: 'auto' }}
              exit={{ height: '0' }}
              transition={{ duration: 0.2, ease: 'easeOut' }}
              onAnimationStart={() => setIsAnimating(true)}
              onAnimationComplete={() => setIsAnimating(false)}
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
                backgroundColor: theme.background.secondary,
                borderColor: theme.border.border,
                overflow: isAnimating ? 'hidden' : 'auto',
              }}
              className={cn(
                'z-[999] box-border w-full rounded-small',
                'border-offset-[-1px] border-[1px]',
                'scrollbar-thin scrollbar-track-transparent scrollbar-thumb-v2-purple-400/70 scrollbar-thumb-rounded-md',
              )}
              {...getFloatingProps()}>
              <li className="centered w-full px-4 py-1" />
              {multiselect && options && options.length > 1 && searchValue === '' && (
                <>
                  <li className="flex h-[40px] cursor-pointer select-none items-center p-4" onClick={toggleSelectAll}>
                    <Checkbox checked={(value as Option[]).length === options.length} />
                    <p className="inputtext pl-2 font-bold" style={{ color: theme.label.primary }}>
                      {`${(value as Option[]).length === options.length ? 'Fjern' : 'Velg'} alle`}
                    </p>
                  </li>
                  <li className="centered w-full px-4 py-2">
                    <div className="h-[1px] w-full opacity-70" style={{ backgroundColor: theme.border.border }} />
                  </li>
                </>
              )}
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option, index) => (
                  <li
                    key={option.value}
                    onClick={() => handleSelect(option)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSelect(option);
                        setSearchValue('');
                      }
                    }}
                    tabIndex={activeIndex === index ? 0 : -1}
                    ref={(node) => {
                      listRef.current[index] = node;
                    }}
                    className={cn(
                      'flex h-[40px] cursor-pointer items-center p-4 transition-colors duration-100 hover:bg-violet-100',
                      (value as Option)?.value === option.value && 'bg-violet-200',
                    )}
                    style={{ borderColor: theme.base.accent }}>
                    {multiselect && <Checkbox checked={(value as Option[]).some((v) => v.value === option.value)} />}
                    <p className={cn('inputtext', multiselect && 'pl-2')} style={{ color: theme.label.primary }}>
                      {option.label}
                    </p>
                  </li>
                ))
              ) : (
                <p className={cn('inputtext text-center')} style={{ color: theme.label.primary }}>
                  {enableCreate ? 'Trykk enter for å opprette' : 'Ingen resultater'}
                </p>
              )}
              <li className="centered w-full px-4 py-1" />
            </motion.ul>
          )}
        </AnimatePresence>
      </FloatingPortal>
      {error && (
        <p className="caption absolute bottom-0 left-0 translate-y-[110%]" style={{ color: theme.label.critical }}>
          😬 {error}
        </p>
      )}
    </div>
  );
}
