import { Invitation } from '__generated__/UtleggsappenApi';
import { api } from 'api/api';
import { useUser } from 'api/users/useUser';
import { FullscreenLoading, LoadingIndicator } from 'components/layout/LoadingIndicator';
import { toast } from 'components/toast/Toast';
import { useTheme } from 'context/theme';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function InviteJoin() {
  const [searchParams, setSearchParams] = useSearchParams();

  const inviteToken = searchParams.get('t');
  const [invite, setInvite] = useState<Invitation | null>(null);
  const [inviteIsInvalid, setInviteIsInvalid] = useState(false);
  const [loadingInvite, setLoadingInvite] = useState(true);
  const [accepted, setAccepted] = useState(false);

  const theme = useTheme();

  const { data: user, mutate: refreshUser, isLoading } = useUser(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (inviteToken) {
      api.invitations
        .getInvitationByToken({ token: inviteToken })
        .then((res) => {
          setInvite(res.data);
        })
        .catch((error) => {
          toast.error({ description: 'Det oppsto en feil. Invitasjonen er ikke gyldig.' });
        })
        .finally(() => {
          setLoadingInvite(false);
        });
    }
  }, []);

  useEffect(() => {
    if (!user || isLoading || accepted) return;
    if (invite) {
      if (
        (invite?.expiresAt && new Date(invite.expiresAt) < new Date()) ||
        (invite?.revokedAt && new Date(invite.revokedAt) < new Date()) ||
        (invite?.redeemedCount && invite.redeemedCount >= invite.invitedCount)
      ) {
        setInviteIsInvalid(true);
      } else {
        handleAccept();
      }
    }
  }, [invite, user, isLoading]);

  const handleAccept = async () => {
    api.invitations
      .acceptInviteByToken({ token: invite!.token })
      .then(async () => {
        setAccepted(true);
        setTimeout(async () => {
          await refreshUser();
          navigate(`/${invite?.organization!.handle}`);
        }, 2000);
      })
      .catch((error) => {
        setInviteIsInvalid(true);
        toast.error({ description: 'Det oppsto en feil. Ta kontakt med support.' });
      });
  };

  if (loadingInvite)
    return (
      <div className="h-screen w-full">
        <FullscreenLoading />
      </div>
    );

  if (inviteIsInvalid) {
    return (
      <div className="centered h-screen w-full">
        <div className="centered flex-col space-y-8">
          <h1 className="bigheading text-center" style={{ color: theme.label.primary }}>
            Utleggsappen
          </h1>
          <p className="smalltext">Invitasjonen er ikke lenger gyldig.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center space-y-6">
      <div>
        <LoadingIndicator size="lrg" backgroundStyle="accentstrong" />
      </div>
      <p className="smalltext">Godtar invitasjon...</p>
    </div>
  );
}
