export function SuchEmpty() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
      <circle cx="100" cy="100" r="100" fill="#100C3A" />
      <mask
        id="mask0_1151_6891"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="200"
        height="200">
        <circle cx="100" cy="100" r="100" fill="#130E46" />
      </mask>
      <g mask="url(#mask0_1151_6891)">
        <path d="M60 -28H134.5L113.998 132H85.9981L60 -28Z" fill="#F5BB54" />
      </g>
      <path
        d="M157.688 80.3809C157.842 80.3809 157.92 80.4583 157.92 80.6133V84.6602C157.92 84.8151 157.842 84.8926 157.688 84.8926H154.201C154.046 84.8926 153.969 84.9746 153.969 85.1387V99.7676C153.969 99.9225 153.887 100 153.723 100H149.211C149.047 100 148.965 99.9225 148.965 99.7676V85.1387C148.965 84.9746 148.887 84.8926 148.732 84.8926H145.219C145.064 84.8926 144.986 84.8151 144.986 84.6602V80.6133C144.986 80.4583 145.064 80.3809 145.219 80.3809H157.688Z"
        fill="white"
      />
      <path
        d="M145.998 99.7266C146.007 99.7995 145.984 99.8633 145.93 99.918C145.884 99.9727 145.829 100 145.766 100H141.404C141.268 100 141.19 99.9271 141.172 99.7812L140.256 89.8418C140.247 89.7142 140.174 89.6458 140.037 89.6367C139.909 89.6185 139.827 89.6732 139.791 89.8008L136.578 99.8359C136.551 99.9453 136.478 100 136.359 100H133.379C133.26 100 133.187 99.9453 133.16 99.8359L129.947 89.8008C129.911 89.6732 129.824 89.6185 129.687 89.6367C129.56 89.6458 129.491 89.7142 129.482 89.8418L128.566 99.7812C128.548 99.9271 128.471 100 128.334 100H123.973C123.909 100 123.85 99.9727 123.795 99.918C123.749 99.8633 123.731 99.7995 123.74 99.7266L126.201 80.5723C126.219 80.4355 126.297 80.3672 126.434 80.3672H131.465C131.592 80.3672 131.67 80.4219 131.697 80.5312L134.678 90.252C134.714 90.3613 134.787 90.416 134.896 90.416C135.015 90.416 135.092 90.3613 135.129 90.252L138.014 80.5312C138.041 80.4219 138.118 80.3672 138.246 80.3672H143.305C143.441 80.3672 143.519 80.4355 143.537 80.5723L145.998 99.7266Z"
        fill="white"
      />
      <path
        d="M109.863 80.6953C111.103 80.1484 112.406 79.875 113.773 79.875C115.141 79.875 116.444 80.1484 117.684 80.6953C118.923 81.2422 119.994 81.9759 120.896 82.8965C121.799 83.8171 122.514 84.9154 123.043 86.1914C123.581 87.4583 123.85 88.7891 123.85 90.1836C123.85 91.5781 123.581 92.9134 123.043 94.1895C122.514 95.4564 121.799 96.5501 120.896 97.4707C119.994 98.3913 118.923 99.125 117.684 99.6719C116.444 100.219 115.141 100.492 113.773 100.492C112.406 100.492 111.103 100.219 109.863 99.6719C108.624 99.125 107.553 98.3913 106.65 97.4707C105.748 96.5501 105.028 95.4564 104.49 94.1895C103.962 92.9134 103.697 91.5781 103.697 90.1836C103.697 88.7891 103.962 87.4583 104.49 86.1914C105.028 84.9154 105.748 83.8171 106.65 82.8965C107.553 81.9759 108.624 81.2422 109.863 80.6953ZM109.877 94.1621C110.952 95.265 112.251 95.8164 113.773 95.8164C115.296 95.8164 116.594 95.265 117.67 94.1621C118.745 93.0592 119.283 91.7331 119.283 90.1836C119.283 88.6341 118.745 87.3079 117.67 86.2051C116.594 85.1022 115.296 84.5508 113.773 84.5508C112.251 84.5508 110.952 85.1022 109.877 86.2051C108.801 87.3079 108.264 88.6341 108.264 90.1836C108.264 91.7331 108.801 93.0592 109.877 94.1621Z"
        fill="white"
      />
      <path
        d="M103.82 80.3809C103.975 80.3809 104.053 80.4583 104.053 80.6133V84.6602C104.053 84.8151 103.975 84.8926 103.82 84.8926H100.334C100.179 84.8926 100.102 84.9746 100.102 85.1387V99.7676C100.102 99.9225 100.02 100 99.8555 100H95.3438C95.1797 100 95.0977 99.9225 95.0977 99.7676V85.1387C95.0977 84.9746 95.0202 84.8926 94.8652 84.8926H91.3516C91.1966 84.8926 91.1191 84.8151 91.1191 84.6602V80.6133C91.1191 80.4583 91.1966 80.3809 91.3516 80.3809H103.82Z"
        fill="white"
      />
      <path
        d="M83.4766 80.3809C83.6315 80.3809 83.709 80.4583 83.709 80.6133V84.6602C83.709 84.8151 83.6315 84.8926 83.4766 84.8926H79.9902C79.8353 84.8926 79.7578 84.9746 79.7578 85.1387V99.7676C79.7578 99.9225 79.6758 100 79.5117 100H75C74.8359 100 74.7539 99.9225 74.7539 99.7676V85.1387C74.7539 84.9746 74.6764 84.8926 74.5215 84.8926H71.0078C70.8529 84.8926 70.7754 84.8151 70.7754 84.6602V80.6133C70.7754 80.4583 70.8529 80.3809 71.0078 80.3809H83.4766Z"
        fill="white"
      />
      <path
        d="M70.543 95.543C70.6159 95.543 70.6751 95.5703 70.7207 95.625C70.7663 95.6797 70.7845 95.7435 70.7754 95.8164L70.3789 99.8359C70.3607 99.9818 70.2786 100.055 70.1328 100.055H59.4688C59.3958 100.055 59.332 100.027 59.2773 99.9727C59.2227 99.918 59.1953 99.8542 59.1953 99.7812V80.6953C59.1953 80.6224 59.2181 80.5632 59.2637 80.5176C59.3184 80.472 59.3776 80.4492 59.4414 80.4492H70.1328C70.2786 80.4492 70.3607 80.5221 70.3789 80.668L70.7754 84.6738C70.7845 84.7467 70.7617 84.8151 70.707 84.8789C70.6615 84.9336 70.6022 84.9609 70.5293 84.9609H64.4316C64.3587 84.9609 64.2995 84.9837 64.2539 85.0293C64.2083 85.0749 64.1855 85.1341 64.1855 85.207V87.5996C64.1855 87.6634 64.2083 87.7227 64.2539 87.7773C64.2995 87.8229 64.3587 87.8457 64.4316 87.8457H68.8613C69.0254 87.8457 69.1074 87.9232 69.1074 88.0781V92.0977C69.1074 92.1706 69.0846 92.2298 69.0391 92.2754C68.9935 92.321 68.9342 92.3438 68.8613 92.3438H64.4316C64.3587 92.3438 64.2995 92.3665 64.2539 92.4121C64.2083 92.4577 64.1855 92.5169 64.1855 92.5898V95.2969C64.1855 95.3698 64.2083 95.429 64.2539 95.4746C64.2995 95.5202 64.3587 95.543 64.4316 95.543H70.543Z"
        fill="white"
      />
      <path
        d="M52.6055 81.2148C54.3737 81.9987 55.7865 83.2109 56.8438 84.8516C57.901 86.4831 58.4297 88.2786 58.4297 90.2383C58.4297 92.1523 57.9193 93.9206 56.8984 95.543C55.9232 97.1016 54.6061 98.2865 52.9473 99.0977C52.4824 99.3255 52.1133 99.4896 51.8398 99.5898C51.5755 99.6901 51.1107 99.7949 50.4453 99.9043C49.7891 100.005 49.0007 100.055 48.0801 100.055H42.2148C42.151 100.055 42.0918 100.032 42.0371 99.9863C41.9915 99.9408 41.9688 99.8815 41.9688 99.8086V80.668C41.9688 80.5951 41.9915 80.5358 42.0371 80.4902C42.0918 80.4447 42.151 80.4219 42.2148 80.4219H47.9297C49.8529 80.4219 51.4115 80.6862 52.6055 81.2148ZM52.127 93.957C53.0384 93.0273 53.4941 91.7832 53.4941 90.2246C53.485 89.5228 53.3757 88.8893 53.166 88.3242C52.9655 87.7591 52.7103 87.2988 52.4004 86.9434C52.0905 86.5788 51.7168 86.2643 51.2793 86C50.8509 85.7357 50.4362 85.5397 50.0352 85.4121C49.6432 85.2754 49.224 85.1706 48.7773 85.0977C48.3398 85.0156 47.9889 84.9701 47.7246 84.9609C47.4603 84.9427 47.2142 84.9382 46.9863 84.9473C46.8314 84.9473 46.7539 85.0293 46.7539 85.1934V95.2285C46.7539 95.3014 46.7767 95.3652 46.8223 95.4199C46.877 95.4655 46.9362 95.4883 47 95.4883H47.2598C48.2988 95.4883 49.2559 95.3516 50.1309 95.0781C51.0059 94.8047 51.6712 94.431 52.127 93.957Z"
        fill="white"
      />
      <path
        d="M147.584 66.2188L150.414 72.6992C150.451 72.7721 150.441 72.8451 150.387 72.918C150.341 72.9909 150.277 73.0273 150.195 73.0273H145.287C145.196 73.0273 145.128 72.9818 145.082 72.8906L142.512 67.0117C142.466 66.9206 142.393 66.875 142.293 66.875H141.514C141.35 66.875 141.268 66.9525 141.268 67.1074V72.7266C141.268 72.8906 141.19 72.9727 141.035 72.9727H136.523C136.46 72.9727 136.4 72.9499 136.346 72.9043C136.3 72.8587 136.277 72.7995 136.277 72.7266V53.5996C136.277 53.4447 136.359 53.3672 136.523 53.3672H142.566C144.207 53.3672 145.456 53.4857 146.312 53.7227C147.178 53.9505 147.898 54.3105 148.473 54.8027C149.193 55.4134 149.749 56.1745 150.141 57.0859C150.533 57.9883 150.729 58.9635 150.729 60.0117C150.729 61.0234 150.542 62.0033 150.168 62.9512C149.803 63.8991 149.316 64.6374 148.705 65.166C148.377 65.4486 148.035 65.6992 147.68 65.918C147.57 66 147.538 66.1003 147.584 66.2188ZM144.836 62.0762C145.574 61.748 145.943 61.0872 145.943 60.0938C145.943 59.1458 145.556 58.5033 144.781 58.166C144.344 57.9746 143.683 57.8789 142.799 57.8789H141.514C141.35 57.8789 141.268 57.9564 141.268 58.1113V62.1172C141.268 62.1901 141.29 62.2493 141.336 62.2949C141.382 62.3405 141.441 62.3633 141.514 62.3633H142.977C143.797 62.3633 144.417 62.2676 144.836 62.0762Z"
        fill="white"
      />
      <path
        d="M135.881 72.7539C135.908 72.8359 135.895 72.9134 135.84 72.9863C135.794 73.0501 135.73 73.082 135.648 73.082H130.809C130.663 73.082 130.567 73.0137 130.521 72.877L129.496 69.7734C129.469 69.6641 129.396 69.6094 129.277 69.6094H123.275C123.148 69.6094 123.07 69.6641 123.043 69.7734L122.004 72.9043C121.967 73.0228 121.89 73.082 121.771 73.082H116.891C116.809 73.082 116.745 73.0501 116.699 72.9863C116.654 72.9134 116.645 72.8359 116.672 72.7539L123.877 53.627C123.923 53.4902 124.018 53.4219 124.164 53.4219H128.402C128.53 53.4219 128.617 53.4811 128.662 53.5996L135.881 72.7539ZM127.787 65.5898C127.869 65.5898 127.933 65.5579 127.979 65.4941C128.033 65.4303 128.047 65.3574 128.02 65.2754L126.502 60.6953C126.465 60.5768 126.388 60.5176 126.27 60.5176C126.151 60.5176 126.074 60.5768 126.037 60.6953L124.52 65.2754C124.492 65.3483 124.501 65.4212 124.547 65.4941C124.602 65.5579 124.67 65.5898 124.752 65.5898H127.787Z"
        fill="white"
      />
      <path
        d="M118.723 53.3535C118.805 53.3535 118.868 53.39 118.914 53.4629C118.969 53.5267 118.982 53.5951 118.955 53.668L112.475 72.8633C112.447 72.9727 112.374 73.0273 112.256 73.0273H107.621C107.493 73.0273 107.416 72.9727 107.389 72.8633L100.908 53.668C100.881 53.5951 100.895 53.5267 100.949 53.4629C100.995 53.39 101.059 53.3535 101.141 53.3535H106.555C106.673 53.3535 106.746 53.4128 106.773 53.5312L109.699 64.7695C109.736 64.888 109.813 64.9473 109.932 64.9473C110.05 64.9473 110.128 64.888 110.164 64.7695L113.09 53.5312C113.117 53.4128 113.195 53.3535 113.322 53.3535H118.723Z"
        fill="white"
      />
      <path
        d="M90.0801 66.2188L92.9102 72.6992C92.9466 72.7721 92.9375 72.8451 92.8828 72.918C92.8372 72.9909 92.7734 73.0273 92.6914 73.0273H87.7832C87.6921 73.0273 87.6237 72.9818 87.5781 72.8906L85.0078 67.0117C84.9622 66.9206 84.8893 66.875 84.7891 66.875H84.0098C83.8457 66.875 83.7637 66.9525 83.7637 67.1074V72.7266C83.7637 72.8906 83.6862 72.9727 83.5312 72.9727H79.0195C78.9557 72.9727 78.8965 72.9499 78.8418 72.9043C78.7962 72.8587 78.7734 72.7995 78.7734 72.7266V53.5996C78.7734 53.4447 78.8555 53.3672 79.0195 53.3672H85.0625C86.7031 53.3672 87.9518 53.4857 88.8086 53.7227C89.6745 53.9505 90.3945 54.3105 90.9688 54.8027C91.6888 55.4134 92.2448 56.1745 92.6367 57.0859C93.0286 57.9883 93.2246 58.9635 93.2246 60.0117C93.2246 61.0234 93.0378 62.0033 92.6641 62.9512C92.2995 63.8991 91.8118 64.6374 91.2012 65.166C90.873 65.4486 90.5312 65.6992 90.1758 65.918C90.0664 66 90.0345 66.1003 90.0801 66.2188ZM87.332 62.0762C88.0703 61.748 88.4395 61.0872 88.4395 60.0938C88.4395 59.1458 88.0521 58.5033 87.2773 58.166C86.8398 57.9746 86.179 57.8789 85.2949 57.8789H84.0098C83.8457 57.8789 83.7637 57.9564 83.7637 58.1113V62.1172C83.7637 62.1901 83.7865 62.2493 83.832 62.2949C83.8776 62.3405 83.9368 62.3633 84.0098 62.3633H85.4727C86.293 62.3633 86.9128 62.2676 87.332 62.0762Z"
        fill="white"
      />
      <path
        d="M77.625 68.543C77.6979 68.543 77.7572 68.5703 77.8027 68.625C77.8483 68.6797 77.8665 68.7435 77.8574 68.8164L77.4609 72.8359C77.4427 72.9818 77.3607 73.0547 77.2148 73.0547H66.5508C66.4779 73.0547 66.4141 73.0273 66.3594 72.9727C66.3047 72.918 66.2773 72.8542 66.2773 72.7812V53.6953C66.2773 53.6224 66.3001 53.5632 66.3457 53.5176C66.4004 53.472 66.4596 53.4492 66.5234 53.4492H77.2148C77.3607 53.4492 77.4427 53.5221 77.4609 53.668L77.8574 57.6738C77.8665 57.7467 77.8438 57.8151 77.7891 57.8789C77.7435 57.9336 77.6842 57.9609 77.6113 57.9609H71.5137C71.4408 57.9609 71.3815 57.9837 71.3359 58.0293C71.2904 58.0749 71.2676 58.1341 71.2676 58.207V60.5996C71.2676 60.6634 71.2904 60.7227 71.3359 60.7773C71.3815 60.8229 71.4408 60.8457 71.5137 60.8457H75.9434C76.1074 60.8457 76.1895 60.9232 76.1895 61.0781V65.0977C76.1895 65.1706 76.1667 65.2298 76.1211 65.2754C76.0755 65.321 76.0163 65.3438 75.9434 65.3438H71.5137C71.4408 65.3438 71.3815 65.3665 71.3359 65.4121C71.2904 65.4577 71.2676 65.5169 71.2676 65.5898V68.2969C71.2676 68.3698 71.2904 68.429 71.3359 68.4746C71.3815 68.5202 71.4408 68.543 71.5137 68.543H77.625Z"
        fill="white"
      />
      <path
        d="M64.6367 53.4355C64.7005 53.4355 64.7552 53.4629 64.8008 53.5176C64.8555 53.5632 64.8828 53.6178 64.8828 53.6816V72.7949C64.8828 72.8587 64.8555 72.918 64.8008 72.9727C64.7552 73.0182 64.7005 73.041 64.6367 73.041H60.125C60.0612 73.041 60.002 73.0182 59.9473 72.9727C59.9017 72.918 59.8789 72.8587 59.8789 72.7949V65.6035C59.8789 65.5397 59.8516 65.485 59.7969 65.4395C59.7513 65.3848 59.6966 65.3574 59.6328 65.3574H54.041C53.877 65.3574 53.7949 65.4349 53.7949 65.5898V72.7949C53.7949 72.8587 53.7721 72.918 53.7266 72.9727C53.681 73.0182 53.6217 73.041 53.5488 73.041H49.0508C48.987 73.041 48.9277 73.0182 48.873 72.9727C48.8275 72.918 48.8047 72.8587 48.8047 72.7949V53.6816C48.8047 53.6178 48.8275 53.5632 48.873 53.5176C48.9277 53.4629 48.987 53.4355 49.0508 53.4355H53.5488C53.6217 53.4355 53.681 53.4629 53.7266 53.5176C53.7721 53.5632 53.7949 53.6178 53.7949 53.6816V60.627C53.7949 60.7819 53.877 60.8594 54.041 60.8594H59.6328C59.6966 60.8594 59.7513 60.8366 59.7969 60.791C59.8516 60.7454 59.8789 60.6862 59.8789 60.6133V53.6816C59.8789 53.6178 59.9017 53.5632 59.9473 53.5176C60.002 53.4629 60.0612 53.4355 60.125 53.4355H64.6367Z"
        fill="white"
      />
      <path
        d="M110.938 129.125H89.0625C88.2337 129.125 87.4388 129.454 86.8528 130.04C86.2667 130.626 85.9375 131.421 85.9375 132.25V141.104C85.9392 141.779 86.1583 142.437 86.5625 142.979L90.625 148.396V169.75C90.625 170.579 90.9542 171.374 91.5403 171.96C92.1263 172.546 92.9212 172.875 93.75 172.875H106.25C107.079 172.875 107.874 172.546 108.46 171.96C109.046 171.374 109.375 170.579 109.375 169.75V148.396L113.438 142.979C113.842 142.437 114.061 141.779 114.062 141.104V132.25C114.062 131.421 113.733 130.626 113.147 130.04C112.561 129.454 111.766 129.125 110.938 129.125ZM101.562 155.688C101.562 156.102 101.398 156.499 101.105 156.792C100.812 157.085 100.414 157.25 100 157.25C99.5856 157.25 99.1882 157.085 98.8951 156.792C98.6021 156.499 98.4375 156.102 98.4375 155.688V149.438C98.4375 149.023 98.6021 148.626 98.8951 148.333C99.1882 148.04 99.5856 147.875 100 147.875C100.414 147.875 100.812 148.04 101.105 148.333C101.398 148.626 101.562 149.023 101.562 149.438V155.688ZM89.0625 136.938V132.25H110.938V136.938H89.0625Z"
        fill="white"
      />
      <mask
        id="mask1_1151_6891"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="64"
        y="0"
        width="67"
        height="132">
        <path d="M64.5 0H131L113.998 132H85.9981L64.5 0Z" fill="#F5BB54" />
      </mask>
      <g mask="url(#mask1_1151_6891)">
        <path
          d="M145.998 99.7266C146.007 99.7995 145.984 99.8633 145.93 99.918C145.884 99.9727 145.829 100 145.766 100H141.404C141.268 100 141.19 99.9271 141.172 99.7812L140.256 89.8418C140.247 89.7142 140.174 89.6458 140.037 89.6367C139.909 89.6185 139.827 89.6732 139.791 89.8008L136.578 99.8359C136.551 99.9453 136.478 100 136.359 100H133.379C133.26 100 133.187 99.9453 133.16 99.8359L129.947 89.8008C129.911 89.6732 129.824 89.6185 129.687 89.6367C129.56 89.6458 129.491 89.7142 129.482 89.8418L128.566 99.7812C128.548 99.9271 128.471 100 128.334 100H123.973C123.909 100 123.85 99.9727 123.795 99.918C123.749 99.8633 123.731 99.7995 123.74 99.7266L126.201 80.5723C126.219 80.4355 126.297 80.3672 126.434 80.3672H131.465C131.592 80.3672 131.67 80.4219 131.697 80.5312L134.678 90.252C134.714 90.3613 134.787 90.416 134.896 90.416C135.015 90.416 135.092 90.3613 135.129 90.252L138.014 80.5312C138.041 80.4219 138.118 80.3672 138.246 80.3672H143.305C143.441 80.3672 143.519 80.4355 143.537 80.5723L145.998 99.7266Z"
          fill="#3627C2"
        />
        <path
          d="M109.863 80.6953C111.103 80.1484 112.406 79.875 113.773 79.875C115.141 79.875 116.444 80.1484 117.684 80.6953C118.923 81.2422 119.994 81.9759 120.896 82.8965C121.799 83.8171 122.514 84.9154 123.043 86.1914C123.581 87.4583 123.85 88.7891 123.85 90.1836C123.85 91.5781 123.581 92.9134 123.043 94.1895C122.514 95.4564 121.799 96.5501 120.896 97.4707C119.994 98.3913 118.923 99.125 117.684 99.6719C116.444 100.219 115.141 100.492 113.773 100.492C112.406 100.492 111.103 100.219 109.863 99.6719C108.624 99.125 107.553 98.3913 106.65 97.4707C105.748 96.5501 105.028 95.4564 104.49 94.1895C103.962 92.9134 103.697 91.5781 103.697 90.1836C103.697 88.7891 103.962 87.4583 104.49 86.1914C105.028 84.9154 105.748 83.8171 106.65 82.8965C107.553 81.9759 108.624 81.2422 109.863 80.6953ZM109.877 94.1621C110.952 95.265 112.251 95.8164 113.773 95.8164C115.296 95.8164 116.594 95.265 117.67 94.1621C118.745 93.0592 119.283 91.7331 119.283 90.1836C119.283 88.6341 118.745 87.3079 117.67 86.2051C116.594 85.1022 115.296 84.5508 113.773 84.5508C112.251 84.5508 110.952 85.1022 109.877 86.2051C108.801 87.3079 108.264 88.6341 108.264 90.1836C108.264 91.7331 108.801 93.0592 109.877 94.1621Z"
          fill="#3627C2"
        />
        <path
          d="M103.82 80.3809C103.975 80.3809 104.053 80.4583 104.053 80.6133V84.6602C104.053 84.8151 103.975 84.8926 103.82 84.8926H100.334C100.179 84.8926 100.102 84.9746 100.102 85.1387V99.7676C100.102 99.9225 100.02 100 99.8555 100H95.3438C95.1797 100 95.0977 99.9225 95.0977 99.7676V85.1387C95.0977 84.9746 95.0202 84.8926 94.8652 84.8926H91.3516C91.1966 84.8926 91.1191 84.8151 91.1191 84.6602V80.6133C91.1191 80.4583 91.1966 80.3809 91.3516 80.3809H103.82Z"
          fill="#3627C2"
        />
        <path
          d="M83.4766 80.3809C83.6315 80.3809 83.709 80.4583 83.709 80.6133V84.6602C83.709 84.8151 83.6315 84.8926 83.4766 84.8926H79.9902C79.8353 84.8926 79.7578 84.9746 79.7578 85.1387V99.7676C79.7578 99.9225 79.6758 100 79.5117 100H75C74.8359 100 74.7539 99.9225 74.7539 99.7676V85.1387C74.7539 84.9746 74.6764 84.8926 74.5215 84.8926H71.0078C70.8529 84.8926 70.7754 84.8151 70.7754 84.6602V80.6133C70.7754 80.4583 70.8529 80.3809 71.0078 80.3809H83.4766Z"
          fill="#3627C2"
        />
        <path
          d="M70.543 95.543C70.6159 95.543 70.6751 95.5703 70.7207 95.625C70.7663 95.6797 70.7845 95.7435 70.7754 95.8164L70.3789 99.8359C70.3607 99.9818 70.2786 100.055 70.1328 100.055H59.4688C59.3958 100.055 59.332 100.027 59.2773 99.9727C59.2227 99.918 59.1953 99.8542 59.1953 99.7812V80.6953C59.1953 80.6224 59.2181 80.5632 59.2637 80.5176C59.3184 80.472 59.3776 80.4492 59.4414 80.4492H70.1328C70.2786 80.4492 70.3607 80.5221 70.3789 80.668L70.7754 84.6738C70.7845 84.7467 70.7617 84.8151 70.707 84.8789C70.6615 84.9336 70.6022 84.9609 70.5293 84.9609H64.4316C64.3587 84.9609 64.2995 84.9837 64.2539 85.0293C64.2083 85.0749 64.1855 85.1341 64.1855 85.207V87.5996C64.1855 87.6634 64.2083 87.7227 64.2539 87.7773C64.2995 87.8229 64.3587 87.8457 64.4316 87.8457H68.8613C69.0254 87.8457 69.1074 87.9232 69.1074 88.0781V92.0977C69.1074 92.1706 69.0846 92.2298 69.0391 92.2754C68.9935 92.321 68.9342 92.3438 68.8613 92.3438H64.4316C64.3587 92.3438 64.2995 92.3665 64.2539 92.4121C64.2083 92.4577 64.1855 92.5169 64.1855 92.5898V95.2969C64.1855 95.3698 64.2083 95.429 64.2539 95.4746C64.2995 95.5202 64.3587 95.543 64.4316 95.543H70.543Z"
          fill="#3627C2"
        />
        <path
          d="M135.881 72.7539C135.908 72.8359 135.895 72.9134 135.84 72.9863C135.794 73.0501 135.73 73.082 135.648 73.082H130.809C130.663 73.082 130.567 73.0137 130.521 72.877L129.496 69.7734C129.469 69.6641 129.396 69.6094 129.277 69.6094H123.275C123.148 69.6094 123.07 69.6641 123.043 69.7734L122.004 72.9043C121.967 73.0228 121.89 73.082 121.771 73.082H116.891C116.809 73.082 116.745 73.0501 116.699 72.9863C116.654 72.9134 116.645 72.8359 116.672 72.7539L123.877 53.627C123.923 53.4902 124.018 53.4219 124.164 53.4219H128.402C128.53 53.4219 128.617 53.4811 128.662 53.5996L135.881 72.7539ZM127.787 65.5898C127.869 65.5898 127.933 65.5579 127.979 65.4941C128.033 65.4303 128.047 65.3574 128.02 65.2754L126.502 60.6953C126.465 60.5768 126.388 60.5176 126.27 60.5176C126.151 60.5176 126.074 60.5768 126.037 60.6953L124.52 65.2754C124.492 65.3483 124.501 65.4212 124.547 65.4941C124.602 65.5579 124.67 65.5898 124.752 65.5898H127.787Z"
          fill="#3627C2"
        />
        <path
          d="M118.723 53.3535C118.805 53.3535 118.868 53.39 118.914 53.4629C118.969 53.5267 118.982 53.5951 118.955 53.668L112.475 72.8633C112.447 72.9727 112.374 73.0273 112.256 73.0273H107.621C107.493 73.0273 107.416 72.9727 107.389 72.8633L100.908 53.668C100.881 53.5951 100.895 53.5267 100.949 53.4629C100.995 53.39 101.059 53.3535 101.141 53.3535H106.555C106.673 53.3535 106.746 53.4128 106.773 53.5312L109.699 64.7695C109.736 64.888 109.813 64.9473 109.932 64.9473C110.05 64.9473 110.128 64.888 110.164 64.7695L113.09 53.5312C113.117 53.4128 113.195 53.3535 113.322 53.3535H118.723Z"
          fill="#3627C2"
        />
        <path
          d="M90.0801 66.2188L92.9102 72.6992C92.9466 72.7721 92.9375 72.8451 92.8828 72.918C92.8372 72.9909 92.7734 73.0273 92.6914 73.0273H87.7832C87.6921 73.0273 87.6237 72.9818 87.5781 72.8906L85.0078 67.0117C84.9622 66.9206 84.8893 66.875 84.7891 66.875H84.0098C83.8457 66.875 83.7637 66.9525 83.7637 67.1074V72.7266C83.7637 72.8906 83.6862 72.9727 83.5312 72.9727H79.0195C78.9557 72.9727 78.8965 72.9499 78.8418 72.9043C78.7962 72.8587 78.7734 72.7995 78.7734 72.7266V53.5996C78.7734 53.4447 78.8555 53.3672 79.0195 53.3672H85.0625C86.7031 53.3672 87.9518 53.4857 88.8086 53.7227C89.6745 53.9505 90.3945 54.3105 90.9688 54.8027C91.6888 55.4134 92.2448 56.1745 92.6367 57.0859C93.0286 57.9883 93.2246 58.9635 93.2246 60.0117C93.2246 61.0234 93.0378 62.0033 92.6641 62.9512C92.2995 63.8991 91.8118 64.6374 91.2012 65.166C90.873 65.4486 90.5312 65.6992 90.1758 65.918C90.0664 66 90.0345 66.1003 90.0801 66.2188ZM87.332 62.0762C88.0703 61.748 88.4395 61.0872 88.4395 60.0938C88.4395 59.1458 88.0521 58.5033 87.2773 58.166C86.8398 57.9746 86.179 57.8789 85.2949 57.8789H84.0098C83.8457 57.8789 83.7637 57.9564 83.7637 58.1113V62.1172C83.7637 62.1901 83.7865 62.2493 83.832 62.2949C83.8776 62.3405 83.9368 62.3633 84.0098 62.3633H85.4727C86.293 62.3633 86.9128 62.2676 87.332 62.0762Z"
          fill="#3627C2"
        />
        <path
          d="M77.625 68.543C77.6979 68.543 77.7572 68.5703 77.8027 68.625C77.8483 68.6797 77.8665 68.7435 77.8574 68.8164L77.4609 72.8359C77.4427 72.9818 77.3607 73.0547 77.2148 73.0547H66.5508C66.4779 73.0547 66.4141 73.0273 66.3594 72.9727C66.3047 72.918 66.2773 72.8542 66.2773 72.7812V53.6953C66.2773 53.6224 66.3001 53.5632 66.3457 53.5176C66.4004 53.472 66.4596 53.4492 66.5234 53.4492H77.2148C77.3607 53.4492 77.4427 53.5221 77.4609 53.668L77.8574 57.6738C77.8665 57.7467 77.8438 57.8151 77.7891 57.8789C77.7435 57.9336 77.6842 57.9609 77.6113 57.9609H71.5137C71.4408 57.9609 71.3815 57.9837 71.3359 58.0293C71.2904 58.0749 71.2676 58.1341 71.2676 58.207V60.5996C71.2676 60.6634 71.2904 60.7227 71.3359 60.7773C71.3815 60.8229 71.4408 60.8457 71.5137 60.8457H75.9434C76.1074 60.8457 76.1895 60.9232 76.1895 61.0781V65.0977C76.1895 65.1706 76.1667 65.2298 76.1211 65.2754C76.0755 65.321 76.0163 65.3438 75.9434 65.3438H71.5137C71.4408 65.3438 71.3815 65.3665 71.3359 65.4121C71.2904 65.4577 71.2676 65.5169 71.2676 65.5898V68.2969C71.2676 68.3698 71.2904 68.429 71.3359 68.4746C71.3815 68.5202 71.4408 68.543 71.5137 68.543H77.625Z"
          fill="#3627C2"
        />
        <path
          d="M64.6367 53.4355C64.7005 53.4355 64.7552 53.4629 64.8008 53.5176C64.8555 53.5632 64.8828 53.6178 64.8828 53.6816V72.7949C64.8828 72.8587 64.8555 72.918 64.8008 72.9727C64.7552 73.0182 64.7005 73.041 64.6367 73.041H60.125C60.0612 73.041 60.002 73.0182 59.9473 72.9727C59.9017 72.918 59.8789 72.8587 59.8789 72.7949V65.6035C59.8789 65.5397 59.8516 65.485 59.7969 65.4395C59.7513 65.3848 59.6966 65.3574 59.6328 65.3574H54.041C53.877 65.3574 53.7949 65.4349 53.7949 65.5898V72.7949C53.7949 72.8587 53.7721 72.918 53.7266 72.9727C53.681 73.0182 53.6217 73.041 53.5488 73.041H49.0508C48.987 73.041 48.9277 73.0182 48.873 72.9727C48.8275 72.918 48.8047 72.8587 48.8047 72.7949V53.6816C48.8047 53.6178 48.8275 53.5632 48.873 53.5176C48.9277 53.4629 48.987 53.4355 49.0508 53.4355H53.5488C53.6217 53.4355 53.681 53.4629 53.7266 53.5176C53.7721 53.5632 53.7949 53.6178 53.7949 53.6816V60.627C53.7949 60.7819 53.877 60.8594 54.041 60.8594H59.6328C59.6966 60.8594 59.7513 60.8366 59.7969 60.791C59.8516 60.7454 59.8789 60.6862 59.8789 60.6133V53.6816C59.8789 53.6178 59.9017 53.5632 59.9473 53.5176C60.002 53.4629 60.0612 53.4355 60.125 53.4355H64.6367Z"
          fill="#3627C2"
        />
      </g>
    </svg>
  );
}
