import { api } from 'api/api';
import { InboxIcon } from 'assets/svgcomponents/InboxIcon';
import { LargeButton } from 'components/buttons/LargeButton';
import Input from 'components/input-field/Input';
import { LoadingIndicator } from 'components/layout/LoadingIndicator';
import { useTheme } from 'context/theme';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { generateDisplayName, generateHandle } from 'utils/generateOrganizationName';
import { useDebounce } from 'utils/useDebounce';
import * as Yup from 'yup';
import { SignupCard } from '../SignupCard';
import { useWizardContext } from '../WizardContext';

export function OrganizationName() {
  const { handleStep, previousStep, nextStep } = useWizard();
  const [stateValue, setState] = useWizardContext();
  const [doesHandleExists, setHandleExists] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const theme = useTheme();

  const unit = stateValue.unit || {
    navn: '',
    organisasjonsnummer: '',
    forretningsadresse: {
      adresse: '',
      postnummer: '',
      poststed: '',
    },
  };
  const validatedUnit = stateValue.validatedUnit || {};

  handleStep(() => {});

  const handleSearch = useDebounce(async (name: string) => {
    const exists = await api.organizations.exists({ handle: generateHandle(name) });

    setHandleExists(exists.data);
    setIsSearching(false);
  }, 500);

  useEffect(() => {
    setIsSearching(true);
    if (unit.displayName !== '' || unit.navn !== '') {
      handleSearch(unit.displayName || unit.navn);
    } else {
      setIsSearching(false);
    }
  }, []);

  return (
    <SignupCard
      imageComponent={<InboxIcon className="h-32 w-full place-self-center" />}
      backButtonOnClick={() => previousStep()}>
      <div
        className="flex w-full flex-col items-center justify-center space-y-3 lg:w-[385px]"
        style={{ color: theme.label.primary }}>
        <div className="centered flex-col">
          <h2 className="bigheading text-center">OPPRETT BEDRIFT</h2>
        </div>
        <div className="w-full" style={{ color: theme.label.primary }}>
          <Formik
            onSubmit={(formValues) => {
              setState({
                ...stateValue,
                validatedUnit: {
                  ...stateValue.validatedUnit,
                  displayName: formValues.displayName,
                },
              });
              nextStep();
            }}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize
            initialValues={{
              displayName: validatedUnit?.displayName || generateDisplayName(unit.navn),
            }}
            validationSchema={Yup.object({
              displayName: Yup.string().required('Påkrevd').min(3, 'Ugyldig navn'),
            })}>
            {({ values, handleSubmit, handleBlur, errors, handleChange }) => (
              <Form onSubmit={handleSubmit} className="flex w-full flex-col items-center space-y-10">
                <div className="flex w-full flex-col space-y-2">
                  <Input.Text
                    label="Navn på bedriften"
                    value={values.displayName || ''}
                    onChange={(e) => {
                      setIsSearching(true);
                      handleSearch(e.target.value);
                      handleChange(e);
                    }}
                    error={errors.displayName as any}
                    onBlur={handleBlur}
                    name="displayName"
                    required
                  />
                  <Input.Text
                    icon={isSearching && <LoadingIndicator size="xs" />}
                    label="Urlnavn"
                    description="Ditt dashbord vil ha denne urlen"
                    disabled={true}
                    value={`utlegg.app/${generateHandle(values.displayName)}` || ''}
                    onChange={() => {}}
                    error={doesHandleExists ? 'Allerede tatt' : undefined}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="flex space-x-3">
                  <LargeButton backgroundColor="light" defaultStyle="indigo" type="submit" disabled={doesHandleExists}>
                    Gå videre
                  </LargeButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </SignupCard>
  );
}
