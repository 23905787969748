export function AcceptTerms({ create }: { create?: boolean }) {
  return (
    <p className={'caption text-center opacity-90'}>
      Ved å {create ? 'opprette bedrift' : 'logge inn'} godtar du{' '}
      <a className="cursor-pointer underline" href="https://www.utleggsappen.no/vilkar" target="_blank">
        vilkår for bruk
      </a>
      .
    </p>
  );
}
