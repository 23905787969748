import { cn } from 'utils/className';

export function Plus({ className }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 16 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('h-[18px]', className)}>
      <path
        d="M7.99915 1.19751L7.99999 7.48209M7.99999 7.48209L8.00082 13.7658M7.99999 7.48209L1.71582 7.48168M7.99999 7.48209L14.2842 7.48251"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
