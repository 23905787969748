export function CaretDoubleLeft({ className }: { className?: string }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M12.8538 13.1463C12.9002 13.1927 12.9371 13.2479 12.9622 13.3086C12.9874 13.3693 13.0003 13.4343 13.0003 13.5C13.0003 13.5657 12.9874 13.6308 12.9622 13.6915C12.9371 13.7522 12.9002 13.8073 12.8538 13.8538C12.8073 13.9002 12.7522 13.9371 12.6915 13.9622C12.6308 13.9874 12.5657 14.0003 12.5 14.0003C12.4343 14.0003 12.3693 13.9874 12.3086 13.9622C12.2479 13.9371 12.1927 13.9002 12.1463 13.8538L7.14628 8.85378C7.09979 8.80735 7.06291 8.7522 7.03775 8.6915C7.01259 8.63081 6.99963 8.56574 6.99963 8.50003C6.99963 8.43433 7.01259 8.36926 7.03775 8.30856C7.06291 8.24786 7.09979 8.19272 7.14628 8.14628L12.1463 3.14628C12.2401 3.05246 12.3673 2.99976 12.5 2.99976C12.6327 2.99976 12.76 3.05246 12.8538 3.14628C12.9476 3.2401 13.0003 3.36735 13.0003 3.50003C13.0003 3.63272 12.9476 3.75996 12.8538 3.85378L8.2069 8.50003L12.8538 13.1463ZM3.2069 8.50003L7.85378 3.85378C7.9476 3.75996 8.00031 3.63272 8.00031 3.50003C8.00031 3.36735 7.9476 3.2401 7.85378 3.14628C7.75996 3.05246 7.63271 2.99976 7.50003 2.99976C7.36735 2.99976 7.2401 3.05246 7.14628 3.14628L2.14628 8.14628C2.09979 8.19272 2.06291 8.24786 2.03775 8.30856C2.01259 8.36926 1.99963 8.43433 1.99963 8.50003C1.99963 8.56574 2.01259 8.63081 2.03775 8.6915C2.06291 8.7522 2.09979 8.80735 2.14628 8.85378L7.14628 13.8538C7.19273 13.9002 7.24788 13.9371 7.30858 13.9622C7.36928 13.9874 7.43433 14.0003 7.50003 14.0003C7.56572 14.0003 7.63078 13.9874 7.69148 13.9622C7.75217 13.9371 7.80732 13.9002 7.85378 13.8538C7.90023 13.8073 7.93708 13.7522 7.96222 13.6915C7.98737 13.6308 8.00031 13.5657 8.00031 13.5C8.00031 13.4343 7.98737 13.3693 7.96222 13.3086C7.93708 13.2479 7.90023 13.1927 7.85378 13.1463L3.2069 8.50003Z"
        fill="#100C3A"
        fillOpacity="0.9"
      />
    </svg>
  );
}
