import { Category } from '__generated__/UtleggsappenApi';
import { api } from 'utils/fetch-wrapper';

const apiUrl = '/categories';

export const categoryService = {
  getById,
  getAllByCustomer,
  create,
  update,
  remove,
};

function getById(categoryId: string) {
  return api.get(`${apiUrl}/${categoryId}`) as Promise<Category>;
}

function getAllByCustomer(organizationId: string) {
  return api.get(`${apiUrl}/?${new URLSearchParams({ organizationId })}`) as Promise<Category[]>;
}

function create(department: {
  title: string;
  accountingCode?: string;
  accountingDescription?: string;
  description?: string;
  isStaff?: boolean;
  organizationId: string;
}) {
  return api.post(`${apiUrl}`, department) as Promise<Category>;
}

function update(
  departmentId: string,
  department: {
    title?: string;
    accountingCode?: string | null;
    accountingDescription?: string | null;
    description?: string | null;
    isStaff?: boolean;
  },
) {
  return api.patch(`${apiUrl}/${departmentId}`, department);
}

function remove(categoryId: string) {
  return api.delete(`${apiUrl}/${categoryId}`);
}
