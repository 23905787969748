export function Trash({ className, fill = '#DD524C' }: { className?: string; fill?: string }) {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1875 2.375H10.375V1.8125C10.375 1.36495 10.1972 0.935725 9.88074 0.619257C9.56428 0.30279 9.13505 0.125 8.6875 0.125H5.3125C4.86495 0.125 4.43572 0.30279 4.11926 0.619257C3.80279 0.935725 3.625 1.36495 3.625 1.8125V2.375H0.8125C0.663316 2.375 0.520242 2.43426 0.414753 2.53975C0.309263 2.64524 0.25 2.78832 0.25 2.9375C0.25 3.08668 0.309263 3.22976 0.414753 3.33525C0.520242 3.44074 0.663316 3.5 0.8125 3.5H1.375V13.625C1.375 13.9234 1.49353 14.2095 1.7045 14.4205C1.91548 14.6315 2.20163 14.75 2.5 14.75H11.5C11.7984 14.75 12.0845 14.6315 12.2955 14.4205C12.5065 14.2095 12.625 13.9234 12.625 13.625V3.5H13.1875C13.3367 3.5 13.4798 3.44074 13.5852 3.33525C13.6907 3.22976 13.75 3.08668 13.75 2.9375C13.75 2.78832 13.6907 2.64524 13.5852 2.53975C13.4798 2.43426 13.3367 2.375 13.1875 2.375ZM4.75 1.8125C4.75 1.66332 4.80926 1.52024 4.91475 1.41475C5.02024 1.30926 5.16332 1.25 5.3125 1.25H8.6875C8.83668 1.25 8.97976 1.30926 9.08525 1.41475C9.19074 1.52024 9.25 1.66332 9.25 1.8125V2.375H4.75V1.8125ZM11.5 13.625H2.5V3.5H11.5V13.625ZM5.875 6.3125V10.8125C5.875 10.9617 5.81574 11.1048 5.71025 11.2102C5.60476 11.3157 5.46168 11.375 5.3125 11.375C5.16332 11.375 5.02024 11.3157 4.91475 11.2102C4.80926 11.1048 4.75 10.9617 4.75 10.8125V6.3125C4.75 6.16332 4.80926 6.02024 4.91475 5.91475C5.02024 5.80926 5.16332 5.75 5.3125 5.75C5.46168 5.75 5.60476 5.80926 5.71025 5.91475C5.81574 6.02024 5.875 6.16332 5.875 6.3125ZM9.25 6.3125V10.8125C9.25 10.9617 9.19074 11.1048 9.08525 11.2102C8.97976 11.3157 8.83668 11.375 8.6875 11.375C8.53832 11.375 8.39524 11.3157 8.28975 11.2102C8.18426 11.1048 8.125 10.9617 8.125 10.8125V6.3125C8.125 6.16332 8.18426 6.02024 8.28975 5.91475C8.39524 5.80926 8.53832 5.75 8.6875 5.75C8.83668 5.75 8.97976 5.80926 9.08525 5.91475C9.19074 6.02024 9.25 6.16332 9.25 6.3125Z"
        className={className}
        fill={fill}
      />
    </svg>
  );
}
