import { useMemo } from 'react';
import Table from 'components/table/Table';
import { useNavigate } from 'react-router-dom';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { DefaultCell, HeaderCell } from 'components/table/TableComponents';
import { useTheme } from 'context/theme';
import { Plus } from 'assets/svgcomponents/Plus';
import { useCategories } from 'api/categories/useCategories';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import BackButton from 'components/buttons/BackButton';
import { SmallButton } from 'components/buttons/SmallButton';

export default function ExpenseTypeSettings() {
  const { data: categories, isLoading } = useCategories();

  const theme = useTheme();
  const navigate = useNavigate();

  const data = useMemo(
    () =>
      categories
        ?.filter((category: any) => category.type === 'EXPENSE')
        .map((category: any) => ({
          id: category.id,
          title: category.title,
          account: category.accountingCode ? `${category.accountingCode}` : ' ',
          description: category.description,
          navigate: category.id,
        })) ?? [],
    [categories],
  );

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: 'title',
        header: () => <HeaderCell>Navn</HeaderCell>,
        cell: (value: CellContext<(typeof data)[number], (typeof data)[number]['title']>) => (
          <DefaultCell className="smalltextbold">{value.getValue()}</DefaultCell>
        ),
      },
      {
        accessorKey: 'account',
        header: () => <HeaderCell>Konto</HeaderCell>,
        cell: (value: CellContext<(typeof data)[number], (typeof data)[number]['title']>) => (
          <DefaultCell>{value.getValue()}</DefaultCell>
        ),
      },
      {
        accessorKey: 'description',
        header: () => <HeaderCell>Kommentar</HeaderCell>,
        cell: (value: CellContext<(typeof data)[number], (typeof data)[number]['description']>) => (
          <DefaultCell>{value.getValue()}</DefaultCell>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex flex-col gap-y-5">
        <BackButton />
        <div className="flex w-full flex-row items-center justify-between gap-x-4">
          <h1 className="bigheading" style={{ color: theme.label.primary }}>
            Utleggstyper
          </h1>
          <div className="flex flex-row gap-x-4">
            <SmallButton
              backgroundColor="light"
              defaultStyle="indigo"
              className="h-fit px-3.5 py-2"
              icon={<Plus className="h-5 w-5" />}
              iconPlacement="left"
              onClick={() => {
                navigate('ny');
              }}>
              Legg til utleggstype
            </SmallButton>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-1 flex-col">
        {!isLoading && categories ? (
          <Table columns={columns} data={data} gridCols="minmax(100px, 2fr) minmax(0, 3fr) minmax(0, 3fr) 68px" />
        ) : (
          <FullscreenLoading />
        )}
      </div>
    </>
  );
}
