export function setAccessToken(token: string) {
  localStorage.setItem('token', token);
}

export function getAccessToken() {
  return localStorage.getItem('token');
}

export function removeAccessToken() {
  localStorage.removeItem('token');
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken');
}

export function setRefreshToken(refreshToken: string) {
  localStorage.setItem('refreshToken', refreshToken);
}

export function removeRefreshToken() {
  localStorage.removeItem('refreshToken');
}
