import { Organization, UpdateOrganizationDto } from '__generated__/UtleggsappenApi';
import { api } from 'utils/fetch-wrapper';

const apiUrl = '/organizations';

export const organizationService = {
  getAll,
  getById,
  create,
  delete: _delete,
  update,
  updateRate,
  updateLogo,
};

function getAll() {
  return api.get(`${apiUrl}`);
}

function getById(organizationId: string): Promise<Organization> {
  return api.get(`${apiUrl}/${organizationId}`);
}

function create(organization: {
  displayName: string;
  legalName?: string;
  handle: string;
  orgNumber?: string;
  invoiceEmail?: string;
}) {
  return api.post(`${apiUrl}`, organization);
}

function update(organizationId: string, organization: Partial<UpdateOrganizationDto>) {
  return api.patch(`${apiUrl}/${organizationId}`, organization);
}

function _delete(organizationId: string) {
  return api.delete(`${apiUrl}/${organizationId}`);
}

function updateRate(organizationId: string, rateId: string, newRate: number) {
  return api.patch(`${apiUrl}/${organizationId}/rates/${rateId}`, `{ "rate": ${newRate} }`);
}

function updateLogo(organizationId: string, image: File) {
  const formData = new FormData();
  formData.append('file', image);
  return api.patch(`${apiUrl}/${organizationId}`, formData);
}
