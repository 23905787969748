import { CellContext } from '@tanstack/react-table';
import { useReports } from 'api/reports/useReports';
import SearchBar from 'components/input-field/SearchBar';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import Table from 'components/table/Table';
import { DefaultCell, HeaderCell, TagWrapper } from 'components/table/TableComponents';
import { useTheme } from 'context/theme';
import { useMemo, useState } from 'react';

type ReportType = 'Utlegg' | 'Utleggsgruppe' | 'Reiseregning' | 'Kjørgodtgjørelse';

type Report = {
  senderName: string;
  approverName: string;
  type: ReportType;
  date: string;
  status: 'Godkjent' | 'Sendt' | 'Levert' | 'Feil' | 'Oppretter...' | 'Opprettet' | 'Sender...';
};

export default function Archive() {
  const { data: reports, isLoading } = useReports();
  const theme = useTheme();

  const [searchFilter, setSearchFilter] = useState('');

  const approvedReports = reports?.data
    .filter((report) => report.status === 'APPROVED')
    .filter((report) => report.account !== undefined);

  const translateInvoiceStatus = (status: string) => {
    switch (status) {
      case 'CREATING':
        return 'Oppretter...';
      case 'CREATED':
        return 'Opprettet';
      case 'SENDING':
        return 'Sendt';
      case 'SENT':
        return 'Sendt';
      case 'DELIVERED':
        return 'Levert';
      case 'FAILED':
        return 'Feil';
      default:
        return 'Sendt';
    }
  };

  const translateInvoiceBackgroundColor = (status: string) => {
    switch (status) {
      case 'Oppretter...':
        return theme.background.info;
      case 'Opprettet':
        return theme.background.info;
      case 'Sendt':
        return theme.background.info;
      case 'Levert':
        return theme.background.success;
      case 'Feil':
        return theme.background.critical;
      default:
        return theme.background.success;
    }
  };

  const translateInvoiceBorderColor = (status: string) => {
    switch (status) {
      case 'Oppretter...':
        return theme.border.info;
      case 'Opprettet':
        return theme.border.info;
      case 'Sendt':
        return theme.border.info;
      case 'Levert':
        return theme.border.success;
      case 'Feil':
        return theme.border.critical;
      default:
        return theme.border.success;
    }
  };

  function getInvoiceWithHighestNumber(report: any) {
    if (!report.invoices || report.invoices.length === 0) {
      return null; // or an appropriate default value
    }
    return report.invoices.reduce((prev: any, current: any) =>
      prev?.invoiceNumber > current?.invoiceNumber ? prev : current,
    );
  }

  function getMostRecentReview(report: NonNullable<ReturnType<typeof useReports>['data']>['data'][number]) {
    if (!report.reviews || report.reviews.length === 0) {
      return null;
    }
    return report.reviews.reduce((prev, current) => (prev?.createdAt > current?.createdAt ? prev : current));
  }

  const defaultData: Array<Report> = useMemo(
    () =>
      approvedReports
        ?.sort((a, b) => {
          return (
            (getInvoiceWithHighestNumber(b)?.invoiceNumber ?? -1) -
            (getInvoiceWithHighestNumber(a)?.invoiceNumber ?? -1)
          );
        })
        .map((report) => {
          const invoice = getInvoiceWithHighestNumber(report);
          const review = getMostRecentReview(report);
          return {
            invoiceNumber: invoice?.invoiceNumber!,
            senderName: `${report.account!.user!.givenName} ${report.account!.user!.familyName}`,
            approverName: review?.reviewer?.user?.givenName
              ? `${review.reviewer.user.givenName} ${review.reviewer.user.familyName}`
              : 'Info mangler',
            type: {
              DRIVE: 'Kjøregodtgjørelse',
              EXPENSE: 'Utlegg',
              TRAVEL: 'Reiseregning',
              EXPENSEGROUP: 'Utleggsgruppe',
            }[report.type] as ReportType,
            date: report.createdAt,
            status: translateInvoiceStatus(invoice?.status as string) || 'Oppretter...',
            url: invoice?.status !== 'CREATING' && invoice?.url,
            amount: report?.amount ?? 0,
          };
        }) ?? [],
    [approvedReports],
  );

  const addBreakpoints = (value: string) => {
    if (value === 'Utleggsgruppe') {
      return 'Utleggs\u{00AD}gruppe';
    } else if (value === 'Kjøregodtgjørelse') {
      return 'Kjøregodt\u{00AD}gjørelse';
    } else if (value === 'Reiseregning') {
      return 'Reise\u{00AD}regning';
    } else {
      return value;
    }
  };

  const defaultColumns = useMemo(
    () => [
      {
        accessorKey: 'invoiceNumber',
        cell: (value: CellContext<Report, string>) => <DefaultCell>{value.getValue()}</DefaultCell>,
        header: () => <HeaderCell className="whitespace-nowrap">Fakturanr.</HeaderCell>,
      },
      {
        accessorKey: 'senderName',
        cell: (value: CellContext<Report, string>) => (
          <DefaultCell className="smalltextbold">{value.getValue()}</DefaultCell>
        ),
        header: () => <HeaderCell>Navn</HeaderCell>,
      },
      {
        accessorKey: 'approverName',
        cell: (value: CellContext<Report, string>) => <DefaultCell>{value.getValue()}</DefaultCell>,
        header: () => <HeaderCell className="whitespace-nowrap">Behandlet av</HeaderCell>,
      },
      {
        accessorKey: 'type',
        cell: (value: CellContext<Report, string>) => <DefaultCell>{addBreakpoints(value.getValue())}</DefaultCell>,
        header: () => <HeaderCell>Type</HeaderCell>,
      },
      {
        accessorKey: 'amount',
        cell: (value: CellContext<Report, number>) => (
          <DefaultCell>{value.getValue().toLocaleString('nb-NO', { style: 'currency', currency: 'NOK' })}</DefaultCell>
        ),
        header: () => <HeaderCell>Sum</HeaderCell>,
      },
      {
        accessorKey: 'date',
        cell: (value: CellContext<Report, string>) => (
          <DefaultCell>
            {new Date(value.getValue()).toLocaleDateString('no-nb', { day: 'numeric', month: 'long', year: 'numeric' })}
          </DefaultCell>
        ),
        header: () => <HeaderCell>Dato</HeaderCell>,
      },
      {
        accessorKey: 'status',
        cell: (status: CellContext<Report, string>) => (
          <TagWrapper>
            <span
              className="smalltext w-fit rounded-small px-4 py-2"
              style={{
                color: theme.label.primary,
                backgroundColor: translateInvoiceBackgroundColor(status.getValue()),
                borderWidth: 1,
                borderColor: translateInvoiceBorderColor(status.getValue()),
              }}>
              {status.getValue()}
            </span>
          </TagWrapper>
        ),
        header: () => <HeaderCell>Status</HeaderCell>,
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex flex-row justify-between py-5">
        <h1 className="bigheading" style={{ color: theme.label.primary }}>
          Arkiv
        </h1>
        {defaultData.length > 0 && (
          <div className="flex flex-row gap-x-4">
            <SearchBar setSearchFilter={setSearchFilter} searchFilter={searchFilter} />
          </div>
        )}
      </div>
      <div className="flex w-full flex-1 flex-col">
        {!isLoading && reports ? (
          <Table
            data={defaultData}
            filter={searchFilter}
            columns={defaultColumns}
            gridCols="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 132px 68px"
            sortBy={[{ id: 'invoiceNumber', desc: true }]}
          />
        ) : (
          <FullscreenLoading />
        )}
      </div>
    </>
  );
}
