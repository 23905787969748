import { Table } from '@tanstack/react-table';
import { CaretDoubleLeft } from 'assets/svgcomponents/CaretDoubleLeft';
import { CaretLeft } from 'assets/svgcomponents/CaretLeft';
import { useTheme } from 'context/theme';
import { useLocation } from 'react-router-dom';

interface PaginationProps {
  /**
   * The table instance the pagination is for. Expects a table instance from @tanstack/react-table useTable().
   */
  table: Table<any>;
}

export function Pagination({ table }: PaginationProps) {
  const theme = useTheme();
  const location = useLocation();
  return (
    <div className="relative mt-8 flex w-full flex-col items-center justify-center gap-2 text-xs sm:flex-row">
      <div className="absolute left-0">
        <span className="caption pr-2">Antall per side</span>
        <select
          className="w-16 rounded border border-gray-200 p-1"
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
            localStorage.setItem(`${location.pathname}-pageSize`, e.target.value);
          }}>
          {[5, 10, 20, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>

      <div className="flex gap-2">
        <button
          className={`${!table.getCanPreviousPage() ? 'opacity-50' : 'hover:curstor-pointer'}`}
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}>
          <CaretDoubleLeft />
        </button>
        <button
          className={`${!table.getCanPreviousPage() ? 'opacity-50' : 'hover:curstor-pointer'}`}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}>
          <CaretLeft />
        </button>
        <span className="smalltext select-none px-4" style={{ color: theme.label.primary }}>
          {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
        </span>
        <button
          className={`${!table.getCanNextPage() ? 'opacity-50' : 'hover:curstor-pointer'}`}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}>
          <CaretLeft className="rotate-180" />
        </button>
        <button
          className={`${!table.getCanNextPage() ? 'opacity-50' : 'hover:curstor-pointer'}`}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}>
          <CaretDoubleLeft className="rotate-180" />
        </button>
      </div>
    </div>
  );
}
