import { useOrganization } from 'api/organizations/useOrganization';
import { Arrow } from 'assets/svgcomponents/Arrow';
import { TextButton } from 'components/buttons/TextButton';
import { SmallButton } from 'components/buttons/SmallButton';
import { useTheme } from 'context/theme';
import { ReactElement } from 'react';
import { cn } from 'utils/className';

export type ChecklistItem = {
  active: boolean;
  item: ReactElement;
};

export function Checklist({ items, setHideChecklist }: { items: ChecklistItem[]; setHideChecklist: any }) {
  const theme = useTheme();
  const { data: organization } = useOrganization();
  const activeItems = items.filter((item) => item.active);

  const handleHide = () => {
    setHideChecklist('true');
    localStorage.setItem(`hideChecklist-${organization?.handle || 'default'}`, 'true');
  };

  return (
    <div>
      <p className="caption px-6 pb-2">
        {activeItems.reduce((prev, curr) => (curr.item.props.checked ? prev + 1 : prev), 0)} / {activeItems.length}{' '}
        oppgaver fullført
      </p>
      <ul>
        {activeItems.map((item, idx) => (
          <div key={idx}>
            {idx !== 0 && (
              <div className="mx-6" style={{ borderBottom: `1px solid ${theme.background.accentstrong}` }} />
            )}
            {item.item}
          </div>
        ))}
        <div className="flex justify-center pt-6">
          <SmallButton backgroundColor="light" defaultStyle="indigo" onClick={handleHide}>
            Jeg gjør dette senere
          </SmallButton>
        </div>
      </ul>
    </div>
  );
}

function Item({
  title,
  emoji,
  subtitle,
  checked,
  buttonText,
  onClick,
}: Readonly<{
  title: string;
  emoji: string;
  subtitle: string;
  checked: boolean;
  buttonText: string;
  onClick: () => void;
}>) {
  return (
    <li
      className={cn(
        'group flex w-full cursor-pointer justify-between px-6 py-3 transition-all hover:bg-v2-purple-200',
        checked && 'opacity-70',
      )}
      onClick={onClick}>
      <div className="flex items-center space-x-2">
        <p className="bodylarge">{emoji}</p>
        <div>
          <p className={cn('smalltext', checked && 'line-through')}>{title}</p>
          <p className={cn('caption', checked && 'line-through')}>{subtitle}</p>
        </div>
      </div>
      {!checked && (
        <TextButton
          className="group-hover:scale-110"
          icon={<Arrow className="h-[21px] rotate-180" />}
          iconPlacement="right"
          backgroundColor="light">
          {buttonText}
        </TextButton>
      )}
    </li>
  );
}

Checklist.Item = Item;
