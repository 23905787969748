import { Close } from 'assets/svgcomponents/Close';
import { Plus } from 'assets/svgcomponents/Plus';
import { LargeButton } from 'components/buttons/LargeButton';
import Input from 'components/input-field/Input';
import { useTheme } from 'context/theme';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import useMeasure from 'react-use-measure';
import { v4 as uuidv4 } from 'uuid';
import { NewDepFormType } from './NewDepartment';

export default function ManageProjects() {
  const theme = useTheme();

  const { values, setFieldValue } = useFormikContext<NewDepFormType>();
  const [ref, bounds] = useMeasure();
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  function handleAdd() {
    if (inputValue.trim().length === 0) {
      setError('Prosjektet må ha et navn');
    } else {
      setFieldValue('projects', values.projects.concat({ id: uuidv4(), title: inputValue }));
      setInputValue('');
    }
  }

  return (
    <>
      <div className="flex flex-row items-start gap-x-4">
        <Input.Text
          label="Prosjekt"
          className="w-60"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            setError('');
          }}
          error={error}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleAdd();
            }
          }}
        />
        <div>
          <div className="h-6" />
          <LargeButton
            backgroundColor="light"
            defaultStyle="white"
            icon={<Plus />}
            iconPlacement="left"
            className="h-fit whitespace-nowrap"
            type="button"
            onClick={() => {
              handleAdd();
            }}>
            Legg til prosjekt
          </LargeButton>
        </div>
      </div>
      <div
        className="-m-1 h-fit w-full overflow-hidden p-1 transition-all"
        style={{ height: `${bounds.height + 8}px` }}>
        <div className="flex h-fit w-full flex-row flex-wrap gap-2" ref={ref}>
          {values.projects?.map((option) => {
            return (
              <button
                key={option.id}
                className="smalltext group flex w-fit cursor-pointer items-center justify-center gap-x-2.5 rounded-small border px-4 py-2"
                style={{ backgroundColor: theme.background.primary, borderColor: theme.border.border }}
                onClick={() => {
                  setFieldValue(
                    'projects',
                    values.projects.filter((el) => el.id !== option.id),
                  );
                }}>
                <p>{option.title}</p>
                <div className="text-base transition-opacity group-hover:opacity-50">
                  <Close className="h-[21px]" />
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
}
