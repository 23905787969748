import * as Tabs from '@radix-ui/react-tabs';
import { api } from 'api/api';
import { useDepartments } from 'api/departments/useDepartments';
import { useOrganization } from 'api/organizations/useOrganization';
import { Plus } from 'assets/svgcomponents/Plus';
import { LargeButton } from 'components/buttons/LargeButton';
import { SmallButton } from 'components/buttons/SmallButton';
import { Combobox, Option } from 'components/input-field/Combobox';
import { AnimateChangeInDimension } from 'components/layout/AnimateChangeInDimension';
import { Modal } from 'components/modal/Modal';
import { ModalCloseButton } from 'components/modal/ModalCloseButton';
import { TabListItem } from 'components/tabs/TabListItem';
import { StaticToast } from 'components/toast/StaticToast';
import { toast } from 'components/toast/Toast';
import { useTheme } from 'context/theme';
import parsePhoneNumber from 'libphonenumber-js';
import { useRef, useState } from 'react';
import { validateEmail } from 'utils/validateEmail';
import { RolesModal } from './RolesModal';

export function InviteModal({ isOpen = false }: Readonly<{ isOpen?: boolean }>) {
  const theme = useTheme();
  const { data: organization, mutate: refreshOrganization } = useOrganization();

  const [activeTab, setActiveTab] = useState<string>('sms');
  const [selectedRole, setSelectedRole] = useState<Option>();
  const [selectedDepartments, setSelectedDepartments] = useState<Option[]>([]);
  const [phoneNumbers, setPhoneNumbers] = useState<Option[]>([]);
  const [emails, setEmails] = useState<Option[]>([]);
  const [tokenDuration, setTokenDuration] = useState<Option>();
  const [invitationLink, setInvitationLink] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | undefined>();
  const [phoneError, setPhoneError] = useState<string | undefined>();

  const { data: departments } = useDepartments();

  const roleOptions: Option[] = [
    {
      value: '4',
      label: 'App',
    },
    {
      value: '2',
      label: 'Dashbord',
    },
    {
      value: '1',
      label: 'Admin',
    },
  ];

  const tokenDurationOptions: Option[] = [
    {
      value: '1',
      label: '1 døgn',
    },
    {
      value: '7',
      label: '1 uke',
    },
    {
      value: '30',
      label: '1 måned',
    },
  ];

  const modalRef = useRef<any>(null);

  const clearSettings = () => {
    setSelectedRole(undefined);
    setSelectedDepartments([]);
    setPhoneNumbers([]);
    setEmails([]);
    setTokenDuration(undefined);
    setInvitationLink('');
  };

  const handleGenerateLink = async () => {
    if (!invitationLink && organization) {
      setLoading(true);
      const {
        data: { url },
      } = await api.invitations.inviteByLink({
        organizationId: organization.id,
        role: selectedRole && parseInt(selectedRole.value),
        expiresAt:
          tokenDuration && new Date(Date.now() + parseInt(tokenDuration.value) * 24 * 60 * 60 * 1000).toISOString(),
        departmentIds:
          selectedRole && selectedRole.value === '2' ? selectedDepartments?.map((d) => d.value) : undefined,
      });

      setInvitationLink(url);
      navigator.clipboard.writeText(url);
    } else {
      navigator.clipboard.writeText(invitationLink);
    }

    toast.success({
      description: `Lenken ble kopiert til utlklippstavlen. Gyldig i ${tokenDuration?.value} dag${tokenDuration?.value !== '1' ? 'er' : ''}.`,
      options: { position: 'top-center' },
    });
    setLoading(false);
  };

  const handleInviteEmails = async () => {
    if (!emails || emails.length < 1) {
      setEmailError('Legg til minst én e-postadresse');
      return;
    }

    setLoading(true);
    await api.invitations
      .inviteByEmail({
        emails: emails.map((email) => email.value).filter((email) => email) as string[],
        organizationId: organization!.id,
        role: selectedRole && parseInt(selectedRole.value),
        departmentIds:
          selectedRole && selectedRole.value === '2' ? selectedDepartments?.map((d) => d.value) : undefined,
      })
      .then(async () => {
        await refreshOrganization();
        toast.success({ description: `Invitasjon${phoneNumbers.length > 1 ? 'er' : ''} sendt!` });
        modalRef.current?.close();
        clearSettings();
      })
      .catch(() => {
        toast.error({
          description: 'Noe gikk galt. Kontakt support om problemet vedvarer.',
        });
      })

      .finally(() => setLoading(false));
  };

  const handleInvitePhoneNumbers = async () => {
    if (!phoneNumbers || phoneNumbers.length < 1) {
      setPhoneError('Legg til minst ett telefonnummer');
      return;
    }
    setLoading(true);

    // Remove spaces and special characters from phone numbers
    phoneNumbers.forEach((phone) => {
      const parsed = parsePhoneNumber(phone.value, 'NO');
      if (parsed) {
        phone.value = parsed.number;
      }
    });

    await api.invitations
      .inviteByPhone({
        phoneNumbers: phoneNumbers.map((phone) => phone.value).filter((phone) => phone) as string[],
        organizationId: organization!.id,
        role: selectedRole && parseInt(selectedRole.value),
        departmentIds:
          selectedRole && selectedRole.value === '2' ? selectedDepartments?.map((d) => d.value) : undefined,
      })
      .then(async () => {
        await refreshOrganization();
        toast.success({ description: `Invitasjon${phoneNumbers.length > 1 ? 'er' : ''} sendt!` });
        modalRef.current?.close();
        clearSettings();
      })
      .catch(() => {
        toast.error({
          description: 'Noe gikk galt. Kontakt support om problemet vedvarer.',
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      ref={modalRef}
      trigger={
        <SmallButton
          backgroundColor="light"
          defaultStyle="indigo"
          className="h-fit px-3.5 py-2"
          icon={<Plus className="h-4 w-4" />}
          iconPlacement="left">
          Ny bruker
        </SmallButton>
      }
      openByDefault={isOpen}>
      <Tabs.Root
        className="TabsRoot"
        defaultValue={activeTab}
        value={activeTab}
        onValueChange={(value) => setActiveTab(value)}
        asChild>
        <AnimateChangeInDimension className="TabsRoot flex flex-col">
          <Tabs.List
            className="TabsList smalltext flex justify-between space-x-8 rounded-large p-4"
            style={{ backgroundColor: theme.background.accent }}
            aria-label="Manage your account">
            <div className="flex space-x-2">
              <TabListItem title="SMS" value="sms" active={activeTab === 'sms'} />
              <TabListItem title="E-post" value="epost" active={activeTab === 'epost'} />
              <TabListItem title="Lenke" value="lenke" active={activeTab === 'lenke'} />
            </div>
            <ModalCloseButton />
          </Tabs.List>
          <h2 className="bigheading whitespace-nowrap pb-6 pt-8" style={{ color: theme.label.primary }}>
            INVITER NYE BRUKERE
          </h2>
          {organization?.subscription?.subscriptionTier?.dashboard ? (
            <>
              <div className="space-y-6">
                <div className="space-y-2">
                  <Combobox
                    required
                    options={roleOptions}
                    label="Rolle"
                    onSelect={(option) => {
                      setInvitationLink('');
                      setSelectedRole(option);
                    }}
                    value={selectedRole}
                    placeholder="Søk etter rolle..."
                    defaultValue={roleOptions[0].value}
                  />
                  <RolesModal />
                </div>

                {selectedRole?.value === '2' && departments && departments.length > 0 && (
                  <Combobox
                    multiselect
                    options={
                      departments?.map((department) => {
                        return { label: department.title, value: department.id };
                      }) || []
                    }
                    label="Avdelinger"
                    onSelect={(options) => {
                      setInvitationLink('');
                      setSelectedDepartments(options);
                    }}
                    value={selectedDepartments}
                    placeholder="Søk etter avdeling..."
                    showValueCount={true}
                  />
                )}
              </div>
              <li className="centered w-full py-6">
                <div className="h-[1px] w-full opacity-70" style={{ backgroundColor: theme.border.border }} />
              </li>
            </>
          ) : null}
          <Tabs.Content className="TabsContent flex-1 space-y-4" value="sms">
            <Combobox
              required
              label="Telefonnummer"
              description="Det er mulig å legge til flere"
              placeholder="+ Legg til"
              onSelect={(options) => {
                setPhoneNumbers(options);
                setPhoneError(undefined);
              }}
              multiselect
              enableCreate={true}
              onCreate={() => {}}
              value={phoneNumbers}
              createValidation={(value) => {
                const phone = parsePhoneNumber(value, 'NO');
                if (!phone || !phone.isValid() || phone.country !== 'NO') {
                  return 'Telefonnummeret er ugyldig';
                }
                return true;
              }}
              error={phoneError}
            />
            <div className="centered pt-4">
              <LargeButton
                backgroundColor="light"
                defaultStyle="indigo"
                onClick={handleInvitePhoneNumbers}
                loading={loading}>
                Inviter
              </LargeButton>
            </div>
          </Tabs.Content>
          <Tabs.Content className="TabsContent flex-1 space-y-4" value="epost">
            <Combobox
              required
              label="E-post"
              description="Det er mulig å legge til flere"
              placeholder="+ Legg til"
              onSelect={(options) => {
                setEmails(options);
                setEmailError(undefined);
              }}
              multiselect
              enableCreate={true}
              onCreate={() => {}}
              value={emails}
              createValidation={(value) => {
                if (!validateEmail(value)) {
                  return 'E-posten er ugyldig';
                }
                return true;
              }}
              error={emailError}
            />
            <div className="centered pt-4">
              <LargeButton backgroundColor="light" defaultStyle="indigo" onClick={handleInviteEmails} loading={loading}>
                Inviter
              </LargeButton>
            </div>
          </Tabs.Content>
          <Tabs.Content className="TabsContent space-y-4" value="lenke">
            <Combobox
              required
              options={tokenDurationOptions}
              label="Hvor lenge skal lenken være gyldig?"
              onSelect={(option) => {
                setInvitationLink('');
                setTokenDuration(option);
              }}
              value={tokenDuration}
              defaultValue={tokenDurationOptions[0].value}
            />
            <StaticToast description="Alle med tilgang til lenken vil kunne bli med i organisasjonen." type="info" />
            <div className="centered">
              <LargeButton backgroundColor="light" defaultStyle="indigo" onClick={handleGenerateLink} loading={loading}>
                Generer lenke
              </LargeButton>
            </div>
            <p className="caption cursor-pointer text-center" onClick={handleGenerateLink}>
              {invitationLink}
            </p>
          </Tabs.Content>
        </AnimateChangeInDimension>
      </Tabs.Root>
    </Modal>
  );
}
