import * as Tabs from '@radix-ui/react-tabs';
import { ErrorMessage } from 'assets/svgcomponents/ErrorMessage';
import { ErrorMessageActive } from 'assets/svgcomponents/ErrorMessageActive';
import { useTheme } from 'context/theme';
import { cn } from 'utils/className';

/**
 * A tab list item for use in a tab list. Must be used inside a Tabs component.
 */
interface TabListItemProps {
  /**
   * The title of the tab
   */
  title: string;

  /**
   * The value of the tab. Used to determine which tab is active.
   */
  value: string;

  /**
   * The icon to display next to the title
   */
  icon?: React.ReactNode;

  /**
   * Whether or not the tab is an overview tab. If true, the tab will have a different style.
   */
  isOverview?: boolean;

  /**
   * Whether or not the tab is active
   */
  active?: boolean;
}

export function TabListItem({ title, value, icon, isOverview, active }: Readonly<TabListItemProps>) {
  const theme = useTheme();
  return (
    <Tabs.Trigger
      className={cn(
        `TabsTrigger smalltext box-border flex items-center whitespace-nowrap rounded-small border-[1px] px-4 py-2 ${!active && 'hover:opacity-50'}`,
      )}
      style={
        active
          ? { backgroundColor: theme.background.inverse, color: theme.label.ondark }
          : isOverview
            ? { backgroundColor: theme.base.accent, borderColor: theme.border.border, color: theme.label.ondark }
            : { backgroundColor: theme.background.primary, borderColor: theme.border.border }
      }
      value={value}>
      {title}
      {icon ? (
        <div className="ml-3 h-[24px] w-[24px]">{active ? <ErrorMessageActive /> : <ErrorMessage />}</div>
      ) : (
        <div className="h-[24px]"></div>
      )}
    </Tabs.Trigger>
  );
}
