import { useTheme } from 'context/theme';
import { CSSProperties, ChangeEvent, FocusEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { cn } from 'utils/className';

interface TextAreaProps {
  /**
   * The label of the textarea.
   */
  label?: string;

  /**
   * The description of the textarea.
   */
  description?: string;

  /**
   * The value of the textarea.
   */
  value?: string;

  /**
   * The error message of the textarea. If provided, the textarea will turn red and show the error message below.
   */
  error?: string;

  /**
   * Whether the textarea is disabled.
   */
  disabled?: boolean;

  /**
   * The function to call when the textarea value changes.
   */
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;

  /**
   * The function to call when the textarea loses focus.
   */
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;

  /**
   * The function to call when a key is pressed down.
   */
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;

  /**
   * The style of the textarea.
   */
  style?: CSSProperties;

  /**
   * Whether the textarea is a password field.
   */
  password?: boolean;

  /**
   * The class name of the textarea container.
   */
  className?: string;

  /**
   * The class name of the textarea.
   */
  inputClassName?: string;

  /**
   * The component to display in front of the textarea.
   */
  frontComponent?: ReactNode;

  /**
   * The name of the textarea. Useful for form submission.
   */
  name?: string;

  /**
   * Whether the textarea is required.
   */
  required?: boolean;

  /**
   * The icon to display on the right side of the textarea.
   */
  icon?: ReactNode;

  /**
   * The width of the textarea.
   */
  width?: 'sm' | 'md' | 'lg' | 'xl' | 'full';

  /**
   * Whether to focus on the textarea when mounted.
   */
  focusOnMount?: boolean;

  /**
   * The unit to display on the right side of the textarea.
   */
  unit?: string;

  /**
   * The ref of the textarea.
   */
  ref?: any;
}

export default function TextArea({
  label,
  description,
  value,
  error,
  disabled,
  onChange,
  onBlur,
  onKeyDown,
  style,
  className,
  inputClassName,
  frontComponent,
  name,
  required,
  icon,
  width = 'full',
  unit,
  focusOnMount,
}: TextAreaProps) {
  const theme = useTheme();
  const errorStyles: CSSProperties = { borderColor: theme.border.critical, backgroundColor: theme.background.critical };
  const [isFocused, setIsFocused] = useState(false);
  const unitRef = useRef<HTMLDivElement>(null);
  const [unitWidth, setUnitWidth] = useState(0);

  useEffect(() => {
    if (unitRef.current) {
      setUnitWidth(unitRef.current.clientWidth);
    }
  }, [unit]);

  const widthMap = {
    sm: 'w-24',
    md: 'w-52',
    lg: 'w-80',
    xl: 'w-[432px]',
    full: 'w-full',
  };

  return (
    <div className={`pb-4 ${widthMap[width]}`}>
      <div className={cn('relative flex cursor-text flex-col gap-y-1', className)}>
        {label && (
          <p className="inputlabel" style={{ color: theme.label.primary }}>
            {label}
            {required && '*'}
          </p>
        )}
        {description && (
          <p className="caption" style={{ color: theme.label.primary }}>
            {description}
          </p>
        )}
        <div className={`relative flex ${widthMap[width]}`}>
          {frontComponent && <div className="absolute bottom-0 left-4 top-0 flex items-center">{frontComponent}</div>}
          <textarea
            autoFocus={focusOnMount}
            name={name}
            disabled={disabled}
            onKeyDown={onKeyDown}
            onChange={onChange}
            onBlur={(e) => {
              onBlur?.(e);
              setIsFocused(false);
            }}
            onFocus={() => setIsFocused(true)}
            value={value}
            className={cn(
              `inputtext h-[108px] min-h-[54px] w-full rounded-small border py-3.5 outline-none focus:border-[3px] focus:stroke-none focus:py-3 disabled:bg-v2-whitealpha-600`,
              inputClassName,
            )}
            style={{
              ...{
                borderColor: disabled ? theme.label.tertiary : isFocused ? theme.border.accent : theme.border.border,
                paddingLeft: 16,
                paddingRight: 16 + (unit ? unitWidth + 8 : 0) + (icon ? 56 : 0),
              },
              ...{ color: disabled ? theme.label.secondary : theme.label.primary },
              ...(error ? errorStyles : {}),
              ...style,
            }}
          />
          {unit && (
            <div className="absolute right-4 top-1/2 -translate-y-1/2" ref={unitRef}>
              <p className="inputtext" style={{ color: theme.label.secondary }}>
                {unit}
              </p>
            </div>
          )}
          {icon && <div className="absolute right-4 top-1/2 -translate-y-1/2">{icon}</div>}
        </div>
        {error && (
          <p className="caption absolute bottom-0 left-0 translate-y-[110%]" style={{ color: theme.label.critical }}>
            😬 {error}
          </p>
        )}
      </div>
    </div>
  );
}
