import Input from 'components/input-field/Input';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import BackButton from 'components/buttons/BackButton';
import { useTheme } from 'context/theme';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'components/toast/Toast';
import { useStoredUser } from 'context/UserContext';
import { useRates } from 'api/rates/useRates';
import { organizationService } from 'services/organizationService';
import { useOrganization } from 'api/organizations/useOrganization';
import { LargeButton } from 'components/buttons/LargeButton';

type FormValues = {
  title: string;
  rate: string;
};

export default function EditDriveRate() {
  const { user } = useStoredUser();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const rateId = pathname.split('/').pop() ?? '';

  const theme = useTheme();

  const { data: organization } = useOrganization();
  const { data: rates } = useRates('DRIVE');

  const rate = rates?.find((rate) => rate.id === rateId);

  const [changedValues, setChangedValues] = useState({
    title: true,
    rate: true,
  });

  if (!rate || !user || !organization) return <></>;

  function handleRateChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: string) => void,
  ) {
    setChangedValues((prev) => ({ ...prev, rate: true }));
    const value = e.currentTarget.value.toString().replace(/ /g, '').replace('.', ',');
    if (value.length === 0) setFieldValue('rate', '');
    if (!/^\d*[.,]?\d{0,2}$/.test(value)) return;
    setFieldValue('rate', value);
  }

  const handleSubmit = async (values: FormValues) => {
    try {
      await organizationService.updateRate(
        organization.id,
        rate.id,
        values.rate.toString().replace(',', '.') as unknown as number,
      );
      toast.success({ description: `${rate.title} oppdatert` });
      navigate(-1);
    } catch (err) {
      toast.error({ description: (err as string) || 'Noe gikk galt. Kontakt support om problemet vedvarer.' });
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col justify-between gap-y-5 pt-5" style={{ color: theme.label.primary }}>
        <BackButton />
        <h1 className="bigheading">Endre sats</h1>
      </div>
      <Formik
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await handleSubmit(values);
          setSubmitting(false);
        }}
        initialValues={{
          title: rate.title ?? '',
          rate: rate.rate.toString().replace('.', ',') ?? '',
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('Påkrevd'),
          rate: Yup.string().required('Påkrevd'),
        })}>
        {({ values, errors, handleChange, isSubmitting, setFieldValue, handleSubmit }) => {
          return (
            <Form className="flex flex-col gap-y-4 pt-10" onSubmit={handleSubmit}>
              <Input.Text
                width="lg"
                label="Navn"
                value={values.title}
                onChange={(e) => {
                  setChangedValues((prev) => ({ ...prev, title: true }));
                  handleChange('title')(e);
                }}
                error={!changedValues.title ? errors.title : undefined}
                disabled
              />
              <Input.Text
                width="md"
                label="Sats"
                value={values.rate.toString()}
                onChange={(e) => {
                  handleRateChange(e, setFieldValue);
                }}
                error={!changedValues.rate ? errors.rate : undefined}
                unit="kr/km"
              />
              <LargeButton
                backgroundColor="light"
                type="submit"
                defaultStyle="white"
                loading={isSubmitting}
                onClick={() => {
                  setChangedValues({
                    title: false,
                    rate: false,
                  });
                }}>
                Lagre
              </LargeButton>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
