import { Arrow } from 'assets/svgcomponents/Arrow';
import { TextButton } from './TextButton';
import { useNavigate } from 'react-router-dom';

type BackButtonProps = {
  /**
   * The content of the button
   */
  children?: React.ReactNode;

  /**
   * Function to run when the button is clicked. Default is navigate(-1).
   */
  onClick?: () => void;
};

export default function BackButton({ children = 'Tilbake', onClick }: BackButtonProps) {
  const navigate = useNavigate();

  return (
    <TextButton
      backgroundColor="light"
      iconPlacement="left"
      icon={<Arrow className="h-[21px]" />}
      onClick={onClick ? onClick : () => navigate(-1)}>
      {children}
    </TextButton>
  );
}
