import { Check } from 'assets/svgcomponents/Check';

export interface CheckboxProps {
  disabled?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = (props: CheckboxProps) => {
  return (
    <div className="relative h-[24px] w-[24px]">
      <input className="peer appearance-none" type="checkbox" readOnly={!props.onChange} {...props} />
      <div className="centered absolute inset-0 rounded-[4px] border-[1.5px] border-black bg-white p-1 transition-all peer-checked:border-transparent peer-checked:bg-[#907AD6]">
        <Check fill="#fff" />
      </div>
    </div>
  );
};
