import * as Switch from '@radix-ui/react-switch';
import { useTheme } from 'context/theme';
import { cn } from 'utils/className';

interface ToggleProps {
  /**
   * The label of the toggle.
   */
  label?: string;

  /**
   * The description of the toggle.
   */
  description?: string | JSX.Element;

  /**
   * Show/hide the toggle. If false, the toggle will be hidden.
   */
  loaded?: boolean;

  /**
   * The value of the toggle.
   */
  value: boolean;

  /**
   * The error message of the toggle. If provided, the toggle will turn red and show the error message below.
   */
  error?: string;

  /**
   * The function to call when the toggle value changes.
   */
  onChange: (value: boolean) => void;

  /**
   * Whether the toggle is disabled.
   */
  disabled?: boolean;

  /**
   * The class name of the toggle container.
   */
  wrapperClassName?: string;

  /**
   * The type of the toggle.
   */
  type?: 'button' | 'submit' | 'reset';
}

export default function Toggle({
  label,
  description,
  loaded = true,
  value,
  error,
  onChange,
  disabled = false,
  wrapperClassName,
  type = 'button',
}: ToggleProps) {
  const theme = useTheme();

  return (
    <div className={cn('flex flex-row items-end justify-between gap-x-4', wrapperClassName)}>
      <div className="flex flex-col gap-y-1">
        <h2 className="smalltextbold">{label}</h2>
        {description && <p className="caption">{description}</p>}
        {error && (
          <p className="smalltext" style={{ color: theme.label.critical }}>
            😬 {error}
          </p>
        )}
      </div>
      {loaded && (
        <div className="flex items-center">
          <Switch.Root
            disabled={disabled}
            className="bg-blackA6 relative h-[31px] w-[51px] rounded-full outline-none ring-black transition-all focus-visible:ring"
            id="airplane-mode"
            checked={value}
            onCheckedChange={onChange}
            type={type}
            style={
              disabled
                ? { backgroundColor: theme.background.accentemphasis, opacity: 0.6 }
                : value
                  ? { backgroundColor: theme.base.accent }
                  : { backgroundColor: theme.background.accentstrong }
            }>
            <Switch.Thumb
              className="block h-[27px] w-[27px] translate-x-0.5 rounded-full transition-all will-change-transform data-[state=checked]:translate-x-[22px]"
              style={
                disabled ? { backgroundColor: theme.background.accent } : { backgroundColor: theme.background.primary }
              }
            />
          </Switch.Root>
        </div>
      )}
    </div>
  );
}
