import { cn } from 'utils/className';
import { BaseButton, ButtonProps } from './BaseButton';

export function BigfootButton(props: ButtonProps) {
  return (
    <BaseButton
      {...props}
      className={cn('buttonlargetext w-[361px] rounded-small px-[4px] py-[20px]', props.className)}>
      {props.children}
    </BaseButton>
  );
}
