import { cn } from 'utils/className';
import { BaseButton, ButtonProps } from './BaseButton';

export function LargeButton(props: ButtonProps) {
  const largeButtonClassName = `buttonlargetext rounded-xxlarge py-[14px] w-fit ${props.children ? 'px-[28px]' : 'px-[14px]'}`;
  return (
    <BaseButton {...props} className={cn(largeButtonClassName, props.className)}>
      {props.children}
    </BaseButton>
  );
}
