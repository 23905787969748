import { MutableRefObject, ReactNode } from 'react';

export interface AlertProps {
  title: string;
  description?: string;
  children?: ReactNode;
  actionLabel?: string;
  onAction?: () => void;
  loading?: boolean;
  disableDefaultAction?: boolean;
}

class Alert {
  alertRef: any;
  constructor() {
    this.alertRef = null;
  }

  info({ title, description, actionLabel, onAction, loading, disableDefaultAction, children }: AlertProps) {
    this.alertRef?.current?.open(
      'indigo',
      title,
      description,
      children,
      actionLabel || title,
      onAction,
      loading,
      disableDefaultAction,
    );
  }

  success({ title, description, actionLabel, onAction, loading, disableDefaultAction, children }: AlertProps) {
    this.alertRef?.current?.open(
      'success',
      title,
      description,
      children,
      actionLabel || title,
      onAction,
      loading,
      disableDefaultAction,
    );
  }

  warning({ title, description, actionLabel, onAction, loading, disableDefaultAction, children }: AlertProps) {
    this.alertRef?.current?.open(
      'warning',
      title,
      description,
      children,
      actionLabel || title,
      onAction,
      loading,
      disableDefaultAction,
    );
  }

  error({ title, description, actionLabel, onAction, loading, disableDefaultAction, children }: AlertProps) {
    this.alertRef?.current?.open(
      'critical',
      title,
      description,
      children,
      actionLabel || title,
      onAction,
      loading,
      disableDefaultAction,
    );
  }

  close() {
    this.alertRef?.current?.close();
  }

  setRef(ref: MutableRefObject<null>) {
    this.alertRef = ref;
  }
}

export const alert = new Alert();
