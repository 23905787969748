import App from 'App';
import { fetcher } from 'api';
import { refreshTokens } from 'api/auth';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { IntercomProvider } from 'react-use-intercom';
import ErrorPage from 'screens/ErrorPage';
import { preload } from 'swr';
import './index.css';

async function bootstrap() {
  await refreshTokens();
  preload('/users/current', fetcher);

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID!}>
      <ErrorBoundary fallback={<ErrorPage />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ErrorBoundary>
    </IntercomProvider>,
  );
}

bootstrap();
