import { useUser } from 'api/users/useUser';
import * as Yup from 'yup';
import { useTheme } from 'context/theme';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { userService } from 'services/userService';
import Input from 'components/input-field/Input';
import { toast } from 'components/toast/Toast';
import BackButton from 'components/buttons/BackButton';
import { LargeButton } from 'components/buttons/LargeButton';

export default function NewPassword() {
  const { data: user } = useUser();
  const navigate = useNavigate();
  const theme = useTheme();

  if (!user) return <></>;

  return (
    <div className="flex flex-col gap-y-10">
      <div className="flex flex-col justify-between gap-y-5 pt-5" style={{ color: theme.label.primary }}>
        <BackButton />
        <h1 className="bigheading">Endre passord</h1>
      </div>
      <Formik
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          userService
            .update(user.id, {
              password: values.password,
            })
            .then(() => {
              toast.success({ description: 'Nytt passord lagret' });
              setSubmitting(false);
              resetForm();
              navigate('..');
            })
            .catch((err) => {
              toast.error({ description: (err as string) || 'Noe gikk galt. Kontakt support om problemet vedvarer.' });
              setSubmitting(false);
            });
        }}
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={Yup.object({
          password: Yup.string().min(8, 'Passordet må være minst 8 tegn'),
          confirmPassword: Yup.string().when('password', () =>
            Yup.string()
              .required('Påkrevd')
              .oneOf([Yup.ref('password')], 'Passordene må være like'),
          ),
        })}>
        {({ values, errors, touched, handleChange, handleBlur }) => {
          return (
            <Form className="flex flex-col gap-y-4">
              <Input.Text
                width="lg"
                password
                label="Nytt passord"
                value={values.password}
                error={touched.password ? errors.password : undefined}
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
              />
              <Input.Text
                width="lg"
                password
                label="Bekreft passord"
                value={values.confirmPassword}
                error={touched.confirmPassword ? errors.confirmPassword : undefined}
                onChange={handleChange('confirmPassword')}
                onBlur={handleBlur('confirmPassword')}
              />
              <div className="mt-6">
                <LargeButton backgroundColor="light" defaultStyle="white" type="submit">
                  Lagre
                </LargeButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
