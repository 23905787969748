import { Account } from '__generated__/UtleggsappenApi';
import { api } from 'utils/fetch-wrapper';

const apiUrl = '/accounts';

export const accountService = {
  create,
  getAll,
  getById,
  delete: _delete,
  revokeToken,
  block,
  unblock,
  getAllByCustomer,
  update,
  canAccountLeave,
};

function create(phoneNumber: string, organizationId: string, role?: number) {
  return api.post(`${apiUrl}`, { phoneNumber, organizationId, role });
}

function getAll(query: string) {
  const searchParams = query ? `?${new URLSearchParams(query)}` : '';
  return api.get(`${apiUrl}/${searchParams}`);
}

function getById(accountId: string) {
  return api.get(`${apiUrl}/${accountId}`) as Promise<Account>;
}

function _delete(accountId: string) {
  return api.delete(`${apiUrl}/${accountId}`);
}

function revokeToken(params: any) {
  return api.post(`${apiUrl}/revoke-token`, params);
}

function block(accountId: string) {
  return api.post(`${apiUrl}/block`, { accountId });
}

function unblock(accountId: string) {
  return api.post(`${apiUrl}/unblock`, { accountId });
}

function getAllByCustomer(organizationId: string) {
  const query = {
    organizationId,
  };
  return api.get(`${apiUrl}/?${new URLSearchParams(query)}`) as Promise<Account[]>;
}

function update(
  accountId: string,
  params: { isStaff?: boolean; role?: number; activatedAt?: Date; activated?: Date; digest?: number },
) {
  return api.patch(`${apiUrl}/${accountId}`, params);
}

async function canAccountLeave(accountId: string, organizationId: string) {
  try {
    const accounts: Account[] = await getAllByCustomer(organizationId);
    const account: Account = await getById(accountId);

    return !(account && account.role <= 1 && accounts && accounts?.filter((a) => a.role <= 1).length <= 1);
  } catch (error) {
    console.error(error);
  }
}
