import { useTheme } from 'context/theme';
import { cn } from 'utils/className';

export const DefaultCell = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  const theme = useTheme();
  return (
    <p
      className={
        // TODO: Fix word wrapping and width issue
        cn('smalltext overflow-x-hidden text-ellipsis whitespace-nowrap break-words rounded-full px-2 py-1', className)
      }
      style={{ color: theme.label.primary }}>
      {children}
    </p>
  );
};

export const HeaderCell = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  const theme = useTheme();
  return (
    <p className={cn('caption flex px-2 py-1', className)} style={{ color: theme.label.primary }}>
      {children}
    </p>
  );
};

export const TagWrapper = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <div className={cn('flex flex-wrap gap-2.5', className)}>{children}</div>
);
