import { TextButton } from '../components/buttons/TextButton';
import { Plus } from '../assets/svgcomponents/Plus';
import { Arrow } from '../assets/svgcomponents/Arrow';
import { LargeButton } from 'components/buttons/LargeButton';
import { SmallButton } from 'components/buttons/SmallButton';
import { ExtraSmallButton } from 'components/buttons/ExtraSmallButton';
import { BigfootButton } from 'components/buttons/BigfootButton';
import { VippsButton } from 'components/buttons/VippsButton';

const Dsm = () => {
  return (
    <section className="bg-gray-400 px-4 md:px-12">
      <div className="centered container mx-auto h-full w-full flex-col space-y-20">
        <section className="w-full space-y-6 py-20">
          <div>
            <h3 className="bigheading text-black">Buttons</h3>
            <div className="h-1 w-full bg-black" />
          </div>
          <div className="flex space-x-10">
            <div className="flex flex-col space-y-6">
              <LargeButton icon={<Plus />} iconPlacement="left" defaultStyle="white" backgroundColor="light">
                Large button
              </LargeButton>
              <LargeButton icon={<Plus />} iconPlacement="right" defaultStyle="white" backgroundColor="light">
                Large button
              </LargeButton>
              <LargeButton defaultStyle="white" backgroundColor="light">
                Large button
              </LargeButton>
              <LargeButton defaultStyle="indigo" backgroundColor="light">
                Large button
              </LargeButton>
              <LargeButton defaultStyle="indigo" backgroundColor="dark">
                Large button
              </LargeButton>
              <LargeButton defaultStyle="solid" backgroundColor="dark">
                Large button
              </LargeButton>
              <LargeButton defaultStyle="outline" backgroundColor="light">
                Large button
              </LargeButton>
              <LargeButton defaultStyle="outline" backgroundColor="dark">
                Large button
              </LargeButton>
              <LargeButton defaultStyle="critical" backgroundColor="light">
                Large button
              </LargeButton>
              <LargeButton defaultStyle="critical" backgroundColor="dark">
                Large button
              </LargeButton>
            </div>
            <div className="flex flex-col space-y-6">
              <SmallButton icon={<Plus />} iconPlacement="left" defaultStyle="white" backgroundColor="light">
                Small button
              </SmallButton>
              <SmallButton icon={<Plus />} iconPlacement="right" defaultStyle="white" backgroundColor="light">
                Small button
              </SmallButton>
              <SmallButton defaultStyle="white" backgroundColor="light">
                Small button
              </SmallButton>
              <SmallButton defaultStyle="indigo" backgroundColor="light">
                Small button
              </SmallButton>
              <SmallButton defaultStyle="indigo" backgroundColor="dark">
                Small button
              </SmallButton>
              <SmallButton defaultStyle="solid" backgroundColor="dark">
                Small button
              </SmallButton>
              <SmallButton defaultStyle="outline" backgroundColor="light">
                Small button
              </SmallButton>
              <SmallButton defaultStyle="outline" backgroundColor="dark">
                Small button
              </SmallButton>
            </div>
            <div className="flex flex-col space-y-6">
              <ExtraSmallButton
                icon={<Plus className="h-4 w-4" />}
                iconPlacement="left"
                defaultStyle="white"
                backgroundColor="light">
                xs button
              </ExtraSmallButton>
              <ExtraSmallButton
                icon={<Plus className="h-4 w-4" />}
                iconPlacement="right"
                defaultStyle="white"
                backgroundColor="light">
                xs button
              </ExtraSmallButton>
              <ExtraSmallButton defaultStyle="white" backgroundColor="light">
                xs button
              </ExtraSmallButton>
              <ExtraSmallButton defaultStyle="indigo" backgroundColor="light">
                xs button
              </ExtraSmallButton>
              <ExtraSmallButton defaultStyle="indigo" backgroundColor="dark">
                xs button
              </ExtraSmallButton>
              <ExtraSmallButton defaultStyle="solid" backgroundColor="dark">
                xs button
              </ExtraSmallButton>
              <ExtraSmallButton defaultStyle="outline" backgroundColor="light">
                xs button
              </ExtraSmallButton>
              <ExtraSmallButton defaultStyle="outline" backgroundColor="dark">
                xs button
              </ExtraSmallButton>
            </div>
            <div className="flex flex-col space-y-6">
              <BigfootButton icon={<Plus />} iconPlacement="left" defaultStyle="white" backgroundColor="light">
                Bigfoot button
              </BigfootButton>
              <BigfootButton icon={<Plus />} iconPlacement="right" defaultStyle="white" backgroundColor="light">
                Bigfoot button
              </BigfootButton>
              <BigfootButton defaultStyle="white" backgroundColor="light">
                Bigfoot button
              </BigfootButton>
              <BigfootButton defaultStyle="indigo" backgroundColor="light">
                Bigfoot button
              </BigfootButton>
              <BigfootButton defaultStyle="indigo" backgroundColor="dark">
                Bigfoot button
              </BigfootButton>
              <BigfootButton defaultStyle="solid" backgroundColor="dark">
                Bigfoot button
              </BigfootButton>
              <BigfootButton defaultStyle="outline" backgroundColor="light">
                Bigfoot button
              </BigfootButton>
              <BigfootButton defaultStyle="outline" backgroundColor="dark">
                Bigfoot button
              </BigfootButton>
              <VippsButton href="" />
            </div>
            <div className="flex flex-col space-y-6">
              <TextButton icon={<Arrow className="h-[21px]" />} iconPlacement="left" backgroundColor="light">
                Text button
              </TextButton>
              <TextButton
                icon={<Arrow className="h-[21px] rotate-180" />}
                iconPlacement="right"
                backgroundColor="light">
                Text button
              </TextButton>
              <TextButton backgroundColor="light">Text button</TextButton>
              <TextButton icon={<Arrow className="h-[21px]" />} iconPlacement="left" backgroundColor="dark">
                Text button
              </TextButton>
              <TextButton icon={<Arrow className="h-[21px] rotate-180" />} iconPlacement="right" backgroundColor="dark">
                Text button
              </TextButton>
              <TextButton backgroundColor="dark">Text button</TextButton>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Dsm;
