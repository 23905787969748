import { config } from 'config';
import { getAccessToken } from 'utils/token-storage';
import { Fetcher } from 'swr';

export const fetcher: Fetcher<any, any> = async (...args: any) => {
  const { url, headers } = requestOptions(args);

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers,
    });

    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
      const error: any = new Error('An error occurred while fetching the data.');
      // Attach extra info to the error object.
      error.info = await res.json();
      error.status = res.status;
      console.error(error);
    }

    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const poster = async (path: string, data: any, shouldRevalidate = true) => {
  const { url, headers } = requestOptions(path);
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(data),
  });
  if (!res.ok) {
    const error: any = new Error('An error occurred while fetching the data.');
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }
  return res.json();
};

export const patcher = async (path: string, data: any, shouldRevalidate = true) => {
  const { url, headers } = requestOptions(path);
  const res = await fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(data),
  });
  if (!res.ok) {
    const error: any = new Error('An error occurred while fetching the data.');
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }
  return res.json();
};

export const deleter = async (path: string, shouldRevalidate = true) => {
  const { url, headers } = requestOptions(path);
  const res = await fetch(url, {
    method: 'DELETE',
    headers,
  });
  if (!res.ok) {
    const error: any = new Error('An error occurred while fetching the data.');
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }
  return res.json();
};

function requestOptions(url: string) {
  // return auth header with jwt if user is logged in and request is to the api url
  const token = getAccessToken();
  const updatedUrl = url.includes('http') ? url : `${config.apiUrl}${url}`;
  if (token && updatedUrl) {
    return { headers: { Authorization: `Bearer ${token}` }, url: updatedUrl };
  } else {
    return { headers: undefined, url: updatedUrl };
  }
}
