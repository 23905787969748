export function SignOut({ className, fill = '#100C3A' }: { className?: string; fill?: string }) {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 27C14.5 27.2652 14.3946 27.5196 14.2071 27.7071C14.0196 27.8946 13.7652 28 13.5 28H6.5C5.96957 28 5.46086 27.7893 5.08579 27.4142C4.71071 27.0391 4.5 26.5304 4.5 26V6C4.5 5.46957 4.71071 4.96086 5.08579 4.58579C5.46086 4.21071 5.96957 4 6.5 4H13.5C13.7652 4 14.0196 4.10536 14.2071 4.29289C14.3946 4.48043 14.5 4.73478 14.5 5C14.5 5.26522 14.3946 5.51957 14.2071 5.70711C14.0196 5.89464 13.7652 6 13.5 6H6.5V26H13.5C13.7652 26 14.0196 26.1054 14.2071 26.2929C14.3946 26.4804 14.5 26.7348 14.5 27ZM28.2075 15.2925L23.2075 10.2925C23.0199 10.1049 22.7654 9.99944 22.5 9.99944C22.2346 9.99944 21.9801 10.1049 21.7925 10.2925C21.6049 10.4801 21.4994 10.7346 21.4994 11C21.4994 11.2654 21.6049 11.5199 21.7925 11.7075L25.0863 15H13.5C13.2348 15 12.9804 15.1054 12.7929 15.2929C12.6054 15.4804 12.5 15.7348 12.5 16C12.5 16.2652 12.6054 16.5196 12.7929 16.7071C12.9804 16.8946 13.2348 17 13.5 17H25.0863L21.7925 20.2925C21.6049 20.4801 21.4994 20.7346 21.4994 21C21.4994 21.2654 21.6049 21.5199 21.7925 21.7075C21.9801 21.8951 22.2346 22.0006 22.5 22.0006C22.7654 22.0006 23.0199 21.8951 23.2075 21.7075L28.2075 16.7075C28.3005 16.6146 28.3742 16.5043 28.4246 16.3829C28.4749 16.2615 28.5008 16.1314 28.5008 16C28.5008 15.8686 28.4749 15.7385 28.4246 15.6171C28.3742 15.4957 28.3005 15.3854 28.2075 15.2925Z"
        fill={fill}
        fillOpacity="0.9"
      />
    </svg>
  );
}
