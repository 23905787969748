import { Report } from '__generated__/UtleggsappenApi';
import { fetcher } from 'api';
import useSWR from 'swr';

export function useReport(reportId: string) {
  // ! Denne har med en del felt som ikke er reelle. Det var vanskelig å generere typen manuelt, da det var mye som var med også
  const { data, error, mutate, isLoading } = useSWR<Report>(`/reports/${reportId}`, fetcher);

  return {
    data,
    isLoading,
    error,
    mutate,
  };
}
