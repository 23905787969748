import { useTheme } from 'context/theme';
import { cn } from 'utils/className';

export function LoadingIndicator({
  backgroundStyle = 'accentstrong',
  size = 'med',
}: {
  backgroundStyle?: 'primary' | 'accent' | 'accentstrong';
  size: 'xs' | 'sml' | 'med' | 'lrg';
}) {
  const theme = useTheme();

  let pixelSize;

  switch (size) {
    case 'xs':
      pixelSize = 8;
      break;
    case 'sml':
      pixelSize = 12;
      break;
    case 'med':
      pixelSize = 16;
      break;
    case 'lrg':
      pixelSize = 24;
      break;
    default:
      pixelSize = 4;
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="flex items-center justify-center">
        <div
          className="animate-loadingBounce rounded-full border"
          style={{
            backgroundColor: theme.background[backgroundStyle],
            borderColor: theme.border.border,
            width: `${pixelSize}px`,
            height: `${pixelSize}px`,
          }}
        />
        <div
          className="animate-loadingBounce rounded-full border"
          style={{
            animationDelay: '200ms',
            backgroundColor: theme.background[backgroundStyle],
            borderColor: theme.border.border,
            width: `${pixelSize}px`,
            height: `${pixelSize}px`,
            marginLeft: `${pixelSize / 3}px`,
          }}
        />
        <div
          className="animate-loadingBounce rounded-full border"
          style={{
            animationDelay: '400ms',
            backgroundColor: theme.background[backgroundStyle],
            borderColor: theme.border.border,
            width: `${pixelSize}px`,
            height: `${pixelSize}px`,
            marginLeft: `${pixelSize / 3}px`,
          }}
        />
      </div>
    </div>
  );
}

export function FullscreenLoading({ fixed, relative }: { fixed?: boolean; relative?: boolean }) {
  return (
    <div
      className={cn(
        'left-0 top-0 flex h-full w-full',
        fixed ? 'fixed' : '',
        relative ? 'relative' : '',
        (!fixed && !relative) ?? 'absolute',
      )}>
      <LoadingIndicator size="lrg" />
    </div>
  );
}
