import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Close } from 'assets/svgcomponents/Close';
import { LargeButton } from 'components/buttons/LargeButton';
import { useTheme } from 'context/theme';
import { ReactNode, forwardRef, useEffect, useImperativeHandle, useState } from 'react';

export const AlertInstance = forwardRef((props: any, ref: any) => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const [style, setStyle] = useState<'critical' | 'indigo' | 'white' | 'solid' | 'outline' | 'success'>('white');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [children, setChildren] = useState<ReactNode>(null);
  const [actionLabel, setActionLabel] = useState('');
  const [onAction, setOnAction] = useState(() => () => null);
  const [loading, setLoading] = useState(false);
  const [disableDefaultAction, setDisableDefaultAction] = useState(false);

  useEffect(() => {
    const html = document.querySelector('html');
    if (isVisible) {
      if (html) {
        html.style.scrollbarGutter = '';
      }
    }
  }, [isVisible]);

  const open = (
    style: 'critical' | 'indigo' | 'white' | 'solid' | 'outline' | 'success',
    title: string,
    description: string,
    children: ReactNode,
    actionLabel: string,
    onAction: () => null,
    loading: boolean,
    disableDefaultAction: boolean,
  ) => {
    setStyle(style);
    setTitle(title);
    setDescription(description);
    setChildren(children);
    setActionLabel(actionLabel);
    setOnAction(() => onAction);
    setLoading(loading);
    setDisableDefaultAction(disableDefaultAction);
    setIsVisible(true);
  };

  useImperativeHandle(ref, () => ({
    open,
    close: () => setIsVisible(false),
  }));

  return (
    <AlertDialog.Root open={isVisible} onOpenChange={() => setIsVisible(false)}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="DialogOverlay fixed inset-0 z-[49] bg-black/50" />
        <AlertDialog.Content
          className={`DialogContent fixed left-1/2 top-1/2 z-50 flex w-[501px] -translate-x-1/2 -translate-y-1/2 flex-col justify-center rounded-xlarge p-4 pb-6 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.15)] ${children ? 'space-y-3' : 'space-y-6'}`}
          style={{ backgroundColor: theme.background.primary }}>
          <div className="flex items-center justify-between">
            <AlertDialog.Title className="smalltextbold">{title}</AlertDialog.Title>
            <AlertDialog.Cancel asChild>
              <button
                disabled={loading}
                className="flex h-[24px] w-[24px] items-center justify-center rounded-full"
                style={{ backgroundColor: theme.background.accentemphasis }}>
                <Close className="h-[16px] w-[16px]" />
              </button>
            </AlertDialog.Cancel>
          </div>
          {description && (
            <AlertDialog.Description className="bodysmall text-center">{description}</AlertDialog.Description>
          )}
          {children && children}
          <div className="flex justify-center space-x-3">
            <LargeButton
              backgroundColor="light"
              defaultStyle="white"
              onClick={() => setIsVisible(false)}
              disabled={loading}>
              Avbryt
            </LargeButton>
            {disableDefaultAction ? (
              <LargeButton backgroundColor="light" defaultStyle={style} onClick={onAction} loading={loading}>
                {actionLabel}
              </LargeButton>
            ) : (
              <AlertDialog.Action asChild>
                <LargeButton backgroundColor="light" defaultStyle={style} onClick={onAction} loading={loading}>
                  {actionLabel}
                </LargeButton>
              </AlertDialog.Action>
            )}
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
});
