import { cn } from 'utils/className';

export function Approved({ className, fill }: { className?: string; fill?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
      fill={fill}
      className={cn('h-[29px] w-[29px] p-1', className)}>
      <path
        d="M14.2456 8.06695C14.3066 8.1279 14.355 8.20028 14.388 8.27995C14.421 8.35961 14.438 8.44501 14.438 8.53125C14.438 8.61749 14.421 8.70289 14.388 8.78255C14.355 8.86222 14.3066 8.9346 14.2456 8.99555L9.6518 13.5893C9.59085 13.6503 9.51848 13.6987 9.43881 13.7317C9.35914 13.7648 9.27375 13.7818 9.1875 13.7818C9.10126 13.7818 9.01587 13.7648 8.9362 13.7317C8.85653 13.6987 8.78415 13.6503 8.72321 13.5893L6.75446 11.6205C6.63132 11.4974 6.56214 11.3304 6.56214 11.1562C6.56214 10.9821 6.63132 10.8151 6.75446 10.692C6.8776 10.5688 7.04461 10.4996 7.21875 10.4996C7.3929 10.4996 7.55991 10.5688 7.68305 10.692L9.1875 12.1972L13.317 8.06695C13.3779 8.00594 13.4503 7.95753 13.5299 7.92451C13.6096 7.89148 13.695 7.87448 13.7813 7.87448C13.8675 7.87448 13.9529 7.89148 14.0326 7.92451C14.1122 7.95753 14.1846 8.00594 14.2456 8.06695ZM19.0313 10.5C19.0313 12.1873 18.5309 13.8368 17.5935 15.2397C16.6561 16.6427 15.3237 17.7361 13.7648 18.3818C12.2059 19.0276 10.4905 19.1965 8.83564 18.8673C7.18074 18.5381 5.66062 17.7256 4.4675 16.5325C3.27438 15.3394 2.46186 13.8193 2.13268 12.1644C1.8035 10.5095 1.97245 8.79411 2.61816 7.23523C3.26387 5.67635 4.35734 4.34395 5.7603 3.40652C7.16325 2.4691 8.81268 1.96875 10.5 1.96875C12.7619 1.97114 14.9305 2.87073 16.5299 4.47013C18.1293 6.06954 19.0289 8.2381 19.0313 10.5ZM17.7188 10.5C17.7188 9.07227 17.2954 7.67659 16.5022 6.48948C15.709 5.30236 14.5816 4.37711 13.2625 3.83074C11.9434 3.28437 10.492 3.14142 9.0917 3.41996C7.6914 3.69849 6.40514 4.38601 5.39558 5.39557C4.38602 6.40513 3.6985 7.69139 3.41996 9.09169C3.14142 10.492 3.28438 11.9434 3.83075 13.2625C4.37712 14.5816 5.30236 15.709 6.48948 16.5022C7.6766 17.2954 9.07227 17.7188 10.5 17.7188C12.4139 17.7166 14.2487 16.9553 15.602 15.602C16.9553 14.2487 17.7166 12.4139 17.7188 10.5Z"
        fill={fill}
        stroke="none"
        fillOpacity="1"
      />
    </svg>
  );
}
