import { api } from 'utils/fetch-wrapper';

const apiUrl = '/users';

export const userService = {
  update,
  _delete,
};

function update(
  id: string,
  params: {
    passwordReset?: Date;
    resetTokenExpires?: Date;
    resetToken?: string;
    streetAddress?: string;
    postalCode?: string;
    region?: string;
    vippsId?: string;
    address?: {
      streetAddress?: string;
      postalCode?: string;
      region?: string;
      country?: string;
    };
    birthDate?: Date;
    givenName?: string;
    familyName?: string;
    phoneNumber?: string;
    email?: string;
    password?: string;
    accountNumber?: string;
    isEmailVerified?: boolean;
  },
) {
  return api.patch(`${apiUrl}/${id}`, params);
}

function _delete(id: string) {
  return api.delete(`${apiUrl}/${id}`);
}
