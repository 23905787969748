import { Car } from 'assets/svgcomponents/Car';
import { CaretRight } from 'assets/svgcomponents/CaretRight';
import { Company } from 'assets/svgcomponents/Company';
import { CreditCard } from 'assets/svgcomponents/CreditCard';
import { Edit } from 'assets/svgcomponents/Edit';
import { Folder } from 'assets/svgcomponents/Folder';
import { Plane } from 'assets/svgcomponents/Plane';
import { useAccess } from 'context/AccessContext';
import { useStoredOrg } from 'context/OrganizationContext';
import { useTheme } from 'context/theme';
import { useNavigate } from 'react-router-dom';

function SettingsCategory({
  icon,
  title,
  description,
  navigate,
  onClick,
}: Readonly<{
  icon: JSX.Element;
  title: string;
  description?: string;
  navigate?: string;
  onClick?: () => void;
}>) {
  const theme = useTheme();
  const navigation = useNavigate();

  return (
    <button
      className="-mx-6 flex flex-row items-center justify-between rounded-large px-6 py-3 outline-none ring-v2-purple-500 transition-all hover:cursor-pointer hover:bg-v2-purple-200 focus-visible:ring active:bg-v2-purple-300"
      onClick={navigate ? () => navigation(navigate) : onClick ? () => onClick() : undefined}>
      <div className="flex w-full flex-row gap-x-4">
        <div>{icon}</div>
        <div className="flex flex-col items-start">
          <h3 className="smalltextbold px-2.5 py-1 text-left">{title}</h3>
          {description && <h2 className="smalltext px-2.5 py-1 text-left">{description}</h2>}
        </div>
      </div>
      <CaretRight fill={theme.label.primary} className="h-6 cursor-pointer transition-all" />
    </button>
  );
}

export function Settings() {
  const theme = useTheme();
  const { combinedRole } = useAccess();
  const { organization } = useStoredOrg();

  if (combinedRole !== null && combinedRole < 2) {
    return (
      <>
        <div
          className="flex flex-col justify-between gap-y-8 py-5"
          style={{
            color: theme.label.primary,
          }}>
          <h1 className="bigheading">Innstillinger</h1>
        </div>
        <h2 className="normalheading mb-1">Organisasjon</h2>
        <div className="flex flex-col">
          <SettingsCategory
            icon={<Company fill={theme.label.primary} className="h-full w-8 p-0" />}
            title="Organisasjonsinformasjon"
            description="Oppdater kontakt- og juridisk informasjon relatert til din organisasjon"
            navigate="organisasjon"
          />
          <SettingsCategory
            icon={<CreditCard fill={theme.label.primary} className="h-full w-8 p-0" />}
            title="Abonnement"
            description="Endre abonnementstype, oppdater betalingsopplysninger og se fakturaer"
            navigate="abonnement"
          />
        </div>
        <h2 className="normalheading mb-1 mt-3">Utlegg</h2>
        <div className="flex flex-col">
          <SettingsCategory
            icon={<Edit fill={theme.label.primary} className="h-full w-8 p-0" />}
            description="Her kan du tilpasse grunnleggende innstillinger for utlegg"
            title="Administrer"
            navigate="administrer"
          />
          {organization.subscription?.subscriptionTier.drives && (
            <SettingsCategory
              icon={<Car fill={theme.label.primary} className="h-full w-8 p-0" />}
              description="Angi satser og regler for kjøregodtgjørelse for ansatte"
              title="Kjøregodtgjørelse"
              navigate="kjoregodtgjorelse"
            />
          )}
          {organization.subscription?.subscriptionTier.travels && (
            <SettingsCategory
              icon={<Plane fill={theme.label.primary} className="h-full w-8 p-0" />}
              description="Definer diettplaner"
              title="Dietter"
              navigate="dietter"
            />
          )}
          <SettingsCategory
            icon={<Folder fill={theme.label.primary} className="h-full w-8 p-0" />}
            description="Tilpass og definer ulike kategorier for registrering av utlegg"
            title="Utleggstyper"
            navigate="utleggstyper"
          />
        </div>
      </>
    );
  } else {
    return <></>;
  }
}
