import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Department } from '__generated__/UtleggsappenApi';
import { useAccounts } from 'api/accounts/useAccounts';
import { useDepartments } from 'api/departments/useDepartments';
import { useOrganization } from 'api/organizations/useOrganization';
import { Plus } from 'assets/svgcomponents/Plus';
import { alert } from 'components/alerts/Alert';
import { SmallButton } from 'components/buttons/SmallButton';
import { Chip } from 'components/chips/Chip';
import Input from 'components/input-field/Input';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import Table from 'components/table/Table';
import { DefaultCell, HeaderCell, TagWrapper } from 'components/table/TableComponents';
import { toast } from 'components/toast/Toast';
import { useTheme } from 'context/theme';
import { Form, Formik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { departmentService } from 'services/departmentService';
import { formatPhoneNumber } from 'utils/format';
import { sortAlphaSpecialLast } from 'utils/sort';
import * as Yup from 'yup';

export default function Departments() {
  const { data: departments, isLoading: isLoadingDepartments, mutate: mutateDepartments } = useDepartments();
  const { data: organization } = useOrganization();
  const { data: accounts } = useAccounts();
  const submitFormRef = useRef<() => Promise<void>>();
  const theme = useTheme();
  const { hash } = useLocation();

  const [isLoadingAdding, setIsLoadingAdding] = useState(false);

  useEffect(() => {
    if (hash.slice(1) === 'ny') showAddAlert();
  }, [hash]);

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: 'title',
        header: () => <HeaderCell>Avdeling</HeaderCell>,
        cell: (value: CellContext<(typeof departmentsData)[number], (typeof departmentsData)[number]['title']>) => (
          <DefaultCell className="smalltextbold">{value.getValue()}</DefaultCell>
        ),
      },
      {
        id: 'accounts',
        accessorFn: (departments: (typeof departmentsData)[number]) =>
          departments.accounts
            .map((account) => `${account.user.givenName ?? ''} ${account.user.familyName ?? ''}`)
            .join(', '),
        header: () => <HeaderCell>Ansvarlig</HeaderCell>,
        cell: (value: CellContext<(typeof departmentsData)[number], string>) => (
          <TagWrapper>
            {value.row.original.accounts
              .slice(0, 3)
              .sort((a, b) =>
                sortAlphaSpecialLast(
                  (accounts?.data?.find((acc) => acc.id === a.id) as any)?.activatedAt ? a.user.givenName : '',
                  (accounts?.data?.find((acc) => acc.id === b.id) as any)?.activatedAt ? b.user.givenName : '',
                ),
              )
              .map((account) => {
                const a = accounts?.data?.find((a) => a.id === account.id);
                if (a) {
                  return (
                    <Chip
                      key={account.id}
                      value={
                        account.user.givenName || account.user.familyName
                          ? `${account.user.givenName} ${account.user.familyName}`
                          : `${formatPhoneNumber(a?.user?.phoneNumber || '')}`
                      }
                      type="accent"
                      size="large"
                    />
                  );
                } else return null;
              })}
            {value.row.original.accounts.length > 3 && (
              <Chip type="overlay" value={`+${value.row.original.accounts.length - 3} ansvarlige`} size="large" />
            )}
          </TagWrapper>
        ),
      },
      {
        id: 'projects',
        accessorFn: (department: (typeof departmentsData)[number]) =>
          department.projects.map((project) => `${project.title ?? ''}`).join(', '),
        header: () => <HeaderCell>Prosjekter</HeaderCell>,
        cell: (value: CellContext<(typeof departmentsData)[number], string>) => (
          <TagWrapper>
            {value.row.original.projects
              .sort((a, b) => sortAlphaSpecialLast(a.title, b.title))
              .slice(0, 4)
              .map((project) => {
                return <Chip key={project.id} type="accent" value={project.title} size="large" />;
              })}
            {value.row.original.projects.length > 4 && (
              <Chip type="overlay" value={`+${value.row.original.projects.length - 4} prosjekter`} size="large" />
            )}
          </TagWrapper>
        ),
      },
    ],
    [accounts],
  );

  const departmentsData = useMemo(
    () =>
      departments?.map((dep) => ({
        ...dep,
        navigate: `${dep.id}`,
      })) || [],
    [departments],
  );

  const handleAdd = async (department: Partial<Department>) => {
    setIsLoadingAdding(true);
    await departmentService
      .create({ ...department, organizationId: organization!.id })
      .then(() => {
        toast.success({ description: 'Avdelingen ble lagt til' });
        mutateDepartments();
      })
      .catch(() => {
        toast.error({ description: 'Det oppsto en feil' });
      })
      .finally(() => {
        alert.close();
        setIsLoadingAdding(false);
      });
  };

  const showAddAlert = () => {
    alert.info({
      title: 'Ny avdeling',
      actionLabel: 'Opprett',
      loading: isLoadingAdding,
      onAction: () => {
        if (submitFormRef.current) submitFormRef.current();
      },
      disableDefaultAction: true,
      children: (
        <Formik
          onSubmit={(formValues) => handleAdd(formValues)}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{ title: '' }}
          validationSchema={Yup.object({ title: Yup.string().required('Påkrevd') })}>
          {({ values, handleSubmit, handleBlur, errors, handleChange, submitForm }) => {
            submitFormRef.current = submitForm;
            return (
              <Form onSubmit={handleSubmit} className="flex w-full flex-col items-center">
                <div className="flex w-full flex-col space-y-2">
                  <Input.Text
                    focusOnMount
                    label="Navn på avdeling"
                    value={values.title || ''}
                    onChange={handleChange}
                    error={errors.title as any}
                    onBlur={handleBlur}
                    name="title"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      ),
    });
  };

  return (
    <>
      <div className="flex flex-row justify-between pt-5">
        <h1 className="bigheading" style={{ color: theme.label.primary }}>
          Avdelinger
        </h1>
        <div className="flex flex-row gap-x-4">
          <SmallButton
            backgroundColor="light"
            defaultStyle="indigo"
            className="h-fit px-3.5 py-2"
            icon={<Plus className="h-4 w-4" />}
            iconPlacement="left"
            onClick={showAddAlert}>
            Ny avdeling
          </SmallButton>
        </div>
      </div>
      <p className="caption max-w-[480px]">
        Organiser brukerne dine i avdelinger. Som standard bruker avdelingene godkjenningsinnstillingene som er satt opp
        på organisasjonsnivå, du kan imidlertid overstyre dette og spesifisere egendefinerte godkjennere for hver
        avdeling.{' '}
      </p>
      <div className="flex w-full flex-1 flex-col">
        {!isLoadingDepartments && departments ? (
          <Table
            columns={columns}
            data={departmentsData}
            gridCols="minmax(100px, 1fr) minmax(0, 2fr) minmax(0, 3fr) 68px"
          />
        ) : (
          <FullscreenLoading />
        )}
      </div>
    </>
  );
}
