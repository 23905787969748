export function OrganizationsIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="403"
      height="307"
      viewBox="0 0 403 307"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2077_808)">
        <path
          opacity="0.2"
          d="M118.036 7.87052C126.387 3.40626 213.314 94.7448 213.314 94.7448C213.314 94.7448 298.111 -3.28031 301.064 0.0826391C305.702 5.36109 287.909 123.418 287.909 123.418C287.909 123.418 403 137.389 403 143.569C403 147.879 287.909 170.645 287.909 170.645C287.909 170.645 369.218 293.953 363.218 297.953C356.564 302.39 215.601 235.167 215.601 235.167C215.601 235.167 176.181 307.968 171.657 306.988C165.362 305.623 161.283 204.534 161.283 204.534C161.283 204.534 -0.0118112 168.537 6.48717e-07 159.848C0.00393793 155.325 143.337 132.449 143.337 132.449C143.337 132.449 110.63 11.8274 118.036 7.87052Z"
          fill="#9676DC"
        />
        <path
          d="M190.206 90.485C189.8 87.1535 126.213 86.2803 120.859 87.6255C115.461 88.9825 34.6047 111.748 33.2621 114.045H33.27C33.1361 114.124 33.0298 114.211 32.955 114.297C28.4704 119.355 29.6398 276.781 35.3331 281.91C40.4634 286.535 149.821 287.487 155.428 282.539C157.145 282.205 184.82 236.181 187.379 227.839C189.21 221.868 191.426 91.4251 190.206 90.485ZM158.168 113.864C158.153 113.904 158.137 113.935 158.125 113.967C158.105 113.939 158.078 113.916 158.042 113.888C158.109 113.88 158.153 113.872 158.168 113.868V113.864Z"
          fill="#9676DC"
        />
        <path
          d="M137.982 87.0198C158.538 87.0198 189.922 88.1448 190.21 90.4851C191.43 91.4251 189.214 221.868 187.383 227.839C184.824 236.181 157.148 282.205 155.432 282.539C152.857 284.809 128.422 285.835 102.239 285.835C71.3906 285.835 38.1127 284.407 35.3369 281.906C29.6436 276.777 28.4743 119.351 32.9588 114.293C33.0376 114.207 33.1399 114.12 33.2738 114.041H33.2659C34.6086 111.744 115.464 88.9786 120.862 87.6216C122.461 87.2204 129.241 87.0159 137.986 87.0159M158.125 113.967C158.137 113.935 158.148 113.904 158.168 113.864C158.152 113.872 158.109 113.88 158.042 113.884C158.078 113.912 158.105 113.935 158.125 113.963M137.982 86.6265C128.599 86.6265 122.323 86.8507 120.764 87.244C118 87.9402 96.2663 94.0211 75.4302 100.106C36.148 111.575 33.3801 113.247 32.9785 113.766C32.8525 113.853 32.7462 113.943 32.6635 114.038C31.5375 115.308 30.7225 124.677 30.246 141.889C29.8208 157.221 29.7106 177.178 29.9389 198.084C30.1712 219.532 30.7421 239.493 31.5414 254.29C32.947 280.285 34.5495 281.729 35.0771 282.201C36.4591 283.444 44.2509 284.455 58.2401 285.202C70.4456 285.855 86.4822 286.228 102.239 286.228C110.472 286.228 151.534 286.067 155.625 282.889C155.967 282.74 156.873 281.87 161.121 275.235C164.019 270.708 167.681 264.725 171.433 258.393C178.449 246.542 186.422 232.323 187.761 227.953C188.741 224.763 189.761 187.889 190.336 157.28C190.655 140.414 190.871 123.729 190.934 111.501C191.025 94.4026 190.82 91.138 190.596 90.4064C190.517 89.8636 189.946 89.2303 184.871 88.5617C181.733 88.1487 177.311 87.7829 171.72 87.4761C162.102 86.949 149.175 86.6187 137.986 86.6187L137.982 86.6265Z"
          fill="black"
        />
        <path
          d="M187.379 227.839C184.82 236.181 157.144 282.205 155.428 282.539C155.495 282.484 155.546 282.421 155.585 282.362C159.227 276.36 161.716 118.297 158.125 113.971C158.137 113.939 158.148 113.908 158.168 113.868C159.696 113.259 190.43 92.3259 190.206 90.4851C191.426 91.4252 189.21 221.868 187.379 227.839Z"
          fill="#9676DC"
        />
        <path
          d="M190.206 90.485C190.43 92.3258 159.696 113.259 158.168 113.868C158.153 113.876 158.109 113.884 158.042 113.888C153.507 114.352 37.8649 113.164 33.2701 114.045H33.2622C34.6048 111.748 115.461 88.9825 120.859 87.6255C126.213 86.2803 189.8 87.1535 190.206 90.485Z"
          fill="#9676DC"
        />
        <path
          opacity="0.15"
          d="M187.379 227.839C184.82 236.181 157.144 282.205 155.428 282.539C155.495 282.484 155.546 282.421 155.585 282.362C159.227 276.36 161.716 118.297 158.125 113.971C158.137 113.939 158.148 113.908 158.168 113.868C159.696 113.259 190.43 92.3259 190.206 90.4851C191.426 91.4252 189.21 221.868 187.379 227.839Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M190.206 90.485C190.43 92.3258 159.696 113.259 158.168 113.868C158.153 113.876 158.109 113.884 158.042 113.888C153.507 114.352 37.8649 113.164 33.2701 114.045H33.2622C34.6048 111.748 115.461 88.9825 120.859 87.6255C126.213 86.2803 189.8 87.1535 190.206 90.485Z"
          fill="black"
        />
        <path
          d="M155.585 282.358C155.546 282.417 155.495 282.48 155.428 282.535C149.821 287.479 40.4634 286.527 35.3331 281.906C29.6398 276.777 28.4704 119.351 32.955 114.293C33.0337 114.207 33.1361 114.12 33.27 114.041C41.428 108.901 153.349 110.1 158.042 113.884C158.078 113.912 158.105 113.935 158.125 113.963C161.716 118.289 159.223 276.352 155.585 282.354V282.358Z"
          fill="#9676DC"
        />
        <g opacity="0.5">
          <path
            d="M119.106 201.384C119.902 199.575 137.005 199.221 137.486 201.211C138.041 203.528 138.423 264.588 137.875 266.259C137.379 267.77 119.488 268.246 118.744 266.133C118.059 264.179 118.236 203.37 119.106 201.388V201.384Z"
            fill="white"
          />
          <path
            d="M52.6294 224.929C53.4247 226.585 70.5283 226.907 71.0086 225.086C71.5638 222.966 71.9457 167.094 71.3984 165.564C70.9023 164.183 53.0113 163.747 52.2672 165.682C51.5821 167.471 51.7593 223.115 52.6294 224.929Z"
            fill="white"
          />
          <path
            d="M87.4663 166.162C88.2617 165.556 105.365 165.438 105.846 166.103C106.401 166.877 106.783 187.326 106.235 187.889C105.739 188.392 87.8482 188.554 87.1041 187.846C86.419 187.193 86.5962 166.826 87.4663 166.162Z"
            fill="white"
          />
          <path
            d="M87.4663 244.669C88.2617 244.107 105.365 243.997 105.846 244.614C106.401 245.338 106.783 264.359 106.235 264.879C105.739 265.351 87.8482 265.496 87.1041 264.839C86.419 264.23 86.5962 245.283 87.4663 244.666V244.669Z"
            fill="white"
          />
          <path
            d="M119.106 186.272C119.902 186.835 137.005 186.945 137.486 186.328C138.041 185.604 138.423 166.582 137.875 166.063C137.379 165.591 119.488 165.446 118.744 166.103C118.059 166.712 118.236 185.659 119.106 186.276V186.272Z"
            fill="white"
          />
          <path
            d="M119.106 153.064C119.902 153.626 137.005 153.737 137.486 153.119C138.041 152.395 138.423 133.374 137.875 132.855C137.379 132.383 119.488 132.237 118.744 132.894C118.059 133.504 118.236 152.45 119.106 153.068V153.064Z"
            fill="white"
          />
          <path
            d="M87.4663 153.064C88.2617 153.626 105.365 153.737 105.846 153.119C106.401 152.395 106.783 133.374 106.235 132.855C105.739 132.383 87.8482 132.237 87.1041 132.894C86.419 133.504 86.5962 152.45 87.4663 153.068V153.064Z"
            fill="white"
          />
          <path
            d="M70.8787 132.921C70.0834 132.359 52.9798 132.249 52.4995 132.866C51.9443 133.59 51.5624 152.611 52.1097 153.131C52.6058 153.603 70.4968 153.748 71.2409 153.091C71.926 152.482 71.7488 133.535 70.8787 132.917V132.921Z"
            fill="white"
          />
          <path
            d="M54.228 264.812C55.0234 265.374 72.1269 265.485 72.6073 264.867C73.1624 264.143 73.5443 245.122 72.997 244.603C72.5009 244.131 54.61 243.985 53.8658 244.642C53.1807 245.252 53.3579 264.198 54.228 264.816V264.812Z"
            fill="white"
          />
          <path
            d="M87.4663 224.543C88.2617 225.157 105.365 225.279 105.846 224.602C106.401 223.816 106.783 203.079 106.235 202.509C105.739 201.998 87.8482 201.836 87.1041 202.552C86.419 203.217 86.5962 223.867 87.4663 224.543Z"
            fill="white"
          />
          <path
            d="M87.4663 224.543C88.2617 225.157 105.365 225.279 105.846 224.602C106.401 223.816 106.783 203.079 106.235 202.509C105.739 201.998 87.8482 201.836 87.1041 202.552C86.419 203.217 86.5962 223.867 87.4663 224.543Z"
            fill="white"
          />
        </g>
        <path
          d="M273.219 177.375C273.003 180.077 235.744 180.085 232.902 178.992C230.024 177.89 201.191 158.393 200.478 156.529C197.6 151.946 199.112 11.3829 201.38 6.86751C203.833 1.98631 261.943 1.33339 263.585 4.82221C264.498 5.0936 278.082 37.197 278.724 40.91C279.271 44.0645 273.869 176.604 273.223 177.371L273.219 177.375Z"
          fill="#00C8A7"
        />
        <path
          d="M239.985 2.62361C252.269 2.62361 262.892 3.35913 263.581 4.82231C264.494 5.0937 278.078 37.1971 278.72 40.9101C279.267 44.0646 273.865 176.604 273.219 177.371C273.086 179.027 259.065 179.668 247.56 179.668C240.276 179.668 234 179.409 232.898 178.988C230.02 177.886 201.187 158.389 200.474 156.525C197.596 151.943 199.108 11.3791 201.376 6.86368C202.801 4.02779 222.984 2.61967 239.985 2.61967M239.989 2.23028C227.283 2.23028 202.797 3.16246 201.025 6.69061C199.825 9.08204 198.899 46.22 198.604 81.6706C198.506 93.6081 198.112 153.213 200.128 156.714C200.797 158.059 210.117 164.576 215.656 168.368C222.779 173.249 231.197 178.756 232.76 179.357C234.122 179.881 241.583 180.065 247.564 180.065C253.903 180.065 260.144 179.873 264.687 179.534C273.495 178.882 273.578 177.882 273.617 177.403H273.613C273.763 176.691 274.023 173.426 274.83 156.187C275.405 143.923 276.109 127.537 276.818 110.049C278.165 76.7816 279.401 42.4795 279.113 40.8393C278.759 38.794 274.853 29.0985 271.621 21.4168C269.77 17.0233 267.991 12.9288 266.605 9.88443C264.75 5.80169 264.172 4.82231 263.892 4.55091C263.66 4.13398 262.79 3.3434 256.171 2.77307C252.021 2.41514 246.277 2.22241 239.993 2.22241L239.989 2.23028Z"
          fill="black"
        />
        <path
          d="M273.219 177.375C273.003 180.077 235.744 180.085 232.902 178.992C230.023 177.89 201.191 158.393 200.478 156.529C202.903 157.256 259.234 156.34 259.923 156.765C260.738 157.26 273.341 175.877 273.219 177.375Z"
          fill="#00C8A7"
        />
        <path
          d="M273.219 177.375C273.338 175.877 260.738 157.264 259.923 156.765C259.911 156.737 259.908 156.71 259.904 156.686C258.868 151.514 262.317 4.45665 263.581 4.82638C264.494 5.09778 278.078 37.2012 278.72 40.9142C279.267 44.0687 273.865 176.608 273.219 177.375Z"
          fill="#00C8A7"
        />
        <path
          opacity="0.15"
          d="M273.219 177.375C273.003 180.077 235.744 180.085 232.902 178.992C230.023 177.89 201.191 158.393 200.478 156.529C202.903 157.256 259.234 156.34 259.923 156.765C260.738 157.26 273.341 175.877 273.219 177.375Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M273.219 177.375C273.338 175.877 260.738 157.264 259.923 156.765C259.911 156.737 259.908 156.71 259.904 156.686C258.868 151.514 262.317 4.45665 263.581 4.82638C264.494 5.09778 278.078 37.2012 278.72 40.9142C279.267 44.0687 273.865 176.608 273.219 177.375Z"
          fill="black"
        />
        <path
          d="M200.474 156.533C203.1 160.71 258.439 161.178 260.014 156.584C261.852 151.227 265.388 8.68882 263.573 4.82634C261.935 1.33753 203.821 1.98651 201.372 6.87164C199.104 11.387 197.596 151.954 200.47 156.533H200.474Z"
          fill="#00C8A7"
        />
        <g opacity="0.5">
          <path
            d="M213.314 18.3134C213.845 16.6221 225.342 16.2917 225.661 18.1522C226.035 20.3194 226.291 77.3755 225.921 78.937C225.586 80.349 213.566 80.7935 213.066 78.819C212.605 76.9939 212.727 20.1699 213.31 18.3173L213.314 18.3134Z"
            fill="white"
          />
          <path
            d="M238.335 18.2859C238.839 16.63 249.73 16.3075 250.033 18.1286C250.387 20.2486 250.632 76.1366 250.281 77.6666C249.966 79.0472 238.576 79.4838 238.103 77.5486C237.666 75.759 237.78 20.0992 238.335 18.2859Z"
            fill="white"
          />
          <path
            d="M238.335 93.8716C238.839 92.3573 249.73 92.0623 250.033 93.726C250.387 95.6691 250.632 146.817 250.281 148.218C249.966 149.48 238.576 149.881 238.103 148.111C237.666 146.475 237.78 95.5314 238.335 93.8716Z"
            fill="white"
          />
          <path
            d="M213.314 94.7449C213.845 93.2305 225.342 92.9355 225.661 94.5993C226.035 96.5424 226.291 147.691 225.921 149.091C225.586 150.353 213.566 150.755 213.066 148.985C212.605 147.348 212.727 96.4047 213.31 94.7449H213.314Z"
            fill="white"
          />
        </g>
        <path
          d="M389.534 189.482C386.42 186.74 237.748 185.273 234.795 188.227C232.91 188.593 202.006 222.124 199.226 228.571C196.517 234.864 194.966 284.478 196.305 285.513C196.529 287.141 349.988 287.066 352.39 286.457C354.497 285.922 384.286 276.384 386.79 274.193C386.806 274.177 386.826 274.161 386.841 274.145C389.893 270.727 393.035 192.569 389.53 189.486L389.534 189.482ZM232.457 273.131C232.457 273.131 232.457 273.131 232.449 273.131C232.472 273.091 232.492 273.052 232.5 273.021C232.52 273.048 232.579 273.072 232.661 273.099C232.551 273.111 232.48 273.115 232.457 273.127V273.131Z"
          fill="#FFB938"
        />
        <path
          d="M286.898 186.602C331.094 186.602 387.629 187.802 389.535 189.482C393.039 192.569 389.897 270.727 386.845 274.141C386.83 274.157 386.81 274.177 386.794 274.189C384.29 276.379 354.501 285.918 352.394 286.453C351.441 286.692 326.676 286.85 297.135 286.85C252.285 286.85 196.443 286.488 196.305 285.505C194.966 284.47 196.517 234.856 199.226 228.563C202.006 222.116 232.91 188.581 234.796 188.219C235.941 187.074 258.951 186.594 286.898 186.594M232.449 273.13C232.449 273.13 232.449 273.13 232.457 273.13C232.481 273.119 232.551 273.115 232.662 273.103C232.579 273.075 232.52 273.052 232.5 273.024C232.488 273.06 232.473 273.099 232.449 273.134M286.898 186.209C272.223 186.209 259.734 186.343 250.785 186.591C236.39 186.992 234.93 187.59 234.575 187.889C232.811 188.742 223.248 198.965 217.054 205.852C213.243 210.088 200.667 224.232 198.868 228.413C197.092 232.539 196.116 252.862 195.856 258.994C195.58 265.555 195.423 272.139 195.423 277.06C195.423 284.069 195.71 285.355 195.947 285.697C196.096 286.063 197.045 286.272 205.798 286.523C211.444 286.685 219.57 286.826 229.945 286.94C247.686 287.133 272.172 287.243 297.131 287.243C322.089 287.243 351.371 287.113 352.485 286.83C353.93 286.464 384.392 276.804 387.05 274.48C387.066 274.468 387.093 274.44 387.121 274.413C387.125 274.409 387.133 274.401 387.137 274.397C388.964 272.352 390.251 249.039 390.605 241.94C391.503 223.788 391.728 206.045 391.169 196.734C390.814 190.795 390.247 189.576 389.794 189.179C389.538 188.954 389.003 188.482 378.928 187.924C372.864 187.586 364.505 187.279 354.083 187.011C334.291 186.504 309.171 186.201 286.898 186.201V186.209Z"
          fill="black"
        />
        <path
          d="M352.39 286.456C354.512 285.918 384.754 276.23 386.841 274.145C381.849 275.129 233.882 272.56 232.457 273.131C230.779 273.799 196.021 283.495 196.301 285.512C196.525 287.137 349.984 287.066 352.39 286.456Z"
          fill="#FFB938"
        />
        <path
          d="M232.504 273.021C232.492 273.056 232.476 273.095 232.453 273.131C230.701 273.823 196.021 283.499 196.309 285.513C194.97 284.478 196.521 234.864 199.23 228.571C202.01 222.124 232.913 188.589 234.799 188.227C237.398 187.727 234.642 266.035 232.508 273.021H232.504Z"
          fill="#FFB938"
        />
        <path
          opacity="0.15"
          d="M352.39 286.456C354.512 285.918 384.754 276.23 386.841 274.145C381.849 275.129 233.882 272.56 232.457 273.131C230.779 273.799 196.021 283.495 196.301 285.512C196.525 287.137 349.984 287.066 352.39 286.456Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M232.504 273.021C232.492 273.056 232.476 273.095 232.453 273.131C230.701 273.823 196.021 283.499 196.309 285.513C194.97 284.478 196.521 234.864 199.23 228.571C202.01 222.124 232.913 188.589 234.799 188.227C237.398 187.727 234.642 266.035 232.508 273.021H232.504Z"
          fill="black"
        />
        <path
          d="M234.792 188.227C231.256 191.759 230.571 270.606 232.504 273.021C233.961 274.842 383.026 276.517 386.747 274.224C389.834 272.324 393.074 192.601 389.534 189.482C386.42 186.74 237.744 185.273 234.792 188.227Z"
          fill="#FFB938"
        />
        <g opacity="0.5">
          <path
            d="M281.913 199.476C284.87 197.042 372.12 197.517 373.978 198.945C376.325 200.751 376.069 211.866 373.967 213.553C372.088 215.06 283.082 216.279 281.523 214.014C280.212 212.11 279.559 201.415 281.917 199.476H281.913Z"
            fill="white"
          />
          <path
            d="M251.226 225.699C255.12 223.56 369.958 223.977 372.403 225.231C375.494 226.82 375.155 236.598 372.387 238.085C369.919 239.411 252.765 240.485 250.71 238.49C248.985 236.815 248.123 227.402 251.226 225.695V225.699Z"
            fill="white"
          />
          <path
            d="M248.096 249.118C249.399 247.026 287.894 247.435 288.713 248.662C289.748 250.215 289.634 259.773 288.709 261.225C287.882 262.519 248.611 263.569 247.922 261.622C247.344 259.986 247.056 250.786 248.096 249.118Z"
            fill="white"
          />
          <path
            d="M332.538 249.118C333.842 247.026 372.336 247.435 373.155 248.662C374.191 250.215 374.077 259.773 373.151 261.225C372.325 262.519 333.054 263.569 332.365 261.622C331.786 259.986 331.499 250.786 332.538 249.118Z"
            fill="white"
          />
          <path
            d="M302.725 249.118C303.312 247.026 320.636 247.435 321.002 248.662C321.467 250.215 321.416 259.773 321.002 261.225C320.628 262.519 302.958 263.569 302.651 261.622C302.391 259.986 302.261 250.786 302.729 249.118H302.725Z"
            fill="white"
          />
          <path
            d="M268.813 200.463C268.227 198.371 250.903 198.78 250.537 200.007C250.072 201.561 250.123 211.119 250.537 212.57C250.911 213.864 268.581 214.914 268.888 212.967C269.148 211.331 269.278 202.131 268.809 200.463H268.813Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2077_808">
          <rect width="403" height="307" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
