import { ChatDots } from '@phosphor-icons/react';
import { useTheme } from 'context/theme';
import { useIntercom } from 'react-use-intercom';
import { cn } from 'utils/className';

export function LiveChatButton() {
  const { boot, show, isOpen, hide } = useIntercom();
  const theme = useTheme();

  return (
    <button
      id="live-chat-button"
      className={cn(
        'centered fixed right-5 z-[10] max-lg:top-9 lg:bottom-5 lg:right-5',
        'space-x-2 rounded-full border-[1px] px-3 py-2',
      )}
      style={{
        backgroundColor: theme.background.primary,
        color: theme.label.primary,
        borderColor: theme.border.border,
      }}
      onClick={() => {
        boot({
          hideDefaultLauncher: true,
          verticalPadding: 70,
        });

        isOpen ? hide() : show();
      }}>
      <span className="buttonsmalltext">Spørsmål?</span>
      <ChatDots className="h-[24px] w-[24px]" />
    </button>
  );
}
