import { fetcher } from 'api';
import { useOrganization } from 'api/organizations/useOrganization';
import useSWR from 'swr';

type rateType = 'GOVERNMENT' | 'TAX_FREE' | 'GOVERNMENT_ABROAD' | 'DRIVE';

export function useRates(rateType: rateType) {
  const { data: organization } = useOrganization();

  const { data, error, mutate, isLoading } = useSWR<
    // Awaited<ReturnType<typeof Api.prototype.organizations.organizationsControllerGetRates>>['data']
    {
      id: string;
      title?: string;
      criteria: string;
      organizationId: string;
      rate: number;
      createdAt: Date;
      updatedAt: Date;
    }[]
  >(organization ? `/organizations/${organization.id}/rates?type=${rateType}` : null, fetcher);

  return {
    data,
    error,
    mutate,
    isLoading,
  };
}
