export function CaretRight({ className, fill }: { className?: string; fill?: string }) {
  return (
    <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M23.2075 16.7071L13.2075 26.7071C13.1146 26.8 13.0043 26.8737 12.8829 26.924C12.7615 26.9743 12.6314 27.0001 12.5 27.0001C12.3686 27.0001 12.2385 26.9743 12.1171 26.924C11.9957 26.8737 11.8854 26.8 11.7925 26.7071C11.6996 26.6142 11.6259 26.5039 11.5756 26.3825C11.5253 26.2611 11.4995 26.131 11.4995 25.9996C11.4995 25.8682 11.5253 25.7381 11.5756 25.6167C11.6259 25.4953 11.6996 25.385 11.7925 25.2921L21.0863 15.9996L11.7925 6.70708C11.6049 6.51944 11.4995 6.26494 11.4995 5.99958C11.4995 5.73422 11.6049 5.47972 11.7925 5.29208C11.9801 5.10444 12.2346 4.99902 12.5 4.99902C12.7654 4.99902 13.0199 5.10444 13.2075 5.29208L23.2075 15.2921C23.3005 15.385 23.3742 15.4952 23.4246 15.6166C23.4749 15.738 23.5008 15.8682 23.5008 15.9996C23.5008 16.131 23.4749 16.2611 23.4246 16.3825C23.3742 16.5039 23.3005 16.6142 23.2075 16.7071Z"
        fill={fill}
      />
    </svg>
  );
}
