import { useTheme } from 'context/theme';
import { cn } from 'utils/className';

type InputLabelProps = {
  label?: string;
  description?: string;
  required?: boolean;
};

export function InputLabel({ label, description, required }: InputLabelProps) {
  const theme = useTheme();

  if (!label && !description) return null;

  return (
    <div className={cn('flex cursor-text flex-col gap-y-1')}>
      {label && (
        <p className="inputlabel" style={{ color: theme.label.primary }}>
          {label}
          {required && '*'}
        </p>
      )}
      {description && (
        <p className="caption" style={{ color: theme.label.primary }}>
          {description}
        </p>
      )}
    </div>
  );
}
