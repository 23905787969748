import { useTheme } from 'context/theme';
import { cn } from '../../utils/className';

interface CommonProps {
  children: React.ReactNode;
  /**
   * Misleading name. Indicates the color behind the button.
   */
  backgroundColor: 'light' | 'dark';
  className?: string;
  styleObject?: React.CSSProperties;
  onClick?: () => void;
}

type ConditionalProps =
  | {
      icon: React.ReactNode;
      iconPlacement: 'left' | 'right';
    }
  | {
      icon?: never;
      iconPlacement?: never;
    };

type Props = CommonProps & ConditionalProps;

export function TextButton({
  icon,
  iconPlacement,
  backgroundColor = 'light',
  children,
  className,
  styleObject,
  onClick,
}: Props) {
  const theme = useTheme();

  const styles: React.CSSProperties = {};
  const commonClasses = 'buttonsmalltext centered flex transition-colors transition-transform w-fit';

  const classes: string[] = [];

  classes.push(className || '');
  classes.push(commonClasses);
  classes.push('hover:scale-110');

  if (backgroundColor === 'light') {
    styles.color = theme.label.primary;
  } else {
    styles.color = theme.label.ondark;
  }

  return (
    <button
      className={cn(classes.join(' '), iconPlacement === 'left' && 'flex-row-reverse')}
      style={{ ...styles, ...styleObject }}
      onClick={onClick}>
      <div className={cn(icon && (iconPlacement === 'left' ? 'pl-1' : 'pr-1'))}>
        <div>{children}</div>
      </div>
      {icon && icon}
    </button>
  );
}
