import { Api } from '__generated__/UtleggsappenApi';
import { fetcher } from 'api';
import { useStoredUser } from 'context/UserContext';
import useSWR from 'swr';

export function useOrganizations() {
  const { user } = useStoredUser();

  const { data, error, mutate, isLoading } = useSWR<
    Awaited<ReturnType<typeof Api.prototype.organizations.findAll>>['data']
  >(user.role < 2 ? '/organizations' : null, fetcher);

  const organizationsData = user?.accounts
    ?.filter((account) => account.role < 4)
    .map((account) => account.organization);

  const organizations = {
    data: organizationsData,
    _count: {
      result: organizationsData?.length,
    },
  };

  return {
    data: data! ?? organizations,
    error,
    mutate,
    isLoading,
  };
}
