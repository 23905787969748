import { api } from 'api/api';
import { Copy } from 'assets/svgcomponents/Copy';
import { InboxIcon } from 'assets/svgcomponents/InboxIcon';
import { Plus } from 'assets/svgcomponents/Plus';
import { TextButton } from 'components/buttons/TextButton';
import Input from 'components/input-field/Input';
import { toast } from 'components/toast/Toast';
import { useTheme } from 'context/theme';
import { useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { cn } from 'utils/className';
import { validateEmail } from 'utils/validateEmail';
import { SignupCard } from '../SignupCard';
import { useWizardContext } from '../WizardContext';
import { LargeButton } from 'components/buttons/LargeButton';

export function Invite() {
  const { handleStep, nextStep } = useWizard();
  const theme = useTheme();

  const [stateValue, setState] = useWizardContext();

  const [emails, setEmails] = useState<{ value?: string; error?: string }[]>([{}, {}, {}]);
  const [noEmails, setNoEmails] = useState(true);
  const [isSendingEmails, setIsSendingEmails] = useState(false);
  const [tokenIsGenerated, setTokenIsGenerated] = useState<boolean>();

  useEffect(() => {
    setNoEmails(emails.filter((email) => email.value && email.value.length > 0).length < 1);
  }, [emails]);

  handleStep(() => {});

  const handleCopyToClipboard = async () => {
    if (!tokenIsGenerated) {
      const {
        data: { url },
      } = await api.invitations.inviteByLink({
        organizationId: stateValue.organization.id,
      });
      navigator.clipboard.writeText(url);
      setTokenIsGenerated(true);
    }
    toast.success({
      description: 'Lenken ble kopiert til utlklippstavlen. Gyldig i 14 dager.',
      options: { position: 'top-center' },
    });
  };

  const handleSendEmails = async () => {
    setIsSendingEmails(true);
    const viewedEmailValues: string[] = [];
    let valid = true;
    for (const email of emails) {
      if (email.value && email.value.length > 0) {
        if (viewedEmailValues.includes(email.value)) {
          email.error = 'Eposten er allerede lagt til';
          setEmails([...emails]);
          valid = false;
          continue;
        }
        viewedEmailValues.push(email.value);
        if (!validateEmail(email.value)) {
          email.error = 'Ugyldig epost';
          setEmails([...emails]);
          valid = false;
        } else {
          email.error = undefined;
        }
      } else {
        email.error = undefined;
      }
    }

    if (!valid) {
      setIsSendingEmails(false);
      return;
    }

    await api.invitations.inviteByEmail({
      emails: emails.map((email) => email.value).filter((email) => email) as string[],
      organizationId: stateValue.organization.id,
    });
    setIsSendingEmails(false);
    nextStep();
  };

  return (
    <SignupCard
      split={false}
      backButtonOnClick={() => {}}
      backButtonHidden
      imageComponent={<InboxIcon className="h-full w-40" />}>
      <div
        className="flex w-fit max-w-full flex-col items-center justify-center space-y-8"
        style={{ color: theme.label.primary }}>
        <div className="centered flex-col space-y-2">
          <h2 className="bigheading text-center">
            Inviter kollegaer
            <br /> til appen
          </h2>
          <TextButton
            icon={<Copy className="mr-2" />}
            iconPlacement="left"
            backgroundColor="light"
            onClick={handleCopyToClipboard}>
            Få en delbar lenke
          </TextButton>
          <div className="flex w-full flex-col items-end space-y-2">
            <div
              className={cn(
                'max-h-[230px] w-full overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-violet-200 scrollbar-track-rounded-full scrollbar-thumb-rounded-full',
                emails.length > 3 && 'pr-2',
              )}>
              {emails.map((email, index) => {
                return (
                  <Input.Text
                    key={index}
                    label="Epost"
                    width="full"
                    value={email.value}
                    error={email.error}
                    onChange={(e) => {
                      const newEmails = [...emails];
                      newEmails[index].value = e.target.value;
                      setEmails(newEmails);
                    }}
                  />
                );
              })}
            </div>
            <TextButton
              backgroundColor="light"
              icon={<Plus className="w-4" />}
              iconPlacement="right"
              onClick={() => {
                setEmails([...emails, {}]);
              }}>
              Legg til flere
            </TextButton>
          </div>
        </div>
        <div className="relative">
          <LargeButton
            loading={isSendingEmails}
            backgroundColor="light"
            defaultStyle="indigo"
            onClick={() => (noEmails ? nextStep() : handleSendEmails())}>
            {!noEmails ? 'Send invitasjon og gå videre' : 'Hopp over'}
          </LargeButton>
          {!noEmails && (
            <div className="centered absolute inset-x-0 bottom-0 translate-y-[120%]">
              <TextButton backgroundColor="light" className="underline" onClick={() => nextStep()}>
                Hopp over
              </TextButton>
            </div>
          )}
        </div>
      </div>
    </SignupCard>
  );
}
