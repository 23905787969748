import { useContext, createContext } from 'react';

import { colors } from '../constants/constants';

const deafultBaseColors = {
  accent: colors.purple[500],
  info: colors.blue[500],
  success: colors.green[500],
  warning: colors.yellow[500],
  critical: colors.red[500],
  overlay: colors.blackalpha[50],
};

const defaultBackgroundColors = {
  primary: colors.white[900],
  secondary: colors.stone[50],
  elevated: colors.whitealpha[100],
  accent: colors.purple[100],
  accentemphasis: colors.purple[200],
  accentstrong: colors.purple[300],
  info: colors.blue[50],
  success: colors.green[50],
  warning: colors.yellow[50],
  critical: colors.red[50],
  overlay: colors.blackalpha[50],
  imageoverlay: colors.blackalpha[400],
  inverse: colors.blue[900],
  staff: colors.brown[300],
};

const defaultLabelColors = {
  primary: colors.bluetransparent[900],
  secondary: colors.bluetransparent[700],
  tertiary: colors.bluetransparent[500],
  onaccent: colors.white[900],
  onlight: colors.bluetransparent[900],
  ondark: colors.white[900],
  success: colors.green[700],
  warning: colors.yellow[500],
  critical: colors.red[500],
  inverse: colors.white[900],
  toggle: colors.whitealpha[100],
  accent: colors.purple[700],
  info: colors.blue[500],
};

const defaultBorderColors = {
  border: colors.stone[800],
  subtle: colors.stone[50],
  accent: colors.purple[400],
  info: colors.blue[300],
  success: colors.green[300],
  warning: colors.yellow[400],
  critical: colors.red[400],
};

const defaultSystemColors = {
  primary: colors.blackalpha[900],
};

const defaultTheme = {
  base: deafultBaseColors,
  background: defaultBackgroundColors,
  label: defaultLabelColors,
  border: defaultBorderColors,
  system: defaultSystemColors,
};

const ThemeContext = createContext(defaultTheme);

export function useTheme() {
  const theme = useContext(ThemeContext);
  return theme;
}
