import { StaticToast } from 'components/toast/StaticToast';
import { Modal } from './Modal';
import { useTheme } from 'context/theme';
import { Check, X } from '@phosphor-icons/react';
import { cn } from 'utils/className';
import { ModalCloseButton } from './ModalCloseButton';
import { FloatingPortal } from '@floating-ui/react';
import { useRef } from 'react';
import { useIsMobile } from 'utils/useIsMobile';

const rows = [
  ['Fungerer med alle regnskapsystem', true, true],
  ['Levere utlegg', '30 / mnd', '1 500 / mnd'],
  ['Kjøregodtgjørelse', false, true],
  ['Reiseregning med diett', false, true],
  ['Dashbord for godkjenning og administrasjon', false, true],
  ['Import av kvitteringer i egen postkasse', true, true],
  ['Scanner utleggene dine automatisk', true, true],
  ['Innlogging og verifisering med Vipps', true, true],
  ['Support i appen', true, true],
  ['Inviter brukere med epost, sms eller lenke', true, true],
];

const Row = ({ children, centered, columnIndex }: any) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  let content = children;

  if (typeof children === 'string') {
    content = <p className={cn('smalltext', columnIndex !== 0 && 'max-lg:caption')}>{children}</p>;
  } else {
    content = children ? (
      <Check className="h-[24px] w-[24px] lg:h-[32px] lg:w-[32px]" />
    ) : (
      <X style={{ color: theme.base.critical }} className="h-[24px] w-[24px] lg:h-[32px] lg:w-[32px]" />
    );
  }

  return <div className={cn('flex h-[42px] items-center', centered && 'justify-center')}>{content}</div>;
};

const Column = ({ title, columnIndex, centered }: any) => {
  const theme = useTheme();

  return (
    <div className="space-y-2 rounded-xlarge lg:p-10" style={{ backgroundColor: theme.background.primary }}>
      <h2
        className={cn(
          'bodysmallbold lg:normalheading h-[43px] border-b-[1px] max-lg:pr-6',
          centered && 'flex justify-center',
        )}
        style={{ borderColor: theme.border.border }}>
        {title}
      </h2>
      {rows.map((row, index) => {
        return (
          <Row key={index} centered={centered} columnIndex={columnIndex}>
            {row[columnIndex]}
          </Row>
        );
      })}
    </div>
  );
};

export function TierModal() {
  const theme = useTheme();
  const isMobile = useIsMobile();
  return (
    <Modal
      style={{
        backgroundColor: isMobile ? theme.background.primary : theme.base.accent,
      }}
      className="max-lg:w-[95%]"
      trigger={
        <div>
          <StaticToast description="Hva er forskjell på Pro og gratis?" type="info" onClick={() => {}} />
        </div>
      }>
      <div className="absolute right-2 top-2">
        <ModalCloseButton />
      </div>
      <div className="max-lg:centered flex max-lg:py-8 lg:space-x-10" style={{ color: theme.label.primary }}>
        <Column title="Funksjonalitet" columnIndex={0} />
        <Column title="Gratis" columnIndex={1} centered />
        <Column title="Pro" columnIndex={2} centered />
      </div>
    </Modal>
  );
}
