import { useTheme } from 'context/theme';
import { CSSProperties, ChangeEvent, FocusEvent, ReactNode, forwardRef, useEffect, useRef, useState } from 'react';
import { cn } from 'utils/className';
import { InputLabel } from './InputLabel';

interface TextProps {
  /**
   * The label of the input field.
   */
  label?: string;

  /**
   * The description of the input field.
   */
  description?: string;

  /**
   * The value of the input field.
   */
  value?: string;

  /**
   * The error message of the input field. If provided, the input field will turn red and show the error message below.
   */
  error?: string;

  /**
   * Whether the input field is disabled.
   */
  disabled?: boolean;

  /**
   * The function to call when the input field value changes.
   */
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;

  /**
   * The function to call when the input field loses focus.
   */
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;

  /**
   * The function to call when a key is pressed down.
   */
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;

  /**
   * The style of the input field.
   */
  style?: CSSProperties;

  /**
   * Whether the input field is a password field.
   */
  password?: boolean;

  /**
   * The class name of the input field container.
   */
  className?: string;

  /**
   * The class name of the input field.
   */
  inputClassName?: string;

  /**
   * The component to display in front of the input field.
   */
  frontComponent?: ReactNode;

  /**
   * The name of the input field. Useful for form submission.
   */
  name?: string;

  /**
   * Whether the input field is required.
   */
  required?: boolean;

  /**
   * The icon to display on the right side of the input field.
   */
  icon?: ReactNode;

  /**
   * The width of the input field.
   */
  width?: 'sm' | 'md' | 'lg' | 'xl' | 'full';

  /**
   * Whether to focus on the input field when mounted.
   */
  focusOnMount?: boolean;

  /**
   * The unit to display on the right side of the input field.
   */
  unit?: string;

  /**
   * The ref of the input field.
   */
  ref?: any;
}

export const Text = forwardRef(
  (
    {
      label,
      description,
      value,
      error,
      disabled,
      onChange,
      onBlur,
      onKeyDown,
      style,
      password = false,
      className,
      inputClassName,
      frontComponent,
      name,
      required,
      icon,
      width = 'full',
      unit,
      focusOnMount,
    }: TextProps,
    ref,
  ) => {
    const theme = useTheme();
    const errorStyles: CSSProperties = {
      borderColor: theme.border.critical,
      backgroundColor: theme.background.critical,
    };
    const [isFocused, setIsFocused] = useState(false);
    const unitRef = useRef<HTMLDivElement>(null);
    const [unitWidth, setUnitWidth] = useState(0);

    useEffect(() => {
      if (unitRef.current) {
        setUnitWidth(unitRef.current.clientWidth);
      }
    }, [unit]);

    const widthMap = {
      sm: 'w-24',
      md: 'w-52',
      lg: 'w-80',
      xl: 'w-[432px]',
      full: 'w-full',
    };

    return (
      <div className={`${widthMap[width]}`}>
        <div className={cn('relative flex cursor-text flex-col gap-y-1', className)}>
          <InputLabel label={label} description={description} required={required} />
          <div className={`relative flex ${widthMap[width]}`}>
            {frontComponent && <div className="absolute bottom-0 left-4 top-0 flex items-center">{frontComponent}</div>}
            <input
              ref={ref as any}
              autoFocus={focusOnMount}
              name={name}
              disabled={disabled}
              onKeyDown={onKeyDown}
              onChange={onChange}
              onBlur={(e) => {
                onBlur === undefined || onBlur(e);
                setIsFocused(false);
              }}
              onSubmit={(e) => e.preventDefault()}
              onFocus={() => setIsFocused(true)}
              value={value}
              type={password ? 'password' : 'text'}
              className={cn(
                `inputtext h-[54px] w-full rounded-small border outline-none focus:border-[3px] focus:stroke-none focus:px-3.5 disabled:bg-v2-whitealpha-600`,
                inputClassName,
              )}
              style={{
                ...{
                  borderColor: disabled ? theme.label.tertiary : isFocused ? theme.border.accent : theme.border.border,
                  paddingLeft: 16,
                  paddingRight: 16 + (unit ? unitWidth + 8 : 0) + (icon ? 56 : 0),
                },
                ...{ color: disabled ? theme.label.secondary : theme.label.primary },
                ...(error ? errorStyles : {}),
                ...style,
              }}
            />
            {unit && (
              <div className="absolute right-4 top-1/2 -translate-y-1/2" ref={unitRef}>
                <p className="inputtext" style={{ color: theme.label.secondary }}>
                  {unit}
                </p>
              </div>
            )}
            {icon && <div className="absolute right-4 top-1/2 -translate-y-1/2">{icon}</div>}
          </div>
          {error && (
            <p className="caption absolute bottom-0 left-0 translate-y-[110%]" style={{ color: theme.label.critical }}>
              😬 {error}
            </p>
          )}
        </div>
      </div>
    );
  },
);

export default Text;
