import { ToastContent, ToastContentProps } from './ToastContent';
import { toast as toastify } from 'react-toastify';
import { cn } from 'utils/className';

export const toastClassName = 'items-start rounded-[8px] text-[#100C3AE5] border p-[8px] min-h-0';
export const toastBodyClassName = 'p-0 m-0 items-start';
export const defaultToastClassName = 'bg-[#fff] border-[#393634]';
export const successToastClassName = 'bg-[#F1FAF8] border-[#95DCCB]';
export const infoToastClassName = 'bg-[#EFEEFA] border-[#877EDB]';
export const warningToastClassName = 'bg-[#FEFAF2] border-[#F7C976]';
export const errorToastClassName = 'bg-[#FCF1F1] border-[#E47570]';

export function toast(content: ToastContentProps) {
  return toastify(<ToastContent description={content.description} action={content.action} title={content.title} />, {
    className: cn(toastClassName, defaultToastClassName),
    bodyClassName: cn(toastBodyClassName, ''),
    autoClose: content.options?.persist ? false : 5000,
    position: content.options?.position,
  });
}

function success(content: ToastContentProps) {
  return toastify.success(
    <ToastContent description={content.description} action={content.action} title={content.title} />,
    {
      className: cn(toastClassName, successToastClassName),
      bodyClassName: cn(toastBodyClassName, ''),
      autoClose: content.options?.persist ? false : 5000,
      position: content.options?.position,
      toastId: content.options?.toastId,
    },
  );
}

function info(content: ToastContentProps) {
  return toastify.info(
    <ToastContent description={content.description} action={content.action} title={content.title} />,
    {
      className: cn(toastClassName, infoToastClassName),
      bodyClassName: cn(toastBodyClassName, ''),
      autoClose: content.options?.persist ? false : 5000,
      position: content.options?.position,
      toastId: content.options?.toastId,
    },
  );
}

function warning(content: ToastContentProps) {
  return toastify.warning(
    <ToastContent description={content.description} action={content.action} title={content.title} />,
    {
      className: cn(toastClassName, warningToastClassName),
      bodyClassName: cn(toastBodyClassName, ''),
      autoClose: content.options?.persist ? false : 5000,
      position: content.options?.position,
      toastId: content.options?.toastId,
    },
  );
}

function error(content: ToastContentProps) {
  return toastify.error(
    <ToastContent description={content.description} action={content.action} title={content.title} />,
    {
      className: cn(toastClassName, errorToastClassName),
      bodyClassName: cn(toastBodyClassName, ''),
      autoClose: content.options?.persist ? false : 5000,
      position: content.options?.position,
      toastId: content.options?.toastId,
    },
  );
}

toast.success = success;
toast.info = info;
toast.warning = warning;
toast.error = error;
