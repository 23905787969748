import { Api, User } from '__generated__/UtleggsappenApi';
import { fetcher } from 'api';
import { refreshTokens } from 'api/auth';
import useSWR from 'swr';

export function useUser(retryOnError = true) {
  const { data, error, mutate, isLoading } = useSWR<
    Awaited<ReturnType<typeof Api.prototype.users.findCurrent>>['data']
  >('/users/current', fetcher, {
    shouldRetryOnError: retryOnError,
    onErrorRetry: async (error, key, config, revalidate, { retryCount }) => {
      if (error.status === 404) return;
      if (error.status === 401) {
        if (retryCount <= 1) {
          const accessToken = await refreshTokens();
          if (!accessToken) return;
          return revalidate({ retryCount });
        }
      }
      if (retryCount >= 3) return;
      setTimeout(() => revalidate({ retryCount }), 5000);
    },
    revalidateOnFocus: false,
  });

  return {
    data,
    mutate,
    isLoading,
    loggedOut: error && error.status === 401,
    error,
  };
}
