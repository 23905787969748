import { useMemo } from 'react';
import { useTheme } from 'context/theme';
import { CellContext } from '@tanstack/react-table';
import Table from 'components/table/Table';
import { cn } from 'utils/className';
import * as Tabs from '@radix-ui/react-tabs';
import { useLocation, useNavigate } from 'react-router-dom';
import { DefaultCell, HeaderCell } from 'components/table/TableComponents';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import { useCategories } from 'api/categories/useCategories';
import BackButton from 'components/buttons/BackButton';
import { useRates } from 'api/rates/useRates';
import { formatAmount } from 'utils/format';

export default function DriveTypeSettings() {
  const { hash } = useLocation();

  const theme = useTheme();
  const navigate = useNavigate();

  const { data: categories, isLoading: categoriesLoading } = useCategories();
  const { data: rates, isLoading: ratesLoading } = useRates('DRIVE');

  const mappingData = useMemo(
    () =>
      categories
        ?.filter((category) => category.type === 'DRIVE')
        .map((category) => ({
          id: category.id,
          title: category.title,
          account: category.accountingCode ? `${category.accountingCode}` : ' ',
          description: category.description,
          navigate: `mapping/${category.id}`,
        })) ?? [],
    [categories],
  );
  const rateData = useMemo(
    () =>
      rates?.map((rate) => ({
        id: rate.id,
        name: rate.title ?? '',
        rate: rate.rate,
        navigate: `satser/${rate.id}`,
      })) ?? [],
    [rates],
  );

  const categoryColumns = useMemo(
    () => [
      {
        accessorKey: 'title',
        cell: (value: CellContext<any, string>) => (
          <DefaultCell className="smalltextbold">{value.getValue()}</DefaultCell>
        ),
        header: () => <HeaderCell>Navn</HeaderCell>,
      },
      {
        accessorKey: 'account',
        cell: (value: CellContext<any, string>) => <DefaultCell>{value.getValue()}</DefaultCell>,
        header: () => <HeaderCell>Konto</HeaderCell>,
      },
      {
        accessorKey: 'description',
        cell: (value: CellContext<any, string>) => <DefaultCell>{value.getValue()}</DefaultCell>,
        header: () => <HeaderCell>Kommentar</HeaderCell>,
      },
    ],
    [],
  );

  const rateColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        cell: (value: CellContext<any, string>) => (
          <DefaultCell className="smalltextbold">{value.getValue()}</DefaultCell>
        ),
        header: () => <HeaderCell>Navn</HeaderCell>,
      },
      {
        accessorKey: 'rate',
        cell: (value: CellContext<any, string>) => <DefaultCell>{formatAmount(value.getValue())} kr/km</DefaultCell>,
        header: () => <HeaderCell>Sats</HeaderCell>,
      },
    ],
    [],
  );

  return (
    <Tabs.Root
      className="TabsRoot flex flex-col gap-y-5"
      defaultValue="mapping"
      value={hash.slice(1) !== 'satser' ? 'mapping' : 'satser'}
      onValueChange={(value) => navigate(`#${value}`)}>
      <div className="flex flex-row justify-between pt-5">
        <div className="flex flex-col justify-between gap-y-5" style={{ color: theme.label.primary }}>
          <BackButton onClick={() => navigate('..')} />
          <h1 className="bigheading">Kjøregodtgjørelse</h1>
        </div>
        <Tabs.List
          className="TabsList smalltext flex items-center gap-x-2"
          style={{ color: theme.label.primary }}
          aria-label="Manage your account">
          <Tabs.Trigger
            className={cn('TabsTrigger rounded-small px-4 py-2 data-[state=inactive]:border-[1px]')}
            style={
              hash.slice(1) !== 'satser'
                ? { backgroundColor: theme.background.inverse, color: theme.label.ondark }
                : { backgroundColor: theme.background.primary, borderColor: theme.border.border }
            }
            value="mapping">
            Mapping
          </Tabs.Trigger>
          <Tabs.Trigger
            className={cn('TabsTrigger rounded-small px-4 py-2 data-[state=inactive]:border-[1px]')}
            style={
              hash.slice(1) === 'satser'
                ? { backgroundColor: theme.background.inverse, color: theme.label.ondark }
                : { backgroundColor: theme.background.primary, borderColor: theme.border.border }
            }
            value="satser">
            Satser
          </Tabs.Trigger>
        </Tabs.List>
      </div>

      <Tabs.Content className="TabsContent" value="mapping">
        <div className="flex w-full min-w-0 flex-col">
          {!categoriesLoading && categories ? (
            <Table
              data={mappingData}
              columns={categoryColumns}
              gridCols="minmax(0, 5fr) minmax(0, 7fr) minmax(150px, 4fr) 68px"
              sortBy={[{ id: 'title', desc: false }]}
            />
          ) : (
            <FullscreenLoading />
          )}
        </div>
      </Tabs.Content>
      <Tabs.Content className="TabsContent" value="satser">
        <div className="flex w-full flex-col">
          {!ratesLoading && rates ? (
            <Table
              data={rateData}
              columns={rateColumns}
              gridCols="minmax(0, 1fr) minmax(0, 1fr) 68px"
              sortBy={[{ id: 'name', desc: false }]}
            />
          ) : (
            <FullscreenLoading />
          )}
        </div>
      </Tabs.Content>
    </Tabs.Root>
  );
}
