import { Arrow } from 'assets/svgcomponents/Arrow';
import { useTheme } from 'context/theme';
import { cn } from 'utils/className';

export function ArrowButton({
  orientation = 'left',
  onClick,
}: {
  orientation?: 'right' | 'left';
  onClick?: () => void;
}) {
  const theme = useTheme();
  return (
    <button
      onClick={onClick}
      className={cn('rounded-full border p-[5px]', orientation === 'right' ? 'rotate-180' : '')}
      style={{ borderColor: theme.border.border }}>
      <Arrow className="h-[16px] w-[16px]" />
    </button>
  );
}
