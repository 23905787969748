import { Vipps } from 'assets/svgcomponents/vippps';
import { cn } from 'utils/className';

export function VippsButton(props: { href: string }) {
  return (
    <a
      className={cn(
        'buttonlargetext inline-flex w-full items-center justify-center space-x-1 rounded-small bg-[#FF5B24] px-1 py-5 text-[#F5F7FD] transition-colors lg:w-[361px]',
        'hover:bg-[#F5F7FD] hover:text-[#1A1423]',
      )}
      href={props.href}>
      <p>Logg inn med</p>
      <Vipps />
    </a>
  );
}
