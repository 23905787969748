export function InboxIcon({ className }: { className?: string }) {
  return (
    <svg
      width="145"
      height="144"
      viewBox="0 0 145 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <g clip-path="url(#clip0_2212_7560)">
        <path
          d="M60.9986 20.0815C60.9986 20.0815 59.0022 5.32696 55.5072 1.52314C52.3058 -1.96172 43.9523 1.09956 44.2004 8.53844C44.5159 18.0109 41.48 20.6823 41.48 20.6823C41.48 20.6823 45.4964 19.393 48.5071 17.0591C50.4529 15.5504 52.1236 12.0385 54.2078 12.2444C59.3144 12.7506 61.0003 20.0815 61.0003 20.0815H60.9986Z"
          fill="#6C4F42"
        />
        <path
          d="M41.4802 20.7659C41.4498 20.7659 41.4228 20.7507 41.4076 20.7237C41.3874 20.6883 41.3958 20.6444 41.4245 20.6191C41.4549 20.5921 44.4267 17.8515 44.1162 8.54105C43.9727 4.24108 46.6239 1.60338 49.1519 0.570579C51.6226 -0.440285 54.1404 -0.0875798 55.5698 1.46669C59.0412 5.24688 61.0629 19.9238 61.0832 20.0706C61.0899 20.1145 61.0595 20.1567 61.014 20.1651C60.9701 20.1736 60.9262 20.1449 60.9161 20.101C60.8992 20.0284 59.1846 12.8225 54.1978 12.328C52.9001 12.1997 51.7204 13.6207 50.5779 14.9944C49.9299 15.774 49.2583 16.5807 48.5579 17.1241C45.5709 19.4395 41.546 20.7474 41.5055 20.7609C41.4971 20.7642 41.4886 20.7642 41.4802 20.7642V20.7659ZM51.923 0.168933C51.0555 0.168933 50.1341 0.351193 49.2178 0.727524C46.7421 1.74008 44.1466 4.32377 44.2866 8.53598C44.5516 16.4795 42.4961 19.6369 41.7772 20.4925C42.7881 20.1331 45.9827 18.9113 48.4567 16.9942C49.1435 16.4626 49.8067 15.6627 50.4497 14.8897C51.6209 13.4823 52.8292 12.0242 54.2164 12.1626C58.0894 12.5457 60.0267 16.8457 60.744 18.9366C60.1871 15.3842 58.3122 4.7001 55.4466 1.58144C54.5927 0.651583 53.3304 0.168933 51.923 0.168933Z"
          fill="black"
        />
        <path
          d="M41.4805 20.7656C41.4552 20.7656 41.4298 20.7538 41.413 20.7319C41.386 20.6964 41.391 20.6475 41.4231 20.6188C41.4805 20.5682 47.1271 15.3991 47.8933 8.81244C48.0519 7.44044 48.5683 4.97487 50.2238 3.86275C51.1115 3.26704 52.2101 3.14553 53.4876 3.5033C55.1955 3.98257 56.8442 6.53589 58.3833 11.0924C58.8137 12.3665 59.1748 13.6271 59.4583 14.7038C60.1671 16.4268 60.1874 17.7769 60.1874 17.8022C60.1874 17.8461 60.1553 17.8815 60.1114 17.8866C60.0675 17.89 60.0287 17.8613 60.0203 17.8191C60.0118 17.7786 59.7553 16.5028 59.2879 14.7308C58.6348 13.1563 57.3961 11.2696 55.052 9.81318C52.8143 8.42261 51.0609 11.1734 49.6518 13.3841C49.2569 14.0035 48.8839 14.589 48.5396 15.0177C46.4825 17.5761 41.575 20.72 41.526 20.7521C41.5125 20.7605 41.4957 20.7656 41.4805 20.7656ZM52.1325 3.47292C51.4524 3.47292 50.8449 3.65012 50.3183 4.00451C48.7185 5.07782 48.2173 7.49106 48.062 8.83269C47.441 14.1688 43.6558 18.5751 42.1521 20.1378C43.5933 19.1641 46.8419 16.8639 48.4097 14.9148C48.7489 14.4929 49.1202 13.9123 49.5117 13.2964C50.9613 11.0232 52.7637 8.1931 55.1432 9.67311C57.1008 10.8899 58.3006 12.4036 59.033 13.7942C57.9294 9.86381 55.9616 4.3724 53.4438 3.66699C52.9814 3.53705 52.5443 3.47292 52.1342 3.47292H52.1325Z"
          fill="black"
        />
        <path
          d="M55.3207 25.6794L88.4648 23.6914C88.4648 23.6914 89.2664 50.3806 90.5912 51.8454C93.1141 54.635 119.709 58.6464 133.616 67.7138C139.617 71.6256 136.37 112.769 136.37 112.769L7.60417 119.56C7.60417 119.56 3.50503 81.8135 7.3561 77.3128C20.283 62.2038 54.4921 56.0104 57.1196 53.0453C58.2891 51.7256 55.319 25.6777 55.319 25.6777L55.3207 25.6794Z"
          fill="#9676DC"
        />
        <path
          d="M7.60572 119.644C7.56353 119.644 7.52641 119.612 7.52134 119.568C7.51122 119.474 6.49023 110.015 5.95358 100.127C5.23129 86.834 5.68188 79.1403 7.29352 77.2569C16.2867 66.7466 35.731 60.4874 47.3484 56.7477C52.5276 55.0804 56.269 53.8754 57.0571 52.9878C57.9616 51.9651 56.2622 34.6825 55.2361 25.686C55.2328 25.6624 55.2412 25.6404 55.2547 25.6219C55.2699 25.605 55.2918 25.5932 55.3138 25.5915L88.458 23.6035C88.4833 23.6035 88.5035 23.6103 88.5204 23.6255C88.5373 23.6406 88.5474 23.6626 88.5474 23.6845C88.5558 23.9512 89.3625 50.3602 90.6518 51.7845C91.6137 52.8477 96.3052 54.1387 102.246 55.7757C111.904 58.4353 125.131 62.0788 133.662 67.6394C136.176 69.278 137.41 77.3751 137.327 91.7043C137.266 102.36 136.461 112.668 136.453 112.77C136.45 112.813 136.416 112.846 136.374 112.848L7.60741 119.639C7.60741 119.639 7.60404 119.639 7.60235 119.639L7.60572 119.644ZM55.4133 25.7586C55.6327 27.6942 58.3413 51.7946 57.1819 53.1025C56.3635 54.0256 52.6035 55.2356 47.399 56.9114C35.7985 60.646 16.3812 66.8951 7.42009 77.3683C3.7209 81.6919 7.4724 117.524 7.67998 119.472L136.293 112.689C136.443 110.722 139.342 71.5478 133.571 67.7862C125.062 62.2391 111.85 58.6007 102.202 55.9444C96.0015 54.2366 91.5226 53.0029 90.5286 51.9043C89.2224 50.4614 88.4428 25.74 88.382 23.7824L55.4133 25.7586Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M55.7642 29.0819L88.4898 25.0469L88.7463 32.9971C88.7463 32.9971 83.8186 42.8509 72.635 42.5741C63.4123 42.3463 56.478 36.3605 56.478 36.3605L55.7642 29.0819Z"
          fill="#020203"
        />
        <path
          d="M72.2381 10.383C62.3657 9.63204 53.7422 18.0025 54.2265 25.7434C54.7226 33.6514 61.073 39.7082 72.952 39.7082C82.3231 39.7082 88.7613 31.8154 89.1072 24.39C89.4532 16.9646 80.055 10.383 72.2381 10.383Z"
          fill="#FFDDD4"
        />
        <path
          d="M70.996 10.3362C71.4061 10.3362 71.8212 10.3514 72.2364 10.3834C80.0533 10.3834 89.4515 16.965 89.1055 24.3904C88.7595 31.8158 82.3214 39.7086 72.9502 39.7086C61.073 39.7086 54.7209 33.6519 54.2248 25.7439C53.7607 18.3286 61.6535 10.3362 70.996 10.3362ZM70.996 10.1674C68.5372 10.1674 66.1206 10.7041 63.8103 11.7622C61.6687 12.7444 59.6909 14.162 58.0927 15.8647C55.32 18.818 53.8485 22.421 54.0577 25.754C54.3142 29.8531 56.098 33.3346 59.215 35.8221C62.5361 38.4733 67.2867 39.8757 72.9502 39.8757C77.3278 39.8757 81.4422 38.1257 84.5338 34.9496C87.369 32.0352 89.0971 28.1892 89.2743 24.3955C89.4059 21.584 88.1908 18.7522 85.7624 16.2073C84.0799 14.4438 81.9181 12.9435 79.5116 11.8702C77.0815 10.7868 74.5686 10.213 72.2431 10.2113C71.8297 10.1793 71.4112 10.1641 70.996 10.1641V10.1674Z"
          fill="#020203"
        />
        <path
          d="M67.2346 19.1246C67.179 19.2765 67.1401 22.2348 67.2549 22.3361C67.3696 22.4373 68.7619 21.8146 68.8378 21.7016C68.9239 21.5733 68.9526 18.4985 68.853 18.3956C68.7535 18.2926 67.2971 18.9542 67.2346 19.1246Z"
          fill="black"
        />
        <path
          d="M70.8393 18.4588C70.7803 18.6056 70.7381 21.4661 70.8613 21.5623C70.9845 21.6585 72.3683 21.0172 72.4493 20.9092C72.5421 20.7843 72.5843 17.9137 72.478 17.8142C72.3717 17.7146 70.9051 18.2934 70.8393 18.4588Z"
          fill="black"
        />
        <path
          d="M63.6249 18.5468C59.7738 21.5473 55.3203 20.2462 55.3203 20.2462C55.3203 20.2462 56.9438 16.358 60.5923 13.8654C64.2122 11.3914 66.9478 10.8379 66.9478 10.8379C66.9478 10.8379 66.8752 16.0154 63.6249 18.5468Z"
          fill="#6C4F42"
        />
        <path
          d="M57.6844 20.6045C56.2786 20.6045 55.3167 20.3345 55.2965 20.3277C55.2728 20.321 55.2543 20.3041 55.2441 20.2839C55.234 20.2619 55.234 20.2366 55.2425 20.2147C55.2593 20.1759 56.9182 16.2758 60.5449 13.7968C64.136 11.3413 66.9037 10.7625 66.9307 10.7558C66.956 10.7507 66.9813 10.7558 67.0016 10.7743C67.0218 10.7912 67.0319 10.8148 67.0319 10.8401C67.0319 10.8924 66.9206 16.0868 63.677 18.6148C61.603 20.2315 59.3365 20.6045 57.6844 20.6045ZM55.4365 20.1894C56.0964 20.3514 60.1061 21.18 63.5724 18.4815C66.4936 16.205 66.8227 11.7008 66.8581 10.9465C66.3231 11.0764 63.807 11.77 60.6377 13.9369C57.357 16.1797 55.7049 19.597 55.4365 20.191V20.1894Z"
          fill="black"
        />
        <path
          d="M58.9976 26.5727L84.5645 22.1562C84.5645 22.1562 85.4893 34.2259 75.2304 36.4569C61.5222 39.4388 58.9976 26.5727 58.9976 26.5727Z"
          fill="#EEEAF9"
        />
        <path
          d="M71.3103 36.9898C68.7503 36.9898 66.4906 36.3536 64.56 35.0862C60.0457 32.1195 58.9251 26.6449 58.915 26.5892C58.9099 26.5673 58.915 26.5437 58.9285 26.5251C58.942 26.5066 58.9606 26.4931 58.9842 26.4897L84.5511 22.0733C84.5748 22.0699 84.5984 22.075 84.6169 22.0901C84.6355 22.1053 84.6473 22.1273 84.649 22.1509C84.6507 22.1813 84.8701 25.2223 83.8255 28.4962C82.8602 31.5254 80.5887 35.3782 75.2492 36.5392C73.8687 36.8396 72.5541 36.9898 71.3103 36.9898ZM59.099 26.6416C59.2593 27.3419 60.5047 32.2207 64.6545 34.9462C67.4812 36.8025 71.0336 37.2835 75.2137 36.3756C80.4722 35.2314 82.71 31.4343 83.6635 28.4507C84.5967 25.5261 84.514 22.7888 84.487 22.2572L59.1006 26.6416H59.099Z"
          fill="black"
        />
        <path
          d="M60.9992 31.3763C60.9587 31.3763 60.9232 31.3476 60.9165 31.3055C60.9097 31.2599 60.9401 31.216 60.9857 31.2093C65.0511 30.5477 78.7407 28.2357 84.2389 26.29C84.2828 26.2748 84.3317 26.2967 84.3469 26.3406C84.3621 26.3845 84.3402 26.4334 84.2963 26.4486C78.7846 28.3994 65.0814 30.7131 61.0127 31.3747C61.0076 31.3747 61.0042 31.3747 60.9992 31.3747V31.3763Z"
          fill="black"
        />
        <path
          d="M70.5846 36.9898C70.5491 36.9898 70.5171 36.9678 70.5052 36.9324C69.118 32.7725 68.0616 27.7097 67.5537 25.0619C67.5452 25.0163 67.5756 24.9725 67.6212 24.9623C67.6667 24.9539 67.7106 24.9843 67.7207 25.0299C68.227 27.6726 69.2834 32.7269 70.6673 36.8784C70.6824 36.9223 70.6588 36.9712 70.6132 36.9847C70.6048 36.9881 70.5947 36.9898 70.5862 36.9898H70.5846Z"
          fill="black"
        />
        <path
          d="M75.9526 36.3643C75.9138 36.3643 75.8784 36.3373 75.8699 36.2968L75.6252 35.0682C74.9316 31.5715 74.0693 27.2209 73.2002 24.131C73.1883 24.0854 73.2137 24.0398 73.2592 24.0263C73.3048 24.0145 73.3504 24.0398 73.3639 24.0854C74.2346 27.1821 75.0987 31.5378 75.7923 35.0361L76.037 36.2647C76.0454 36.3103 76.0167 36.3541 75.9712 36.3643C75.9661 36.3643 75.9594 36.3643 75.9543 36.3643H75.9526Z"
          fill="black"
        />
        <path
          d="M80.413 33.9587C80.3742 33.9587 80.3404 33.9334 80.3303 33.8946C79.5388 30.6763 78.4824 26.1553 77.9019 23.31C77.8934 23.2645 77.9221 23.2206 77.9677 23.2105C78.0132 23.202 78.0571 23.2307 78.0672 23.2763C78.6478 26.1182 79.7042 30.6375 80.494 33.8541C80.5058 33.8996 80.4771 33.9452 80.4315 33.9553C80.4248 33.957 80.418 33.957 80.4113 33.957L80.413 33.9587Z"
          fill="black"
        />
        <path
          d="M16.877 68.1955C13.3584 71.2432 17.0255 140.507 20.0378 143.165C23.2746 146.022 124.093 140.088 127.372 137.297C130.932 134.266 127.444 63.5934 124.233 60.6469C120.864 57.5552 19.7425 65.713 16.877 68.1938V68.1955Z"
          fill="#EEEAF9"
        />
        <path
          d="M30.7944 143.999C24.6415 143.999 20.5963 143.77 19.9821 143.226C17.7646 141.269 15.2568 104.675 15.3564 83.167C15.4087 71.742 16.1833 68.6824 16.8229 68.1289C18.3434 66.8126 45.5405 64.1158 70.3599 62.246C84.2352 61.2013 97.0473 60.4352 106.437 60.0875C117.481 59.6808 123.487 59.8479 124.29 60.5837C126.703 62.7978 129.312 103.391 128.916 124.709C128.772 132.385 128.273 136.641 127.427 137.36C125.753 138.785 99.1298 140.989 73.6945 142.432C57.6422 143.341 41.2034 143.999 30.7944 143.999ZM116.245 60.042C107.19 60.042 90.9855 60.8621 70.3717 62.4147C45.2334 64.3082 18.3704 67.0117 16.9326 68.2571C16.0652 69.0081 15.5656 74.3038 15.5251 83.1686C15.4779 93.4089 16.0314 107.694 16.9697 120.449C17.9502 133.771 19.118 142.239 20.0934 143.1C21.2613 144.129 38.2839 144.269 73.6844 142.263C103.308 140.584 125.865 138.467 127.316 137.232C127.869 136.76 128.568 134.205 128.745 124.705C129.142 103.416 126.565 62.9007 124.175 60.7086C123.686 60.2597 120.918 60.042 116.245 60.042Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M15.4375 99.1543C15.4375 99.1543 17.5132 141.676 20.0379 143.165C23.4974 145.203 70.0208 142.434 79.1996 142.026C100.097 141.094 124.233 138.122 124.233 138.122C124.233 138.122 79.343 139.393 56.3463 130.427C28.4893 119.567 15.4392 99.1543 15.4392 99.1543H15.4375Z"
          fill="black"
        />
        <path
          d="M76.2784 113.29C74.3023 113.29 63.7262 105.736 44.8421 90.8364C30.7136 79.6882 16.9615 68.3729 16.8248 68.2598C16.7894 68.2295 16.7843 68.1771 16.813 68.1417C16.8434 68.1063 16.8957 68.1012 16.9311 68.1299C17.4796 68.5805 71.6342 113.121 76.2734 113.121C76.2835 113.121 76.2919 113.121 76.3021 113.121C80.7387 112.937 123.735 61.118 124.169 60.5948C124.199 60.5594 124.251 60.5543 124.287 60.583C124.322 60.6134 124.327 60.6657 124.299 60.7011C124.191 60.8328 113.318 73.9436 102.031 86.911C86.8647 104.335 78.2107 113.21 76.3105 113.29C76.3004 113.29 76.2903 113.29 76.2801 113.29H76.2784Z"
          fill="black"
        />
        <path
          d="M4.69455 134.564C7.68495 136.04 33.0916 134.592 35.5672 130.402C36.4701 128.873 36.9426 121.573 35.3816 118.959C36.7621 118.677 37.6312 117.865 38.2471 116.8C39.1821 115.187 38.2758 104.471 36.9274 104.272C38.9053 103.642 38.2269 98.6064 37.9045 96.048C37.4236 92.2239 36.055 89.2015 34.3826 89.4479C35.3816 88.0151 33.073 78.5511 30.6479 77.4137C26.8137 75.6147 5.69529 78.7587 2.31505 82.733C1.22994 84.0088 0.0452521 94.5933 2.24418 95.1114C0.198822 95.351 0.126256 108.303 2.28468 108.823C0.126256 109.582 0.485712 121.455 2.35387 122.013C0.681472 122.654 2.45175 133.455 4.69455 134.562V134.564Z"
          fill="#FFDDD4"
        />
        <path
          d="M26.6028 76.8934C28.449 76.8934 29.8902 77.0554 30.6513 77.4131C33.0764 78.5506 35.385 88.0129 34.386 89.4473C34.4501 89.4372 34.5125 89.4338 34.5767 89.4338C36.163 89.4338 37.4472 92.3702 37.908 96.0475C38.2286 98.6058 38.907 103.642 36.9308 104.271C38.2792 104.47 39.1855 115.186 38.2505 116.8C37.6346 117.863 36.7638 118.675 35.385 118.958C36.946 121.574 36.4752 128.874 35.5707 130.402C33.7244 133.525 19.1336 135.127 10.5876 135.127C7.67148 135.127 5.45906 134.94 4.69796 134.565C2.45516 133.458 0.68488 122.657 2.35728 122.016C0.48912 121.457 0.127977 109.585 2.28809 108.826C0.129664 108.306 0.203918 95.3539 2.24758 95.1142C0.04866 94.5944 1.23503 84.0099 2.31846 82.7358C5.02873 79.5496 19.1386 76.8967 26.6045 76.8967M26.6028 76.7246C22.8108 76.7246 17.4612 77.3861 12.6397 78.4527C5.23967 80.0897 2.91587 81.7671 2.18852 82.6227C1.22659 83.7534 0.281547 91.199 1.22659 94.0814C1.38523 94.5674 1.59618 94.91 1.85269 95.1058C0.931267 95.8315 0.652816 98.7425 0.573499 100.072C0.379427 103.302 0.693318 107.893 1.93369 108.836C0.88739 109.702 0.578562 112.981 0.644378 115.704C0.683192 117.291 0.8452 118.796 1.1034 119.94C1.3481 121.029 1.66199 121.729 2.03832 122.031C1.74806 122.338 1.5793 122.973 1.53542 123.923C1.37679 127.332 2.81293 133.822 4.61864 134.715C5.63963 135.22 8.79373 135.296 10.5826 135.296C15.2504 135.296 21.0102 134.838 25.6122 134.103C29.6236 133.461 34.6307 132.314 35.7107 130.488C36.1782 129.698 36.5292 127.479 36.5646 125.089C36.5832 123.828 36.5275 120.791 35.6348 119.071C36.8093 118.772 37.7122 118.055 38.3906 116.884C39.2378 115.421 38.6623 107.013 37.5586 104.727C37.4692 104.541 37.3764 104.399 37.2802 104.296C38.9846 103.28 38.4294 98.8792 38.0902 96.1909L38.07 96.0272C37.8371 94.1692 37.3899 92.4867 36.8093 91.2885C36.1917 90.0093 35.4458 89.3123 34.6526 89.2684C35.0036 88.2272 34.6273 85.7633 34.0518 83.6116C33.343 80.9655 32.0959 77.9093 30.7172 77.2629C29.9594 76.9086 28.5739 76.728 26.5978 76.728L26.6028 76.7246Z"
          fill="#020203"
        />
        <path
          d="M142.603 124.449C140.053 126.599 116.404 131.711 112.153 128.059C110.807 126.903 110.915 120.395 111.973 117.538C109.05 117.739 107.583 116.311 107.129 111.171C106.839 107.879 106.897 104.093 108.37 102.85C106.49 102.161 105.867 98.7015 105.867 95.6841C105.867 91.8296 106.082 88.1406 108.166 88.1406C105.523 86.7095 106.662 75.9106 108.837 74.3513C112.278 71.8824 132.604 68.9207 136.654 72.2114C137.953 73.2679 140.831 83.4457 139.327 84.303C141.269 84.0752 144.02 97.8054 142.241 98.7049C144.221 98.9327 144.925 111.956 143.267 112.422C145.039 112.607 144.375 122.954 142.603 124.449Z"
          fill="#FFDDD4"
        />
        <path
          d="M128.935 70.7527C132.441 70.7527 135.368 71.1662 136.654 72.2125C137.953 73.2689 140.83 83.4468 139.327 84.3041C139.342 84.3024 139.359 84.3007 139.376 84.3007C141.315 84.3007 144.006 97.8132 142.243 98.7059C144.222 98.9337 144.926 111.957 143.269 112.423C145.041 112.608 144.376 122.955 142.604 124.45C140.692 126.062 126.92 129.339 118.355 129.339C115.496 129.339 113.218 128.974 112.155 128.06C110.808 126.904 110.916 120.396 111.974 117.539C111.801 117.551 111.632 117.558 111.468 117.558C108.881 117.558 107.558 116.007 107.131 111.174C106.841 107.88 106.898 104.096 108.371 102.852C106.491 102.164 105.869 98.7042 105.869 95.6868C105.869 91.8324 106.083 88.1433 108.167 88.1433C105.524 86.7122 106.664 75.9134 108.839 74.3541C111.188 72.6698 121.401 70.7544 128.936 70.7544M128.935 70.584C121.58 70.584 111.198 72.4505 108.739 74.2157C107.197 75.3227 106.269 80.431 106.5 84.088C106.588 85.4854 106.878 87.2219 107.725 88.0269C105.864 88.4994 105.698 92.0518 105.698 95.6868C105.698 96.8833 105.794 98.6367 106.25 100.191C106.662 101.588 107.273 102.501 108.069 102.906C106.685 104.39 106.696 108.192 106.961 111.189C107.376 115.894 108.638 117.728 111.465 117.728C111.551 117.728 111.639 117.727 111.73 117.723C111.271 119.119 110.984 121.321 110.992 123.439C110.997 124.6 111.109 127.388 112.044 128.19C113.063 129.066 115.186 129.509 118.353 129.509C126.872 129.509 140.729 126.252 142.712 124.58C143.087 124.264 143.423 123.601 143.711 122.607C143.964 121.731 144.172 120.633 144.309 119.428C144.59 116.947 144.525 114.539 144.141 113.293C144.005 112.851 143.829 112.547 143.613 112.387C144.499 111.386 144.432 107.191 144.249 104.913C144.141 103.568 143.718 99.3944 142.56 98.6502C143.456 97.4689 142.761 92.6762 142.051 89.7026C141.683 88.1619 140.756 84.7378 139.663 84.2045C139.791 84.0003 139.872 83.6999 139.904 83.2966C140.14 80.4209 137.901 73.009 136.762 72.0826C135.239 70.8456 131.667 70.5857 128.936 70.5857L128.935 70.584Z"
          fill="#020203"
        />
        <path
          d="M58.2448 19.3793C56.9352 19.3793 55.9311 19.1093 55.9125 19.1043C55.867 19.0924 55.8417 19.0452 55.8535 18.9996C55.8653 18.9541 55.9125 18.9287 55.9581 18.9406C55.9952 18.9507 59.7586 19.9632 62.187 18.0056C65.0053 15.7358 65.741 11.2739 65.7495 11.23C65.7562 11.1844 65.8001 11.1524 65.8457 11.1591C65.8912 11.1659 65.9233 11.2097 65.9166 11.2553C65.9098 11.3009 65.1622 15.8253 62.2933 18.1356C61.0664 19.1245 59.5122 19.3776 58.2465 19.3776L58.2448 19.3793Z"
          fill="black"
        />
        <path
          d="M58.4927 17.5332C57.6726 17.5332 57.1191 17.3256 57.0786 17.3104C57.0347 17.2935 57.0127 17.2446 57.0296 17.2007C57.0465 17.1568 57.0954 17.1349 57.1393 17.1518C57.1545 17.1585 58.7526 17.7543 60.5533 16.8885C62.8433 15.7882 63.7378 12.03 63.7462 11.9928C63.7563 11.9473 63.8019 11.9186 63.8475 11.9304C63.893 11.9405 63.9217 11.9861 63.9099 12.0317C63.9082 12.0418 63.6804 13.0088 63.1674 14.1006C62.6915 15.1132 61.8629 16.4464 60.6259 17.0421C59.8361 17.4218 59.0918 17.5332 58.4911 17.5332H58.4927Z"
          fill="black"
        />
        <path
          d="M107.949 88.1604C107.905 88.1604 107.868 88.1266 107.865 88.0811C107.862 88.0338 107.897 87.995 107.944 87.9916C111.568 87.7587 123.907 86.9284 130.565 86.0087C130.609 86.002 130.654 86.034 130.659 86.0813C130.666 86.1268 130.634 86.1707 130.587 86.1758C123.924 87.0972 111.579 87.9275 107.954 88.1587C107.953 88.1587 107.951 88.1587 107.949 88.1587V88.1604Z"
          fill="black"
        />
        <path
          d="M108.197 102.966C108.152 102.966 108.115 102.93 108.113 102.885C108.111 102.837 108.147 102.799 108.194 102.797C115.223 102.488 125.031 101.816 131.476 100.854C131.521 100.848 131.565 100.88 131.572 100.925C131.579 100.971 131.547 101.015 131.501 101.021C125.049 101.985 115.234 102.658 108.201 102.967H108.197V102.966Z"
          fill="black"
        />
        <path
          d="M12.9654 93.5446C12.9232 93.5446 12.8878 93.5142 12.8827 93.472C12.876 93.4265 12.908 93.3826 12.9553 93.3775C14.4049 93.1784 18.1936 92.4257 22.5779 91.5566C26.7007 90.7381 31.3719 89.8117 34.5277 89.2666C34.5733 89.2598 34.6171 89.2902 34.6256 89.3357C34.634 89.3813 34.602 89.4252 34.5564 89.4336C31.404 89.977 26.7327 90.9035 22.6117 91.722C18.2239 92.5928 14.4336 93.3455 12.9789 93.5446C12.9755 93.5446 12.9705 93.5446 12.9671 93.5446H12.9654Z"
          fill="black"
        />
        <path
          d="M13.5068 108.552C13.4646 108.552 13.4274 108.52 13.4224 108.476C13.4173 108.428 13.4511 108.388 13.4983 108.383C19.3002 107.794 31.0526 105.961 37.1228 104.189C37.1667 104.176 37.214 104.201 37.2275 104.247C37.241 104.292 37.2156 104.338 37.1701 104.351C31.0897 106.127 19.3239 107.961 13.5152 108.552C13.5118 108.552 13.5101 108.552 13.5068 108.552Z"
          fill="black"
        />
        <path
          d="M13.5068 121.915C13.4612 121.915 13.4241 121.88 13.4224 121.834C13.4207 121.787 13.4562 121.748 13.5034 121.746C15.0223 121.674 28.5432 120.98 35.3982 118.945C35.4437 118.932 35.4893 118.957 35.5028 119.002C35.5163 119.046 35.491 119.094 35.4454 119.107C28.5702 121.147 15.0307 121.843 13.5102 121.915H13.5068Z"
          fill="black"
        />
        <path
          d="M111.893 117.713C111.849 117.713 111.812 117.679 111.809 117.635C111.805 117.588 111.841 117.549 111.886 117.544L113.01 117.465C118.185 117.099 126.847 116.486 132.578 114.991C132.624 114.979 132.669 115.006 132.681 115.052C132.693 115.097 132.666 115.143 132.62 115.155C126.874 116.655 118.203 117.268 113.022 117.634L111.898 117.713C111.898 117.713 111.895 117.713 111.892 117.713H111.893Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M2.00608 127.804C2.00608 127.804 7.25616 130.365 16.4214 130.365C28.378 130.365 36.389 126.785 36.389 126.785C36.389 126.785 36.2725 129.487 35.508 130.663C32.9176 134.649 8.29909 136.197 4.69441 134.563C2.89544 133.748 2.00439 127.804 2.00439 127.804H2.00608Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M111.105 121.979C111.105 121.979 114.347 124.15 123.514 124.15C135.47 124.15 144.129 120.408 144.129 120.408C144.129 120.408 143.714 123.405 142.776 124.448C141.033 126.387 117.364 131.73 112.151 128.228C110.818 127.332 111.105 121.981 111.105 121.981V121.979Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2212_7560">
          <rect width="143.958" height="144" fill="white" transform="translate(0.520996)" />
        </clipPath>
      </defs>
    </svg>
  );
}
