import { Api } from '__generated__/UtleggsappenApi';
import { config } from 'config';
import { getAccessToken } from 'utils/token-storage';

export const api = new Api({
  baseUrl: config.apiUrl,
  securityWorker: () => {
    return {
      headers: { Authorization: `Bearer ${getAccessToken()}` } as unknown as HeadersInit,
    };
  },
});
