import { Close } from 'assets/svgcomponents/Close';
import { IconButton } from 'components/buttons/IconButton';
import { Modal } from 'components/modal/Modal';
import { StaticToast } from 'components/toast/StaticToast';
import * as Dialog from '@radix-ui/react-dialog';
import { Chip } from 'components/chips/Chip';

export function RolesModal() {
  const roles = [
    {
      name: 'Admin',
      description: 'Administratorer kan redigere brukere og avdelinger',
    },
    {
      name: 'Dashbord',
      description: 'Tilgang til dashbord og utvalgte avdelinger',
    },
    {
      name: 'App',
      description: 'Tilgang til appen',
    },
  ];

  return (
    <Modal
      zIndex={55}
      trigger={<StaticToast description="Hva betyr de forskjellige rollene?" type="info" onClick={() => {}} />}>
      <div className="space-y-8">
        <div className="flex items-center justify-between space-x-8">
          <h2 className="normalheading">Hva betyr de forskjellige rollene?</h2>
          <Dialog.Close asChild>
            <IconButton icon={<Close />} />
          </Dialog.Close>
        </div>

        <table>
          <tbody>
            {roles.map((role) => (
              <tr key={role.name} className="p-4">
                <td className="py-2 pr-4">
                  <div className="w-fit">
                    <Chip type="overlay" value={role.name} size="large" />
                  </div>
                </td>
                <td>{role.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
