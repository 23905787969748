import { CellContext } from '@tanstack/react-table';
import { useCategories } from 'api/categories/useCategories';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import BackButton from 'components/buttons/BackButton';
import Input from 'components/input-field/Input';
import Table from 'components/table/Table';
import { DefaultCell, HeaderCell } from 'components/table/TableComponents';
import { useTheme } from 'context/theme';
import { useMemo } from 'react';
import { organizationService } from 'services/organizationService';
import { toast } from 'components/toast/Toast';
import { useStoredOrg } from 'context/OrganizationContext';
import { Form, Formik } from 'formik';
import { RateLevelTypeEnum } from '__generated__/UtleggsappenApi';

const RateEnum = RateLevelTypeEnum;

export default function DietTypeSettings() {
  const { data: categories, isLoading: categoriesLoading } = useCategories();
  const { organization } = useStoredOrg();

  const theme = useTheme();

  const defaultData: Array<any> = useMemo(
    () =>
      categories
        ?.filter((category) => category.type === 'DIET')
        .map((category) => ({
          id: category.id,
          title: category.title,
          account: category.accountingCode ? `${category.accountingCode}` : ' ',
          description: category.description,
          navigate: category.id,
        })) ?? [],
    [categories],
  );

  const defaultColumns = useMemo(
    () => [
      {
        accessorKey: 'title',
        cell: (value: CellContext<any, string>) => (
          <DefaultCell className="smalltextbold">{value.getValue()}</DefaultCell>
        ),
        header: () => <HeaderCell>Navn</HeaderCell>,
      },
      {
        accessorKey: 'account',
        cell: (value: CellContext<any, string>) => <DefaultCell>{value.getValue()}</DefaultCell>,
        header: () => <HeaderCell className="whitespace-nowrap">Konto</HeaderCell>,
      },
      {
        accessorKey: 'description',
        cell: (value: CellContext<any, string>) => <DefaultCell>{value.getValue()}</DefaultCell>,
        header: () => <HeaderCell>Kommentar</HeaderCell>,
      },
    ],
    [],
  );

  const handleToggleAction = async ({
    taxableRates,
    taxFreeRates,
  }: {
    taxableRates: boolean;
    taxFreeRates: boolean;
  }) => {
    let option;
    if (taxableRates && taxFreeRates) {
      option = RateEnum.ALL;
    } else if (taxableRates) {
      option = RateEnum.TAXABLE;
    } else if (taxFreeRates) {
      option = RateEnum.TAX_FREE;
    } else {
      option = RateEnum.NONE;
    }

    try {
      organizationService.update(organization.id, { activeRates: option });
    } catch (error) {
      toast.error({ description: 'Kunne ikke oppdatere satser' });
    }
  };

  return (
    <>
      <div className="flex flex-col justify-between gap-y-5 py-5">
        <div className="flex flex-col">
          <BackButton />
          <h1 className="bigheading pt-5" style={{ color: theme.label.primary }}>
            Dietter
          </h1>
          <p className="caption py-2.5">
            Sett opp koblinger til regnskapssystemet ved hjelp av konto eller ekstern referanse. Du kan også velge
            hvilke satser som vil være tilgjengelige for dine ansatte.
          </p>
        </div>
        <Formik
          initialValues={{
            taxableRates: organization.activeRates === 'ALL' || organization.activeRates === 'TAXABLE',
            taxFreeRates: organization.activeRates === 'ALL' || organization.activeRates === 'TAX_FREE',
          }}
          onSubmit={handleToggleAction}>
          {({ values, setFieldValue }) => {
            return (
              <Form className="flex w-fit flex-row gap-x-10">
                <Input.Toggle
                  label="Statens Satser"
                  description="Reisekostnadssatser basert på statens avtaler"
                  onChange={(checked) => {
                    setFieldValue('taxableRates', checked);
                    handleToggleAction({ taxableRates: checked, taxFreeRates: values.taxFreeRates });
                  }}
                  value={values.taxableRates}
                />
                <Input.Toggle
                  label="Trekkfrie satser"
                  description="Trekkfrie reisekostnadssatser hentet fra Skatteetaten"
                  onChange={(checked) => {
                    setFieldValue('taxFreeRates', checked);
                    handleToggleAction({ taxableRates: values.taxableRates, taxFreeRates: checked });
                  }}
                  value={values.taxFreeRates}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
      {categories && organization && !categoriesLoading ? (
        <div className="flex w-full flex-1 flex-col">
          <Table
            data={defaultData}
            columns={defaultColumns}
            gridCols="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 68px"
            sortBy={[{ id: 'title', desc: false }]}
          />
        </div>
      ) : (
        <FullscreenLoading />
      )}
    </>
  );
}
