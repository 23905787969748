export function Car({ className, fill }: { className?: string; fill?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 32" fill={fill} className={className}>
      <path
        d="M30.5 14H29.15L25.6775 6.1875C25.5204 5.83403 25.2641 5.53372 24.9397 5.32296C24.6153 5.11219 24.2368 5 23.85 5H9.15C8.76317 5 8.38465 5.11219 8.06029 5.32296C7.73593 5.53372 7.47965 5.83403 7.3225 6.1875L3.85 14H2.5C2.23478 14 1.98043 14.1054 1.79289 14.2929C1.60536 14.4804 1.5 14.7348 1.5 15C1.5 15.2652 1.60536 15.5196 1.79289 15.7071C1.98043 15.8946 2.23478 16 2.5 16H3.5V26C3.5 26.5304 3.71071 27.0391 4.08579 27.4142C4.46086 27.7893 4.96957 28 5.5 28H8.5C9.03043 28 9.53914 27.7893 9.91421 27.4142C10.2893 27.0391 10.5 26.5304 10.5 26V24H22.5V26C22.5 26.5304 22.7107 27.0391 23.0858 27.4142C23.4609 27.7893 23.9696 28 24.5 28H27.5C28.0304 28 28.5391 27.7893 28.9142 27.4142C29.2893 27.0391 29.5 26.5304 29.5 26V16H30.5C30.7652 16 31.0196 15.8946 31.2071 15.7071C31.3946 15.5196 31.5 15.2652 31.5 15C31.5 14.7348 31.3946 14.4804 31.2071 14.2929C31.0196 14.1054 30.7652 14 30.5 14ZM9.15 7H23.85L26.9613 14H6.03875L9.15 7ZM27.5 26H24.5V23C24.5 22.7348 24.3946 22.4804 24.2071 22.2929C24.0196 22.1054 23.7652 22 23.5 22H9.5C9.23478 22 8.98043 22.1054 8.79289 22.2929C8.60536 22.4804 8.5 22.7348 8.5 23V26H5.5V16H27.5V26Z"
        fill={fill}
        fillOpacity="1"
      />
    </svg>
  );
}
