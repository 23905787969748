import { useTheme } from 'context/theme';
import { cn } from 'utils/className';

interface BarChartProps {
  className?: string;
  max?: number;
  data: BarChartData[];
}

interface BarChartData {
  title: string;
  value: number;
  className?: string;
}

export default function BarChart({ className, max = 0, data }: BarChartProps) {
  const theme = useTheme();

  if (max === 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].value > max) {
        max = data[i].value;
      }
    }
    max = Math.round(max);
  }

  return (
    <div className={cn('flex flex-col items-center', className)}>
      <div
        className="flex w-full justify-center rounded-xlarge sm:h-40 md:h-52 lg:h-64 xl:h-72 2xl:h-96"
        style={{ backgroundColor: theme.background.inverse }}>
        <div className="z-10 flex h-full w-11/12 flex-row pt-6">
          {data &&
            data.map((elem: any, i: any) => {
              const styles: React.CSSProperties = {
                height: elem && max ? `calc(${(elem.value / max) * 100}%)` : 0,
                backgroundColor: theme.base.accent,
              };
              return (
                <div key={i} className="flex w-full flex-col items-center justify-end">
                  {elem.value !== 0 && (
                    <p className="caption flex h-7" style={{ color: theme.label.ondark }}>
                      {elem.value}
                    </p>
                  )}
                  <div
                    className={cn(
                      'w-3 rounded-t-[4px] sm:w-4 sm:rounded-t-md md:w-6 md:rounded-t-small lg:w-9 lg:rounded-t-medium xl:w-12 xl:rounded-t-large',
                      elem.className,
                    )}
                    style={styles}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div className="mt-3 flex w-11/12 text-center">
        {data &&
          data.map((elem: any, i: any) => (
            <p key={i} className="smalltext w-full">
              {elem.title}
            </p>
          ))}
      </div>
    </div>
  );
}
