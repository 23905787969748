import { api } from 'utils/fetch-wrapper';

const apiUrl = '/expenses';

export const expenseService = {
  addRejectMessage,
};

function addRejectMessage(message: string, id: string) {
  return api.post(`${apiUrl}/${id}/reject`, { rejectReason: message });
}
