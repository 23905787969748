import { LoadingIndicator } from 'components/layout/LoadingIndicator';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWizardContext } from '../WizardContext';
import { useUser } from 'api/users/useUser';
import { useIsMobile } from 'utils/useIsMobile';

export function LastStep() {
  const { mutate: mutateUser } = useUser();

  const [stateValue, setState] = useWizardContext();
  const loadingTexts = ['Oppretter dashboard'];

  const [activeLoadingText, setActiveLoadingText] = useState(0);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  useEffect(() => {
    const interval = setInterval(() => {
      const randIndex = Math.floor(Math.random() * loadingTexts.length);
      setActiveLoadingText(randIndex);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const mutate = async () => {
      await mutateUser();
      setTimeout(() => {
        navigate(`/${stateValue.organization.handle}/velkommen`);
      }, 3000);
    };
    mutate();
  }, []);

  return (
    <div className="space-y-6">
      <LoadingIndicator size="lrg" backgroundStyle="accentstrong" />
      <AnimatePresence mode="wait">
        {loadingTexts.map((text, index) => {
          return (
            activeLoadingText === index && (
              <motion.p
                key={activeLoadingText === index ? index : 'NA'}
                className="smalltext text-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                {loadingTexts[index]}
              </motion.p>
            )
          );
        })}
      </AnimatePresence>
    </div>
  );
}
