import { Alert } from '__generated__/UtleggsappenApi';
import { fetcher } from 'api';
import useSWR from 'swr';

export function useAlerts() {
  const { data, error, mutate, isLoading } = useSWR<Alert[]>('/alerts/active', fetcher);

  return {
    data,
    error,
    mutate,
    isLoading,
  };
}
