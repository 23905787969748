import { api } from 'api/api';
import { OrganizationsIcon } from 'assets/svgcomponents/OrganizationsIcon';
import { alert } from 'components/alerts/Alert';
import { LargeButton } from 'components/buttons/LargeButton';
import { useTheme } from 'context/theme';
import { useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { authService } from 'services/authService';
import { generateDisplayName } from 'utils/generateOrganizationName';
import { useDebounce } from 'utils/useDebounce';
import { DropdownSearch } from '../DropdownSearch';
import { SignupCard } from '../SignupCard';
import { useWizardContext } from '../WizardContext';

export function OrganizationSearch() {
  const { nextStep, goToStep } = useWizard();
  const theme = useTheme();

  const [stateValue, setState] = useWizardContext();

  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState<any>(stateValue.unit);
  const [isSearching, setIsSearching] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const brregUrl = 'https://data.brreg.no';
  const brregContext = '/enhetsregisteret/api/enheter';

  const handleSelect = async () => {
    const exists = await api.organizations.exists({ orgnumber: selectedUnit.organisasjonsnummer });

    if (exists.data) {
      alert.info({
        actionLabel: 'Kontakt support',
        onAction: () => {
          window.open('mailto:hei@logiskbrist.no');
        },
        title: '',
        description:
          'Det er allerede registrert en organisasjon med dette organisasjonsnummeret. Ta kontakt med support hvis du mener dette er en feil.',
      });
    } else {
      setState({ unit: { displayName: generateDisplayName(selectedUnit.navn), ...selectedUnit } });

      if (
        selectedUnit?.navn &&
        selectedUnit?.organisasjonsnummer &&
        selectedUnit?.forretningsadresse?.adresse &&
        selectedUnit?.forretningsadresse?.postnummer &&
        selectedUnit?.forretningsadresse?.poststed
      ) {
        goToStep(3);
      } else {
        nextStep();
      }
    }
  };

  const handleEmpty = async () => {
    setSelectedUnit(null);
  };

  const handleSearch = useDebounce(async (name: string) => {
    setNoResults(false);
    if (name === '') {
      setUnits([]);
      setIsSearching(false);
      return;
    }
    await fetch(`${brregUrl}${brregContext}?navn=${name}&size=10`)
      .then(async (res) => {
        return await res.json();
      })
      .then((data) => {
        setUnits(data._embedded.enheter);
      })
      .catch(() => {
        setNoResults(true);
      })
      .finally(() => {
        setIsSearching(false);
      });
  }, 500);

  return (
    <SignupCard
      imageComponent={<OrganizationsIcon className="h-full w-48" />}
      backButtonOnClick={() => authService.logout()}>
      <div className="flex w-full flex-col items-center justify-center space-y-8 lg:w-[385px]">
        <div className="w-full space-y-8">
          <h2 className="normalheading lg:bigheading text-center" style={{ color: theme.label.primary }}>
            Søk etter bedrift
          </h2>
          <div className="w-full space-y-2">
            <DropdownSearch
              label="Organisasjonsnavn"
              options={units}
              nameKey={'navn'}
              handleSearch={(value: string) => {
                setIsSearching(true);
                handleSearch(value);
              }}
              isSearching={isSearching}
              setSelectedValue={setSelectedUnit}
              initialValue={stateValue.unit}
              noResults={noResults}
            />
            {/* <div className="caption flex w-full space-x-1 text-center underline" style={{ color: theme.label.primary }}>
              <button
                className="clickable w-full"
                onClick={() => {
                  handleEmpty().then(() => {
                    nextStep();
                  });
                }}>
                Jeg finner ikke bedriften min i listen
              </button>
            </div> */}
          </div>
        </div>
        <div className="flex space-x-3 pt-2">
          <LargeButton backgroundColor="light" defaultStyle="indigo" onClick={handleSelect} disabled={!selectedUnit}>
            Opprett bedrift
          </LargeButton>
        </div>
      </div>
    </SignupCard>
  );
}
