import { api } from 'api/api';
import { ApprovedIcon } from 'assets/svgcomponents/ApprovedIcon';
import { LargeButton } from 'components/buttons/LargeButton';
import { TextButton } from 'components/buttons/TextButton';
import { TierModal } from 'components/modal/TierModal';
import { useTheme } from 'context/theme';
import { useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { SignupCard } from '../SignupCard';
import { useWizardContext } from '../WizardContext';

export function SelectTier() {
  const { handleStep, nextStep } = useWizard();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const [stateValue, setState] = useWizardContext();

  handleStep(() => {});

  async function handleStartFreeTrial() {
    setIsLoading(true);
    await api.subscriptions.startSubscription(stateValue.organization.id, {
      trial: true,
    });
    setIsLoading(false);
    nextStep();
  }

  return (
    <SignupCard
      split={false}
      backButtonOnClick={() => {}}
      backButtonHidden
      imageComponent={<ApprovedIcon className="h-full w-40" />}>
      <div
        className="flex w-fit max-w-full flex-col items-center justify-center space-y-8"
        style={{ color: theme.label.primary }}>
        <div className="centered flex-col space-y-2 text-center">
          <h2 className="normalheading lg:bigheading">
            Prøv Pro
            <br className="max-lg:hidden" /> gratis i 30 dager
          </h2>
          <p className="smalltext text-center">
            Bruk utleggsappen så mye du vil i en måned, <br className="max-lg:hidden" />
            risikofritt, helt uforpliktende.
          </p>
        </div>
        <div className="centered flex-col space-y-3">
          <LargeButton backgroundColor="light" defaultStyle="indigo" onClick={handleStartFreeTrial} loading={isLoading}>
            Prøv en måned gratis
          </LargeButton>
          <TextButton
            backgroundColor="light"
            className="underline"
            onClick={() => {
              nextStep();
            }}>
            Nei takk, ta meg til gratisplanen
          </TextButton>
        </div>
        <TierModal />
      </div>
    </SignupCard>
  );
}
