const colors = {
  stone: {
    50: '#f4f4f4ff',
    100: '#eae9e8ff',
    200: '#cecccaff',
    300: '#aeaaa8ff',
    400: '#928d8aff',
    500: '#77716dff',
    600: '#5f5a57ff',
    700: '#474341ff',
    800: '#393634ff',
    900: '#2b2927ff',
    950: '#100f0eff',
  },
  brown: {
    50: '#f8f5f1ff',
    100: '#f0ebe4ff',
    200: '#ddd1c0ff',
    300: '#c7b398ff',
    400: '#b49975ff',
    500: '#a17f52ff',
    600: '#816642ff',
    700: '#604c31ff',
    800: '#4d3d27ff',
    900: '#3a2e1eff',
    950: '#2b2216ff',
  },
  red: {
    50: '#fcf1f1ff',
    100: '#fae4e3ff',
    200: '#f3c0beff',
    300: '#eb9894ff',
    400: '#e47570ff',
    500: '#dd524cff',
    600: '#b1423dff',
    700: '#84312dff',
    800: '#6a2724ff',
    900: '#501e1bff',
    950: '#ffffffff',
  },
  purple: {
    50: '#f6f5fcff',
    100: '#eeeaf9ff',
    200: '#d7cff0ff',
    300: '#bdb0e6ff',
    400: '#a695deff',
    500: '#907ad6ff',
    600: '#7362abff',
    700: '#564980ff',
    800: '#453a66ff',
    900: '#342c4dff',
    950: '#2e2745ff',
  },
  blue: {
    50: '#efeefaff',
    100: '#dfddf5ff',
    200: '#b6b1e9ff',
    300: '#877edbff',
    400: '#5e52ceff',
    500: '#3627c2ff',
    600: '#2b1f9bff',
    700: '#201774ff',
    800: '#1a135dff',
    900: '#130e46ff',
    950: '#100c3aff',
  },
  green: {
    50: '#f1faf8ff',
    100: '#e3f6f1ff',
    200: '#bfeadfff',
    300: '#95dccbff',
    400: '#71d0b9ff',
    500: '#4ec4a8ff',
    600: '#3e9d86ff',
    700: '#2e7564ff',
    800: '#255e50ff',
    900: '#1c473dff',
    950: '#15342dff',
  },
  yellow: {
    50: '#fefaf2ff',
    100: '#fdf4e4ff',
    200: '#fbe6c1ff',
    300: '#f9d699ff',
    400: '#f7c976ff',
    500: '#f5bb54ff',
    600: '#c49643ff',
    700: '#926f32ff',
    800: '#755928ff',
    900: '#58431eff',
    950: '#433317ff',
  },
  white: {
    50: '#ffffffff',
    100: '#ffffffff',
    200: '#ffffffff',
    300: '#ffffffff',
    400: '#ffffffff',
    500: '#ffffffff',
    600: '#ffffffff',
    700: '#ffffffff',
    800: '#ffffffff',
    900: '#ffffffff',
    950: '#ffffffff',
  },
  whitealpha: {
    50: '#ffffff0d',
    100: '#ffffff1a',
    200: '#ffffff33',
    300: '#ffffff4d',
    400: '#ffffff66',
    500: '#ffffff80',
    600: '#ffffff99',
    700: '#ffffffb3',
    800: '#ffffffcc',
    900: '#ffffffe6',
    950: '#ffffffff',
  },
  blackalpha: {
    50: '#1a1a1a0d',
    100: '#1a1a1a1a',
    200: '#1a1a1a33',
    300: '#1a1a1a4d',
    400: '#1a1a1a66',
    500: '#1a1a1a80',
    600: '#1a1a1a99',
    700: '#1a1a1ab3',
    800: '#1a1a1acc',
    900: '#1a1a1ae6',
    950: '#000000ff',
  },
  bluetransparent: {
    50: '#100c3a0d',
    100: '#100c3a1a',
    200: '#100c3a33',
    300: '#100c3a4d',
    400: '#100c3a66',
    500: '#100c3a80',
    600: '#100c3a99',
    700: '#100c3ab3',
    800: '#100c3acc',
    900: '#100c3ae6',
    950: '#100c3af2',
  },
} as const;

export { colors };
