import { config } from 'config';
import { getAccessToken } from './token-storage';

export const api = {
  get,
  post,
  put,
  patch,
  delete: _delete,
};

async function get(url: string) {
  const { updatedUrl, headers } = requestOptions(url);
  const options = {
    method: 'GET',
    headers,
  };
  return fetch(updatedUrl, options).then(handleResponse);
}

async function post(url: string, body?: any) {
  const { updatedUrl, headers } = requestOptions(url);
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    credentials: 'include' as RequestCredentials,
    body: JSON.stringify(body),
  };
  return fetch(updatedUrl, options).then(handleResponse);
}

async function put(url: string, body: any) {
  const { updatedUrl, headers } = requestOptions(url);
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...headers },
    body: JSON.stringify(body),
  };
  return fetch(updatedUrl, options).then(handleResponse);
}

async function patch(url: string, body: any | FormData) {
  const { updatedUrl, headers } = requestOptions(url);
  const options = {
    method: 'PATCH',
    headers: { ...(!(body instanceof FormData) && { 'Content-Type': 'application/json' }), ...headers },
    body: typeof body === 'string' || body instanceof FormData ? body : JSON.stringify(body),
  };
  return fetch(updatedUrl, options).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(url: string) {
  const { updatedUrl, headers } = requestOptions(url);
  const options = {
    method: 'DELETE',
    headers,
  };
  return fetch(updatedUrl, options).then(handleResponse);
}

// helper functions

function requestOptions(url: string) {
  // return auth header with jwt if user is logged in and request is to the api url
  const token = getAccessToken();
  const updatedUrl = url.includes('http') ? url : `${config.apiUrl}${url}`;
  if (token && updatedUrl) {
    return { headers: { Authorization: `Bearer ${token}` }, updatedUrl };
  } else {
    return { headers: undefined, updatedUrl };
  }
}

function handleResponse(response: any) {
  return response.text().then((text: string) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
