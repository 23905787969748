/* eslint-disable prettier/prettier */
import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { Close } from 'assets/svgcomponents/Close';
import { Line } from 'assets/svgcomponents/Line';
import { useTheme } from 'context/theme';
import { Fragment, MouseEventHandler, useEffect, useRef, useState } from 'react';
import useMeasure from 'react-use-measure';
import { cn } from 'utils/className';

type DropdownCheckboxProps = {
  label: string;
  description?: string;
  disabled?: boolean;
  options: { id: string; title: string }[];
  selectedOptions: { id: string; title: string }[];
  onSelectOption: (option: { id: string; title: string }) => void;
  onRemoveOption: (option: { id: string; title: string }) => void;
};

export default function DropdownCheckbox({
  label,
  description,
  disabled = false,
  options,
  selectedOptions,
  onSelectOption,
  onRemoveOption,
}: Readonly<DropdownCheckboxProps>) {
  const theme = useTheme();

  const [accordionOpen, setAccordionOpen] = useState<string>('');
  const [selectableOptions, setSelectableOptions] = useState<{ id: string; title: string }[]>(
    options.filter((option) => !selectedOptions.find((el) => el.id === option.id)),
  );
  const [filteredSelectableOptions, setFilteredSelectableOptions] = useState<{ id: string; title: string }[]>([]);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [search, setSearch] = useState<string>('');

  const [ref, bounds] = useMeasure();

  const accordionRef = useRef<HTMLDivElement>(null);
  const accordionInputRef = useRef<HTMLInputElement>(null);
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectableOptions(options.filter((option) => !selectedOptions.find((el) => el.id === option.id)));
  }, [options, selectedOptions]);

  useEffect(() => {
    setFilteredSelectableOptions(() => {
      const newList = selectableOptions.filter((option) => option.title.toLowerCase().includes(search.toLowerCase()));
      setSelectedOption((prev) => (newList.length === 0 ? null : Math.min(prev!, newList.length - 1)));
      return newList;
    });
  }, [search, selectableOptions]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      const newSelectedOption =
        e.key === 'ArrowDown'
          ? ((selectedOption ?? -1) + 1) % filteredSelectableOptions.length
          : ((selectedOption ?? 1) + filteredSelectableOptions.length - 1) % filteredSelectableOptions.length;
      e.preventDefault();
      setAccordionOpen('content');
      setSelectedOption(filteredSelectableOptions.length === 0 ? null : newSelectedOption);
      const scrollPosition = scrollAreaRef.current?.scrollTop ?? 0;
      const newScrollPosition =
        scrollPosition > newSelectedOption * 55.33
          ? newSelectedOption * 55.33 // Nytt element over viewport
          : newSelectedOption * 55.33 > scrollPosition + 55.33 * 4
            ? (newSelectedOption - 4) * 55.33 // Nytt element under viewport
            : scrollPosition; // Nytt element innenfor viewport
      scrollAreaRef.current?.scrollTo({ top: newScrollPosition, behavior: 'smooth' });
    } else if (e.key === 'Enter') {
      if (selectedOption !== null) {
        e.preventDefault();
        onSelectOption(filteredSelectableOptions[selectedOption]);
        setSelectableOptions((prevOptns) => {
          const nullOrZero = prevOptns.length === 1 ? null : 0;
          setSelectedOption((prevIdx) => (prevIdx! - 1 < 0 ? nullOrZero : prevIdx! - 1));
          return prevOptns.filter((el) => el.id !== filteredSelectableOptions[selectedOption].id);
        });
        accordionInputRef.current?.focus();
      } else {
        setAccordionOpen((prev) => (prev === 'content' ? '' : 'content'));
      }
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setSelectedOption(null);
      setAccordionOpen('');
      accordionInputRef.current?.blur();
    }
  };

  return (
    <div className={cn('flex flex-col gap-y-1', disabled && 'opacity-50')}>
      <h2 className="smalltextbold">{label}</h2>
      {description && <p className="caption">{description}</p>}
      <div className="flex flex-col gap-y-2.5">
        <Accordion.Root
          ref={accordionRef}
          className={cn(
            'w-96 overflow-hidden rounded-small ring-v2-purple-500 focus-within:relative focus-within:ring ',
          )}
          style={{ backgroundColor: theme.background.primary }}
          type="single"
          defaultValue="item-1"
          collapsible
          value={accordionOpen && !disabled ? 'content' : ''}
          disabled={disabled}
          onKeyDown={handleKeyDown}
          onBlur={(e) => {
            if (!e.currentTarget.contains(e.relatedTarget as Node)) {
              setSelectedOption(null);
              setAccordionOpen('');
            }
          }}>
          <Accordion.Item
            className="mt-px overflow-hidden border first:mt-0 first:rounded-t-small last:rounded-b-small focus-within:border-0"
            value="content"
            style={{
              borderColor: theme.label.primary,
            }}>
            <Accordion.Header className="flex">
              <Accordion.Trigger
                className="group flex h-[45px] flex-1 cursor-default items-center justify-end px-5 text-[15px] leading-none outline-none"
                style={{ boxShadow: `0 2px 0 ${theme.base.accent}` }}
                tabIndex={-1}
                onMouseDown={() => {
                  setAccordionOpen((prev) => (prev === 'content' ? '' : 'content'));
                }}>
                <input
                  ref={accordionInputRef}
                  className="inputtext w-full focus:outline-none"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onFocus={() => setAccordionOpen('content')}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  disabled={disabled}
                />
                <ChevronDownIcon
                  className="transition-transform group-data-[state=open]:rotate-180"
                  style={{ color: theme.label.primary }}
                  aria-hidden
                />
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content
              className="inputtext overflow-hidden transition-all data-[state=closed]:animate-slideUp data-[state=open]:animate-slideDown"
              style={{ height: `${Math.min(filteredSelectableOptions.length, 5) * 55.33}px` }}>
              {options.length !== selectedOptions.length && (
                <ScrollArea.Root
                  className="w-full overflow-hidden transition-all"
                  style={{
                    height: `${Math.min(filteredSelectableOptions.length, 5) * 55.33}px`,
                  }}>
                  <ScrollArea.Viewport className="mt-0.5 h-full w-full" ref={scrollAreaRef}>
                    <div role="menu">
                      {filteredSelectableOptions.map((option, index) => (
                        <Fragment key={option.id}>
                          <button
                            type="button"
                            aria-selected={selectedOption === index}
                            tabIndex={-1}
                            className={cn(
                              'flex w-full cursor-default px-4 py-4',
                              selectedOption === index && 'bg-v2-purple-200',
                            )}
                            onClick={
                              (() => {
                                onSelectOption(option);
                                setSelectableOptions((prevOptns) => {
                                  const nullOrZero = prevOptns.length === 1 ? null : 0;
                                  setSelectedOption((prevIdx) => (prevIdx! - 1 < 0 ? nullOrZero : prevIdx! - 1));
                                  return prevOptns.filter((el) => el.id !== option.id);
                                });
                                accordionInputRef.current?.focus();
                              }) as MouseEventHandler<HTMLButtonElement>
                            }
                            onMouseEnter={() => {
                              setSelectedOption(index);
                            }}>
                            {option.title}
                          </button>
                          <Line stroke={theme.base.accent} className="h-0.5 last:hidden" />
                        </Fragment>
                      ))}
                    </div>
                  </ScrollArea.Viewport>
                  <ScrollArea.Scrollbar
                    className="mt-0.5 flex w-2.5 touch-none select-none bg-zinc-300 p-0.5 transition-colors ease-in-out hover:bg-zinc-400"
                    orientation="vertical">
                    <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-zinc-500 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
                  </ScrollArea.Scrollbar>
                </ScrollArea.Root>
              )}
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
        <div
          className={cn('-m-1 w-full overflow-hidden p-1 transition-all', disabled ? 'my-0 py-0' : '')}
          style={disabled ? { height: '0px' } : { height: `${bounds.height + 8}px` }}>
          <div className="flex h-fit w-full flex-row flex-wrap gap-2" ref={ref}>
            {selectedOptions?.map((option) => {
              return (
                <button
                  type="button"
                  key={option.id}
                  className="smalltext group flex w-fit cursor-pointer items-center justify-center gap-x-2.5 rounded-small border px-4 py-2"
                  style={{ backgroundColor: theme.background.primary, borderColor: theme.border.border }}
                  onClick={() => {
                    setSelectableOptions((prev) => [...prev, option]);
                    onRemoveOption(option);
                  }}
                  disabled={disabled}>
                  <p>{option.title}</p>
                  <div className="text-base transition-opacity group-hover:opacity-50">
                    <Close className="h-[21px]" />
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
