import { FloatingPortal } from '@floating-ui/react';
import { Alert, AlertType } from '__generated__/UtleggsappenApi';
import { CaretRight } from 'assets/svgcomponents/CaretRight';
import { useTheme } from 'context/theme';
import { EventEmitter } from 'events';
import { useEffect, useState } from 'react';

export const eventEmitter = new EventEmitter();

export interface BannerProps {
  content: React.ReactNode;
  id: string;
}

export function banner({ content, id }: BannerProps) {
  eventEmitter.emit('showBanner', { content, id });
}

export function hideBanner(id: string) {
  eventEmitter.emit('hideBanner', id);
}

export const BannerContainer = () => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const [content, setContent] = useState<React.ReactNode>(null);
  const [navbarElement, setNavbarElement] = useState<HTMLElement | null>(null);
  const [dashboardElement, setDashboardElement] = useState<HTMLElement | null>(null);

  const setElements = () => {
    const navBar = document.getElementById('navbar');
    const dashboard = document.getElementById('dashboard');
    if (navBar) {
      setNavbarElement(navBar);
    }

    if (dashboard) {
      setDashboardElement(dashboard);
    }
  };

  useEffect(() => {
    setElements();

    const showBanner = ({ content }: BannerProps) => {
      setIsVisible(true);
      setContent(content);
    };

    const hideBanner = (id: string) => {
      setIsVisible(false);
    };

    // Subscribe to the 'showBanner' event
    eventEmitter.on('showBanner', showBanner);
    eventEmitter.on('hideBanner', hideBanner);

    // Unsubscribe when the component is unmounted
    return () => {
      eventEmitter.off('showBanner', showBanner);
      eventEmitter.off('hideBanner', hideBanner);
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <FloatingPortal>
      <div
        className="centered fixed left-0 right-0 top-0 z-[10] border-b-[1px] p-2"
        style={{
          backgroundColor: theme.background.info,
          borderColor: theme.border.info,
          left: navbarElement ? navbarElement.getBoundingClientRect().right : 0,
          right: dashboardElement ? window.innerWidth - dashboardElement.getBoundingClientRect().right : 0,
        }}>
        {content}
      </div>
    </FloatingPortal>
  );
};

export function AlertBanner({ content: alerts }: { content: Alert[] }) {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(0);

  const [backgroundColor, setBackgroundColor] = useState<any>(theme.background.info);
  const [borderColor, setBorderColor] = useState<any>(theme.border.info);

  useEffect(() => {
    if (alerts.length > 0) {
      switch (alerts[activeIndex].type as unknown as AlertType) {
        case AlertType.ERROR:
          setBackgroundColor(theme.background.critical);
          setBorderColor(theme.border.critical);
          break;
        case AlertType.WARNING:
          setBackgroundColor(theme.background.warning);
          setBorderColor(theme.border.warning);
          break;
        case AlertType.INFO:
          setBackgroundColor(theme.background.info);
          setBorderColor(theme.border.info);
          break;
        default:
          break;
      }
    }
  }, [alerts, activeIndex, theme.background.critical]);

  return (
    <div
      className="centered fixed left-0 right-0 top-0 z-[10] border-b-[1px] p-2"
      style={{
        backgroundColor: backgroundColor,
        borderColor: borderColor,
      }}>
      <p className="caption absolute left-10">
        {new Date(alerts[activeIndex].updatedAt).toLocaleDateString('no-NO', {
          hour: '2-digit',
          minute: '2-digit',
        })}
      </p>
      <div className="flex items-center space-x-1">
        <p className="smalltextbold">{alerts[activeIndex].title}:</p>
        <p>{alerts[activeIndex].message}</p>
        {alerts[activeIndex].url && (
          <>
            <p> | </p>
            <a className="underline" href={alerts[activeIndex].url!}>
              Les mer
            </a>
          </>
        )}
      </div>
      {alerts.length > 1 && (
        <div className="absolute right-10 flex items-center justify-center space-x-2">
          <button
            className="h-5 w-5 rounded-full border bg-white"
            style={{ borderColor: theme.border.border }}
            onClick={() => {
              setActiveIndex((activeIndex - 1 + alerts.length) % alerts.length);
            }}>
            <CaretRight fill="black" className="rotate-180" />
          </button>
          <p>
            {activeIndex + 1} / {alerts.length}
          </p>
          <button
            className="h-5 w-5 rounded-full border bg-white"
            style={{ borderColor: theme.border.border }}
            onClick={() => {
              setActiveIndex((activeIndex + 1) % alerts.length);
            }}>
            <CaretRight fill="black" className="" />
          </button>
        </div>
      )}
    </div>
  );
}
