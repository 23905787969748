import { useTheme } from 'context/theme';
import { useState } from 'react';
import { cn } from '../../utils/className';
import { LoadingIndicator } from '../layout/LoadingIndicator';

interface CommonProps {
  children?: React.ReactNode;
  /**
   * Style of the button
   */
  defaultStyle: 'white' | 'indigo' | 'solid' | 'outline' | 'critical' | 'success' | 'warning';

  /**
   * Misleading name, indicates the color behind the button
   */
  backgroundColor: 'light' | 'dark';

  /**
   * Classname for the button element
   */
  className?: string;

  /**
   * Style object for the button element
   */
  styleObject?: React.CSSProperties;

  /**
   * onClick handler
   */
  onClick?: () => void;

  /**
   * Type of the button element
   */
  type?: HTMLButtonElement['type'];

  /**
   * Href for the button element. If href is provided, the button will be wrapped in an anchor tag.
   */
  href?: string;

  /**
   * If true, the button will be disabled
   */
  disabled?: boolean;

  /**
   * If true, the button will show a loading indicator
   */
  loading?: boolean;
}

type ConditionalProps =
  | {
      icon: React.ReactNode;
      iconPlacement: 'left' | 'right';
    }
  | {
      icon?: never;
      iconPlacement?: never;
    };

export type ButtonProps = CommonProps & ConditionalProps;

export function BaseButton({
  icon,
  iconPlacement,
  defaultStyle = 'white',
  backgroundColor = 'light',
  children,
  className,
  styleObject,
  type,
  href,
  onClick,
  disabled,
  loading,
}: ButtonProps) {
  const [isHovering, setIsHovering] = useState(false);
  const theme = useTheme();

  const styles: React.CSSProperties = { borderColor: theme.border.border };
  const hoverStyles: React.CSSProperties = {};
  const borderClasses = 'border';
  const commonClasses = 'centered flex transition-colors';
  const disabledClasses = 'opacity-50';

  const classes: string[] = [];

  classes.push(className ?? '');
  classes.push(commonClasses);
  if (disabled) classes.push(disabledClasses);

  if (backgroundColor === 'light') {
    classes.push(borderClasses);
  }

  if (defaultStyle === 'white') {
    styles.backgroundColor = theme.background.primary;
    styles.color = theme.label.primary;
    hoverStyles.backgroundColor = theme.background.inverse;
    hoverStyles.borderColor = theme.background.inverse;
    hoverStyles.color = theme.label.ondark;
  }

  if (defaultStyle === 'critical') {
    styles.backgroundColor = theme.background.critical;
    styles.color = theme.label.critical;
    if (backgroundColor === 'light') {
      hoverStyles.backgroundColor = theme.base.critical;
      styles.borderColor = theme.border.critical;
      hoverStyles.borderColor = theme.label.critical;
      hoverStyles.color = theme.label.onaccent;
    } else {
      hoverStyles.backgroundColor = theme.background.primary;
      styles.borderColor = theme.background.primary;
      hoverStyles.color = theme.label.primary;
      classes.push(borderClasses);
    }
  }

  if (defaultStyle === 'success') {
    styles.backgroundColor = theme.base.success;
    styles.color = theme.label.primary;
    if (backgroundColor === 'light') {
      hoverStyles.backgroundColor = theme.background.inverse;
      hoverStyles.color = theme.label.ondark;
      styles.borderColor = theme.border.border;
      hoverStyles.borderColor = theme.label.primary;
    } else {
      hoverStyles.backgroundColor = theme.background.primary;
      styles.borderColor = theme.background.primary;
      hoverStyles.color = theme.label.primary;
      classes.push(borderClasses);
    }
  }

  if (defaultStyle === 'warning') {
    styles.backgroundColor = theme.base.warning;
    styles.color = theme.label.primary;
    if (backgroundColor === 'light') {
      hoverStyles.backgroundColor = theme.background.inverse;
      hoverStyles.color = theme.label.ondark;
      styles.borderColor = theme.border.border;
      hoverStyles.borderColor = theme.label.primary;
    } else {
      hoverStyles.backgroundColor = theme.background.primary;
      styles.borderColor = theme.background.primary;
      hoverStyles.color = theme.label.primary;
      classes.push(borderClasses);
    }
  }

  if (defaultStyle === 'indigo') {
    styles.backgroundColor = theme.base.accent;
    styles.color = theme.label.onaccent;
    if (backgroundColor === 'light') {
      hoverStyles.backgroundColor = theme.background.inverse;
      hoverStyles.color = theme.label.ondark;
      hoverStyles.borderColor = theme.label.primary;
    } else {
      hoverStyles.backgroundColor = 'transparent';
      hoverStyles.color = theme.label.ondark;
      hoverStyles.borderColor = theme.border.subtle;
      classes.push('border border-transparent');
    }
  }

  if (defaultStyle === 'solid') {
    styles.backgroundColor = theme.background.inverse;
    styles.color = theme.label.ondark;
    styles.borderColor = theme.label.primary;
    classes.push('border border-transparent');
    hoverStyles.backgroundColor = 'transparent';
    hoverStyles.color = theme.label.primary;
    hoverStyles.borderColor = theme.border.border;
  }

  if (defaultStyle === 'outline') {
    classes.push('bg-transparent');
    if (backgroundColor === 'light') {
      styles.color = theme.label.primary;
      styles.borderColor = theme.label.primary;
      hoverStyles.backgroundColor = theme.background.inverse;
      hoverStyles.color = theme.label.ondark;
    } else {
      styles.borderColor = theme.border.subtle;
      styles.color = theme.label.ondark;
      hoverStyles.backgroundColor = theme.background.primary;
      hoverStyles.borderColor = theme.border.border;
      hoverStyles.color = theme.label.primary;
      classes.push('border');
    }
  }

  const returnButton = (
    <button
      type={type}
      className={cn(classes.join(' '), iconPlacement === 'left' && 'flex-row-reverse', 'relative')}
      style={{ ...styles, ...(isHovering ? hoverStyles : {}), ...styleObject }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={onClick}
      disabled={disabled ?? loading}>
      <div
        className={cn(
          icon && children && (iconPlacement === 'left' ? 'pl-1' : 'pr-1'),
          loading && 'opacity-0',
          styles,
        )}>
        {children}
      </div>
      <div className={cn(loading && 'opacity-0')}>{icon}</div>
      {loading && (
        <div className="absolute flex h-full w-full items-center justify-center">
          <LoadingIndicator size="med" backgroundStyle="accent" />
        </div>
      )}
    </button>
  );

  return href ? <a href={href}>{returnButton}</a> : returnButton;
}
