export function Search({ className, fill }: { className?: string; fill?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" fill={fill} className={className}>
      <path
        d="M18.8393 17.9108L14.732 13.8043C15.9225 12.3751 16.5161 10.5419 16.3894 8.68614C16.2627 6.83038 15.4254 5.09491 14.0517 3.84074C12.6781 2.58658 10.8738 1.91029 9.01418 1.95255C7.15458 1.99481 5.38286 2.75238 4.06759 4.06765C2.75232 5.38292 1.99475 7.15464 1.95249 9.01424C1.91023 10.8738 2.58652 12.6781 3.84068 14.0518C5.09485 15.4255 6.83032 16.2627 8.68608 16.3894C10.5418 16.5161 12.375 15.9225 13.8042 14.7321L17.9107 18.8394C17.9717 18.9003 18.0441 18.9487 18.1237 18.9817C18.2034 19.0147 18.2888 19.0317 18.375 19.0317C18.4612 19.0317 18.5466 19.0147 18.6263 18.9817C18.7059 18.9487 18.7783 18.9003 18.8393 18.8394C18.9003 18.7784 18.9486 18.706 18.9816 18.6263C19.0146 18.5467 19.0316 18.4613 19.0316 18.3751C19.0316 18.2888 19.0146 18.2035 18.9816 18.1238C18.9486 18.0441 18.9003 17.9717 18.8393 17.9108ZM3.28125 9.18756C3.28125 8.01942 3.62765 6.87751 4.27664 5.90623C4.92562 4.93495 5.84805 4.17793 6.92728 3.7309C8.00651 3.28387 9.19406 3.16691 10.3398 3.3948C11.4855 3.6227 12.5378 4.18521 13.3639 5.01122C14.1899 5.83722 14.7524 6.88961 14.9803 8.03531C15.2082 9.18101 15.0912 10.3686 14.6442 11.4478C14.1971 12.527 13.4401 13.4494 12.4688 14.0984C11.4976 14.7474 10.3556 15.0938 9.1875 15.0938C7.6216 15.0921 6.12033 14.4693 5.01307 13.362C3.90581 12.2547 3.28299 10.7535 3.28125 9.18756Z"
        fill={fill}
        fillOpacity="1"
      />
    </svg>
  );
}
