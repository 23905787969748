export function CircleCheck({ className, fill = '#100C3A' }: { className?: string; fill?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M7.55419 9.79744L9.53169 11.7741L13.0792 8.22577M16.665 9.99911C16.665 13.6801 13.681 16.6641 10 16.6641C6.31904 16.6641 3.33502 13.6801 3.33502 9.99911C3.33502 6.31813 6.31904 3.33411 10 3.33411C13.681 3.33411 16.665 6.31813 16.665 9.99911Z"
        stroke={fill}
        strokeOpacity="0.9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
