import { fetcher } from 'api';
import { useOrganization } from 'api/organizations/useOrganization';
import useSWR from 'swr';

export function useDepartments() {
  // ! Inferred type i backend stemmer ikke og inneholder uendelig recursion. Dermed blir inferred object[], så jeg har lagt inn manuelt
  // Awaited<ReturnType<typeof Api.prototype.departments.findAll>>['data']
  const { data: organization } = useOrganization();

  const { data, error, mutate, isLoading } = useSWR<
    {
      projects: {
        id: string;
        title: string;
        accountingCode: string;
        accountingDescription: string;
        description: string;
        isStaff: boolean;
        departmentId: string;
        createdAt: Date;
        updatedAt: Date;
      }[];
      accounts: {
        id: string;
        user: {
          id: string;
          role: number;
          givenName: string;
          familyName: string;
        };
      }[];
      id: string;
      title: string;
      accountingCode: string;
      accountingDescription: string;
      description: string;
      isStaff: boolean;
      organizationId: string;
      createdAt: Date;
      updatedAt: Date;
    }[]
  >(organization ? '/departments/?' + new URLSearchParams({ organizationId: organization?.id ?? '' }) : null, fetcher);

  return {
    data,
    isLoading,
    error,
    mutate,
  };
}
