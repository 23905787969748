import { Api } from '__generated__/UtleggsappenApi';
import { fetcher } from 'api';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

export function useOrganization() {
  const { handle } = useParams();

  const { data, error, mutate, isLoading } = useSWR<
    Awaited<ReturnType<typeof Api.prototype.organizations.findOne>>['data']
  >(handle ? '/organizations/' + handle : null, fetcher, {
    onErrorRetry: async (error, key, config, revalidate, { retryCount }) => {
      if (error.status === 404 || error.status === 403) {
        window.location.replace('/404');
      }
    },
    revalidateOnFocus: false,
  });

  return {
    data,
    mutate,
    isLoading,
    error,
  };
}
