import { useNavigate, useSearchParams } from 'react-router-dom';
import { setAccessToken, setRefreshToken } from 'utils/token-storage';
import { authService } from 'services/authService';
import { useCallback, useEffect } from 'react';
import { useUser } from 'api/users/useUser';
import { LoadingIndicator } from 'components/layout/LoadingIndicator';

export default function LoginCallback() {
  const { mutate: setUser } = useUser();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  let redirect = '/';

  const url = new URL(window.location.href);
  const pathArray = url.pathname.slice(1).split('/');
  const callbackIndex = pathArray.indexOf('callback');

  if (callbackIndex < pathArray.length) {
    redirect += pathArray.slice(callbackIndex + 1).join('/');
  }

  const refreshUser = useCallback(async () => {
    setRefreshToken(searchParams.get('refreshToken') || '');
    const user = await authService.refreshToken();
    if (user) {
      setAccessToken(user.accessToken);
      setRefreshToken(user.refreshToken);
      setUser();
      navigate(redirect);
    }
  }, [searchParams, setUser, navigate, redirect]);

  useEffect(() => {
    if (searchParams.get('refreshToken')) {
      refreshUser();
    } else {
      navigate('/login');
    }
  }, [navigate, refreshUser, searchParams]);

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <LoadingIndicator size="med" />
    </div>
  );
}
