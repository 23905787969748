import { Arrow } from 'assets/svgcomponents/Arrow';
import { ToastPosition } from 'react-toastify';

export interface ToastContentProps {
  title?: string;
  description: string;
  action?: { label: string; onClick: () => void };
  options?: {
    toastId?: string;
    persist?: boolean;
    position?: ToastPosition;
  };
}

export function ToastContent({ title, description, action }: ToastContentProps) {
  const base = (
    <>
      {title && <p className="smalltextbold">{title}</p>}
      <p className="smalltext">{description}</p>
      {action && (
        <p className="smalltext flex items-center">
          {action.label}{' '}
          <Arrow className="ml-[4px] w-[18px] rotate-180 transition-transform group-hover:translate-x-1/4" />
        </p>
      )}
    </>
  );

  if (action) {
    return (
      <button onClick={action.onClick} className="group space-y-[8px] text-left">
        {base}
      </button>
    );
  }

  return <div className="space-y-[8px] text-left">{base}</div>;
}
