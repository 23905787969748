export const sortAlphaSpecialLast = (a: string, b: string) => {
  const startsWithAlpha = (str: string) => str != null && /^[A-Za-z]/.test(str);

  if (startsWithAlpha(a) && !startsWithAlpha(b)) {
    return -1;
  } else if (!startsWithAlpha(a) && startsWithAlpha(b)) {
    return 1;
  }

  const alphaCompare = a.localeCompare(b, undefined, { numeric: false });
  if (alphaCompare !== 0) return alphaCompare;

  const numA = parseInt(a, 10);
  const numB = parseInt(b, 10);
  if (!isNaN(numA) && !isNaN(numB)) return numA - numB;

  return isNaN(numA) ? 1 : -1;
};
