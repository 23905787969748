import { cn } from 'utils/className';

export function Home({
  className,
  stroke,
  fill,
  fillOpacity,
}: {
  className?: string;
  stroke?: string;
  fill?: string;
  fillOpacity?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
      fill={fill}
      className={cn('h-[29px] w-[29px] p-1', className)}>
      <path
        d="M18.375 9.47866V17.0624C18.375 17.4105 18.2367 17.7444 17.9906 17.9905C17.7444 18.2367 17.4106 18.3749 17.0625 18.3749H13.7812C13.4332 18.3749 13.0993 18.2367 12.8532 17.9905C12.607 17.7444 12.4688 17.4105 12.4688 17.0624V13.7812C12.4688 13.6071 12.3996 13.4402 12.2765 13.3172C12.1535 13.1941 11.9865 13.1249 11.8125 13.1249H9.1875C9.01345 13.1249 8.84653 13.1941 8.72346 13.3172C8.60039 13.4402 8.53125 13.6071 8.53125 13.7812V17.0624C8.53125 17.4105 8.39297 17.7444 8.14683 17.9905C7.90069 18.2367 7.56685 18.3749 7.21875 18.3749H3.9375C3.5894 18.3749 3.25556 18.2367 3.00942 17.9905C2.76328 17.7444 2.625 17.4105 2.625 17.0624V9.47866C2.62497 9.29701 2.66265 9.11733 2.73565 8.95099C2.80865 8.78466 2.91539 8.63528 3.0491 8.51233L9.6116 2.32061L9.62063 2.31159C9.86224 2.09185 10.1771 1.97009 10.5037 1.97009C10.8303 1.97009 11.1451 2.09185 11.3868 2.31159C11.3896 2.3148 11.3926 2.31781 11.3958 2.32061L17.9583 8.51233C18.0907 8.63593 18.196 8.7856 18.2677 8.9519C18.3394 9.11821 18.376 9.29755 18.375 9.47866Z"
        fill={fill}
        stroke={stroke}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}
