import { fetcher } from 'api';
import { useOrganization } from 'api/organizations/useOrganization';
import useSWR from 'swr';
import Stripe from 'stripe';

export function useCustomer() {
  const { data: organization } = useOrganization();

  const { data, error, mutate, isLoading } = useSWR<Stripe.Customer & { default_payment_method: Stripe.PaymentMethod }>(
    organization ? `/subscriptions/${organization.id}/customer` : null,
    fetcher,
  );

  return {
    data,
    isLoading,
    error,
    mutate,
  };
}
