import { useAccount } from 'api/accounts/useAccount';
import { api } from 'api/api';
import { CaretRight } from 'assets/svgcomponents/CaretRight';
import { Check } from 'assets/svgcomponents/Check';
import { Link } from 'assets/svgcomponents/Link';
import { SignOut } from 'assets/svgcomponents/SignOut';
import { Slack } from 'assets/svgcomponents/Slack';
import { Sliders } from 'assets/svgcomponents/Sliders';
import { Users } from 'assets/svgcomponents/Users';
import { Warning } from 'assets/svgcomponents/Warning';
import { alert } from 'components/alerts/Alert';
import { toast } from 'components/toast/Toast';
import { useStoredOrg } from 'context/OrganizationContext';
import { useStoredUser } from 'context/UserContext';
import { useTheme } from 'context/theme';
import { useEffect } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { authService } from 'services/authService';
import { userService } from 'services/userService';
import NewPassword from './NewPassword';
import PreferencesSettings from './PreferencesSettings';
import UserSettings from './UserSettings';

function SettingsCategory({
  icon,
  arrowIcon,
  title,
  description,
  navigate,
  onClick,
}: Readonly<{
  icon: JSX.Element;
  arrowIcon?: JSX.Element;
  title: string;
  description?: string;
  navigate?: string;
  onClick?: () => void;
}>) {
  const theme = useTheme();
  const navigation = useNavigate();

  return (
    <button
      className="-mx-6 flex flex-row items-center justify-between rounded-large px-6 py-3 outline-none ring-v2-purple-500 transition-all hover:cursor-pointer hover:bg-v2-purple-200 focus-visible:ring active:bg-v2-purple-300"
      onClick={
        navigate
          ? () => {
              navigation(navigate);
            }
          : onClick
            ? () => {
                onClick();
              }
            : undefined
      }>
      <div className="flex w-full flex-row gap-x-4">
        <div className="mb-auto mt-auto">{icon}</div>
        <div className="flex flex-col items-start">
          <h3 className={`smalltextbold px-2.5 py-1 text-left ${!description && 'py-[18px]'}`}>{title}</h3>
          {description && <h2 className="smalltext px-2.5 py-1 text-left">{description}</h2>}
        </div>
      </div>
      {arrowIcon || <CaretRight fill={theme.label.primary} className="h-6 cursor-pointer transition-all" />}
    </button>
  );
}

function Settings() {
  const theme = useTheme();
  const organization = useStoredOrg();
  const { user, setUser } = useStoredUser();
  const { shutdown } = useIntercom();
  const { data: account } = useAccount();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('slack_success') === 'true') {
      toast.success({ description: 'Slack koblet til' });
      setSearchParams({});
    } else if (searchParams.get('slack_success') === 'false') {
      toast.error({ description: 'Det oppsto en feil' });
      setSearchParams({});
    }
  }, [searchParams]);

  return (
    <div
      className="flex flex-col justify-between gap-y-6 py-5"
      style={{
        color: theme.label.primary,
      }}>
      <h1 className="bigheading">Profil</h1>
      <div className="flex flex-col">
        <SettingsCategory
          icon={<Users fill={theme.label.primary} className="h-full w-8 p-0" />}
          title="Personlige opplysninger"
          description="Se og endre dine personlige opplysninger"
          navigate="personlige_opplysninger"
        />
        {organization && (
          <SettingsCategory
            icon={<Sliders fill={theme.label.primary} className="w-8" />}
            title="Preferanser"
            description="Endre innstillinger for varsler og e-poster eller forlat en organisasjon"
            navigate="preferanser"
          />
        )}
        <SettingsCategory
          icon={<Link fill={theme.label.primary} className="w-8" />}
          title="Personvernserklæring"
          description="Les om hvordan vi behandler dine personlige opplysninger"
          onClick={() => window.open('https://www.utleggsappen.no/personvern', '_blank', 'noopener,noreferrer')}
        />
        <SettingsCategory
          icon={<Warning fill={theme.label.primary} className="w-8" />}
          title="Slett konto"
          description="Dette kan ikke angres, og vil slette all data tilknyttet kontoen din"
          onClick={() =>
            alert.error({
              title: 'Slett konto?',
              description: 'Er du sikker på at du vil slette kontoen din? Dette kan ikke angres.',
              actionLabel: 'Slett konto',
              onAction: () => {
                userService._delete(user.id);
                toast.success({ description: 'Konto slettet' });
                shutdown();
                authService.logout();
              },
            })
          }
        />
        {account && (
          <SettingsCategory
            icon={<Slack className="w-8" />}
            arrowIcon={
              (account as any).slackInstallation && (
                <Check fill={theme.label.primary} className="w-6 cursor-pointer transition-all" />
              )
            }
            title={(account as any).slackInstallation ? 'Slack koblet til' : 'Koble til Slack'}
            description={
              (account as any).slackInstallation
                ? 'Du får varsel om nye utlegg til godkjenning direkte i Slack.'
                : 'Få varsel om nye utlegg til godkjenning direkte i Slack.'
            }
            onClick={async () => {
              if ((account as any).slackInstallation) {
                account &&
                  alert.warning({
                    title: 'Koble fra Slack?',
                    description:
                      'Er du sikker på at du vil koble fra Slack? Ønsker du å få varsler fra andre organisasjoner kan dette gjøres under profil i den aktuelle organisasjonen.',
                    actionLabel: 'Koble fra',
                    onAction: async () => {
                      await api.slack
                        .uninstall(account?.id)
                        .then(() => toast.success({ description: 'Slack koblet fra' }))
                        .catch((error) => {
                          toast.error({ description: 'Det oppsto en feil' });
                        });
                      setUser();
                    },
                  });
              } else {
                const installUrl =
                  account && (await api.slack.getInstallUrl(account?.id, { redirect: window.location.href }));
                if (installUrl) {
                  window.location.href = await installUrl.text();
                }
              }
            }}
          />
        )}
        <SettingsCategory
          icon={<SignOut fill={theme.label.primary} className="w-8" />}
          title="Logg ut"
          onClick={() => {
            shutdown();
            authService.logout();
          }}
        />
      </div>
    </div>
  );
}

export function ProfileSettings() {
  return (
    <Routes>
      <Route index element={<Settings />} />
      <Route path="personlige_opplysninger">
        <Route index element={<UserSettings />} />
        <Route path="passord" element={<NewPassword />} />
      </Route>
      <Route path="preferanser" element={<PreferencesSettings />} />
    </Routes>
  );
}
