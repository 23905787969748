import { LoadingIndicator } from 'components/layout/LoadingIndicator';
import { toast } from 'components/toast/Toast';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { api } from 'utils/fetch-wrapper';
import { useWizardContext } from '../WizardContext';

export function CreateOrganization() {
  const { nextStep } = useWizard();

  const [stateValue, setState] = useWizardContext();
  const loadingTexts = [
    'Oppretter organisasjon',
    'Snart ferdig',
    'Lager plass til nye utlegg',
    'Henter kaffe',
    'Skjekker at alt er på plass',
  ];

  const [activeLoadingText, setActiveLoadingText] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const randIndex = Math.floor(Math.random() * loadingTexts.length);
      setActiveLoadingText(randIndex);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const unit = stateValue.validatedUnit || stateValue.unit;
    api
      .post('/organizations', {
        legalName: unit.navn,
        displayName: unit.displayName,
        orgNumber: unit.organisasjonsnummer,
        address: unit.forretningsadresse.adresse[0],
        postalCode: unit.forretningsadresse.postnummer,
        region: unit.forretningsadresse.poststed,
      })
      .then((res) => {
        setState({ ...stateValue, organization: res });
        nextStep();
      })
      .catch((error) => {
        toast.error({ description: 'Det oppsto en feil, ta kontakt med support.' });
      });
  }, []);

  return (
    <div className="space-y-6">
      <LoadingIndicator size="lrg" backgroundStyle="accentstrong" />
      <AnimatePresence mode="wait">
        {loadingTexts.map((text, index) => {
          return (
            activeLoadingText === index && (
              <motion.p
                key={activeLoadingText === index ? index : 'NA'}
                className="smalltext text-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                {loadingTexts[index]}
              </motion.p>
            )
          );
        })}
      </AnimatePresence>
    </div>
  );
}
