import BackButton from 'components/buttons/BackButton';
import { useTheme } from 'context/theme';
import { ReactNode } from 'react';
import { cn } from 'utils/className';

export function SignupCard({
  children,
  backButtonOnClick,
  backButtonHidden,
  imageComponent,
}: {
  children: ReactNode;
  imageComponent?: ReactNode;
  backButtonOnClick: any;
  split?: boolean;
  backButtonHidden?: boolean;
}) {
  const theme = useTheme();

  return (
    <div
      className="relative flex h-full w-full flex-col space-y-12 rounded-xlarge px-6 py-12 lg:h-fit lg:w-fit lg:px-12"
      style={{ backgroundColor: theme.background.primary }}>
      {!backButtonHidden && (
        <div className="w-full">
          <BackButton onClick={backButtonOnClick} />
        </div>
      )}
      <div className={cn('centered h-full w-full')}>
        <div className="flex w-full flex-col items-center justify-center space-y-8">
          {imageComponent}
          {children}
        </div>
      </div>
    </div>
  );
}
