import { TravelIcon } from 'assets/svgcomponents/TravelIcon';
import { SmallButton } from 'components/buttons/SmallButton';
import { useTheme } from 'context/theme';

export function Welcome() {
  const theme = useTheme();

  return (
    <div className="centered h-screen p-2">
      <div className="flex w-full flex-col space-y-5">
        <p
          className="normalheading pt-16 text-center"
          style={{
            color: theme.label.primary,
          }}>
          Velkommen til Utleggsappen
        </p>
        <p
          className="bodysmall flex-grow px-10 pb-5 text-center"
          style={{
            color: theme.label.primary,
          }}>
          Din bedrift er nå satt opp og er klar for bruk.{' '}
          <span className="lg:hidden">
            For å legge til avdelinger, endre fakturamottak eller godkjenne utlegg må du logge på dashbordet fra en pc.
          </span>
        </p>
        <TravelIcon className="h-72 place-self-center" />
        <div className="place-self-center lg:hidden">
          <SmallButton
            onClick={() => {
              window.location.href = 'https://www.utleggsappen.no/last-ned';
            }}
            defaultStyle="indigo"
            backgroundColor="light"
            className="place-self-center">
            Gå til utleggsappen
          </SmallButton>
        </div>
        <div className="place-self-center max-lg:hidden">
          <SmallButton
            onClick={() => {
              window.location.href = `./`;
            }}
            defaultStyle="indigo"
            backgroundColor="light"
            className="place-self-center">
            Gå til dashbord
          </SmallButton>
        </div>
      </div>
    </div>
  );
}
