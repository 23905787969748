import { useOrganization } from 'api/organizations/useOrganization';
import { createContext, useContext } from 'react';

export const OrgContext = createContext<{
  organization: NonNullable<ReturnType<typeof useOrganization>['data']>;
  setOrganization: ReturnType<typeof useOrganization>['mutate'];
}>(null!);

export function useStoredOrg() {
  const context = useContext(OrgContext);
  return context;
}
