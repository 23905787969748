export const vechicleTypes = [
  { id: '1', title: 'Bensin', bilSize: '1', litenBilType: '1', storBilType: '0' },
  { id: '2', title: 'Diesel', bilSize: '1', litenBilType: '2', storBilType: '0' },
  { id: '3', title: 'Elbil', bilSize: '1', litenBilType: '3', storBilType: '0' },
  { id: '4', title: 'Ladbar hybrid', bilSize: '1', litenBilType: '4', storBilType: '0' },
  { id: '5', title: 'Hydrogen', bilSize: '1', litenBilType: '5', storBilType: '0' },
  { id: '6', title: 'Motorsykkel', bilSize: '3', litenBilType: '0', storBilType: '0' },
  { id: '7', title: 'Lastebil: EuroVI', bilSize: '2', litenBilType: '0', storBilType: '1' },
  { id: '8', title: 'Lastebil: EuroV', bilSize: '2', litenBilType: '0', storBilType: '2' },
  { id: '9', title: 'Lastebil: Elektrisk', bilSize: '2', litenBilType: '0', storBilType: '3' },
  { id: '10', title: 'Lastebil: Ladbar hybrid', bilSize: '2', litenBilType: '0', storBilType: '4' },
];

export const accommodationTypes = [
  {
    id: 'HOTEL',
    title: 'Hotell',
  },
  {
    id: 'COOKING_AVAILABLE',
    title: 'Med kokemuligheter',
  },
  {
    id: 'COOKING_UNAVAILABLE',
    title: 'Uten kokemuligheter',
  },
  {
    id: 'NONE',
    title: 'Ikke aktuelt',
  },
];

export const rateLevels = [
  {
    id: 'TAXABLE',
    title: 'Statens satser',
  },
  {
    id: 'TAX_FREE',
    title: 'Trekkfrie satser',
  },
  {
    id: 'NONE',
    title: 'Ingen',
  },
];
