import * as Tabs from '@radix-ui/react-tabs';
import { useDepartment } from 'api/departments/useDepartment';
import BackButton from 'components/buttons/BackButton';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import { TabListItem } from 'components/tabs/TabListItem';
import { useTheme } from 'context/theme';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DepartmentUsers from './DepartmentManagers';
import DepartmentSettings from './DepartmentSettings';
import Projects from './Projects';

export default function EditDepartment() {
  const { hash } = useLocation();
  const { departmentId } = useParams();
  const { data: department } = useDepartment(departmentId!);

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hash) {
      navigate('#godkjennere', { replace: true });
    }
  }, [hash, navigate]);

  if (!department) {
    return <FullscreenLoading />;
  }

  return (
    <>
      <BackButton />
      <div className="flex flex-row justify-between">
        <h1 className="bigheading" style={{ color: theme.label.primary }}>
          {department?.title}
        </h1>
      </div>
      <Tabs.Root
        className="TabsRoot flex flex-1 flex-col gap-y-5"
        defaultValue="godkjennere"
        value={hash ? hash.slice(1) : 'godkjennere'}
        onValueChange={(value) => navigate(`#${value}`, { replace: true })}>
        <Tabs.List
          className="TabsList smalltext flex gap-x-2 rounded-large p-4"
          style={{ color: theme.label.primary, backgroundColor: theme.background.accentemphasis }}
          aria-label="Manage department">
          {/* <TabListItem title="Brukere" value="brukere" /> */}
          <TabListItem title="Godkjennere" value="godkjennere" active={hash.slice(1) === 'godkjennere'} />
          <TabListItem title="Prosjekt" value="prosjekt" active={hash.slice(1) === 'prosjekt'} />
          <TabListItem title="Innstillinger" value="innstillinger" active={hash.slice(1) === 'innstillinger'} />
        </Tabs.List>
        {/* <Tabs.Content className="TabsContent flex-1" value="brukere">
          BRUKERE
        </Tabs.Content> */}
        <Tabs.Content className="TabsContent flex-1" value="godkjennere">
          <div className="flex h-full w-full flex-col">
            <DepartmentUsers />
          </div>
        </Tabs.Content>
        <Tabs.Content className="TabsContent flex-1" value="prosjekt">
          <div className="flex h-full w-full flex-col">
            <Projects />
          </div>
        </Tabs.Content>
        <Tabs.Content className="TabsContent flex-1" value="innstillinger">
          <div className="flex h-full w-full flex-col">
            <DepartmentSettings />
          </div>
        </Tabs.Content>
      </Tabs.Root>
    </>
  );
}
