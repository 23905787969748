import { Chip } from 'components/chips/Chip';
import { useTheme } from 'context/theme';

interface ListSmallProps {
  /**
   * The title of the list.
   */
  title: string;
  /**
   * The content of the list. Should be 'ListSmall.Item' components.
   */
  children: React.ReactNode;
}

export function ListSmall({ children, title }: ListSmallProps) {
  const theme = useTheme();
  return (
    <div className="flex flex-1 flex-col">
      <p className="smalltextbold rounded-small p-4" style={{ backgroundColor: theme.background.accent }}>
        {title}
      </p>
      <ul
        className="divide-y divide-[##D7CFF0] overflow-y-auto px-6"
        style={{ scrollbarWidth: 'thin', scrollbarColor: '#100C3A80 transparent' }}>
        {children}
      </ul>
    </div>
  );
}

interface ItemSmallProps {
  /**
   * The title of the item.
   */
  title: string;
  /**
   * The value of the item.
   */
  value: string;
  /**
   * Text to display as a badge.
   */
  badge?: string;
  /**
   * Enable hover effect.
   */
  enableHover?: boolean;
}

export function ItemSmall({ title, value, badge, enableHover }: ItemSmallProps) {
  return (
    <li className={`-mx-6 flex justify-between rounded-small px-6 py-3 ${enableHover && 'hover:bg-v2-purple-200'}`}>
      <div>
        <p className="smalltextbold">{title}</p>
        <p className="smalltext">{value}</p>
      </div>
      {badge && <Chip size="large" type="accent" value={badge} />}
    </li>
  );
}

ListSmall.Item = ItemSmall;
