import { SubscriptionStatusTypeEnum } from '__generated__/UtleggsappenApi';
import { api } from 'api/api';
import { useOrganization } from 'api/organizations/useOrganization';
import { useCustomer } from 'api/subscriptions/useCustomer';
import { useSubscription } from 'api/subscriptions/useSubscription';
import { PaymentCardLogo } from 'components/PaymentCardLogo';
import BackButton from 'components/buttons/BackButton';
import { LargeButton } from 'components/buttons/LargeButton';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import { useTheme } from 'context/theme';
import { useNavigate } from 'react-router-dom';
import { InvoicesList } from './InvoicesList';

export default function Subscription() {
  const theme = useTheme();
  const { data, isLoading: isLoadingOrganization } = useOrganization();
  const { data: subscription, isLoading: isLoadingSubscription } = useSubscription();
  const { data: customer, isLoading: isLoadingCustomer } = useCustomer();

  const navigate = useNavigate();

  if (isLoadingOrganization || isLoadingSubscription || isLoadingCustomer) {
    return <FullscreenLoading />;
  }

  const handleCustomerPortal = async () => {
    await api.subscriptions.getStripeCustomerPortal(data!.id).then((res) => {
      window.location.href = res.data.url;
    });
  };

  console.log(subscription);

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex flex-col gap-y-2.5">
        <div className="flex flex-col justify-between gap-y-5 pt-5">
          <BackButton />
          <h1 className="bigheading" style={{ color: theme.label.primary }}>
            Abonnement
          </h1>
        </div>
        {subscription?.subscriptionTier && (
          <div className="flex space-x-6">
            <p className="smalltext">
              Lisensierte brukere: {data?.usage!.usersCount} /{' '}
              {subscription?.subscriptionTier?.usersLimit! > 10000 ? '∞' : subscription?.subscriptionTier?.usersLimit}
            </p>
            <p className="smalltext">
              Utlegg: {data?.usage!.expensesCount! + data?.usage!.drivesCount! + data?.usage!.travelsCount!} /{' '}
              {subscription?.subscriptionTier?.totalExpensesLimit! > 10000
                ? '∞'
                : `${subscription?.subscriptionTier?.totalExpensesLimit?.toLocaleString('no-NO')} stk`}
            </p>
          </div>
        )}
      </div>
      <div
        className="flex w-fit min-w-[435px] flex-col justify-center gap-4 rounded-large px-4 py-6"
        style={{ backgroundColor: theme.background.primary }}>
        <p className="normalheading flex flex-col py-[5px] text-center" style={{ color: theme.label.primary }}>
          {subscription?.subscriptionTier?.name}
          <span className="caption">
            {subscription?.subscriptionStatus === SubscriptionStatusTypeEnum.Trialing && 'Prøveperiode'}
          </span>
        </p>
        <p className="smalltext text-center">
          {(subscription as any)?.price?.amount} kr{' '}
          {(subscription as any)?.price?.type === 'PER_USER' ? '/ bruker ' : ''}/{' '}
          {subscription?.price?.recurringInterval === 'MONTH' ? 'mnd' : 'år'}
        </p>
        <p className="smalltext text-center">
          {subscription?.subscriptionTier?.totalExpensesLimit! > 10000
            ? 'Ubegrenset antall'
            : subscription?.subscriptionTier?.totalExpensesLimit?.toLocaleString('no-NO')}{' '}
          utlegg
        </p>
        <div className="my-2.5 h-px" style={{ backgroundColor: theme.background.accentstrong }} />
        {subscription?.billingPeriodEnd && (
          <p className="smalltext text-center">
            {subscription?.cancelAtPeriodEnd ? 'Avsluttes' : 'Fornyes'}{' '}
            {new Date(subscription?.billingPeriodEnd!).toLocaleDateString('no-NO', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </p>
        )}
        {subscription?.subscriptionTier.name !== 'Enterprise' && (
          <LargeButton
            className="ml-auto mr-auto"
            defaultStyle="critical"
            backgroundColor="light"
            onClick={() => navigate('endre')}>
            Endre abonnement
          </LargeButton>
        )}
        {!subscription?.subscriptionTier?.dashboard &&
          !subscription?.cancelAtPeriodEnd &&
          subscription?.stripeCustomer && (
            <>
              <div className="my-2.5 h-px" style={{ backgroundColor: theme.background.accentstrong }} />
              {subscription?.stripeCustomer.invoice_settings?.default_payment_method?.card?.brand && (
                <>
                  <div className="centered">
                    <PaymentCardLogo
                      type={subscription.stripeCustomer.invoice_settings.default_payment_method.card.brand as any}
                    />
                  </div>
                  <p className="smalltext text-center">
                    Kort som slutter på{' '}
                    {subscription?.stripeCustomer.invoice_settings?.default_payment_method.card.last4}
                  </p>
                </>
              )}

              <LargeButton
                className="ml-auto mr-auto"
                defaultStyle="indigo"
                backgroundColor="light"
                onClick={handleCustomerPortal}>
                {subscription?.stripeCustomer?.invoice_settings?.default_payment_method ? 'Endre' : 'Legg til'}{' '}
                betalingsmetode
              </LargeButton>
            </>
          )}
      </div>
      {subscription?.stripeInvoices && <InvoicesList />}
    </div>
  );
}
