import * as Dialog from '@radix-ui/react-dialog';
import { Close } from 'assets/svgcomponents/Close';
import { IconButton } from 'components/buttons/IconButton';

/**
 * Should only be used inside a Modal component.
 */
export function ModalCloseButton() {
  return (
    <Dialog.Close asChild>
      <IconButton icon={<Close />} />
    </Dialog.Close>
  );
}
