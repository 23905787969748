export const config = {
  apiUrl: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'http://localhost:4000',
  vehicles: {
    0: 'Bensin',
    1: 'Diesel',
    2: 'Elbil',
    3: 'Ladbar hybrid',
    4: 'Hydrogen',
  },
};
