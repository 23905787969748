type FormatCurrencyFunction = ({ amount, code }: { amount: number; code: string }) => string;

export const currencyMapping: {
  [key: string]: { symbol: string; locale: string; position: 'before' | 'after' };
} = {
  AUD: { symbol: '$', locale: 'en-AU', position: 'before' },
  BDT: { symbol: '৳', locale: 'bn-BD', position: 'before' },
  BGN: { symbol: 'лв', locale: 'bg-BG', position: 'after' },
  BRL: { symbol: 'R$', locale: 'pt-BR', position: 'before' },
  BYN: { symbol: 'RBL', locale: 'be-BY', position: 'before' },
  CAD: { symbol: '$', locale: 'en-CA', position: 'before' },
  CHF: { symbol: 'CHF', locale: 'de-CH', position: 'before' },
  CNY: { symbol: '¥', locale: 'zh-CN', position: 'before' },
  CZK: { symbol: 'Kč', locale: 'cs-CZ', position: 'after' },
  DKK: { symbol: 'kr', locale: 'da-DK', position: 'after' },
  EUR: { symbol: '€', locale: 'en-EU', position: 'before' },
  GBP: { symbol: '£', locale: 'en-GB', position: 'before' },
  HKD: { symbol: 'HK$', locale: 'en-HK', position: 'before' },
  HUF: { symbol: 'FT', locale: 'hu-HU', position: 'after' },
  IDR: { symbol: 'RP', locale: 'id-ID', position: 'before' },
  ILS: { symbol: '₪', locale: 'he-IL', position: 'before' },
  INR: { symbol: '₹', locale: 'hi-IN', position: 'before' },
  ISK: { symbol: 'kr', locale: 'is-IS', position: 'after' },
  JPY: { symbol: '¥', locale: 'ja-JP', position: 'before' },
  KRW: { symbol: '₩', locale: 'ko-KR', position: 'before' },
  MMK: { symbol: 'Ks.', locale: 'my-MM', position: 'before' },
  MXN: { symbol: '$', locale: 'es-MX', position: 'before' },
  MYR: { symbol: 'RM', locale: 'ms-MY', position: 'before' },
  NOK: { symbol: 'kr', locale: 'nb-NO', position: 'after' },
  NZD: { symbol: '$', locale: 'en-NZ', position: 'before' },
  PHP: { symbol: '₱', locale: 'en-PH', position: 'before' },
  PKR: { symbol: '₨.', locale: 'ur-PK', position: 'before' },
  PLN: { symbol: 'zł', locale: 'pl-PL', position: 'after' },
  RON: { symbol: 'lei', locale: 'ro-RO', position: 'after' },
  RUB: { symbol: '₽', locale: 'ru-RU', position: 'after' },
  SEK: { symbol: 'kr', locale: 'sv-SE', position: 'after' },
  SGD: { symbol: '$', locale: 'en-SG', position: 'before' },
  THB: { symbol: '฿', locale: 'th-TH', position: 'before' },
  TRY: { symbol: '₺', locale: 'tr-TR', position: 'before' },
  TWD: { symbol: 'NT$', locale: 'zh-TW', position: 'before' },
  USD: { symbol: '$', locale: 'en-US', position: 'before' },
  VND: { symbol: '₫', locale: 'vi-VN', position: 'after' },
  ZAR: { symbol: 'R', locale: 'en-ZA', position: 'before' },
};

export const formatCurrency: FormatCurrencyFunction = ({ amount, code }) => {
  const currency = currencyMapping[code];
  if (!currency) {
    throw new Error(`Unsupported currency code: ${code}`);
  }

  const { symbol, locale, position } = currency;
  const formattedAmount = amount.toLocaleString(locale, { maximumFractionDigits: 2 }).replace(/\s/g, ' ');
  return position === 'before' ? `${symbol} ${formattedAmount}` : `${formattedAmount} ${symbol}`;
};
