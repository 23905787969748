import { fetcher } from 'api';
import useSWR from 'swr';
import { Account, Department, User } from '__generated__/UtleggsappenApi';

export function useDepartment(departmentId: string) {
  const { data, error, mutate, isLoading } = useSWR<Department & { accounts: (Account & { user: User })[] }>(
    `/departments/${departmentId}`,
    fetcher,
  );

  return {
    data,
    isLoading,
    error,
    mutate,
  };
}
