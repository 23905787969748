import { Subscription } from '__generated__/UtleggsappenApi';
import { fetcher } from 'api';
import { useOrganization } from 'api/organizations/useOrganization';
import useSWR from 'swr';

export function useSubscription() {
  const { data: organization } = useOrganization();

  const { data, error, mutate, isLoading } = useSWR<Subscription>(
    organization ? `/subscriptions/${organization.id}` : null,
    fetcher,
  );

  return {
    data,
    isLoading,
    error,
    mutate,
  };
}
