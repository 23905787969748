import { api } from 'utils/fetch-wrapper';
import { getRefreshToken, removeAccessToken, removeRefreshToken } from 'utils/token-storage';

const apiUrl = '/auth';

export const authService = {
  login,
  logout,
  refreshToken,
  forgotPassword,
  resetPassword,
  validateResetToken,
  update,
};

function login(username: string, password: string) {
  return api.post(`${apiUrl}/login`, { username, password });
}

function logout() {
  return api
    .post(`${apiUrl}/logout`, {
      refreshToken: getRefreshToken(),
    })
    .finally(() => {
      removeRefreshToken();
      removeAccessToken();
      window.location.reload();
    });
}

function refreshToken() {
  const refreshToken = getRefreshToken();
  if (refreshToken) {
    return api.post(`${apiUrl}/refresh`, { refreshToken });
  } else {
    throw new Error('No refresh token');
  }
}

function forgotPassword(email: string) {
  return api.post(`${apiUrl}/forgot-password`, { email });
}

function resetPassword(password: string, token: string) {
  return api.post(`${apiUrl}/reset-password`, { token, password });
}

function validateResetToken(token: string) {
  return api.post(`${apiUrl}/validate-reset-token`, { token });
}

function getById(id: string) {
  return api.get(`${apiUrl}/${id}`);
}

function update(id: string, params: string) {
  return api.patch(`${apiUrl}/${id}`, params);
}

function _delete(id: string) {
  return api.delete(`${apiUrl}/${id}`);
}
