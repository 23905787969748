import { useTheme } from 'context/theme';
import { cn } from 'utils/className';

export function Chip({
  type,
  value,
  size = 'small',
}: {
  type: 'accent' | 'info' | 'success' | 'warning' | 'critical' | 'overlay';
  value: string | boolean;
  size?: 'small' | 'large';
}) {
  const theme = useTheme();

  return (
    <div
      className={cn(
        size === 'small' ? 'captionbold h-fit rounded-full px-2 py-1' : 'smalltext h-fit rounded-small px-3 py-[6px]',
        'border-[1px]',
      )}
      style={{
        backgroundColor: size === 'small' || type === 'accent' ? theme.base[type] : theme.background[type],
        color: type === 'critical' || type === 'accent' ? theme.label.onaccent : theme.label.onlight,
        borderColor: type === 'overlay' ? undefined : theme.border[type],
        borderWidth: type === 'overlay' ? undefined : 1,
      }}>
      {value}
    </div>
  );
}
