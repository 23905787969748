import { useUser } from 'api/users/useUser';
import { createContext, useContext } from 'react';

export const UserContext = createContext<{
  user: NonNullable<ReturnType<typeof useUser>['data']>;
  setUser: ReturnType<typeof useUser>['mutate'];
}>(null!);

export function useStoredUser() {
  const context = useContext(UserContext);
  return context;
}
