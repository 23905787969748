import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { cn } from 'utils/className';

interface AnimateChangeInDimensionProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;

  /**
   * Whether or not to only animate the height of the element
   */
  verticalOnly?: boolean;

  /**
   * Whether or not to only animate the width of the element
   */
  horizontalOnly?: boolean;
}

/**
 * Animate the change in dimension of a component. When the children of this component changes size, the component will animate to the new size.
 */
export const AnimateChangeInDimension: React.FC<AnimateChangeInDimensionProps> = ({
  children,
  className,
  style,
  verticalOnly,
  horizontalOnly,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number | 'auto'>('auto');
  const [width, setWidth] = useState<number | 'auto'>('auto');

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        // We only have one entry, so we can use entries[0].
        const observedHeight = entries[0].contentRect.height;
        const observedWidth = entries[0].contentBoxSize[0].inlineSize;

        if (!horizontalOnly) {
          setHeight(observedHeight);
        }

        if (!verticalOnly) {
          setWidth(observedWidth);
        }
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <motion.div
      style={{ ...style, height, width }}
      animate={{ height, width }}
      className="overflow-visible"
      transition={{ duration: 0.2, ease: 'easeInOut' }}>
      <div ref={containerRef} className={cn('w-fit', className)}>
        {children}
      </div>
    </motion.div>
  );
};
