import { createContext, useContext } from 'react';

export const AccessContext = createContext<{
  userRole: number | null;
  accountRole: number | null;
  combinedRole: number | null;
}>({ userRole: null, accountRole: null, combinedRole: null });

export function useAccess() {
  const context = useContext(AccessContext);
  return context;
}
