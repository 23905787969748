import { SubscriptionTier } from '__generated__/UtleggsappenApi';
import { fetcher } from 'api';
import useSWR from 'swr';

export function useSubscriptionTiers() {
  const { data, error, mutate, isLoading } = useSWR<SubscriptionTier[]>('/subscriptiontiers', fetcher);

  return {
    data,
    isLoading,
    error,
    mutate,
  };
}
