import React from 'react';
import { cn } from 'utils/className';

interface Props {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const Widget = ({ children }: { children: React.ReactNode }) => {
  const subComponentList = Object.keys(Widget);
  const subComponents = subComponentList.map((key) => {
    return React.Children.map(children, (child: any) => (child.type.name === key ? child : null));
  });

  return <>{subComponents.map((component) => component)}</>;
};

const Grid = ({ children, className }: Props) => {
  return <div className={cn('grid grid-cols-4 gap-10', className)}>{children}</div>;
};

const Large = ({ children, className, onClick }: Props) => {
  return (
    <div className={cn('relative col-span-4 w-full rounded-xl bg-white p-6', className)} onClick={onClick}>
      {children}
    </div>
  );
};

const Medium = ({ children, className, onClick }: Props) => {
  return (
    <div
      className={cn('relative col-span-4 w-full rounded-xl bg-white p-7 lg:col-span-2', className)}
      onClick={onClick}>
      {children}
    </div>
  );
};

const Small = ({ children, className, onClick }: Props) => {
  return (
    <div
      className={cn('relative col-span-4 w-full rounded-xl bg-white p-6 sm:col-span-2 lg:col-span-1', className)}
      onClick={onClick}>
      {children}
    </div>
  );
};

Widget.Grid = Grid;
Widget.Large = Large;
Widget.Medium = Medium;
Widget.Small = Small;

export default Widget;
