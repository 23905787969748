import { useTheme } from 'context/theme';

export function SignupLayout({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  return (
    <div
      className="relative flex h-screen items-center justify-center overflow-x-hidden"
      style={{ backgroundColor: theme.background.accent }}>
      {children}
    </div>
  );
}
