import { cn } from 'utils/className';
import { BaseButton, ButtonProps } from './BaseButton';

export function SmallButton(props: ButtonProps) {
  const smallButtonClassName = `buttonsmalltext rounded-xxlarge py-[10px] w-fit ${props.children ? 'px-[18px]' : 'px-[10px]'}`;
  return (
    <BaseButton {...props} className={cn(smallButtonClassName, props.className)}>
      {props.children}
    </BaseButton>
  );
}
