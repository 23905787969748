import { Api } from '__generated__/UtleggsappenApi';
import { fetcher } from 'api';
import useSWR from 'swr';

export function useAttatchments(expenseId: string, enabled: boolean) {
  const { data, error, mutate, isLoading } = useSWR<
    Awaited<ReturnType<typeof Api.prototype.expenses.findAllAttachmentsByExpenseId>>['data']
  >(enabled ? `/expenses/${expenseId}/attachments` : null, fetcher);

  return {
    data,
    isLoading,
    error,
    mutate,
  };
}
