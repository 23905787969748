export function Check({ className, fill = '#100C3A' }: { className?: string; fill?: string }) {
  return (
    <svg className={className} viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3538 1.35403L4.35375 9.35403C4.30732 9.40052 4.25217 9.4374 4.19147 9.46256C4.13077 9.48772 4.06571 9.50067 4 9.50067C3.9343 9.50067 3.86923 9.48772 3.80853 9.46256C3.74783 9.4374 3.69269 9.40052 3.64625 9.35403L0.146253 5.85403C0.052433 5.76021 -0.000274658 5.63296 -0.000274658 5.50028C-0.000274658 5.3676 0.052433 5.24035 0.146253 5.14653C0.240074 5.05271 0.367321 5 0.500003 5C0.632685 5 0.759933 5.05271 0.853753 5.14653L4 8.2934L11.6463 0.646528C11.7401 0.552707 11.8673 0.5 12 0.5C12.1327 0.5 12.2599 0.552707 12.3538 0.646528C12.4476 0.740348 12.5003 0.867596 12.5003 1.00028C12.5003 1.13296 12.4476 1.26021 12.3538 1.35403Z"
        fill={fill}
      />
    </svg>
  );
}
