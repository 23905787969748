import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Report } from '__generated__/UtleggsappenApi';
import { useSubscription } from 'api/subscriptions/useSubscription';
import { Arrow } from 'assets/svgcomponents/Arrow';
import { TextButton } from 'components/buttons/TextButton';
import { Chip } from 'components/chips/Chip';
import Table from 'components/table/Table';
import { DefaultCell, TagWrapper } from 'components/table/TableComponents';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function InvoicesList() {
  const { data: subscription, isLoading: isLoadingSubscription } = useSubscription();

  const [invoices, setInvoices] = useState(subscription?.stripeInvoices.invoices);

  const navigate = useNavigate();

  useEffect(() => {
    if (subscription?.stripeInvoices?.invoices.length === 0) {
      setInvoices([]);
    }
    if (subscription?.stripeInvoices.invoices && subscription.stripeInvoices.upcoming) {
      subscription.stripeInvoices.upcoming.hosted_invoice_url = 'neste';
      setInvoices([
        subscription.stripeInvoices.upcoming as any,
        ...(subscription.stripeInvoices.invoices && subscription.stripeInvoices.invoices),
      ]);
    }
  }, [subscription]);

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: 'created',
        cell: (value: CellContext<Report, string>) => (
          <DefaultCell className="smalltextbold">
            {new Date(Math.round(parseInt(value.getValue()) * 1000)).toLocaleDateString('no-NO', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })}
          </DefaultCell>
        ),
        header: () => <></>,
        size: 50,
      },
      {
        accessorKey: 'amount_due',
        cell: (value: CellContext<Report, string>) => (
          <DefaultCell>
            {((value.getValue() as unknown as number) / 100).toLocaleString('no-NO', { minimumFractionDigits: 2 })} kr
          </DefaultCell>
        ),
        header: () => <></>,
        size: 50,
      },
      {
        accessorKey: 'paid',
        cell: (value: CellContext<Report, string>) => (
          <div>
            {(value.getValue() as unknown as boolean) === true ? (
              <Chip value="Betalt" type="success" size="large" />
            ) : (
              <Chip value="Planlagt" type="warning" size="large" />
            )}
          </div>
        ),
        header: () => <></>,
        size: 50,
      },
      {
        accessorKey: 'hosted_invoice_url',
        header: () => null,
        cell: (value: CellContext<Report, string>) => (
          <TagWrapper className="flex w-full justify-end">
            <TextButton
              icon={<Arrow className="w-[19px]" />}
              iconPlacement="left"
              backgroundColor="light"
              onClick={() => {
                if (value.getValue() === 'neste') {
                  navigate('neste');
                } else {
                  window.open(value.getValue(), '_blank');
                }
              }}>
              {value.getValue() === 'neste' ? 'Se forhåndsvisning' : 'Se faktura'}
            </TextButton>
          </TagWrapper>
        ),
      },
    ],
    [],
  );

  return (
    <>
      {!isLoadingSubscription && invoices && invoices.length > 0 && (
        <div className="flex w-full flex-1 flex-col">
          <Table
            data={invoices}
            columns={columns}
            gridCols="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 68px"
            numberOfItems={5}
          />
        </div>
      )}
    </>
  );
}
