import { Api } from '__generated__/UtleggsappenApi';
import { fetcher } from 'api';
import { useOrganization } from 'api/organizations/useOrganization';
import useSWR from 'swr';

export function useCategories() {
  const { data: organization } = useOrganization();

  const { data, error, mutate, isLoading } = useSWR<
    Awaited<ReturnType<typeof Api.prototype.categories.findAll>>['data']
  >(organization ? '/categories?' + new URLSearchParams({ organizationId: organization.id }) : null, fetcher);

  return {
    data,
    error,
    mutate,
    isLoading,
  };
}
