/* eslint-disable indent */
import { useReport } from 'api/reports/useReport';
import { useTheme } from 'context/theme';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import { useEffect, useState } from 'react';
import { Overview } from './tabs/Overview';
import { Expense } from './tabs/Expense';
import { reportService } from 'services/reportService';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import { toast } from 'components/toast/Toast';
import { TextButton } from 'components/buttons/TextButton';
import { Arrow } from 'assets/svgcomponents/Arrow';
import { TabListItem } from 'components/tabs/TabListItem';
import { formatAccountNumber, formatAmount, formatPhoneNumber } from 'utils/format';
import { alert } from 'components/alerts/Alert';
import { LargeButton } from 'components/buttons/LargeButton';

export function ApproveExpense() {
  const { reportId } = useParams();
  const { data, isLoading, mutate } = useReport(reportId as string);
  const theme = useTheme();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const [activeTab, setActiveTab] = useState('oversikt');
  const [isSingleDrive, setIsSingleDrive] = useState(false);
  const [isSingleExpense, setIsSingleExpense] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  useEffect(() => {
    if (isLoading || !data) {
      return;
    }

    const singleDrive = data?.expenses!.some((expense) => expense.type === 'DRIVE') && data?.expenses!.length === 1;
    setIsSingleDrive(singleDrive);

    const singleExpense = data?.type === 'EXPENSE' && data?.expenses!.length === 1;
    setIsSingleExpense(singleExpense);

    if (hash === '') {
      navigate(singleDrive || singleExpense ? '#' + data.expenses![0].id : '#oversikt', { replace: true });
    } else {
      setActiveTab(hash.slice(1));
    }
  }, [hash, navigate, data, isLoading, isSingleDrive, isSingleExpense]);

  if (isLoading || !data) {
    return <FullscreenLoading />;
  }

  function mapReportType(type: string) {
    switch (type) {
      case 'TRAVEL':
        return 'Reiseregning';
      case 'EXPENSE':
        return 'Utlegg';
      case 'DRIVE':
        return 'Kjøregodtgjørelse';
      case 'EXPENSEGROUP':
        return 'Utleggsgruppe';
      default:
        return 'Ukjent';
    }
  }

  function handleReject() {
    setIsRejecting(true);
    reportService
      .reject(reportId as string)
      .then(() => {
        setIsRejecting(false);
        navigate('..');
        toast.success({
          description: 'Utlegget ble avslått',
        });
      })
      .catch(() => {
        setIsRejecting(false);
        toast.error({
          description: 'Det oppsto en feil',
        });
      });
  }

  function handleApprove() {
    setIsApproving(true);
    reportService
      .approve(reportId as string)
      .then(() => {
        setIsApproving(false);
        navigate('..');
        toast.success({
          description: 'Utlegget er sendt til regnskap',
        });
      })
      .catch(() => {
        setIsApproving(false);
        toast.error({
          description: 'Det oppsto en feil',
        });
      });
  }

  return (
    <Tabs.Root
      defaultValue="oversikt"
      className="-my-10 flex h-screen max-h-screen w-full flex-col overflow-hidden py-10"
      onValueChange={(value) => navigate(`#${value}`)}
      value={activeTab}>
      <TextButton
        backgroundColor="light"
        className="-mt-4 mb-4"
        onClick={() => navigate('..')}
        icon={<Arrow className="h-[21px]" />}
        iconPlacement="left">
        Tilbake
      </TextButton>
      <div
        className="relative flex items-center justify-between rounded-t-large p-4"
        style={{ backgroundColor: theme.background.accentemphasis }}>
        <div
          className="grid w-full space-x-4 overflow-x-auto"
          style={{
            gridTemplateColumns: `repeat(${data?.expenses!.length! + 1}, minmax(0, 1fr))`,
            scrollbarWidth: 'thin',
            scrollbarColor: '#100C3A80 transparent',
          }}>
          <Tabs.List className="flex max-w-full space-x-4">
            {!isSingleDrive && !isSingleExpense && (
              <TabListItem title={mapReportType(data.type)} value="oversikt" isOverview />
            )}
            {data
              ?.expenses!.filter((expense) => expense.type === 'DIET')
              .map((expense) => {
                return <TabListItem key={expense.id} title={'Diett'} value={expense.id} icon={expense.rejectReason} />;
              })}
            {data
              ?.expenses!.filter((expense) => expense.type === 'DEFAULT')
              .map((expense, index) => {
                return (
                  <TabListItem
                    key={expense.id}
                    title={isSingleExpense ? 'Utlegg' : `Utlegg ${index + 1}`}
                    value={expense.id}
                    active={hash.slice(1) === expense.id}
                    icon={expense.rejectReason}
                  />
                );
              })}
            {data
              ?.expenses!.filter((expense) => expense.type === 'DRIVE')
              .map((expense, index) => {
                return (
                  <TabListItem
                    key={expense.id}
                    title={`Kjøregodtgjørelse ${isSingleDrive ? '' : index + 1}`}
                    value={expense.id}
                    icon={expense.rejectReason}
                  />
                );
              })}
          </Tabs.List>
        </div>
      </div>
      <Overview data={data} />
      {data?.expenses!.map((expense) => {
        return (
          <Expense
            data={expense}
            key={expense.id}
            id={expense.id}
            revalidate={mutate}
            isOverviewTab={activeTab === 'oversikt' || isSingleExpense || isSingleDrive}
          />
        );
      })}
      <div
        className="divide-x-white flex divide-x rounded-b-large"
        style={{ backgroundColor: theme.background.accentemphasis }}>
        <div className="p-6">
          <p className="smalltextbold">
            {data.account?.user!.givenName} {data.account?.user!.familyName}
          </p>
          <p className="smalltext">{formatPhoneNumber(data.account?.user!.phoneNumber!)}</p>
          <p className="smalltext">{data.account?.user!.email}</p>
        </div>
        <div className="p-6">
          <p className="smalltext">Kontonummer</p>
          <p className="smalltextbold">{formatAccountNumber(data.account?.user!.accountNumber!) || 'Ikke oppgitt'}</p>
        </div>
        <div className="flex flex-1 justify-between p-6">
          <div>
            <p className="smalltext">Totalt beløp</p>
            <p className="bigheading">{formatAmount(data?.amount)} kr</p>
          </div>
          <div className="flex items-center space-x-6">
            <LargeButton
              backgroundColor="light"
              defaultStyle="critical"
              onClick={() =>
                alert.error({
                  title: `Avslå ${data?.expenses!.length} utlegg`,
                  actionLabel: 'Avslå',
                  description: !data?.expenses!.some((e) => e.rejectReason !== null && e.rejectReason !== '')
                    ? 'Det er ikke lagt til noen kommentarer. Ønsker du fortsatt å avslå?'
                    : isSingleDrive
                      ? 'Ønsker du å avslå kjøregodtgjørelsen?'
                      : isSingleExpense
                        ? 'Ønsker du å avslå utlegget?'
                        : data.type === 'TRAVEL'
                          ? 'Ønsker du å avslå reiseregningen?'
                          : 'Ønsker du å avslå hele utleggsgruppen?',
                  onAction: handleReject,
                  loading: isRejecting,
                })
              }
              loading={isRejecting}
              disabled={isApproving || isRejecting}>
              Avslå
            </LargeButton>
            <LargeButton
              backgroundColor="light"
              defaultStyle="success"
              onClick={handleApprove}
              loading={isApproving}
              disabled={isApproving || isRejecting}>
              Send til regnskap
            </LargeButton>
          </div>
        </div>
      </div>
    </Tabs.Root>
  );
}
