import { jwtDecode } from 'jwt-decode';
import { authService } from 'services/authService';
import { setAccessToken, setRefreshToken } from 'utils/token-storage';

let refreshTokenTimeout: any;

function startRefreshTokenTimer(accessToken: string, callback: any) {
  const jwtToken: any = jwtDecode(accessToken);

  // set a timeout to refresh the token a minute before it expires
  const expires = new Date(jwtToken.exp * 1000);
  const timeout = expires.getTime() - Date.now() - 60 * 1000;
  refreshTokenTimeout = setTimeout(callback, timeout);
}

export function stopRefreshTokenTimer() {
  clearTimeout(refreshTokenTimeout);
}

export async function refreshTokens() {
  try {
    const { accessToken, refreshToken } = await authService.refreshToken();
    if (accessToken && refreshToken) {
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      startRefreshTokenTimer(accessToken, refreshTokens);
      return accessToken;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}
