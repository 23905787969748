import Input from 'components/input-field/Input';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import BackButton from 'components/buttons/BackButton';
import { useTheme } from 'context/theme';
import { useEffect, useState } from 'react';
import { useStoredUser } from 'context/UserContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { categoryService } from 'services/categoryService';
import { toast } from 'components/toast/Toast';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import { LargeButton } from 'components/buttons/LargeButton';

type FormValues = {
  title: string;
  accountingCode: string;
  description: string;
  isStaff: boolean;
};

export default function EditDietType() {
  const { user } = useStoredUser();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const categoryId = pathname.split('/').pop() ?? '';

  const theme = useTheme();

  const [category, setCategory] = useState<Awaited<ReturnType<typeof categoryService.getById>> | null>(null);

  const [changedValues, setChangedValues] = useState({
    title: true,
    accountingCode: true,
    description: true,
  });

  useEffect(() => {
    (async () => {
      const category = await categoryService.getById(categoryId);
      setCategory(category);
    })();
  }, [categoryId]);

  if (!category || !user)
    return (
      <>
        <FullscreenLoading />
      </>
    );

  function handleAccountingCodeChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: string) => void,
  ) {
    setChangedValues((prev) => ({ ...prev, accountingCode: true }));
    const value = e.currentTarget.value.toString().replace(/ /g, '');
    if (value.length > 4) return;
    if (value.length === 0) setFieldValue('accountingCode', '');
    if (!/^[0-9 ]+$/.test(value)) return;
    setFieldValue('accountingCode', value);
  }

  const handleSubmit = async (values: FormValues) => {
    try {
      await categoryService.update(category.id, {
        title: values.title,
        accountingCode: values.accountingCode.length === 0 ? null : values.accountingCode,
        description: values.description.length === 0 ? null : values.description,
      });
      toast.success({ description: `${category.title} oppdatert` });
      navigate(-1);
    } catch (err) {
      toast.error({ description: (err as string) || 'Noe gikk galt. Kontakt support om problemet vedvarer.' });
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col justify-between gap-y-5 pt-5" style={{ color: theme.label.primary }}>
        <BackButton />
        <h1 className="bigheading">Endre diett</h1>
      </div>
      <Formik
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await handleSubmit(values);
          setSubmitting(false);
        }}
        initialValues={{
          title: category.title,
          accountingCode: category.accountingCode ?? '',
          description: category.description ?? '',
          isStaff: false,
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('Påkrevd'),
          accountingCode: Yup.number()
            .transform((_, value) => {
              return +value?.toString().replace(/ /g, '');
            })
            .positive('Ugyldig')
            .integer('Ugyldig')
            .typeError('Ugyldig')
            .test('len', 'Ugyldig', (val) => val?.toString().length === 4 || val === undefined),
          descrption: Yup.string(),
        })}>
        {({ values, errors, handleChange, isSubmitting, setFieldValue, handleSubmit }) => {
          return (
            <Form className="flex flex-col gap-y-4 pt-10" onSubmit={handleSubmit}>
              <Input.Text
                width="lg"
                label="Navn"
                value={values.title}
                onChange={(e) => {
                  setChangedValues((prev) => ({ ...prev, title: true }));
                  handleChange('title')(e);
                }}
                error={!changedValues.title ? errors.title : undefined}
                disabled
              />
              <Input.Text
                width="md"
                label="Konto"
                value={values.accountingCode}
                onChange={(e) => handleAccountingCodeChange(e, setFieldValue)}
                error={!changedValues.accountingCode ? errors.accountingCode : undefined}
              />
              <Input.Text
                width="xl"
                label="Kommentar"
                value={values.description}
                onChange={(e) => {
                  setChangedValues((prev) => ({ ...prev, description: true }));
                  handleChange('description')(e);
                }}
                error={!changedValues.description ? errors.description : undefined}
              />
              <LargeButton
                backgroundColor="light"
                type="submit"
                defaultStyle="white"
                loading={isSubmitting}
                onClick={() => {
                  setChangedValues({
                    title: false,
                    accountingCode: false,
                    description: false,
                  });
                }}>
                Lagre
              </LargeButton>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
