export const validAccountNumber = (value: string) => {
  const numValue = value.replace(/[\s.]/g, ''); // Remove whitespace and periods
  if (!/^\d{11}$/.test(numValue)) return false; // Should be exactly 11 digits

  const controlDigit = parseInt(numValue.slice(-1), 10);
  const accountNumber = numValue.slice(0, -1);

  const weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  const remainder = accountNumber.split('').reduce((acc, val, idx) => acc + parseInt(val) * weights[idx], 0);
  return (remainder + controlDigit) % 11 === 0;
};
