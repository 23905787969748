import { api } from 'utils/fetch-wrapper';

const apiUrl = '/invoices';

export const invoiceService = {
  send,
  create,
};

function send(id: string, params: { email: boolean; ehf: boolean }) {
  return api.post(`${apiUrl}/${id}/send`, { email: params.email, ehf: params.ehf });
}

function create(params: { reportId: string }) {
  return api.post(`${apiUrl}`, params);
}
