export function CaretLeft({ className }: { className?: string }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M10.3538 13.1463C10.4002 13.1927 10.4371 13.2479 10.4622 13.3086C10.4874 13.3693 10.5003 13.4343 10.5003 13.5C10.5003 13.5657 10.4874 13.6308 10.4622 13.6915C10.4371 13.7522 10.4002 13.8073 10.3538 13.8538C10.3073 13.9002 10.2522 13.9371 10.1915 13.9622C10.1308 13.9874 10.0657 14.0003 10 14.0003C9.93433 14.0003 9.86928 13.9874 9.80858 13.9622C9.74788 13.9371 9.69273 13.9002 9.64628 13.8538L4.64628 8.85378C4.59979 8.80735 4.56291 8.7522 4.53775 8.6915C4.51259 8.63081 4.49963 8.56574 4.49963 8.50003C4.49963 8.43433 4.51259 8.36926 4.53775 8.30856C4.56291 8.24786 4.59979 8.19272 4.64628 8.14628L9.64628 3.14628C9.7401 3.05246 9.86735 2.99976 10 2.99976C10.1327 2.99976 10.26 3.05246 10.3538 3.14628C10.4476 3.2401 10.5003 3.36735 10.5003 3.50003C10.5003 3.63272 10.4476 3.75996 10.3538 3.85378L5.7069 8.50003L10.3538 13.1463Z"
        fill="#100C3A"
        fillOpacity="0.9"
      />
    </svg>
  );
}
