import { Search } from 'assets/svgcomponents/Search';
import { useTheme } from 'context/theme';

/**
 * Text input wrapper. Contains a search icon. Used for search in tables.
 */
export default function SearchBar({
  setSearchFilter,
  searchFilter,
}: {
  setSearchFilter: (value: string) => void;
  searchFilter: string;
}) {
  const theme = useTheme();

  return (
    <div className="relative flex h-fit flex-row items-center">
      <Search className="absolute left-3 h-5 w-5" />
      <input
        className="buttonsmalltext centered placeholder:[#100c3ae6] flex h-fit w-52 rounded-small border px-3.5 py-2 pl-10"
        style={{
          borderColor: theme.border.border,
          color: theme.label.primary,
        }}
        onChange={(e) => setSearchFilter(e.target.value)}
        value={searchFilter}
        placeholder="Søk"
      />
    </div>
  );
}
