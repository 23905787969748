export function CircleExclamation({ className, fill }: { className?: string; fill?: string }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99927 7.31602V10.1144M9.99927 12.6839L10.0008 12.6814M16.665 9.99911C16.665 13.6801 13.681 16.6641 10 16.6641C6.31904 16.6641 3.33502 13.6801 3.33502 9.99911C3.33502 6.31813 6.31904 3.33411 10 3.33411C13.681 3.33411 16.665 6.31813 16.665 9.99911Z"
        stroke="#100C3A"
        strokeOpacity="0.9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
