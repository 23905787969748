export function Arrow({ className }: { className?: string }) {
  return (
    <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M18.375 10.4999C18.375 10.6739 18.3059 10.8408 18.1828 10.9639C18.0597 11.087 17.8928 11.1561 17.7188 11.1561H4.8653L9.65183 15.9418C9.7128 16.0028 9.76116 16.0752 9.79416 16.1549C9.82716 16.2345 9.84414 16.3199 9.84414 16.4061C9.84414 16.4924 9.82716 16.5777 9.79416 16.6574C9.76116 16.7371 9.7128 16.8095 9.65183 16.8704C9.59085 16.9314 9.51847 16.9798 9.4388 17.0128C9.35914 17.0458 9.27376 17.0627 9.18753 17.0627C9.1013 17.0627 9.01592 17.0458 8.93625 17.0128C8.85659 16.9798 8.7842 16.9314 8.72323 16.8704L2.81698 10.9642C2.75597 10.9032 2.70756 10.8308 2.67454 10.7512C2.64151 10.6715 2.62451 10.5861 2.62451 10.4999C2.62451 10.4136 2.64151 10.3282 2.67454 10.2486C2.70756 10.1689 2.75597 10.0965 2.81698 10.0356L8.72323 4.12933C8.84637 4.00619 9.01338 3.93701 9.18753 3.93701C9.36167 3.93701 9.52869 4.00619 9.65183 4.12933C9.77496 4.25247 9.84414 4.41948 9.84414 4.59363C9.84414 4.76777 9.77496 4.93478 9.65183 5.05792L4.8653 9.84363H17.7188C17.8928 9.84363 18.0597 9.91277 18.1828 10.0358C18.3059 10.1589 18.375 10.3258 18.375 10.4999Z"
        fill="currentColor"
      />
    </svg>
  );
}
