import { AnimatePresence } from 'framer-motion';
import { useRef, useState } from 'react';
import { Wizard } from 'react-use-wizard';
import AnimatedStep from './AnimatedStep';
import { SignupLayout } from './SignupLayout';
import { WizardContext } from './WizardContext';
import { CreateOrganization } from './steps/CreateOrganization';
import { Invite } from './steps/Invite';
import { LastStep } from './steps/LastStep';
import { OrganizationInfo } from './steps/OrganizationInfo';
import { OrganizationName } from './steps/OrganizationName';
import { OrganizationSearch } from './steps/OrganizationSearch';
import { SelectTier } from './steps/SelectTier';

export default function SignupOrganization() {
  const previousStep = useRef<number>(0);

  const organizationDataState = useState({});
  const [organizationData, setOrganizationData] = organizationDataState;

  const steps = [
    <OrganizationSearch />,
    <OrganizationName />,
    <OrganizationInfo />,
    <CreateOrganization />,
    <SelectTier />,
    <Invite />,
    <LastStep />,
  ];

  return (
    <WizardContext.Provider value={[organizationData, setOrganizationData]}>
      <SignupLayout>
        <Wizard wrapper={<AnimatePresence mode="wait" />}>
          {steps.map((step, index) => {
            return (
              <AnimatedStep key={index} previousStep={previousStep}>
                {step}
              </AnimatedStep>
            );
          })}
        </Wizard>
      </SignupLayout>
    </WizardContext.Provider>
  );
}
