import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Account } from '__generated__/UtleggsappenApi';
import { useOrganizations } from 'api/organizations/useOrganizations';
import { alert } from 'components/alerts/Alert';
import BackButton from 'components/buttons/BackButton';
import { SmallButton } from 'components/buttons/SmallButton';
import Input from 'components/input-field/Input';
import Table from 'components/table/Table';
import { DefaultCell, HeaderCell } from 'components/table/TableComponents';
import { toast } from 'components/toast/Toast';
import { useTheme } from 'context/theme';
import { useStoredUser } from 'context/UserContext';
import { useMemo, useState } from 'react';
import { accountService } from 'services/accountService';

const accessLevels = { 0: 'Admin', 1: 'Admin', 2: 'Bruker', 3: 'Ingen', 4: 'Ingen' } as const;
const digestFrequency = { 0: 'Ingen', 1: 'Daglig', 2: 'Ukentlig', 3: 'Månedlig' } as const;

function NotificationComponent({
  current,
  account,
}: Readonly<{
  current?: { title: string; id: string };
  account?: Account;
}>) {
  const [selected, setSelected] = useState(current);

  return (
    <Input.Dropdown
      onSelectOption={(option) => {
        setSelected(option);
        accountService.update(account!.id, { digest: parseInt(option.id) });
      }}
      options={Object.keys(digestFrequency).map((key) => ({
        id: key,
        title: digestFrequency[parseInt(key) as 0 | 1 | 2 | 3],
      }))}
      selectedOption={selected ?? { id: '-1', title: '' }}
    />
  );
}

export default function PreferencesSettings() {
  const { data: organizations } = useOrganizations();
  const { user, setUser } = useStoredUser();
  const theme = useTheme();

  const handleDelete = async (account: Account) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 0));
      const canDelete = await accountService.canAccountLeave(account.id, account.organizationId);

      if (!canDelete) {
        toast.error({
          title: 'Kunne ikke forlate organisasjon',
          description: 'Det er ingen andre administratorer i denne organisasjonen',
        });
        return;
      }

      await accountService.delete(account.id);
      await setUser();
      toast.success({ description: `Du har forlatt organisasjonen ${account.organization!.displayName}` });
    } catch (error) {
      toast.error({ description: 'Noe gikk galt. Kontakt support om problemet vedvarer.' });
    }
  };

  const tableData = useMemo(() => {
    return organizations?.data.map((org) => {
      const account = user.accounts!.find((account) => account.organization!.id === org!.id);
      return {
        displayName: org!.displayName,
        access: account ? (accessLevels as any)[account.role] : 'Internadmin (uten konto)',
        digest: account ? { title: (digestFrequency as any)[account.digest], id: `${account.digest}` } : undefined,
        leave: org!.id,
        account: account,
      };
    });
  }, [organizations, user.accounts]);

  type OrgType = NonNullable<NonNullable<typeof tableData>[number]>;

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: 'displayName',
        header: () => <HeaderCell>Virksomhet</HeaderCell>,
        cell: (value: CellContext<OrgType, OrgType['displayName']>) => (
          <div className="flex h-full items-center">
            <DefaultCell className="smalltextbold">{value.getValue()}</DefaultCell>
          </div>
        ),
      },
      {
        accessorKey: 'access',
        header: () => <HeaderCell>Tilgang til dashbord</HeaderCell>,
        cell: (value: CellContext<OrgType, OrgType['access']>) => (
          <div className="flex h-full items-center">
            <DefaultCell>{value.getValue()}</DefaultCell>
          </div>
        ),
      },
      {
        accessorKey: 'digest',
        header: () => <HeaderCell>Varslinger på e-post</HeaderCell>,
        cell: (value: CellContext<OrgType, OrgType['digest']>) => {
          return (
            value.getValue() && (
              <NotificationComponent current={value.getValue()} account={value.row.original.account} />
            )
          );
        },
      },
      {
        accessorKey: 'leave',
        header: () => null,
        cell: ({ row }: CellContext<OrgType, OrgType['leave']>) => {
          return (
            row.original.account && (
              <SmallButton
                backgroundColor="light"
                defaultStyle="critical"
                onClick={() =>
                  alert.error({
                    title: 'Forlat organisasjon',
                    description: 'Ønsker du forlate organisasjonen? Dette kan ikke reverseres.',
                    actionLabel: 'Bekreft',
                    onAction: () => handleDelete(row.original.account!),
                  })
                }>
                Forlat organisasjon
              </SmallButton>
            )
          );
        },
      },
    ],
    [],
  );

  if (user.accounts!.length === 0) return null;

  return (
    <div className="flex flex-col gap-y-10">
      <div className="flex flex-col justify-between gap-y-5 pt-5" style={{ color: theme.label.primary }}>
        <BackButton />
        <h1 className="bigheading">Preferanser</h1>
      </div>
      <Table columns={columns} data={tableData ?? []} gridCols="1fr 1fr 1fr 1fr" />
    </div>
  );
}
