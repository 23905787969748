import { CellContext } from '@tanstack/react-table';
import { Account } from '__generated__/UtleggsappenApi';
import { useAccounts } from 'api/accounts/useAccounts';
import { useDepartment } from 'api/departments/useDepartment';
import { Check } from 'assets/svgcomponents/Check';
import { Plus } from 'assets/svgcomponents/Plus';
import { Trash } from 'assets/svgcomponents/Trash';
import { alert } from 'components/alerts/Alert';
import { SmallButton } from 'components/buttons/SmallButton';
import SearchBar from 'components/input-field/SearchBar';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import Table from 'components/table/Table';
import { DefaultCell, HeaderCell, TagWrapper } from 'components/table/TableComponents';
import { toast } from 'components/toast/Toast';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { departmentService } from 'services/departmentService';
import { formatPhoneNumber } from 'utils/format';

export default function DepartmentUsers() {
  const { departmentId } = useParams();
  const { data: department, isLoading: isLoadingDepartment, mutate: mutateDepartment } = useDepartment(departmentId!);
  const { data: accounts, isLoading: isLoadingAccounts } = useAccounts();
  const [searchFilter, setSearchFilter] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const [usersToBeAdded, setUsersToBeAdded] = useState<string[]>([]);
  const [isLoadingAdding, setIsLoadingAdding] = useState(false);
  const [isLoadingRemoving, setIsLoadingRemoving] = useState(false);
  const [managerAccounts, setManagerAccounts] = useState<any>([]);

  useEffect(() => {
    if (accounts) {
      setManagerAccounts(
        accounts?.data?.filter((a) => department?.accounts?.map((acc) => acc.id).includes(a.id)) || [],
      );
    }
  }, [department, isLoadingAccounts]);

  const handleRemove = async (accountIds: string[]) => {
    setIsLoadingRemoving(true);
    await departmentService
      .disconnectAccounts(department!.id, accountIds)
      .then(async () => {
        await mutateDepartment();
      })
      .then(() => {
        toast.success({ description: 'Brukeren ble fjernet som godkjenner' });
      })
      .catch(() => {
        toast.error({ description: 'Det oppsto et problem' });
      })
      .finally(() => {
        setIsLoadingRemoving(false);
      });
  };

  const checkAccountsPermission = (accountIds: string[]) => {
    if (accountIds?.length < 1) {
      toast.error({ description: 'Ingen brukere valgt!' });
      return;
    }
    setIsLoadingAdding(true);
    for (const accountId of accountIds) {
      const role = accounts?.data?.find((a) => a.id === accountId)?.role || 9;
      if (role > 3) {
        setIsLoadingAdding(false);
        alert.info({
          title: 'Legg til brukere',
          description:
            'En eller flere av brukerne mangler godkjennertillatelser. Disse vil få oppdaterte rettigheter til godkjenner. Ønsker du å fortsette?',
          actionLabel: 'Legg til brukere',
          onAction: () => add(accountIds),
          loading: isLoadingAdding,
        });
        return;
      }
    }
    add(accountIds);
  };

  const add = async (accountIds: string[]) => {
    setIsLoadingAdding(true);
    await departmentService
      .connectAccounts(department!.id, accountIds)
      .then(async () => {
        await mutateDepartment();
      })
      .then(() => {
        toast.success({ description: 'Brukerene ble lagt til som godkjennere' });
        setIsAdding(false);
        setUsersToBeAdded([]);
      })
      .catch(() => {
        toast.error({ description: 'Det oppsto et problem' });
      })
      .finally(() => {
        setIsLoadingAdding(false);
      });
  };

  const handleAddUser = (accountId: string) => {
    setUsersToBeAdded([...usersToBeAdded, accountId]);
  };

  const handleUndoAddUser = (accountId: string) => {
    setUsersToBeAdded([...usersToBeAdded.filter((u) => u !== accountId)]);
  };

  const usersColumns = useMemo(
    () => [
      {
        id: 'name',
        accessorFn: (row: any) =>
          row.user.givenName || row.user.familyName
            ? `${row.user.givenName} ${row.user.familyName}`
            : formatPhoneNumber(row.user.phoneNumber),
        cell: (value: CellContext<Account, string>) => (
          <DefaultCell className="smalltextbold">{value.getValue()}</DefaultCell>
        ),
        header: () => <HeaderCell>Navn</HeaderCell>,
        size: 350,
      },
      {
        accessorKey: 'id',
        header: () => null,
        cell: (id: CellContext<Account, string>) => (
          <TagWrapper className="flex w-full justify-end">
            <SmallButton
              backgroundColor="light"
              defaultStyle="critical"
              icon={<Trash className="h-4 w-4" fill="currentColor" />}
              iconPlacement="left"
              loading={isLoadingRemoving}
              onClick={() =>
                alert.error({
                  title: 'Fjern bruker',
                  description: 'Er du sikker på at du vil fjerne brukeren som godkjenner?',
                  actionLabel: 'Fjern',
                  onAction: () => handleRemove([id.getValue()]),
                })
              }>
              Fjern
            </SmallButton>
          </TagWrapper>
        ),
      },
    ],
    [],
  );
  const addUsersColumns = useMemo(
    () => [
      {
        id: 'name',
        accessorFn: (row: any) =>
          row.user.givenName || row.user.familyName
            ? `${row.user.givenName} ${row.user.familyName}`
            : formatPhoneNumber(row.user.phoneNumber),
        cell: (value: CellContext<Account, string>) => (
          <DefaultCell className="smalltextbold">{value.getValue()}</DefaultCell>
        ),
        header: () => <HeaderCell>Navn</HeaderCell>,
        size: 350,
      },
      {
        accessorKey: 'id',
        header: () => null,
        cell: (id: CellContext<Account, string>) => {
          const isBeingAdded = usersToBeAdded.includes(id.getValue());
          return (
            <TagWrapper className="flex w-full justify-end">
              <SmallButton
                backgroundColor="light"
                defaultStyle={isBeingAdded ? 'success' : 'outline'}
                icon={isBeingAdded ? <Check className="h-4 w-4" fill="currentColor" /> : <Plus className="h-4 w-4" />}
                iconPlacement="left"
                onClick={() => (isBeingAdded ? handleUndoAddUser(id.getValue()) : handleAddUser(id.getValue()))}>
                {isBeingAdded ? 'Legges til' : 'Legg til'}
              </SmallButton>
            </TagWrapper>
          );
        },
      },
    ],
    [usersToBeAdded],
  );

  return (
    <>
      <div className="flex flex-1 flex-row justify-end gap-x-4">
        {isAdding && (
          <div className="flex flex-1 justify-between">
            <SmallButton
              backgroundColor="light"
              defaultStyle="critical"
              className="h-fit px-3.5 py-2"
              onClick={() => {
                setUsersToBeAdded([]);
                setIsAdding(false);
              }}
              disabled={isLoadingAdding}>
              Avbryt
            </SmallButton>
            <SmallButton
              backgroundColor="light"
              defaultStyle="success"
              className="h-fit px-3.5 py-2"
              icon={<Plus className="h-4 w-4" />}
              iconPlacement="left"
              onClick={() => checkAccountsPermission(usersToBeAdded)}
              loading={isLoadingAdding}>
              Legg til markerte
            </SmallButton>
          </div>
        )}
        <SearchBar setSearchFilter={setSearchFilter} searchFilter={searchFilter} />
        {!isAdding && (
          <SmallButton
            backgroundColor="light"
            defaultStyle="indigo"
            className="h-fit px-3.5 py-2"
            icon={<Plus className="h-4 w-4" />}
            iconPlacement="left"
            onClick={() => {
              setIsAdding(true);
            }}>
            Legg til bruker
          </SmallButton>
        )}
      </div>
      <div className="flex h-full w-full flex-col">
        {!isLoadingDepartment && department && !isLoadingAccounts && accounts ? (
          !isAdding ? (
            <Table
              disableHover={false}
              data={managerAccounts}
              filter={searchFilter}
              columns={usersColumns}
              gridCols="minmax(0, 5fr) minmax(0, 7fr) minmax(150px, 4fr) minmax(96px, 2fr) 68px"
              sortBy={[{ id: 'name', desc: true }]}
            />
          ) : (
            <Table
              disableHover={false}
              data={accounts?.data?.filter((a) => !a?.departments?.find((a) => a.id === department.id)) || []}
              filter={searchFilter}
              columns={addUsersColumns}
              gridCols="minmax(0, 5fr) minmax(0, 7fr) minmax(150px, 4fr) minmax(96px, 2fr) 68px"
              autoResetPageIndex={false}
            />
          )
        ) : (
          <FullscreenLoading />
        )}
      </div>
    </>
  );
}
