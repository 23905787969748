import { ReactComponent as Logo } from '../assets/svg/logo.svg';
import { ReactComponent as NotFound } from '../assets/svg/404.svg';
import { useNavigate } from 'react-router-dom';

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div className="flex h-screen w-screen justify-center">
      <div className="mx-8 flex h-5/6 flex-row flex-wrap items-center justify-center space-x-20">
        <div className="flex flex-col items-start justify-center">
          <div className="flex items-center">
            <Logo className="mr-[12px] w-[30px] fill-primary_light stroke-primary_light" />
            <div className="select-none text-[30px] font-medium leading-[42px] tracking-wide text-[#293e4e]/90">
              UTLEGGSAPPEN
            </div>
          </div>
          <div className="mt-4 text-black/70">
            <span className="font-semibold text-black">404.</span> Siden du leter etter finnes ikke.
          </div>
          <div className="mt-7">
            Gå til{' '}
            <span onClick={() => navigate('/')} className="cursor-pointer text-link hover:opacity-70">
              startsiden
            </span>
          </div>
        </div>
        <NotFound className="hidden md:block" />
      </div>
    </div>
  );
}
