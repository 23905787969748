import { Department } from '__generated__/UtleggsappenApi';
import { useDepartment } from 'api/departments/useDepartment';
import { useOrganization } from 'api/organizations/useOrganization';
import { alert } from 'components/alerts/Alert';
import { LargeButton } from 'components/buttons/LargeButton';
import Input from 'components/input-field/Input';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import { toast } from 'components/toast/Toast';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { departmentService } from 'services/departmentService';
import * as Yup from 'yup';
import { DepartmentConfig } from './DepartmentConfig';

export default function DepartmentSettings() {
  const { departmentId } = useParams();
  const { data: department, isLoading: isLoadingDepartment, mutate: mutateDepartment } = useDepartment(departmentId!);
  const { data: organization } = useOrganization();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (department: Partial<Department>) => {
    setIsSubmitting(true);

    await departmentService
      .update(departmentId!, department)
      .then(() => {
        toast.success({ description: 'Endringene ble lagret' });

        setIsSubmitting(false);
        mutateDepartment();
      })
      .catch(() => {
        toast.error({ description: 'Det oppsto en feil' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    await departmentService
      .remove(department?.id!)
      .then(() => {
        navigate(`/${organization?.handle}/avdelinger`);
        toast.success({ description: 'Avdelingen ble slettet' });
      })
      .catch(() => {
        toast.error({ description: 'Det oppsto en feil' });
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <div className="flex h-full w-full flex-col space-y-10">
      {!isLoadingDepartment && department ? (
        <>
          <div className="flex w-full flex-row items-start">
            <Formik
              onSubmit={onSubmit}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize
              initialValues={{
                title: department.title,
              }}
              validationSchema={Yup.object({
                title: Yup.string().required('Påkrevd').min(3).max(40),
              })}>
              {({ values, handleSubmit, handleBlur, errors, handleChange }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="flex w-2/5 flex-col space-y-2">
                    <Input.Text
                      label="Navn"
                      value={values.title || ''}
                      onChange={handleChange}
                      error={errors.title as any}
                      onBlur={handleBlur}
                      name="title"
                      className="w-[300px]"
                    />
                    <LargeButton backgroundColor="light" defaultStyle="white" type="submit" loading={isSubmitting}>
                      Lagre
                    </LargeButton>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="h-full px-5">
              <div className="h-full w-[1px] bg-v2-stone-500" />
            </div>
            {organization?.subscription?.subscriptionTier?.name === 'Enterprise' && (
              <div className="w-1/2 space-y-2 px-5">
                <DepartmentConfig department={department} />
              </div>
            )}
          </div>
          <div className="h-[1px] w-full bg-v2-stone-500" />
          <LargeButton
            backgroundColor="light"
            defaultStyle="critical"
            onClick={() =>
              alert.error({
                title: 'Slett avdeling',
                loading: isDeleting,
                onAction: handleDelete,
                description:
                  'Ønsker du å slette avdelingen? Alle prosjekter og innstillinger tilknyttet avdelingen vil også bli slettet. Dette kan ikke angres.',
              })
            }>
            Slett avdeling
          </LargeButton>
        </>
      ) : (
        <FullscreenLoading />
      )}
    </div>
  );
}
