import { Api } from '__generated__/UtleggsappenApi';
import { fetcher } from 'api';
import { useOrganization } from 'api/organizations/useOrganization';
import useSWR from 'swr';

export function useReports() {
  const { data: organization } = useOrganization();

  const { data, error, mutate, isLoading } = useSWR<
    Awaited<ReturnType<typeof Api.prototype.reports.findAllWhere>>['data']
  >(organization ? '/reports/?' + new URLSearchParams({ organizationId: organization?.id ?? '' }) : null, fetcher);

  return {
    data,
    isLoading: !organization && isLoading,
    error,
    mutate,
  };
}
