export function CreditCard({ className, fill }: { className?: string; fill?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 32" fill={fill} className={className}>
      <path
        d="M28.5 6H4.5C3.96957 6 3.46086 6.21071 3.08579 6.58579C2.71071 6.96086 2.5 7.46957 2.5 8V24C2.5 24.5304 2.71071 25.0391 3.08579 25.4142C3.46086 25.7893 3.96957 26 4.5 26H28.5C29.0304 26 29.5391 25.7893 29.9142 25.4142C30.2893 25.0391 30.5 24.5304 30.5 24V8C30.5 7.46957 30.2893 6.96086 29.9142 6.58579C29.5391 6.21071 29.0304 6 28.5 6ZM28.5 8V11H4.5V8H28.5ZM28.5 24H4.5V13H28.5V24ZM26.5 21C26.5 21.2652 26.3946 21.5196 26.2071 21.7071C26.0196 21.8946 25.7652 22 25.5 22H21.5C21.2348 22 20.9804 21.8946 20.7929 21.7071C20.6054 21.5196 20.5 21.2652 20.5 21C20.5 20.7348 20.6054 20.4804 20.7929 20.2929C20.9804 20.1054 21.2348 20 21.5 20H25.5C25.7652 20 26.0196 20.1054 26.2071 20.2929C26.3946 20.4804 26.5 20.7348 26.5 21ZM18.5 21C18.5 21.2652 18.3946 21.5196 18.2071 21.7071C18.0196 21.8946 17.7652 22 17.5 22H15.5C15.2348 22 14.9804 21.8946 14.7929 21.7071C14.6054 21.5196 14.5 21.2652 14.5 21C14.5 20.7348 14.6054 20.4804 14.7929 20.2929C14.9804 20.1054 15.2348 20 15.5 20H17.5C17.7652 20 18.0196 20.1054 18.2071 20.2929C18.3946 20.4804 18.5 20.7348 18.5 21Z"
        fill={fill}
      />
    </svg>
  );
}
