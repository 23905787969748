export function Vipps() {
  return (
    <svg width="81" height="21" viewBox="0 0 81 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_29_4990)">
        <path
          d="M6.09675 1.35004L9.36106 11.2479L12.5614 1.35004H16.3377L10.7052 16.0307H7.88892L2.25638 1.35004H6.09675Z"
          fill="currentColor"
        />
        <path
          d="M24.8505 13.7056C27.2187 13.7056 28.5629 12.5099 29.843 10.7828C30.5471 9.8528 31.4431 9.65351 32.0832 10.1849C32.7233 10.7164 32.7873 11.7128 32.0832 12.6428C30.227 15.1671 27.8588 16.6949 24.8505 16.6949C21.5862 16.6949 18.7059 14.8349 16.7217 11.5799C16.1457 10.7164 16.2737 9.78637 16.9138 9.32137C17.5538 8.85638 18.5139 9.05566 19.09 9.98566C20.4981 12.1778 22.4183 13.7056 24.8505 13.7056ZM29.2669 5.53496C29.2669 6.73067 28.3709 7.52781 27.3468 7.52781C26.3227 7.52781 25.4266 6.73067 25.4266 5.53496C25.4266 4.33925 26.3227 3.54211 27.3468 3.54211C28.3709 3.54211 29.2669 4.40568 29.2669 5.53496Z"
          fill="currentColor"
        />
        <path
          d="M38.2918 1.35005V3.3429C39.2519 1.9479 40.724 0.951477 42.9002 0.951477C45.6525 0.951477 48.8528 3.3429 48.8528 8.45787C48.8528 13.8386 45.7805 16.4293 42.5802 16.4293C40.916 16.4293 39.3799 15.765 38.2278 14.1043V21.1457H34.7714V1.35005H38.2918ZM38.2918 8.65716C38.2918 11.6464 39.9559 13.2407 41.8121 13.2407C43.6043 13.2407 45.3965 11.7793 45.3965 8.65716C45.3965 5.60146 43.6043 4.14003 41.8121 4.14003C40.0199 4.14003 38.2918 5.53503 38.2918 8.65716Z"
          fill="currentColor"
        />
        <path
          d="M54.9334 1.35005V3.3429C55.8935 1.9479 57.3656 0.951477 59.5418 0.951477C62.2941 0.951477 65.4944 3.3429 65.4944 8.45787C65.4944 13.8386 62.4221 16.4293 59.2218 16.4293C57.5576 16.4293 56.0215 15.765 54.8694 14.1043V21.1457H51.4131V1.35005H54.9334ZM54.9334 8.65716C54.9334 11.6464 56.5976 13.2407 58.4537 13.2407C60.2459 13.2407 62.0381 11.7793 62.0381 8.65716C62.0381 5.60146 60.2459 4.14003 58.4537 4.14003C56.5976 4.14003 54.9334 5.53503 54.9334 8.65716Z"
          fill="currentColor"
        />
        <path
          d="M72.8551 0.951477C75.7353 0.951477 77.7835 2.34647 78.6796 5.80074L75.5433 6.33217C75.4793 4.60503 74.4552 4.00718 72.9191 4.00718C71.767 4.00718 70.8709 4.5386 70.8709 5.40217C70.8709 6.06645 71.3189 6.73074 72.663 6.99645L75.0313 7.46145C77.3355 7.92645 78.6156 9.52072 78.6156 11.6464C78.6156 14.835 75.8633 16.4293 73.2391 16.4293C70.4868 16.4293 67.4145 14.9678 66.9665 11.3807L70.1028 10.8493C70.2948 12.7093 71.3829 13.3736 73.1751 13.3736C74.5192 13.3736 75.4153 12.8421 75.4153 11.9786C75.4153 11.1814 74.9673 10.5836 73.4951 10.3179L71.3189 9.9193C69.0147 9.4543 67.6066 7.79359 67.6066 5.66788C67.6706 2.34647 70.5508 0.951477 72.8551 0.951477Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_29_4990">
          <rect width="81" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
