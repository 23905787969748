import { AppInHandIcon } from 'assets/svgcomponents/AppInHandIcon';
import { LargeButton } from 'components/buttons/LargeButton';
import Input from 'components/input-field/Input';
import { useTheme } from 'context/theme';
import { Form, Formik } from 'formik';
import { useWizard } from 'react-use-wizard';
import * as Yup from 'yup';
import { SignupCard } from '../SignupCard';
import { useWizardContext } from '../WizardContext';

export function OrganizationInfo() {
  const { handleStep, previousStep, nextStep } = useWizard();
  const [stateValue, setState] = useWizardContext();
  const theme = useTheme();

  const unit = stateValue.unit || {
    navn: '',
    organisasjonsnummer: '',
    forretningsadresse: {
      adresse: '',
      postnummer: '',
      poststed: '',
    },
  };
  const validatedUnit = stateValue.validatedUnit || {};

  handleStep(() => {});

  return (
    <SignupCard
      imageComponent={<AppInHandIcon className="h-32 w-full place-self-center" />}
      backButtonOnClick={() => previousStep()}>
      <div
        className="flex w-full flex-col items-center justify-center space-y-3 lg:w-[385px]"
        style={{ color: theme.label.primary }}>
        <div className="centered flex-col">
          <h2 className="bigheading text-center">Opprett Bedrift</h2>
        </div>
        <div className="w-full" style={{ color: theme.label.primary }}>
          <Formik
            onSubmit={(formValues) => {
              setState({
                ...stateValue,
                validatedUnit: {
                  ...stateValue.validatedUnit,
                  forretningsadresse: {
                    adresse: [formValues.address],
                    postnummer: formValues.postalCode,
                    poststed: formValues.region,
                  },
                },
              });
              nextStep();
            }}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize
            initialValues={{
              address:
                validatedUnit?.forretningsadresse?.adresse && validatedUnit?.forretningsadresse?.adresse?.length > 0
                  ? validatedUnit?.forretningsadresse?.adresse[0]
                  : unit?.forretningsadresse?.adresse && unit?.forretningsadresse?.adresse?.length > 0
                    ? unit?.forretningsadresse?.adresse[0]
                    : undefined,
              postalCode: validatedUnit?.forretningsadresse?.postnummer || unit?.forretningsadresse?.postnummer,
              region: validatedUnit?.forretningsadresse?.poststed || unit?.forretningsadresse?.poststed,
            }}
            validationSchema={Yup.object({
              address: Yup.string().required('Påkrevd'),
              postalCode: Yup.string().required('Påkrevd').min(4, 'Ugyldig postnummer').max(4, 'Ugyldig postnummer'),
              region: Yup.string().required('Påkrevd'),
            })}>
            {({ values, handleSubmit, handleBlur, errors, handleChange }) => (
              <Form onSubmit={handleSubmit} className="flex w-full flex-col items-center space-y-10">
                <div className="flex w-full flex-col space-y-2">
                  <Input.Text
                    label="Adresse"
                    value={values.address || ''}
                    onChange={handleChange}
                    error={errors.address as any}
                    onBlur={handleBlur}
                    name="address"
                    required
                  />
                  <Input.Text
                    label="Postnummer"
                    value={values.postalCode || ''}
                    onChange={(e) => {
                      if (/^\d{0,4}$/.test(e.currentTarget.value)) {
                        handleChange(e);
                      }
                    }}
                    error={errors.postalCode as any}
                    onBlur={handleBlur}
                    name="postalCode"
                    required
                  />
                  <Input.Text
                    label="Poststed"
                    value={values.region}
                    onChange={handleChange}
                    error={errors.region as any}
                    onBlur={handleBlur}
                    name="region"
                    required
                  />
                </div>
                <div className="flex space-x-3">
                  <LargeButton backgroundColor="light" defaultStyle="indigo" type="submit">
                    Gå videre
                  </LargeButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </SignupCard>
  );
}
