import { useUser } from 'api/users/useUser';
import { ReceiptsIcon } from 'assets/svgcomponents/ReceiptsIcon';
import { AcceptTerms } from 'components/AcceptTerms';
import { VippsButton } from 'components/buttons/VippsButton';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import { Tooltip } from 'components/tooltip/Tooltip';
import { useTheme } from 'context/theme';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignupCard } from './SignupCard';

export default function Signup() {
  const { data: user, loggedOut, isLoading } = useUser(false);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (user && !loggedOut) {
      navigate('/registrer/organisasjon', { replace: true });
    }
  }, [user, loggedOut, navigate]);

  if (isLoading) {
    return (
      <div className="centered h-screen" style={{ backgroundColor: theme.background.accent }}>
        <FullscreenLoading />
      </div>
    );
  }

  return (
    <div className="centered h-screen overflow-y-auto" style={{ backgroundColor: theme.background.accent }}>
      <div className="centered relative h-full w-full max-w-[1500px] flex-col space-y-3">
        <SignupCard
          imageComponent={<ReceiptsIcon className="h-full w-28" />}
          backButtonOnClick={() => navigate('/login')}>
          <h1 className="bigheading text-center" style={{ color: theme.label.primary }}>
            Opprett bedrift
          </h1>
          <VippsButton href={process.env.REACT_APP_VIPPS_LOGIN_URI + '/registrer'} />
          <AcceptTerms />
        </SignupCard>
      </div>
    </div>
  );
}
