export function Copy({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M23 0.5H7C6.73478 0.5 6.48043 0.605357 6.29289 0.792893C6.10536 0.98043 6 1.23478 6 1.5V6.5H1C0.734784 6.5 0.48043 6.60536 0.292893 6.79289C0.105357 6.98043 0 7.23478 0 7.5V23.5C0 23.7652 0.105357 24.0196 0.292893 24.2071C0.48043 24.3946 0.734784 24.5 1 24.5H17C17.2652 24.5 17.5196 24.3946 17.7071 24.2071C17.8946 24.0196 18 23.7652 18 23.5V18.5H23C23.2652 18.5 23.5196 18.3946 23.7071 18.2071C23.8946 18.0196 24 17.7652 24 17.5V1.5C24 1.23478 23.8946 0.98043 23.7071 0.792893C23.5196 0.605357 23.2652 0.5 23 0.5ZM16 22.5H2V8.5H16V22.5ZM22 16.5H18V7.5C18 7.23478 17.8946 6.98043 17.7071 6.79289C17.5196 6.60536 17.2652 6.5 17 6.5H8V2.5H22V16.5Z"
        fill="#343330"
      />
    </svg>
  );
}
