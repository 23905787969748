import { api } from 'utils/fetch-wrapper';

const apiUrl = '/reports';

export const reportService = {
  get,
  getAll,
  create,
  approve,
  reject,
  update,
};

function getAll(query: string) {
  return api.get(`${apiUrl}?` + new URLSearchParams(query));
}

function create(accountId: string) {
  return api.post(`${apiUrl}`, { accountId });
}

function get(id: string) {
  return api.get(`${apiUrl}/${id}`);
}

function approve(id: string) {
  return api.post(`${apiUrl}/${id}/approve`);
}

function reject(id: string) {
  return api.post(`${apiUrl}/${id}/reject`);
}

function update(
  accountId: string,
  params: { isStaff?: boolean; role?: number; activatedAt?: Date; activated?: Date; digest?: number },
) {
  return api.patch(`${apiUrl}/${accountId}`, params);
}
