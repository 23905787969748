import { cn } from 'utils/className';

export function Archive({ className, fill }: { className?: string; fill?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
      fill={fill}
      className={cn('h-[29px] w-[29px] p-1', className)}>
      <path
        d="M18.375 3.9375H2.625C2.2769 3.9375 1.94306 4.07578 1.69692 4.32192C1.45078 4.56806 1.3125 4.9019 1.3125 5.25V7.21875C1.3125 7.56685 1.45078 7.90069 1.69692 8.14683C1.94306 8.39297 2.2769 8.53125 2.625 8.53125V15.75C2.625 16.0981 2.76328 16.4319 3.00942 16.6781C3.25556 16.9242 3.5894 17.0625 3.9375 17.0625H17.0625C17.4106 17.0625 17.7444 16.9242 17.9906 16.6781C18.2367 16.4319 18.375 16.0981 18.375 15.75V8.53125C18.7231 8.53125 19.0569 8.39297 19.3031 8.14683C19.5492 7.90069 19.6875 7.56685 19.6875 7.21875V5.25C19.6875 4.9019 19.5492 4.56806 19.3031 4.32192C19.0569 4.07578 18.7231 3.9375 18.375 3.9375ZM17.0625 15.75H3.9375V8.53125H17.0625V15.75ZM18.375 7.21875H2.625V5.25H18.375V7.21875ZM7.875 11.1562C7.875 10.9822 7.94414 10.8153 8.06721 10.6922C8.19028 10.5691 8.3572 10.5 8.53125 10.5H12.4688C12.6428 10.5 12.8097 10.5691 12.9328 10.6922C13.0559 10.8153 13.125 10.9822 13.125 11.1562C13.125 11.3303 13.0559 11.4972 12.9328 11.6203C12.8097 11.7434 12.6428 11.8125 12.4688 11.8125H8.53125C8.3572 11.8125 8.19028 11.7434 8.06721 11.6203C7.94414 11.4972 7.875 11.3303 7.875 11.1562Z"
        fill={fill}
        stroke="none"
        fillOpacity="1"
      />
    </svg>
  );
}
