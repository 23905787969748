export function CircleInfo({ className, fill }: { className?: string; fill?: string }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.65895 9.86902H10.0015V12.6665M10.0001 7.33647L10.0016 7.33402M16.6666 9.99837C16.6666 13.6794 13.6826 16.6634 10.0016 16.6634C6.32063 16.6634 3.33661 13.6794 3.33661 9.99837C3.33661 6.3174 6.32063 3.33337 10.0016 3.33337C13.6826 3.33337 16.6666 6.3174 16.6666 9.99837Z"
        stroke="#100C3A"
        strokeOpacity="0.9"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
