export function Close({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 22" fill="currentColor" className={className}>
      <path
        d="M16.8705 16.4423C16.9315 16.5033 16.9799 16.5757 17.0129 16.6553C17.0459 16.735 17.0629 16.8204 17.0629 16.9066C17.0629 16.9928 17.0459 17.0782 17.0129 17.1579C16.9799 17.2376 16.9315 17.3099 16.8705 17.3709C16.8096 17.4319 16.7372 17.4803 16.6575 17.5132C16.5779 17.5462 16.4925 17.5632 16.4062 17.5632C16.32 17.5632 16.2346 17.5462 16.155 17.5132C16.0753 17.4803 16.0029 17.4319 15.942 17.3709L10.5 11.9281L5.05805 17.3709C4.93491 17.4941 4.76789 17.5632 4.59375 17.5632C4.4196 17.5632 4.25259 17.4941 4.12945 17.3709C4.00631 17.2478 3.93713 17.0808 3.93713 16.9066C3.93713 16.7325 4.00631 16.5655 4.12945 16.4423L9.57223 11.0004L4.12945 5.55841C4.00631 5.43527 3.93713 5.26826 3.93713 5.09411C3.93713 4.91997 4.00631 4.75296 4.12945 4.62982C4.25259 4.50668 4.4196 4.4375 4.59375 4.4375C4.76789 4.4375 4.93491 4.50668 5.05805 4.62982L10.5 10.0726L15.942 4.62982C16.0651 4.50668 16.2321 4.4375 16.4062 4.4375C16.5804 4.4375 16.7474 4.50668 16.8705 4.62982C16.9937 4.75296 17.0629 4.91997 17.0629 5.09411C17.0629 5.26826 16.9937 5.43527 16.8705 5.55841L11.4278 11.0004L16.8705 16.4423Z"
        fill="currentColor"
      />
    </svg>
  );
}
