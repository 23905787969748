import * as Dialog from '@radix-ui/react-dialog';
import { useTheme } from 'context/theme';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { cn } from 'utils/className';

type ModalProps = {
  /**
   * The trigger that opens the modal.
   */
  trigger: React.ReactNode;

  /**
   * The content of the modal.
   */
  children?: React.ReactNode;

  /**
   * The z-index of the modal. Used if multiple modals are open at the same time. The backdrop will always be one z-index lower than this number.
   */
  zIndex?: number;

  /**
   * The class name of the modal.
   */
  className?: string;

  /**
   * The style of the modal.
   */
  style?: React.CSSProperties;

  /**
   * Whether the modal should be open by default. Defaults to false.
   */
  openByDefault?: boolean;
};

export const Modal = forwardRef(
  ({ trigger, children, className, style, zIndex = 50, openByDefault = false }: ModalProps, ref) => {
    const theme = useTheme();

    const [open, setOpen] = useState(openByDefault);

    useImperativeHandle(ref, () => ({
      close: () => setOpen(false),
      open: () => setOpen(true),
      isOpen: open,
    }));

    useEffect(() => {
      const html = document.querySelector('html');
      document.body.style.pointerEvents = 'auto';
      if (open) {
        if (html) {
          html.style.scrollbarGutter = '';
          const timer = setTimeout(() => {
            document.body.style.pointerEvents = '';
          }, 0);

          return () => clearTimeout(timer);
        }
      } else {
        document.body.style.pointerEvents = 'auto';
      }
    }, [open]);

    const ForwardedTrigger = forwardRef((props, ref) => {
      return (
        <div {...props} className="h-fit">
          {trigger}
        </div>
      );
    });

    return (
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Trigger asChild>{<ForwardedTrigger />}</Dialog.Trigger>
        <Dialog.Portal container={document.getElementById('root') || document.body}>
          <Dialog.Overlay className="DialogOverlay fixed inset-0 bg-black/50" style={{ zIndex: zIndex - 1 }} />
          <Dialog.Content
            ref={ref as any}
            className={cn(
              `DialogContent fixed left-1/2 top-1/2 flex max-h-[90%] w-fit -translate-x-1/2 -translate-y-1/2 flex-col justify-center overflow-hidden rounded-xlarge p-4 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.15)] lg:p-10`,
              children ? 'space-y-3' : 'space-y-6',
              className,
            )}
            style={{
              backgroundColor: theme.background.primary,
              zIndex: zIndex,
              ...style,
            }}>
            {children}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  },
);
