import {
  CellContext,
  ColumnDef,
  RowSelectionState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { SuchEmpty } from 'assets/svgcomponents/SuchEmpty';
import { useTheme } from 'context/theme';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { cn } from 'utils/className';
import { Pagination } from './Pagination';
import { RowCheckbox } from './RowCheckbox';

interface TableProps {
  /**
   * List of items to display in the table. If onClick is set, the row will be clickable.
   */
  data: any[];

  /**
   * The filter to apply to the table. Used to ex. search for a specific item
   */
  filter?: string;

  /**
   * The columns to display in the table
   */
  columns: ColumnDef<any, any>[];

  /**
   * !!THIS IS CURRENTLY NOT WORKING. MUST BE NOT NULL TO RENDER PROPERLY!!
   */
  gridCols?: CSSProperties['gridTemplateColumns'];

  /**
   * The number of items to display per page. Can be overridden by the user.
   */
  numberOfItems?: number;

  /**
   * Whether or not to disable hover effect on the table
   */
  disableHover?: boolean;

  /**
   * The selected rows in the table. Used when the table is selectable.
   */
  selectedRows?: RowSelectionState;

  /**
   * Function to set the selected rows in the table. Used when the table is selectable.
   */
  setSelectedRows?: React.Dispatch<React.SetStateAction<RowSelectionState>>;

  /**
   * The sorting state of the table. Used to sort the table by a specific column
   */
  sortBy?: SortingState;

  /**
   * Whether or not to reset the page index when the data changes
   */
  autoResetPageIndex?: boolean;
}

export default function Table({
  data,
  filter,
  columns,
  gridCols,
  numberOfItems,
  disableHover,
  selectedRows,
  setSelectedRows,
  sortBy,
  autoResetPageIndex,
}: TableProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [sorting, setSorting] = useState<SortingState>(sortBy || []);
  const pageSize = parseInt(localStorage.getItem(`${location.pathname}-pageSize`) || numberOfItems?.toString() || '20');

  useEffect(() => {
    setGlobalFilter(filter);
  }, [filter]);

  const table = useReactTable({
    data: data,
    columns: [
      ...[
        selectedRows &&
          setSelectedRows &&
          ({
            size: 30,
            id: 'select',
            header: ({ table }: any) => (
              <RowCheckbox
                indeterminate={table.getIsSomeRowsSelected()}
                checked={table.getIsAllRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
            ),
            cell: ({ row }: CellContext<any, any>) => (
              <RowCheckbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
            ),
          } as any),
      ],
      ...columns,
    ].filter((a) => a),
    state: {
      sorting,
      rowSelection: selectedRows,
    },
    enableMultiRowSelection: true,
    onRowSelectionChange: setSelectedRows,
    initialState: {
      pagination: {
        pageSize: pageSize,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    enableGlobalFilter: true,
    autoResetPageIndex: autoResetPageIndex,
  });

  const { setGlobalFilter } = table;

  const grid = !!gridCols;

  if (data?.length === 0) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center py-8 transition-all">
        <SuchEmpty />
      </div>
    );
  }

  if (grid) {
    return (
      <div className="flex grow flex-col justify-between">
        <table className="w-full table-fixed">
          <colgroup>
            {table.getAllColumns().map((column) => {
              return <col key={`${column.id}-columndef`} span={1} style={{ width: `${column.getSize()}px` }} />;
            })}
          </colgroup>
          <thead className="">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="px-6 py-2">
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className="px-2 first:pl-8 last:pr-0" style={{ width: header.column.getSize() }}>
                    <div
                      {...{
                        className: header.column.getCanSort() ? 'cursor-pointer select-none flex min-w-[36px]' : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <span className="pl-2">↑</span>,
                        desc: <span className="pl-2">↓</span>,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="relative rounded-large">
            {table.getRowModel().rows.map((row, indexRow) => (
              <React.Fragment key={row.id + 'fragment'}>
                <tr
                  key={row.id}
                  className={cn(
                    'group',
                    (row.original.navigate || row.original.url || row.original.onClick) &&
                      !disableHover &&
                      'hover:cursor-pointer hover:bg-v2-purple-100/50',
                  )}
                  onClick={() => {
                    if (row.original.onClick) {
                      row.original.onClick();
                    }
                    if (row.original.navigate) {
                      navigate(row.original.navigate);
                    }
                    if (row.original.url) {
                      window.open(row.original.url);
                    }
                  }}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={cn(
                        'p-0 first:pl-6 last:pr-6',
                        'group-first:first:rounded-tl-large group-first:last:rounded-tr-large',
                        'group-last:first:rounded-bl-large group-last:last:rounded-br-large',
                        'bg-v2-purple-50',
                        (row.original.navigate || row.original.url || row.original.onClick) &&
                          !disableHover &&
                          'transition-colors duration-100 group-hover:bg-v2-purple-200',
                      )}>
                      <div className="flex h-full items-center px-2 py-6">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </div>
                    </td>
                  ))}
                </tr>
                {indexRow !== table.getRowModel().rows.length - 1 && (
                  <tr key={row.id + 'border'} className="group-hover:opacity-0">
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id + 'border'} className="bg-v2-purple-50 p-0 first:pl-6 last:pr-6">
                        <div className="h-[1px] w-full bg-v2-purple-200" />
                      </td>
                    ))}
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <Pagination table={table} />
      </div>
    );
  } else {
    return (
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="group/row">
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="pb-2">
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="group/row">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="group/item w-full p-0 group-first/row:first:rounded-tl-large group-first/row:last:rounded-tr-large group-last/row:first:rounded-bl-large group-last/row:last:rounded-br-large"
                  style={{ backgroundColor: theme.background.primary }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
