import { Api } from '__generated__/UtleggsappenApi';
import { fetcher } from 'api';
import { useOrganization } from 'api/organizations/useOrganization';
import useSWR from 'swr';

export function useAccounts() {
  // ! Av en eller annen grunn er denne typen generert som 'Never', så jeg har kopiert typen direkte fra backend
  // Awaited<ReturnType<typeof Api.prototype.categories.findAll>>['data']
  const { data: organization } = useOrganization();

  const { data, error, mutate, isLoading } = useSWR<Awaited<ReturnType<typeof Api.prototype.accounts.findAll>>['data']>(
    organization ? '/accounts/?' + new URLSearchParams({ organizationId: organization.id }) : null,
    fetcher,
    {
      shouldRetryOnError: false,
    },
  );

  const nonDeletedAccounts = data?.data.filter((account) => !account.deletedAt);

  return {
    data: {
      data: nonDeletedAccounts,
      _count: data?._count,
    },
    isLoading,
    error,
    mutate,
  };
}
