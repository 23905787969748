import { Check } from 'assets/svgcomponents/Check';
import { useTheme } from 'context/theme';
import { cn } from 'utils/className';

export function RowCheckbox({
  checked,
  indeterminate,
  onChange,
}: {
  checked?: boolean;
  indeterminate?: boolean;
  onChange?: any;
}) {
  const theme = useTheme();
  return (
    <div
      onClick={onChange}
      className={cn('centered h-[24px] w-[24px] rounded-full border-[1px]')}
      style={{
        borderColor: theme.border.border,
        color: checked || indeterminate ? theme.label.onaccent : theme.label.onlight,
        backgroundColor: checked || indeterminate ? theme.base.accent : 'transparent',
        transition: 'backgroundColor 2s',
      }}>
      <Check fill="currentColor" />
    </div>
  );
}
