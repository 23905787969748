import { cn } from 'utils/className';
import { BaseButton, ButtonProps } from './BaseButton';

export function ExtraSmallButton(props: ButtonProps) {
  return (
    <BaseButton {...props} className={cn('buttonsmalltext w-fit rounded-xxlarge px-[12px] py-[4px]', props.className)}>
      {props.children}
    </BaseButton>
  );
}
