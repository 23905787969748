import { Amex } from 'assets/svgcomponents/Amex';
import { Mastercard } from 'assets/svgcomponents/Mastercard';
import { Visa } from 'assets/svgcomponents/Visa';

export function PaymentCardLogo({ type }: { type: 'visa' | 'amex' | 'mastercard' }) {
  switch (type) {
    case 'visa':
      return <Visa className="h-[34px] w-[52px]" />;
    case 'amex':
      return <Amex className="h-[34px] w-[52px]" />;
    case 'mastercard':
      return <Mastercard className="h-[34px] w-[52px]" />;
    default:
      return <></>;
  }
}
