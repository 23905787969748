import { useOrganization } from 'api/organizations/useOrganization';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import { toast } from 'components/toast/Toast';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

export default function Success() {
  const { data } = useOrganization();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2345);
  });

  if (loading) {
    return <FullscreenLoading />;
  }

  toast.success({ description: 'Betaling vellykket' });

  return <Navigate to={`/${data!.handle}/innstillinger/abonnement`} />;
}
