import { Department, DepartmentConfiguration } from '__generated__/UtleggsappenApi';
import { LargeButton } from 'components/buttons/LargeButton';
import Input from 'components/input-field/Input';
import { toast } from 'components/toast/Toast';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { DropdownSearch } from 'screens/signup/DropdownSearch';
import { departmentService } from 'services/departmentService';
import { useDebounce } from 'utils/useDebounce';

export function DepartmentConfig({ department }: { department: Department }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function onSubmit(values: Partial<DepartmentConfiguration>) {
    setIsSubmitting(true);
    try {
      await departmentService.update(department.id, {
        configuration: values as DepartmentConfiguration,
      });
      toast.success({ description: 'Endringene ble lagret' });
    } catch (err) {
      console.error(err);
      toast.error({ description: 'Noe gikk galt' });
    }
    setIsSubmitting(false);
  }

  const [units, setUnits] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const brregUrl = 'https://data.brreg.no';
  const brregContext = '/enhetsregisteret/api/enheter';

  const handleSearch = useDebounce(async (name: string) => {
    setNoResults(false);
    if (name === '') {
      setUnits([]);
      setIsSearching(false);
      return;
    }
    await fetch(`${brregUrl}${brregContext}?navn=${name}&size=10`)
      .then(async (res) => {
        return await res.json();
      })
      .then((data) => {
        setUnits(data._embedded.enheter);
      })
      .catch(() => {
        setNoResults(true);
      })
      .finally(() => {
        setIsSearching(false);
      });
  }, 500);

  return (
    <Formik
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        shouldOverrideInvoiceBuyer: !!department.configuration?.shouldOverrideInvoiceBuyer,
        deliverByEhf: !!department.configuration?.deliverByEhf,
        deliverByEmail: !!department.configuration?.deliverByEmail,
        deliveryEmail: department.configuration?.deliveryEmail || '',
        invoiceOrgNumber: department.configuration?.invoiceOrgNumber || '',
        invoiceLegalName: department.configuration?.invoiceLegalName || '',
        invoiceAddress: department.configuration?.invoiceAddress || '',
        invoicePostalCode: department.configuration?.invoicePostalCode || '',
        invoiceRegion: department.configuration?.invoiceRegion || '',
        invoicePaymentTerms: department.configuration?.invoicePaymentTerms || 5,
      }}>
      {({ values, handleSubmit, handleBlur, errors, handleChange, setFieldValue }) => (
        <Form onSubmit={handleSubmit} className="flex w-full flex-row items-start">
          <div className="flex flex-col space-y-2">
            <h1 className="normalheading">Avansert</h1>
            <Input.Toggle
              label="Vil du overstyre fakturainnstillinger for denne avdelingen?"
              value={values.shouldOverrideInvoiceBuyer || false}
              onChange={() => setFieldValue('shouldOverrideInvoiceBuyer', !values.shouldOverrideInvoiceBuyer)}
              wrapperClassName="pb-3"
            />
            {values?.shouldOverrideInvoiceBuyer && (
              <>
                <DropdownSearch
                  label="Organisasjonsnavn"
                  options={units}
                  nameKey={'navn'}
                  handleSearch={(value: string) => {
                    setIsSearching(true);
                    handleSearch(value);
                  }}
                  isSearching={isSearching}
                  setSelectedValue={(e: any) => {
                    setFieldValue('invoiceOrgNumber', e.organisasjonsnummer);
                    setFieldValue('invoiceLegalName', e.navn);
                    setFieldValue('invoicePostalCode', e.forretningsadresse.postnummer);
                    setFieldValue('invoiceRegion', e.forretningsadresse.poststed);
                    if (e.forretningsadresse.adresse.length > 0) {
                      setFieldValue('invoiceAddress', e.forretningsadresse.adresse.join(', '));
                    }
                  }}
                  initialValue={{ navn: values.invoiceLegalName || '' }}
                  noResults={noResults}
                />
                {values?.invoiceOrgNumber && (
                  <>
                    <p>Organisasjonsnummer: {values.invoiceOrgNumber}</p>
                    <p>{values.invoiceAddress}</p>
                    <p>
                      {values.invoicePostalCode} {values.invoiceRegion}
                    </p>
                  </>
                )}
                <div className="space-y-2 pb-2 pt-5">
                  <p className="bodysmall">Integrasjon med regnskap</p>
                  <Input.Toggle
                    label="Send som EHF"
                    value={values?.deliverByEhf || false}
                    onChange={() => setFieldValue('deliverByEhf', !values.deliverByEhf)}
                  />
                  <Input.Toggle
                    label="Send som e-post"
                    value={values?.deliverByEmail || false}
                    onChange={() => setFieldValue('deliverByEmail', !values.deliverByEmail)}
                  />
                  {values?.deliverByEmail && (
                    <Input.Text
                      label="E-post (for eksempel til fakturamottak eller regnskapsfører)"
                      value={values?.deliveryEmail || ''}
                      onChange={handleChange}
                      error={errors.deliveryEmail as any}
                      onBlur={handleBlur}
                      name="deliveryEmail"
                    />
                  )}
                </div>
              </>
            )}
            <LargeButton backgroundColor="light" defaultStyle="white" onClick={handleSubmit} loading={isSubmitting}>
              Lagre
            </LargeButton>
          </div>
        </Form>
      )}
    </Formik>
  );
}
