import { useOrganization } from 'api/organizations/useOrganization';
import { useStoredUser } from 'context/UserContext';

export function useAccount() {
  const { user } = useStoredUser();
  const { data: organization } = useOrganization();

  const account = user.accounts!.find((account) => account.organizationId === organization?.id);

  return {
    data: account,
  };
}
