export const formatPhoneNumber = (phoneNumberString: string) => {
  let cleaned = ('' + phoneNumberString).replace(/[^\d+]/g, '');

  if (cleaned.startsWith('+47')) {
    if (cleaned.length > 11) {
      cleaned = cleaned.substring(0, 11);
    }
  } else if (cleaned.startsWith('47') && cleaned.length > 10) {
    // If it starts with "47" without the plus, adjust accordingly
    cleaned = cleaned.substring(0, 10);
  } else if (!cleaned.startsWith('+') && !cleaned.startsWith('47') && cleaned.length > 8) {
    // If it's just the number part without country code
    cleaned = cleaned.substring(0, 8);
  }

  // Adjust the regex to allow for partial matches of the phone number
  const match = cleaned.match(/^(\+?47)?(\d{0,3})?(\d{0,2})?(\d{0,3})?$/);

  let intlCode = '';
  let part1 = '';
  let part2 = '';
  let part3 = '';

  if (match) {
    intlCode = match[1] ? '+47 ' : '';
    part1 = match[2] ? match[2] : '';
    part2 = match[3] ? match[3] : '';
    part3 = match[4] ? match[4] : '';
  }

  let formatted = [intlCode, part1, part2, part3].filter(Boolean).join(' ');

  formatted = formatted.replace(/\s+/g, ' ');

  return formatted.length > 0 ? formatted : cleaned;
};

export const formatAccountNumber = (accountNumberString: string): string => {
  // Remove any non-digit characters
  const cleaned = ('' + accountNumberString).replace(/\D/g, '');

  // Match the string against a flexible pattern
  const match = cleaned.match(/(\d{1,4})?(\d{0,2})?(\d{0,5})?/);

  // Variables to hold the parts of the account number
  let part1 = '';
  let part2 = '';
  let part3 = '';

  // If there's a match, assign the matched groups to the variables
  if (match) {
    part1 = match[1] ? match[1] : '';
    part2 = match[2] ? match[2] : '';
    part3 = match[3] ? match[3] : '';
  }

  // Build the formatted account number with spaces
  let formatted = [part1, part2, part3].filter(Boolean).join(' ');

  // Replace consecutive spaces with a single space (in case some parts are missing)
  formatted = formatted.replace(/\s+/g, ' ');

  // Return the formatted string or the original input if it's completely invalid
  return formatted.length > 0 ? formatted : accountNumberString;
};

export const handlePositionChange = (event: React.ChangeEvent<HTMLInputElement>, formattedString: string) => {
  const inputElement = event.target;
  const originalValue = inputElement.value;
  const originalPosition = inputElement.selectionStart;
  const selectionEnd = inputElement.selectionEnd;
  const isAtEnd = originalPosition === originalValue.length; // Check if editing at the end

  // Check if more than one character is selected
  const isMultipleCharsSelected =
    selectionEnd !== null && originalPosition !== null && selectionEnd - originalPosition > 0;

  // Determine if a character was added or removed
  const lengthDifference = formattedString.length - originalValue.length;

  // Set the input value to the formatted account number
  inputElement.value = formattedString.length > 0 ? formattedString : originalValue;

  if (originalPosition !== null) {
    let newPosition = originalPosition;

    // Adjust the cursor position based on the user's action and position
    if (lengthDifference > 0) {
      // Adding a character
      newPosition = originalPosition + lengthDifference - 1;
    } else if (lengthDifference < 0) {
      // Removing a character or characters
      newPosition = originalPosition + lengthDifference + (isAtEnd ? 0 : 1);

      // Adjust for multiple character selection
      if (isMultipleCharsSelected) {
        newPosition = originalPosition - (originalPosition - selectionEnd) + lengthDifference;
      }
    }

    // Special handling to keep the cursor at the end if editing at the end
    if (isAtEnd) {
      newPosition = formattedString.length + 2;
    }

    // Asynchronously set the cursor position to avoid conflicts with React's rendering cycle
    setTimeout(() => {
      inputElement.setSelectionRange(newPosition, newPosition);
    }, 0);
  }
};

export const formatAmount = (num: string | number, formatRealTime?: boolean): string => {
  num = num.toString();
  // Determine if the original input had a comma
  const inputHadComma = num.includes(',') || num.includes('.');

  const number = parseFloat(num.replace(/ /g, '').replace(/,/g, '.'));
  if (isNaN(number)) {
    return formatRealTime ? '' : '0';
  }

  // Round the number to two decimal places first to handle the edge cases
  const roundedNum = Math.round(number * 100) / 100;

  // Convert the number to a string for splitting
  let numString = roundedNum.toString();

  // Determine how many decimal places are present
  let decimalPlaces = 0;
  if (numString.includes('.')) {
    decimalPlaces = numString.split('.')[1].length;
  }

  // Depending on the decimal places, format the number string accordingly
  if (decimalPlaces > 0) {
    // If there are decimals, ensure they are correctly rounded
    numString = roundedNum.toFixed(decimalPlaces);
  }

  const parts = numString.split('.');
  let formattedString = '';
  let counter = 0;

  // Format the integer part with spaces
  for (let i = parts[0].length - 1; i >= 0; i--) {
    formattedString = parts[0][i] + formattedString;
    counter++;
    if (counter === 3 && i !== 0) {
      formattedString = ' ' + formattedString;
      counter = 0;
    }
  }

  // Append the decimal part if it exists, or a comma if in real-time format and the input had a comma
  if (parts.length > 1) {
    formattedString += ',' + parts[1].substring(0, 2); // Ensure no more than 2 decimal places
  } else if (formatRealTime && inputHadComma) {
    formattedString += ',';
  }

  return formattedString;
};

export const formatOrgNumber = (value: string): string => {
  let cleaned = value.replace(/\D/g, '');

  if (cleaned.length > 9) {
    cleaned = cleaned.substring(0, 9);
  }

  const match = cleaned.match(/(\d{1,3})?(\d{0,3})?(\d{0,3})?/);

  let part1 = '';
  let part2 = '';
  let part3 = '';

  if (match) {
    part1 = match[1] ? match[1] : '';
    part2 = match[2] ? ' ' + match[2] : '';
    part3 = match[3] ? ' ' + match[3] : '';
  }

  const formatted = (part1 + part2 + part3).trim();

  return formatted.length > 0 ? formatted : cleaned;
};

export const formatPaymentMethodType = (type: string): string => {
  switch (type) {
    case 'CARD': {
      return 'Privat';
    }
    case 'COMPANY_CARD': {
      return 'Firmakort';
    }
    case 'INVOICE': {
      return 'Faktura';
    }
    default: {
      return type;
    }
  }
};
