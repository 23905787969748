import { useUser } from 'api/users/useUser';
import { VippsButton } from 'components/buttons/VippsButton';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import { useTheme } from 'context/theme';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { InviteJoin } from './InviteJoin';

export default function InviteJoinLogin() {
  const { data: user, loggedOut, isLoading } = useUser(false);
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const inviteToken = searchParams.get('t');

  useEffect(() => {
    if (inviteToken === null) {
      setSearchParams({ t: localStorage.getItem('inviteToken') || 'null' });
    } else {
      localStorage.setItem('inviteToken', inviteToken!);
    }
  }, []);

  if (isLoading) {
    return (
      <div className="centered h-screen" style={{ backgroundColor: theme.background.accent }}>
        <FullscreenLoading />
      </div>
    );
  }

  if (inviteToken === 'null') {
    return (
      <div className="centered h-screen overflow-y-auto" style={{ backgroundColor: theme.background.accent }}>
        <div className="centered relative h-full w-full max-w-[1500px] flex-col space-y-3">Missing token</div>
      </div>
    );
  }

  if (!user || loggedOut) {
    return (
      <div className="centered h-screen overflow-y-auto" style={{ backgroundColor: theme.background.accent }}>
        <div className="centered relative h-full w-full max-w-[1500px] flex-col space-y-3">
          <h1 className="bigheading text-center" style={{ color: theme.label.primary }}>
            Utleggsappen
          </h1>
          <VippsButton href={process.env.REACT_APP_VIPPS_LOGIN_URI + '/invitasjon'} />
        </div>
      </div>
    );
  } else {
    return <InviteJoin />;
  }
}
