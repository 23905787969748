import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { Line } from 'assets/svgcomponents/Line';
import { useTheme } from 'context/theme';
import { Fragment, MouseEventHandler, useRef, useState } from 'react';
import useMeasure from 'react-use-measure';
import { cn } from 'utils/className';

type DropdownCheckboxProps = {
  label?: string;
  description?: string;
  disabled?: boolean;
  options: { id: string; title: string }[];
  selectedOption: { id: string; title: string };
  onSelectOption: (option: { id: string; title: string }) => void;
};

export default function Dropdown({
  label,
  description,
  disabled = false,
  options,
  selectedOption,
  onSelectOption,
}: Readonly<DropdownCheckboxProps>) {
  const theme = useTheme();

  const [accordionOpen, setAccordionOpen] = useState<string>('');
  const [activeOption, setActiveOption] = useState<number | null>(null);

  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const [ref, bounds] = useMeasure();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      const newActiveOption =
        e.key === 'ArrowDown'
          ? ((activeOption ?? -1) + 1) % options.length
          : ((activeOption ?? 1) + options.length - 1) % options.length;
      e.preventDefault();
      setAccordionOpen('content');
      setActiveOption(options.length === 0 ? null : newActiveOption);
      const scrollPosition = scrollAreaRef.current?.scrollTop ?? 0;
      const newScrollPosition =
        scrollPosition > newActiveOption * 55.33
          ? newActiveOption * 55.33 // Nytt element over viewport
          : newActiveOption * 55.33 > scrollPosition + 55.33 * 4
            ? (newActiveOption - 4) * 55.33 // Nytt element under viewport
            : scrollPosition; // Nytt element innenfor viewport
      scrollAreaRef.current?.scrollTo({ top: newScrollPosition, behavior: 'smooth' });
    } else if (e.key === 'Enter' || e.key === ' ') {
      if (activeOption !== null && accordionOpen === 'content') {
        e.preventDefault();
        onSelectOption(options[activeOption]);
        setAccordionOpen((prev) => (prev === 'content' ? '' : 'content'));
      } else {
        setAccordionOpen((prev) => (prev === 'content' ? '' : 'content'));
      }
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setActiveOption(null);
      setAccordionOpen('');
    }
  };

  return (
    <div className={cn('flex flex-col gap-y-1', disabled && 'opacity-50')} style={{ height: `${bounds.height}px` }}>
      {label && <h2 className="smalltextbold">{label}</h2>}
      {description && <p className="caption">{description}</p>}
      <div className="flex flex-col gap-y-2.5">
        <Accordion.Root
          className={cn(
            'absolute w-40 overflow-hidden rounded-small ring-v2-purple-500 focus-within:relative focus-within:ring',
          )}
          style={{ backgroundColor: theme.background.primary }}
          type="single"
          defaultValue="item-1"
          collapsible
          value={accordionOpen && !disabled ? 'content' : ''}
          disabled={disabled}
          onKeyDown={handleKeyDown}
          onBlur={(e) => {
            if (!e.currentTarget.contains(e.relatedTarget as Node)) {
              setActiveOption(null);
              setAccordionOpen('');
            }
          }}>
          <Accordion.Item
            className="mt-px overflow-hidden border first:mt-0 first:rounded-t-small last:rounded-b-small focus-within:border-0"
            value="content"
            style={{
              borderColor: theme.label.primary,
            }}>
            <Accordion.Header className="flex" ref={ref}>
              <Accordion.Trigger
                className="group flex h-[45px] flex-1 cursor-default items-center justify-end px-5 text-[15px] leading-none outline-none"
                style={{ boxShadow: `0 2px 0 ${theme.base.accent}` }}
                onMouseDown={() => {
                  setAccordionOpen((prev) => (prev === 'content' ? '' : 'content'));
                }}>
                <span className="flex-1">{selectedOption.title}</span>
                <ChevronDownIcon
                  className="transition-transform group-data-[state=open]:rotate-180"
                  style={{ color: theme.label.primary }}
                  aria-hidden
                />
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content
              className="inputtext relative z-10 overflow-hidden transition-all data-[state=closed]:animate-slideUp data-[state=open]:animate-slideDown"
              style={{ height: `${Math.min(options.length, 5) * 55.33}px` }}>
              <ScrollArea.Root
                className="w-full overflow-hidden transition-all"
                style={{
                  height: `${Math.min(options.length, 5) * 55.33}px`,
                }}>
                <ScrollArea.Viewport className="mt-0.5 h-full w-full" ref={scrollAreaRef}>
                  <div role="menu">
                    {options.map((option, index) => (
                      <Fragment key={option.id}>
                        <option
                          aria-selected={activeOption === index}
                          tabIndex={-1}
                          className={cn(
                            'flex w-full cursor-default px-4 py-4',
                            activeOption === index ? 'bg-v2-purple-200' : 'bg-v2-white-950',
                          )}
                          onClick={
                            (() => {
                              onSelectOption(option);
                              setAccordionOpen('');
                            }) as MouseEventHandler<HTMLOptionElement>
                          }
                          onMouseEnter={() => {
                            setActiveOption(index);
                          }}>
                          {option.title}
                        </option>
                        <Line stroke={theme.base.accent} className="h-0.5 last:hidden" />
                      </Fragment>
                    ))}
                  </div>
                </ScrollArea.Viewport>
                <ScrollArea.Scrollbar
                  className="mt-0.5 flex w-2.5 touch-none select-none bg-zinc-300 p-0.5 transition-colors ease-in-out hover:bg-zinc-400"
                  orientation="vertical">
                  <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-zinc-500 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
                </ScrollArea.Scrollbar>
              </ScrollArea.Root>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
      </div>
    </div>
  );
}
