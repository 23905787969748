export function validateOrgNumber(orgNumber: string) {
  // Remove any hyphens and spaces from the input
  orgNumber = orgNumber.replace(/[-\s]/g, '');

  // Check if the input is a 9-digit number
  if (!/^\d{9}$/.test(orgNumber)) {
    return false;
  }

  // Convert the string into an array of digits
  const digits = orgNumber.split('').map(Number);

  // Calculate the check digit
  let checkDigit =
    11 -
    ((3 * digits[0] +
      2 * digits[1] +
      7 * digits[2] +
      6 * digits[3] +
      5 * digits[4] +
      4 * digits[5] +
      3 * digits[6] +
      2 * digits[7]) %
      11);

  // Handle special cases for check digit
  if (checkDigit === 11) {
    checkDigit = 0;
  } else if (checkDigit === 10) {
    return false;
  }

  // Validate the check digit
  if (digits[8] === checkDigit) {
    return true;
  }

  return false;
}
