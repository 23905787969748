import { Department, Project } from '__generated__/UtleggsappenApi';
import { api } from 'utils/fetch-wrapper';

const serviceUrl = '/departments';

export const departmentService = {
  getById,
  create,
  update,
  remove,
  connectAccounts,
  disconnectAccounts,
  addProjects,
  removeProjects,
};

function getById(departmentId: string) {
  return api.get(`${serviceUrl}/${departmentId}`) as Promise<Department & { projects: Project[] }>;
}

function create(department: Omit<Partial<Department>, 'organizationId'> & { organizationId: string }) {
  return api.post(`${serviceUrl}`, department) as Promise<Department>;
}

function update(departmentId: string, department: Partial<Department>) {
  return api.patch(`${serviceUrl}/${departmentId}`, department);
}

function remove(departmentId: string) {
  return api.delete(`${serviceUrl}/${departmentId}`);
}

function connectAccounts(departmentId: string, accountIds: string[]) {
  return api.post(`${serviceUrl}/${departmentId}/accounts/connect`, { accountIds });
}

function disconnectAccounts(departmentId: string, accountIds: string[]) {
  return api.post(`${serviceUrl}/${departmentId}/accounts/disconnect`, { accountIds });
}

function addProjects(departmentId: string, projects: Partial<Project>[]) {
  return api.post(`${serviceUrl}/${departmentId}/projects/add`, projects);
}

function removeProjects(departmentId: string, projectIds: string[]) {
  return api.post(`${serviceUrl}/${departmentId}/projects/remove`, { projectIds });
}
