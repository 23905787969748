export function Edit({ className, fill }: { className?: string; fill?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" fill={fill} className={className}>
      <path
        d="M14.2069 5.0861L11.4144 2.29297C11.3215 2.20009 11.2113 2.12641 11.0899 2.07614C10.9686 2.02587 10.8385 2 10.7072 2C10.5759 2 10.4458 2.02587 10.3245 2.07614C10.2031 2.12641 10.0929 2.20009 10 2.29297L2.29313 10.0005C2.19987 10.093 2.12593 10.2031 2.0756 10.3245C2.02528 10.4458 1.99959 10.576 2.00001 10.7073V13.5005C2.00001 13.7657 2.10536 14.02 2.2929 14.2076C2.48043 14.3951 2.73479 14.5005 3.00001 14.5005H5.79313C5.9245 14.5009 6.05464 14.4752 6.17599 14.4249C6.29735 14.3746 6.40748 14.3006 6.50001 14.2073L14.2069 6.50047C14.2998 6.40761 14.3734 6.29736 14.4237 6.17602C14.474 6.05468 14.4999 5.92463 14.4999 5.79329C14.4999 5.66195 14.474 5.53189 14.4237 5.41055C14.3734 5.28921 14.2998 5.17896 14.2069 5.0861ZM5.79313 13.5005H3.00001V10.7073L8.50001 5.20735L11.2931 8.00047L5.79313 13.5005ZM12 7.29297L9.20688 4.50047L10.7069 3.00047L13.5 5.79297L12 7.29297Z"
        fill={fill}
        fillOpacity="1"
      />
    </svg>
  );
}
