import { LoadingIndicator } from 'components/layout/LoadingIndicator';
import { useTheme } from 'context/theme';
import { AnimatePresence } from 'framer-motion';
import { createRef, useEffect, useState } from 'react';
import { cn } from 'utils/className';

export function DropdownSearch({
  options,
  nameKey,
  handleSearch,
  setSelectedValue,
  isSearching,
  placeholder,
  initialValue,
  noResults,
  label,
}: {
  options: any;
  nameKey: string;
  handleSearch: any;
  setSelectedValue: any;
  isSearching: boolean;
  placeholder?: string;
  initialValue?: any;
  noResults?: boolean;
  label?: string;
}) {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState<string | null>(initialValue ? initialValue[nameKey] : null);
  const [isSelecting, setIsSelecting] = useState(false);

  const scrollRef = createRef<HTMLUListElement>();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (scrollRef.current && !scrollRef.current.contains(event.target)) {
        setIsSelecting(false);
      }
    }
    function handleEscape(event: any) {
      if (event.key === 'Escape') {
        setIsSelecting(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [scrollRef, setIsSelecting]);

  return (
    <div className="relative w-full">
      {label && (
        <p className="inputlabel pb-2" style={{ color: theme.label.primary }}>
          {label}
        </p>
      )}
      <div className="relative">
        <input
          placeholder={placeholder}
          className="inputtext w-full rounded-md border-[1px] p-3 outline outline-0 focus:outline-2 focus:outline-v2-purple-400"
          style={{
            borderColor: theme.border.border,
          }}
          value={(searchValue as string) || ''}
          onChange={async (e) => {
            setSearchValue(e.target.value);
            setIsSelecting(true);
            handleSearch(e.target.value);
            scrollRef.current && scrollRef.current!.scrollTo({ top: 0 });
          }}
        />
        {isSearching && (
          <div className="absolute right-0 top-1/2 -translate-y-1/2 pr-3">
            <LoadingIndicator size="xs" />
          </div>
        )}
      </div>
      <AnimatePresence mode="wait">
        {isSelecting && (
          <ul
            ref={scrollRef}
            className={cn(
              'absolute z-50 mt-2 max-h-[200px] w-full divide-y-[1px] divide-v2-purple-400/50 overflow-y-auto rounded-md',
              'scrollbar-thin scrollbar-track-transparent scrollbar-thumb-v2-purple-400/70 scrollbar-thumb-rounded-md',
            )}
            style={{ backgroundColor: theme.background.primary }}>
            {!noResults ? (
              options.map((option: any, index: number) => {
                return (
                  <li
                    key={option[nameKey] + index}
                    className="cursor-pointer px-3 py-2 first:rounded-t-md last:rounded-b-md hover:bg-v2-purple-50"
                    onClick={() => {
                      setSelectedValue(option);
                      setSearchValue(option[nameKey]);
                      setIsSelecting(false);
                    }}>
                    {option[nameKey]}
                  </li>
                );
              })
            ) : (
              <li className="px-3 py-2 first:rounded-t-md last:rounded-b-md hover:bg-v2-purple-50">Ingen Resultater</li>
            )}
          </ul>
        )}
      </AnimatePresence>
    </div>
  );
}
