import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useOrganization } from 'api/organizations/useOrganization';
import BackButton from 'components/buttons/BackButton';
import { useTheme } from 'context/theme';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

loadStripe.setLoadParameters({ advancedFraudSignals: false });
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY!, { locale: 'no' });

export default function Checkout() {
  const theme = useTheme();
  const { data: organization } = useOrganization();

  const [clientSecret, setClientSecret] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const priceId = searchParams.get('priceId');

  useEffect(() => {
    // Create a Checkout Session as soon as the page loads
    // api.subscriptions
    //   .checkout(
    //     organization!.id,
    //     { redirect: `${organization?.handle}/innstillinger/abonnement/suksess` },
    //     { priceId: priceId! },
    //   )
    //   .then((res) => {
    //     setClientSecret(res.data.client_secret);
    //   });
  }, [organization]);

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex flex-col justify-between gap-y-5 pt-5">
        <BackButton />
        <h1 className="bigheading" style={{ color: theme.label.primary }}>
          Abonner
        </h1>
      </div>
      <div
        id="checkout"
        className="max-w-[1100px] rounded-large p-10"
        style={{ backgroundColor: theme.background.primary }}>
        {clientSecret && (
          <EmbeddedCheckoutProvider stripe={stripePromise as any} options={{ clientSecret }}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </div>
  );
}
