import { api } from 'api/api';
import BackButton from 'components/buttons/BackButton';
import { LargeButton } from 'components/buttons/LargeButton';
import Input from 'components/input-field/Input';
import { toast } from 'components/toast/Toast';
import { useStoredOrg } from 'context/OrganizationContext';
import { useTheme } from 'context/theme';
import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { organizationService } from 'services/organizationService';
import * as Yup from 'yup';

export default function CustomizationSettings() {
  const theme = useTheme();

  const [submitAttempted, setSubmitAttempted] = useState(false);

  const { organization, setOrganization } = useStoredOrg();

  const [isElmaRegistered, setIsElmaRegistered] = useState<boolean | null>(null);

  useEffect(() => {
    const checkElma = async () => {
      const status = await api.organizations.getEhfStatus(organization.id);
      setIsElmaRegistered(status.data);
    };

    if (organization) {
      checkElma();
    }
  }, []);

  if (!organization) {
    return (
      <div className="flex flex-grow flex-col space-y-8">
        <div className="border-b pb-8"></div>
      </div>
    );
  }

  return (
    <div className="flex w-[500px] flex-col justify-between gap-y-5 pt-5" style={{ color: theme.label.primary }}>
      <BackButton />
      <h1 className="bigheading">Administrer</h1>
      {organization.subscription?.subscriptionTier.approvalFlow && (
        <div className="flex flex-col gap-y-4">
          <Formik
            initialValues={{
              active: organization.isApprovalFlowActive,
            }}
            onSubmit={async (values) => {
              await organizationService.update(organization.id, {
                isApprovalFlowActive: values.active,
              });
              setOrganization();
            }}>
            {({ values, setFieldValue }) => (
              <Form>
                <Input.Toggle
                  type="submit"
                  value={values.active}
                  onChange={(value) => {
                    setFieldValue('active', value);
                  }}
                  label="Godkjenningsflyt"
                  description="Krev godkjenning på utlegg og reiseregninger"
                />
              </Form>
            )}
          </Formik>
          <Formik
            initialValues={{
              active: organization.isTravelActive,
            }}
            onSubmit={async (values) => {
              await organizationService.update(organization.id, {
                isTravelActive: values.active,
              });
              setOrganization();
            }}>
            {({ values, setFieldValue }) => (
              <Form>
                <Input.Toggle
                  type="submit"
                  value={values.active}
                  onChange={(value) => {
                    setFieldValue('active', value);
                  }}
                  label="Reiseregning"
                  description="Aktiver reiseregninger i Utleggsappen"
                />
              </Form>
            )}
          </Formik>
          <Formik
            initialValues={{
              active: organization.isDriveActive,
            }}
            onSubmit={async (values) => {
              await organizationService.update(organization.id, {
                isDriveActive: values.active,
              });
              setOrganization();
            }}>
            {({ values, setFieldValue }) => (
              <Form>
                <Input.Toggle
                  type="submit"
                  value={values.active}
                  onChange={(value) => {
                    setFieldValue('active', value);
                  }}
                  label="Kjøregodtgjørelse"
                  description="Aktiver kjøregodtgjørelser i Utleggsappen"
                />
              </Form>
            )}
          </Formik>
        </div>
      )}
      {organization.subscription?.subscriptionTier.projectsLimit &&
        organization.subscription?.subscriptionTier.projectsLimit > 0 && (
          <div className="flex flex-col gap-y-4 pt-7">
            <h2 className="normalheading">Obligatoriske felt</h2>
            <Formik
              initialValues={{
                active: organization.isProjectRequired,
              }}
              onSubmit={async (values) => {
                await organizationService.update(organization.id, {
                  isProjectRequired: values.active,
                });
                setOrganization();
              }}>
              {({ values, setFieldValue }) => (
                <Form>
                  <Input.Toggle
                    type="submit"
                    value={values.active}
                    onChange={(value) => {
                      setFieldValue('active', value);
                    }}
                    label="Prosjekt"
                    description="Velg om feltet «Prosjekt» skal være obligatorisk eller ikke"
                  />
                </Form>
              )}
            </Formik>
          </div>
        )}
      <div className="flex flex-col gap-y-4 pt-7">
        <h2 className="normalheading">Utleggsskjema</h2>
        {organization.subscription?.subscriptionTier.ehf && (
          <>
            <Formik
              initialValues={{
                active: organization.invoiceAsEhf,
              }}
              onSubmit={async (values) => {
                await organizationService.update(organization.id, {
                  invoiceAsEhf: values.active,
                });
                setOrganization();
              }}>
              {({ values, setFieldValue }) => {
                let description: string | JSX.Element;
                if (values.active) {
                  description = `Skjema sendes til ${organization.legalName ?? organization.displayName}`;
                } else if (isElmaRegistered === null) {
                  description = <span>&nbsp;</span>;
                } else if (!isElmaRegistered) {
                  description = 'Organisasjonen må være ELMA-registrert for å motta EHF-fakturaer';
                } else {
                  description = `Skjema sendes til ${organization.legalName ?? organization.displayName}`;
                }
                return (
                  <Form>
                    <Input.Toggle
                      type="submit"
                      value={values.active}
                      onChange={(value) => {
                        setFieldValue('active', value);
                      }}
                      label="EHF"
                      description={description}
                      disabled={!(values.active || isElmaRegistered)}
                    />
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
        <Formik
          initialValues={{ invoiceEmail: organization.invoiceEmail, invoiceAsPdf: !!organization.invoiceEmail }}
          onSubmit={async ({ invoiceEmail }, { setSubmitting }) => {
            try {
              await organizationService.update(organization.id, {
                invoiceEmail: invoiceEmail || (null as any),
              });
              setOrganization();
              !!invoiceEmail && toast.success({ description: 'E-post lagret' });
            } catch (error) {
              !!invoiceEmail && toast.error({ description: 'Kunne ikke lagre e-post' });
            }
            setSubmitting(false);
          }}
          validationSchema={Yup.object({
            invoiceEmail: Yup.string().email('Ugyldig e-postadresse').required('Påkrevd'),
          })}>
          {({ values, handleChange, handleSubmit, isSubmitting, errors, setValues, submitForm, setFieldValue }) => (
            <Form className="flex flex-col gap-y-4" onSubmit={handleSubmit}>
              {organization.subscription?.subscriptionTier.dashboard && (
                <Input.Toggle
                  value={values.invoiceAsPdf}
                  onChange={async (value) => {
                    if (!value) {
                      setValues({ invoiceEmail: '', invoiceAsPdf: false });
                      setSubmitAttempted(false);
                      submitForm();
                    } else {
                      setFieldValue('invoiceAsPdf', true);
                    }
                  }}
                  label="PDF til e-post"
                  description="Send skjema direkte til fakturamottaket eller til en e-postadresse"
                />
              )}
              <motion.div
                className="overflow-hidden"
                initial={{ height: 0 }}
                animate={{
                  height: values.invoiceAsPdf ? 'fit-content' : 0,
                }}
                transition={{
                  duration: 0.15,
                  curve: 'easeInOut',
                }}>
                <div className="flex flex-row items-start justify-between gap-x-4 pb-4">
                  <div className="flex-grow">
                    <Input.Text
                      label="E-post - fakturamottak"
                      onChange={(value) => handleChange('invoiceEmail')(value)}
                      value={values.invoiceEmail ?? ''}
                      error={submitAttempted ? errors.invoiceEmail : undefined}
                    />
                  </div>
                  <LargeButton
                    disabled={!values.invoiceEmail}
                    backgroundColor="light"
                    defaultStyle="indigo"
                    className="mt-[25px] h-fit"
                    type="submit"
                    loading={isSubmitting}
                    onClick={() => setSubmitAttempted(true)}>
                    Lagre
                  </LargeButton>
                </div>
              </motion.div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
