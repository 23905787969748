/* eslint-disable indent */
import * as Tabs from '@radix-ui/react-tabs';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { Diet, Report } from '__generated__/UtleggsappenApi';
import { useDepartments } from 'api/departments/useDepartments';
import { List } from 'components/lists/List';
import { ListSmall } from 'components/lists/ListSmall';
import { Directions } from 'components/maps/Directions';
import { useTheme } from 'context/theme';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from 'utils/format-currency';
import { accommodationTypes } from 'utils/staticTypes';

export function Overview({ data }: { data: Report }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { data: departments } = useDepartments();

  const departmentTitle =
    departments?.find((department) => department.id === data.departmentId)?.title || 'Ikke oppgitt';

  const expenses = data.expenses!.filter((expense) => expense.type === 'DEFAULT');
  const diets = data.expenses!.filter((expense) => expense.type === 'DIET');
  //const drives = data.expenses!.filter((expense) => expense.type === 'DRIVE');

  // An expense with type "DIET" will have an array of diets. Count all diets in all expenses
  const dietsTotal = diets.reduce((acc, expense) => acc + (expense.diets ? expense.diets.length : 0), 0);

  const rateLevels = [
    {
      id: 'TAXABLE',
      title: 'Statens satser',
    },
    {
      id: 'TAX_FREE',
      title: 'Trekkfrie satser',
    },
    {
      id: 'NONE',
      title: 'Ingen',
    },
  ];

  return (
    <Tabs.Content
      className="h-full max-h-full flex-1 overflow-hidden"
      value="oversikt"
      style={{ backgroundColor: theme.background.primary }}>
      <div className="flex h-full">
        <div className="flex h-full w-1/2 gap-32 overflow-y-auto px-6 pt-6">
          <List>
            <List.Item
              title="Dato for innsendelse"
              value={new Date(data?.createdAt!).toLocaleDateString('no-NO', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              })}
            />
            {data?.type === 'TRAVEL' && (
              <>
                <List.Item
                  title="Dato for reise"
                  value={`${new Date(data?.travel?.fromDateTime!).toLocaleTimeString('no-NO', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })} - ${new Date(data?.travel?.toDateTime!).toLocaleTimeString('no-NO', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}`}
                />
                <List.Item
                  title="Reiserute"
                  value={`${data.travel?.fromLocationName} - ${data.travel?.toLocationName}`}
                />
              </>
            )}
            <List.Item title="Avdeling" value={departmentTitle} />
            {data?.type === 'TRAVEL' && (
              <>
                {data.projectId && (
                  <List.Item
                    title="Prosjekt"
                    value={
                      departments
                        ?.find((department) => department.id === data.department?.id)
                        ?.projects?.find((project) => project.id === data.projectId)?.title || 'Ikke oppgitt'
                    }
                  />
                )}
                <List.Item title="Utbetalt forskudd" value={`${data.travel?.advanceAmount || '0'} kr`} />
                <List.Item
                  title="Diett"
                  value={
                    accommodationTypes.find((type: any) => type.id === data.travel?.typeOfAccommodation!)?.title ||
                    'Ikke oppgitt'
                  }
                />
                <List.Item
                  title="Sats"
                  value={rateLevels.find((rate) => rate.id === data.travel?.rateLevel)?.title || ''}
                />
                <List.Item title="Formål" value={data.travel?.purpose!} />
              </>
            )}
            {data?.type === 'EXPENSEGROUP' && (
              <>
                <List.Item title="Tittel" value={data.title || 'Ikke oppgitt'} />
                <List.Item title="Formål" value={data.description || 'Ikke oppgitt'} />
              </>
            )}
            <List.Item title="Sum" value={formatCurrency({ amount: data?.amount, code: 'NOK' })} />
          </List>
        </div>
        <div className="max-h-full w-1/2 overflow-y-auto p-6">
          {data?.type === 'TRAVEL' && (
            <div className="h-1/2 w-full justify-center pb-3">
              <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}>
                <Map disableDefaultUI={true} className="h-full w-full rounded-large">
                  <Directions origin={data.travel?.fromLocationName!} destination={data.travel?.toLocationName!} />
                </Map>
              </APIProvider>
            </div>
          )}
          {expenses.length > 0 && (
            <ListSmall title={`Totalt ${data?.expenses!.length} utlegg`}>
              {data
                ?.expenses!.filter((expense) => expense.type === 'DEFAULT')
                .map((expense, index) => {
                  return (
                    <button className="w-full text-left" onClick={() => navigate(`#${expense.id}`)} key={expense.id}>
                      <ListSmall.Item
                        enableHover
                        key={expense.id}
                        title={`Utlegg ${index + 1}`}
                        value={new Date(expense.createdAt).toLocaleDateString('no-NO', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                        badge={`${formatCurrency({ amount: expense?.amount, code: expense.currency })}`}
                      />
                    </button>
                  );
                })}
              {data
                ?.expenses!.filter((expense) => expense.type === 'DRIVE')
                .map((expense, index) => {
                  return (
                    <button className="w-full text-left" onClick={() => navigate(`#${expense.id}`)} key={expense.id}>
                      <ListSmall.Item
                        enableHover
                        key={expense.id}
                        title={`Kjøregodtgjørelse ${index + 1}`}
                        value={new Date(expense.createdAt).toLocaleDateString('no-NO', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                        badge={expense?.amount + ' kr'}
                      />
                    </button>
                  );
                })}
            </ListSmall>
          )}
          {diets.length > 0 && (
            <ListSmall title={`Diett for ${dietsTotal} dag${dietsTotal > 1 ? 'er' : ''}`}>
              {data?.expenses!.map((expense) => {
                return expense?.diets
                  ?.sort((a: Diet, b: Diet) => {
                    return new Date(a.date).getTime() - new Date(b.date).getTime();
                  })
                  ?.map((diet: Diet) => {
                    return (
                      <button
                        className="w-full text-left"
                        onClick={() => navigate(`#${expense.id}`)}
                        key={expense.id + diet.id}>
                        <ListSmall.Item
                          enableHover
                          key={expense.id}
                          title={new Date(diet.date).toLocaleDateString('no-NO', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                          })}
                          value={
                            diet.breakfast && diet.lunch && diet.dinner
                              ? 'Alle måltider inkludert'
                              : [diet.breakfast && 'Frokost', diet.lunch && 'Lunsj', diet.dinner && 'Middag']
                                  .filter((v) => v)
                                  .join(' · ')
                          }
                          badge={diet?.amount + ' kr'}
                        />
                      </button>
                    );
                  });
              })}
            </ListSmall>
          )}
        </div>
      </div>
    </Tabs.Content>
  );
}
