interface ListProps {
  /**
   * The content of the list. Should be 'List.Item' components.
   */
  children: React.ReactNode;
}

export function List({ children }: ListProps) {
  return <ul className="flex-1 divide-y divide-[##D7CFF0]">{children}</ul>;
}

export function Item({ title, value }: { title: string; value: string }) {
  return (
    <li className="flex flex-col py-3">
      <p className="captionbold">{title}</p>
      <p className="smalltext">{value}</p>
    </li>
  );
}

List.Item = Item;
