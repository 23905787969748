import { cn } from 'utils/className';
import {
  defaultToastClassName,
  errorToastClassName,
  infoToastClassName,
  successToastClassName,
  toastBodyClassName,
  toastClassName,
  warningToastClassName,
} from './Toast';
import { CircleCheck } from 'assets/svgcomponents/CircleCheck';
import { CircleInfo } from 'assets/svgcomponents/CircleInfo';
import { CircleExclamation } from 'assets/svgcomponents/CircleExclamation';

type StaticToastProps = {
  type?: 'success' | 'info' | 'warning' | 'error' | 'default';
  description: string;
  title?: string;
  /**
   * Function to call when the toast is clicked. If provided, the toast will have hover effects.
   */
  onClick?: () => void;
};

export function StaticToast({ type = 'default', description, title, onClick }: StaticToastProps) {
  let typeClassName = '';
  let icon = null;

  switch (type) {
    case 'success':
      typeClassName = successToastClassName;
      icon = <CircleCheck />;
      break;
    case 'info':
      typeClassName = infoToastClassName;
      icon = <CircleInfo />;
      break;
    case 'warning':
      typeClassName = warningToastClassName;
      icon = <CircleExclamation />;
      break;
    case 'error':
      typeClassName = errorToastClassName;
      icon = <CircleExclamation />;
      break;
    default:
      typeClassName = defaultToastClassName;
      icon = null;
      break;
  }

  return (
    <button
      className={cn(toastClassName, typeClassName, 'w-full', onClick && 'hover:opacity-70')}
      onClick={onClick}
      disabled={!onClick}>
      <div className={cn(toastBodyClassName, 'flex space-x-1')}>
        {icon}
        <div className="space-y-[8px] text-left">
          {title && <p className="smalltextbold">{title}</p>}
          <p className="caption">{description}</p>
        </div>
      </div>
    </button>
  );
}
