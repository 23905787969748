import { api } from 'api/api';
import { useOrganization } from 'api/organizations/useOrganization';
import { Plus } from 'assets/svgcomponents/Plus';
import BackButton from 'components/buttons/BackButton';
import { LargeButton } from 'components/buttons/LargeButton';
import Input from 'components/input-field/Input';
import { LoadingIndicator } from 'components/layout/LoadingIndicator';
import { toast } from 'components/toast/Toast';
import { useStoredUser } from 'context/UserContext';
import { useTheme } from 'context/theme';
import { Form, Formik } from 'formik';
import { ChangeEvent, ReactNode, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { organizationService } from 'services/organizationService';
import { formatOrgNumber, handlePositionChange } from 'utils/format';
import { validateOrgNumber } from 'utils/validateOrgNumber';
import * as Yup from 'yup';

type FormValues = {
  legalName: string;
  displayName: string;
  orgNumber: string;
  address: string;
  postalCode: string;
  region: string;
};

export default function BusinessSettings() {
  const { user } = useStoredUser();
  const { data: organization, mutate: setOrganization } = useOrganization();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [changedValues, setChangedValues] = useState({
    legalName: true,
    displayName: true,
    orgNumber: true,
    invoiceEmail: true,
    address: true,
    postalCode: true,
    region: true,
    paymentTerms: true,
  });

  if (!organization || !user) return <></>;

  function handlePostalCodeChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: string) => void,
  ) {
    setChangedValues((prev) => ({ ...prev, postalCode: true }));
    const value = e.currentTarget.value.toString().replace(/ /g, '');
    if (value.length > 4) return;
    if (value.length === 0) setFieldValue('postalCode', '');
    if (!/^[0-9 ]+$/.test(value)) return;
    setFieldValue('postalCode', value);
  }

  function handleOrgNumberChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: string) => void,
  ) {
    setChangedValues((prev) => ({ ...prev, orgNumber: true }));
    const value = e.currentTarget.value.toString().replace(/ /g, '');

    if (formatOrgNumber(value) || value === '') {
      handlePositionChange(e, formatOrgNumber(value) || '');
      setFieldValue('orgNumber', formatOrgNumber(value) || '');
    } else return;
  }

  const handleSubmit = async (values: FormValues) => {
    setIsLoading(true);
    try {
      const tempOrgNumber = values.orgNumber.toString().replace(/ /g, '');
      const updateParams = {
        handle: organization.handle,
        orgNumber: tempOrgNumber,
        legalName: values.legalName,
        displayName: values.displayName,
        address: !values.address ? undefined : values.address,
        postalCode: !values.postalCode ? undefined : values.postalCode,
        region: !values.region ? undefined : values.region,
      };

      await api.organizations.update(organization.id, updateParams);
      setIsLoading(false);
      toast.success({ description: 'Endringer lagret' });
      setOrganization();
      navigate(-1);
    } catch (err) {
      setIsLoading(false);
      toast.error({ description: (err as string) || 'Noe gikk galt. Kontakt support om problemet vedvarer.' });
    }
  };

  return (
    <div className="flex flex-col space-y-5">
      <div className="flex flex-col justify-between gap-y-5 pt-5" style={{ color: theme.label.primary }}>
        <BackButton />
        <h1 className="bigheading">Rediger organisasjon</h1>
      </div>

      {organization.iconUrl ? (
        <UploadLogoButton
          icon={<img src={organization.iconUrl} className="h-full w-full object-cover" />}
          label="Endre logo"
        />
      ) : (
        <UploadLogoButton icon={<Plus />} label="Last opp logo" />
      )}
      <Formik
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await handleSubmit(values as FormValues);
          setSubmitting(false);
        }}
        initialValues={{
          orgNumber: organization?.orgNumber ? formatOrgNumber(organization.orgNumber) || '' : '',
          legalName: organization?.legalName || '',
          displayName: organization?.displayName || '',
          address: organization?.address || '',
          postalCode: organization?.postalCode || '',
          region: organization?.region || '',
        }}
        validationSchema={Yup.object({
          displayName: Yup.string().required('Påkrevd'),
          orgNumber: Yup.string()
            .test('orgnr', 'Ugyldig organisasjonsnummer', (val) => {
              return val === undefined || validateOrgNumber(val || '');
            })
            .required('Påkrevd'),
          address: Yup.string().required('Påkrevd'),
          postalCode: Yup.string()
            .test('len', 'Ugyldig postnummer', (val) => val?.toString().length === 4 || val === undefined)
            .required('Påkrevd'),
          region: Yup.string().required('Påkrevd'),
        })}>
        {({ values, errors, handleChange, setFieldValue, handleSubmit }) => {
          return (
            <Form className="flex flex-col gap-y-4" onSubmit={handleSubmit}>
              <Input.Text
                width="lg"
                label="Foretaksnavn"
                value={values.legalName}
                onChange={(e) => {
                  setChangedValues((prev) => ({ ...prev, legalName: true }));
                  handleChange('legalName')(e);
                }}
                error={errors.legalName}
                disabled={user.role > 1}
              />
              <Input.Text
                width="md"
                label="Organisasjonsnummer"
                value={values.orgNumber}
                onChange={(e) => handleOrgNumberChange(e, setFieldValue)}
                error={!changedValues.orgNumber ? errors.orgNumber : undefined}
                disabled={user.role > 1}
              />
              <Input.Text
                width="lg"
                label="Visningsnavn"
                value={values.displayName}
                onChange={(e) => {
                  setChangedValues((prev) => ({ ...prev, displayName: true }));
                  handleChange('displayName')(e);
                }}
                error={!changedValues.displayName ? errors.displayName : undefined}
              />
              <Input.Text
                width="lg"
                label="Adresse"
                value={values.address}
                onChange={(e) => {
                  setChangedValues((prev) => ({ ...prev, address: true }));
                  handleChange('address')(e);
                }}
                error={!changedValues.address ? errors.address : undefined}
              />
              <Input.Text
                width="md"
                label="Postnummer"
                value={values.postalCode}
                onChange={(e) => handlePostalCodeChange(e, setFieldValue)}
                error={!changedValues.postalCode ? errors.postalCode : undefined}
              />
              <Input.Text
                width="md"
                label="Poststed"
                value={values.region.toUpperCase()}
                onChange={(e) => {
                  setChangedValues((prev) => ({ ...prev, region: true }));
                  handleChange('region')(e);
                }}
                error={!changedValues.region ? errors.region : undefined}
              />
              <LargeButton
                loading={isLoading}
                backgroundColor="light"
                type="submit"
                defaultStyle="white"
                onClick={() => {
                  setChangedValues({
                    legalName: false,
                    displayName: false,
                    orgNumber: false,
                    invoiceEmail: false,
                    address: false,
                    postalCode: false,
                    region: false,
                    paymentTerms: false,
                  });
                }}>
                Lagre
              </LargeButton>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

function UploadLogoButton(props: { label: string; icon: ReactNode }) {
  const theme = useTheme();
  const hiddenFileInput = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const { data: organization, mutate: mutateOrganization } = useOrganization();

  const handleClick = (event: any) => {
    (hiddenFileInput.current as any).click();
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files![0];

    if (fileUploaded.size > 3 * 1000 * 1000) {
      toast.error({ description: 'Maks størrelse på logo er 3MB' });
      return;
    }

    setIsUploading(true);
    await organizationService.updateLogo(organization!.id, fileUploaded);
    await mutateOrganization();
    toast.success({ description: 'Logoen ble lagret' });
    setIsUploading(false);
  };
  return (
    <button className="centered w-fit flex-col" onClick={handleClick} disabled={isUploading}>
      <div
        style={{ backgroundColor: theme.background.primary, borderColor: theme.border.border }}
        className="centered flex h-[45px] w-[45px] flex-col overflow-hidden rounded-full border-[1px]">
        {isUploading ? <LoadingIndicator size="xs" /> : props.icon}
      </div>
      <p>{props.label}</p>
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        className="hidden"
        accept="image/png,image/jpeg"
      />
      <p className="caption">(Maks 3MB)</p>
    </button>
  );
}
